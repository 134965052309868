export function canCopyNodeStyle(node) {
    return true
}

export async function copyNodeStyle(nodes) {
    if (0 === nodes.length) {
        return
    }

    if (1 < nodes.length) {
        this.logger.warn('只能拷贝单一节点的样式，忽略除第一个节点外的其余节点', nodes)
    }

    const [node] = nodes

    const data = {
        map: this.data,
        style: node.data.style,
    }

    const json = JSON.stringify(data)
    await navigator.clipboard.writeText(json)
    this.logger.info('节点样式已拷贝到剪贴板', [node])
}
