import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    ssInsName: {
        name: () => '子系统实例名称',
        required: () => true,
    },

    ssInsDesc: {
        name: () => '子系统实例描述',
    },

    ssInsMemo: {
        name: () => '子系统实例备注',
    },

    ssInsPort: {
        name: () => '子系统端口',
    },

    ssInsStatus: {
        name: () => '运行状态',
    },

    ssInsBu: {
        name: () => '归属BU',
    },

    ssInsDm: {
        name: () => '开发负责人',
    },

    ssInsOm: {
        name: () => '运维负责人',
    },

    ssInsEnv: {
        name: () => '环境',
    },
})
