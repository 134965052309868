import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaFa.mjs'
import PropertiesPane from './PropertiesPaneFa.jsx'
import api from './apiFa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(FM)$/.test(type)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archFmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.GOLD,
            })
        },

        defaultChildType(map, node) {
            return 'FM'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FM'],
            ]
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archFmList'])
        },

        _fixChild(map, node, child) {
            return map.BizNode.FM._fixChild(map, node, child)
        },
    })
}
