import {css} from '@emotion/react'

import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons'

import {useMapContext} from '@/components/MindMap/index.mjs'

const LevelColor = {
    error: 'red',
    info: 'black',
    warn: 'orange',
}

const LevelIcon = {
    error: ExclamationCircleOutlined,
    info: InfoCircleOutlined,
    warn: WarningOutlined,
}

const cssMessage = css({
    display: 'flex',
    gap: 8,
})

export default function SystemMessage(props) {
    const map = useMapContext()
    const logs = map.useLogs()

    const lastLog = logs.find(
        ({level}) => 'debug' !== level
    )

    if (lastLog) {
        const {level, text, timestamp} = lastLog
        const color = LevelColor[level]
        const Icon = LevelIcon[level]

        return (
            <div
                css={cssMessage}
                style={{color}}
                {...props}
            >
                <Icon />
                <span>{text}</span>
                <span>{new Date(timestamp).toLocaleString()}</span>
            </div>
        )
    }
    else {
        return <div {...props}></div>
    }
}
