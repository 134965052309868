function appendChild() {
    const {
        commands: {canInsertChild, appendChild},
        selectedNodes,
    } = this

    const nodes = canInsertChild(selectedNodes)
    appendChild(nodes)
}

function prependChild() {
    const {
        commands: {canInsertChild, prependChild},
        selectedNodes,
    } = this

    const nodes = canInsertChild(selectedNodes)
    prependChild(nodes)
}

export default {
    'Shift+Tab': prependChild,
    'Tab': appendChild,
}
