import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectVm from '../VM/SelectVm.jsx'

export default function PropertiesControllerVm({
    className,
    style,
    codeProp = 'vmCode',
    disabled,
    hidden,
    idProp = 'vmId',
    label,
    labelSuffix,
    nameProp = 'vmName',
    readOnly,
    required,
    ssCodeProp = 'ssCode',
    ssIdProp = 'ssId',
    ssNameProp = 'ssName',
    ...props
}) {
    const [values, update] = useProperties()

    const {
        ssCode,
        [codeProp]: vmCode,
        [idProp]: vmId,
        [nameProp]: vmName,
    } = values

    const handleChange = vm => {
        if (vm) {
            const {
                ssCode,
                ssId,
                ssName,
                vmCode,
                vmId,
                vmName,
            } = vm

            update({
                [codeProp]: vmCode,
                [idProp]: vmId,
                [nameProp]: vmName,
                [ssCodeProp]: ssCode,
                [ssIdProp]: ssId,
                [ssNameProp]: ssName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectVm
                disabled={p.disabled}
                readOnly={p.readOnly}
                ssCode={ssCode}
                value={{vmCode, vmId, vmName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
