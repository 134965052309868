import {createShape} from '@/components/MindMap/index.mjs'

export const EllipseRectangle = (textRect, style) => {
    const bw = style.borderWidth
    const h = textRect.height
    const paddings = [bw, bw + h / 2, bw, bw + h / 2]

    const draw = (w, h) => `
        <path
            d="
                M ${h / 2} ${bw / 2}
                A ${h / 2 - bw / 2} ${h / 2 - bw / 2} 0 1 0 ${h / 2} ${h - bw / 2}
                H ${w - h / 2}
                A ${h / 2 - bw / 2} ${h / 2 - bw / 2} 0 1 0 ${w - h / 2} ${bw / 2}
                Z
            "
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}

export const RightEllipseRectangle = (textRect, style) => {
    const bw = style.borderWidth
    const h = textRect.height
    const paddings = [bw, bw + h / 2, bw, bw]

    const draw = (w, h) => `
        <path
            d="
                M ${bw / 2} ${bw / 2}
                H ${w - h / 2}
                A ${h / 2 - bw / 2} ${h / 2 - bw / 2} 0 1 1 ${w - h / 2} ${h - bw / 2}
                H ${bw / 2}
                Z
            "
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
