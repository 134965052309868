import {useEffect, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import History from '@/script/History.mjs'

export default () => {
    const refHistory = useRef(new History)
    const refIsChanging = useRef(false)
    const history = refHistory.current
    const refLastSelectedNodes = useRef(new Set)

    const extensions = () => ({
        get canRedoChange() {
            return history.cursor < history.end
        },

        get canUndoChange() {
            return - 1 < history.cursor
        },

        async redoChange() {
            if (! this.canRedoChange) {
                return
            }

            history.forward()
            const {redo: [actions, selectedNodes]} = history.peek()
            refIsChanging.current = true

            await this.execute(() => {
                for (const action of actions) {
                    action()
                }

                this.selectNodes(selectedNodes)
            })

            refIsChanging.current = false
        },

        async undoChange() {
            if (! this.canUndoChange) {
                return
            }

            const {undo: [actions, selectedNodes]} = history.back()
            refIsChanging.current = true

            await this.execute(() => {
                for (const action of actions) {
                    action()
                }

                this.selectNodes(selectedNodes)
            })

            refIsChanging.current = false
        },

        useChangeHistory() {
            const getHistoryStates = () => {
                const canUndo = this.canUndoChange
                const canRedo = this.canRedoChange
                return {canRedo, canUndo}
            }

            const [states, setStates] = useState(getHistoryStates)

            useEffect(
                () => {
                    const handleHistoryChange = () => {
                        setStates(getHistoryStates())
                    }

                    subscribe(history, 'change', handleHistoryChange)

                    return () => {
                        unsubscribe(history, 'change', handleHistoryChange)
                    }
                },

                []
            )

            return states
        }
    })

    const watchers = {
        task_finish() {
            if (refIsChanging.current) {
                return
            }

            const {actions, reverts} = this.lastChange

            if (0 < actions.length) {
                history.push({
                    redo: [actions, new Set(this.selectedNodes)],
                    undo: [reverts, refLastSelectedNodes.current],
                })
            }
        },

        task_start() {
            refLastSelectedNodes.current = new Set(this.selectedNodes)
        },
    }

    return {extensions, watchers}
}
