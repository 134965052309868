export const canShrinkNode = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canShrinkNode(node)
        )
    }
}

export const shrinkNode = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.actions.shrinkNode(nodes),
        )
    }
}
