import {Space} from 'antd'
import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import ButtonDownload from '../components/PropertiesPaneButtonDownload.jsx'
import ButtonPasteUpload from './PropertiesPaneButtonPasteUpload.jsx'
import ButtonUpload from './PropertiesPaneButtonUpload.jsx'
import Thumb from './PropertiesControllerThumb.jsx'
import Model from './ModelPic.mjs'

const PropertiesPanePic = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <Text prop="text" />
        <Thumb />

        <Space>
            <ButtonDownload />
            <ButtonUpload />
            <ButtonPasteUpload />
        </Space>
    </NodePropertiesPane>
)

export default PropertiesPanePic
