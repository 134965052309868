import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    demoType: {
        disabled: ({pkid}) => pkid,
        name: () => '界面视图类型',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    pageType: {
        disabled: ({pkid, uvType}) => pkid || 'MOBILE' === uvType,
        hidden: ({demoType}) => 'DEMO' !== demoType,
        name: () => '页面类型',
        required: () => true,
    },

    uvName: {
        name: () => '界面视图名称',
        required: () => true,
    },

    uvType: {
        disabled: ({pkid}) => pkid,
        hidden: ({demoType}) => 'DEMO' !== demoType,
        name: () => '终端类型',
        required: () => true,
    },

    uvUserCode: {
        name: () => '界面视图代码',
    },
})
