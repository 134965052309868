import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaSf_3.mjs'
import FormChoose from './FormChooseSf_3.jsx'
import TableChoose from './TableChooseSf_3.jsx'
import api from './apiSf_3.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async choose(map, node) {
            const items = await this._choose(map, node, {multiple: false})

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'SF'},
            }))
        },
    })
}
