import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SsType from './SsType.mjs'

const FormItemSsName = (props) => {
    return (
        <Form.Item
            label="子系统名称"
            name="ssName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSsCode = (props) => {
    return (
        <Form.Item
            label="子系统代码"
            name="ssUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemIsUi = (props) => {
    return (
        <Form.Item
            label="子系统类型"
            name="isUi"
            {...props}
        >
            <Select
                allowClear
                options={SsType.map(([value, label]) => ({label, value}))}
            />
        </Form.Item>
    )
}

const FormChooseSs = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemSsName />
            </Col>

            <Col span={6}>
                <FormItemSsCode />
            </Col>

            <Col span={6}>
                <FormItemIsUi />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseSs
