import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'

const PropertiesControllerProjectNumber = () => {
    return (
        <Text
            label="项目编号"
            prop="prjNo"
            readOnly
        />
    )
}

export default PropertiesControllerProjectNumber
