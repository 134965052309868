import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
//import ButtonClone from './PropertiesPaneButtonClone.jsx'
import ButtonDesign from './PropertiesPaneButtonDesign.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'
import DemoType from './DemoType.mjs'
import Model from './ModelUv.mjs'
import PageType from './PageType.mjs'
import UvType from './UvType.mjs'

const PropertiesPaneUv = (props) => (
    <ComponentPropertiesPane
        buttons={
            <>
                <ButtonDesign />
                <ButtonPreview />
            </>
        }

        controllers={
            <>
                <Text prop="uvName" />
                <Text prop="uvUserCode" />

                <Select
                    options={DemoType}
                    prop="demoType"
                />

                <Select
                    options={UvType}
                    prop="uvType"
                />

                <Select
                    options={PageType}
                    prop="pageType"
                />

                <LongText prop="memo" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneUv
