export const canPullProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canPullProduct(node)
        )
    }
}

export const pullProduct = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.pullProduct(nodes),
                {action: '刷新制品'},
            )
        )
    }
}
