import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmUserCode: {
        name: () => '功能模块代码',
    },

    fmName: {
        name: () => '功能模块名称',
        required: () => true,
    },
})
