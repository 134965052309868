import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    middlewareName: {
        name: () => '中间件名称',
        required: () => true,
    },

    middlewareType: {
        name: () => '中间件类型',
    },

    middlewareDesc: {
        name: () => '描述',
    },

    middlewareMemo: {
        name: () => '备注',
    },
})
