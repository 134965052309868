import {createShape} from '@/components/MindMap/index.mjs'

const B = 16

export const BottomWavyRectangle = (textRect, style) => {
    const bw = style.borderWidth
    const paddings = [bw, bw, bw + B, bw]

    const draw = (w, h) => `
        <path
            d="
                M ${bw / 2} ${bw / 2}
                H ${w - bw / 2}
                V ${h - B / 2}
                Q ${w * 0.75} ${h - B * 1.5 + bw / 2} ${w * 0.5} ${h - B / 2}
                T ${bw / 2} ${h - B / 2}
                Z
            "
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
