export function onClickJoint(node, event) {
    event.stopPropagation()

    this.execute(
        () => {
            if (node.isFolded) {
                this.actions.unfoldNode([node])
            }
            else {
                this.actions.foldNode([node])
            }

            if (! this.selectedNodes.has(node)) {
                if (event.ctrlKey || event.metaKey) {
                    this.selectNodes([...this.selectedNodes, node])
                }
                else {
                    this.selectNodes([node])
                }
            }
        }
    )
}
