import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonSaveHierarchyMap(props) {
    const map = useMapContext()
    const handleClick = () => map.commands.saveMap()

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            保存地图
        </MenuButton>
    )
}
