export default queries => {
    const watchers = {
        load() {
            const nodes = queries
                .map(q => this.queryNodes(q))
                .flat()

            this.execute(() => {
                if (0 < nodes.length) {
                    this.markNodes(nodes)
                }
            })
        },
    }

    return {watchers}
}
