import {Button} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonCode = (props) => {
    const [values] = useProperties()
    const {pkid, fcName, fcUserCode} = values

    if (! pkid) {
        return null
    }

    const handleClick = () => {
        const url = buildUrl(
            '/apiIDE',

            {
                fmFcId: pkid,
                fmFcName: fcName,
                fmFcUserCode: fcUserCode,
            }
        )

        window.open(url)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >查看代码</Button>
    )
}

export default PropertiesPaneButtonCode
