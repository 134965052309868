import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaSf.mjs'
import IconInf from './icon-inf.svg?react'
import IconTime from './icon-time.svg?react'
import Model from './ModelSf.mjs'
import PropertiesPane from './PropertiesPaneSf.jsx'
import FormChoose from './FormChooseSf.jsx'
import TableChoose from './TableChooseSf.jsx'
import api from './apiSf.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_SF_KEY'],
        },

        canMountType(map, node, type) {
            return /^(SF_(ALG|DES|MAIN|OTHER|PROG|RULE))$/.test(type)
        },

        async choose(map, node) {
            const getQuery = ({fms = [], ...query}) => {
                const fmIds = fms.map(({fmId}) => fmId)

                for (const ancestor of map.chain(node)) {
                    const {bizNodeType} = ancestor.data

                    if ('LS' === bizNodeType) {
                        return {fmIds, sfTypeCode: 'INF', ...query}
                    }
                }

                return {fmIds, ...query}
            }

            return this._choose(map, node, {getQuery})
        },

        getIcons(map, node) {
            const {sfTypeCode} = node.data

            if ('EXPERIENCE' === sfTypeCode) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="X"
                        textColor="#000"
                    />
                ]
            }
            else if ('INF' === sfTypeCode) {
                return [<IconInf key="type" />]
            }
            else if ('TIME' === sfTypeCode) {
                return [<IconTime key="type" />]
            }
            else {
                return []
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                mainOprList: [],
                otherOprList: [],
                progList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.GOLD}),
                shape: 'BreakangleRectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,

                [
                    'mainOprList',
                    'otherOprList'
                ]
            )
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    fmCode,
                    fmId,
                    fmName,
                } = n.data

                if ('CAT_ROOT_FM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        fmCode,
                        fmId,
                        fmName,
                    }

                    break
                }
                else if ('LS' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        sfTypeCode: 'INF',
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        async _onInsert(map, node) {
            for (const n of map.chainNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree({pkid})
                    const sf = map.importTree(treeData)

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            map.BizNode[t].classes.includes('category')
                        )

                        return {yieldChildren, yieldNode}
                    }

                    for (
                        const n of
                        map.walkDown(sf, {excludeTarget: true, next})
                    ) {
                        const {
                            arTypeCode,
                            [map.BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [map.BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }

                    map.deleteTree(sf)
                }

                break
            }

            await ProductNode._onInsert.call(this, map, node)
        },

        async onPull(map, node) {
            await ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                FOPR: 'sfOprSfs',
                SF: 'rFunList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            for (const n of map.chain(node.parent)) {
                const {bizNodeType} = n.data

                if (/^(BD_SUMMARY|BF_DATAFLOW)$/.test(bizNodeType)) {
                    node.isFolded = false

                    for (const nn of node.children) {
                        nn.isFolded = 'SF_DES' !== nn.data.bizNodeType
                    }
                }
            }

            return newDepth
        },

        async _onAttach(map, node, event) {
            if (node !== event.target) {
                return
            }

            const p = (() => {
                for (const n of map.chainNoComment(node.parent)) {
                    return n
                }
            })()

            const {bizNodeType} = p.data

            if ('LSI_SF' === bizNodeType) {
                if (node.data.prjId === map.data.prjId) {
                    event.stopPropagation()
                    map.deleteTree(node)
                    map.logger.error('只能插入外部项目的节点', [p])
                }
                else {
                    const oldSf = (() => {
                        for (const n of p.children) {
                            if (
                                n !== node &&
                                'SF' === n.data.bizNodeType
                            ) {
                                return n
                            }
                        }
                    })()

                    if (oldSf) {
                        map.unlinkTree(node)
                        map.insertSiblingAfter(oldSf, node)
                        map.deleteTree(oldSf)

                        map.logger.warn(
                            `只能有一个${map.BizNode.SF.name}子节点，覆盖原有节点`,
                            [node]
                        )
                    }

                    await this.emitEventDown(map, node, event)
                }
            }
            else {
                await ProductNode._onAttach.call(this, map, node, event)
            }
        },
    })
}
