import React, { useEffect, useState } from 'react';
import { Input, Tree, Spin, Tag, Empty, Select, TreeSelect } from 'antd';
import Modal from '@/components/Modal/Modal';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

const mapNode = (obj, valueKey = 'pkid') => {
    let type = '';
    let color = 'red';

    if ('0' == obj.isUi) {
        type = '服务';
        color = 'rgb(213, 251, 97)'; // green: #d5fb61
    } else if ('1' == obj.isUi) {
        type = '界面';
        color = 'rgb(80, 195, 247)'; // blue: #287ef6
    } else if ('2' == obj.isUi) {
        type = '程序库';
        color = 'rgb(253, 216, 52)'; // #287e00
    }

    return {
        obj: { ...obj },
        key: obj[valueKey],
        // value: obj[valueKey],
        title: <span><Tag color={color} style={{ color: '#000000' }}>{type}</Tag>{obj.ssName}</span>,
    }
}

const Dialog = ({ params, value, onOk, multiple = false, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const prjId = params.prjId;
            const sVer = params.sVer;
            const isUi = params.isUi;
            const stsCodes = ['RLS', 'REVISE'];

            const data = await runAsync(
                async () => {
                    return await http.get(`/archsss`, { prjId, sVer, isUi, stsCodes });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const tree = data.map(mapNode);
            setData(tree);
            setFilterData(tree);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleOk = () => {
        const sss = [];
        for (const node of selectedNodes) {
            const ss = node.obj;
            sss.push({ ssId: ss.pkid, ssCode: ss.ssCode, ssName: ss.ssName, ssUserCode: ss.ssUserCode, terminalType: ss.terminalType });
        }

        if (multiple) {
            onOk(sss);
        } else {
            if (sss.length > 0) {
                onOk(sss[0]);
            }
        }
    }

    const handleSearch = (keyword) => {
        setFilterData(searchKeyword(keyword));
    }

    const searchKeyword = (keyword) => {
        const iterate = (item) => {
            const ssName = item.obj.ssName;
            const isMatch = () => (ssName && ssName.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
            return isMatch() ? { ...item } : null;
        }

        return data.map(iterate).filter(v => v);
    }

    const multipleProps = {
        selectable: false,
        checkable: true,
        checkedKeys: selectedKeys,
        onCheck: (selectedKeys, e) => {
            setSelectedNodes(e.checkedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    const singleProps = {
        selectable: true,
        checkable: false,
        selectedKeys: selectedKeys,
        onSelect: (selectedKeys, e) => {
            setSelectedNodes(e.selectedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    return (
        <Modal
            title={"选择子系统"}
            width={800}
            style={{ top: 20 }}
            bodyStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12 }}
            onOk={handleOk}
            {...props}
        >
            <div style={{ marginBottom: 12, }}>
                <Input.Search
                    enterButton="查询"
                    allowClear
                    size="small"
                    onSearch={handleSearch}
                    placeholder='可输入子系统名称进行查询' />
            </div>

            {
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}><Spin /></div>
                    :
                    filterData.length > 0 ?
                        <div style={{ overflow: 'hidden', border: '1px solid #f0f0f0', padding: 8 }}>
                            <Tree
                                height={430}
                                defaultExpandAll
                                showLine={{ showLeafIcon: false }}
                                showIcon={true}
                                treeData={filterData}
                                multiple={multiple}
                                {...(multiple ? multipleProps : singleProps)} />
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1', background: '#fff' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
            }
        </Modal>
    );
}

export default Dialog;

export const SsSelect = ({ params, value, onChange, onCall, valueKey, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const prjId = params.prjId;
            const sVer = params.sVer;
            const isUi = params.isUi;
            const stsCodes = ['RLS', 'REVISE'];

            const data = await runAsync(
                async () => {
                    return await http.get(`/archsss`, { prjId, sVer, isUi, stsCodes });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const tree = data.map((item) => mapNode(item, valueKey));
            setData(tree);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const mapNode = (obj, valueKey = 'pkid') => {
        let type = '';
        let color = 'red';

        if ('0' == obj.isUi) {
            type = '服务';
            color = 'rgb(213, 251, 97)'; // green: #d5fb61
        } else if ('1' == obj.isUi) {
            type = '界面';
            color = 'rgb(80, 195, 247)'; // blue: #287ef6
        } else if ('2' == obj.isUi) {
            type = '程序库';
            color = 'rgb(253, 216, 52)'; // #287e00
        }

        return {
            obj: { ...obj },
            value: obj[valueKey],
            label: <span><Tag color={color} style={{ color: '#000000' }}>{type}</Tag>{obj.ssName}</span>,
        }
    }

    const doCall = (value) => {
        onChange(value);

        if (onCall) {
            const list = data.map(v => v.obj).filter(v => v.pkid == value);
            if (list.length > 0) {
                const result = list[0];
                onCall({ ...result, ssId: result.pkid });
            } else {
                onCall({});
            }
        }
    }

    return (
        // <TreeSelect
        //     allowClear
        //     showSearch
        //     treeData={data}
        //     onChange={doCall}
        //     dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        //     filterTreeNode={(keyWord, { obj }) => {
        //         if (keyWord && keyWord.trim().length > 0) {
        //             const ssName = obj.ssName;
        //             const matchName = ssName && ssName.toLowerCase().indexOf(keyWord.trim().toLowerCase()) > -1;
        //             return matchName;
        //         }
        //         return false;
        //     }}
        // />

        <Select
            allowClear
            showSearch
            loading={loading}
            options={data}
            onChange={doCall}
            filterOption={(keyWord, { obj }) => {
                if (keyWord && keyWord.trim().length > 0) {
                    const ssName = obj.ssName;
                    const matchName = ssName && ssName.toLowerCase().indexOf(keyWord.trim().toLowerCase()) > -1;
                    return matchName;
                }
                return false;
            }}
        />
    );
}
