import {CheckOutlined} from '@ant-design/icons'
import {useMapContext} from '@/components/MindMap/index.mjs'

const Prefix = () => {
    const map = useMapContext()
    const showNodeDesc = map.useShowNodeDesc()
    return showNodeDesc ? <CheckOutlined /> : null
}

export default () => {
    const map = useMapContext()

    const onClick = e => {
        e.stopPropagation()
        map.showNodeDesc = ! map.showNodeDesc
    }

    return {
        key: 'show-node-desc',
        label: '显示节点释义',
        prefix: <Prefix />,
        onClick,
    }
}
