import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCopyNodeStyle, copyNodeStyle} = map.commands
    const nodes = canCopyNodeStyle(selectedNodes)

    return {
        disabled: 1 !== nodes.length,
        key: 'copy-node-style',
        label: '拷贝样式',
        suffix: 'Ctrl+Alt+C',
        onClick: () => copyNodeStyle(nodes),
    }
}
