import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDb from '../DB/SelectDb.jsx'

export default function PropertiesControllerDb({
    className,
    style,
    codeProp = 'dbCode',
    disabled,
    hidden,
    idProp = 'dbId',
    label,
    labelSuffix,
    nameProp = 'dbName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: dbCode,
        [idProp]: dbId,
        [nameProp]: dbName,
    } = values

    const handleChange = db => {
        if (db) {
            const {dbCode, dbId, dbName} = db

            update({
                [codeProp]: dbCode,
                [idProp]: dbId,
                [nameProp]: dbName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectDb
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{dbCode, dbId, dbName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
