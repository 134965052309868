import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dmUserCode',
    mapIdProp: 'dataMapId',
    mapStsCodeProp: 'dataMapStsCode',
    mapTypeCode: 'DATA_MAP',
    name: '库表域',
    ownerIdProp: 'dbId',
    ownerTextProp: 'dbName',
    ownerType: 'DB',
    textProp: 'dmName',
    type: 'DM',
}
