import useSWR from 'swr/immutable'
import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import SidePane from '../../SidePanel/SidePane.jsx'
import ButtonImportProducts from '../components/PropertiesPaneButtonImportProducts.jsx'
import {PropertiesContext} from '../../SidePanel/RightPanel/Properties.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'

const useBm = (bmId) => {

    const fetchBm = (bmId) => runAsync(
        () => http.get(`/archbms/${bmId}`),

        {
            error: {
                content: (err) => `读取业务域失败: ${err.message}`,
            },

            loading: {
                content: '正在读取业务域，请稍候',
            },
        }
    )

    const {data} = useSWR(bmId, fetchBm)
    return data
}

const PropertiesPaneCatRootBm = ({nodes, ...props}) => {
    const bm = useBm(nodes[0].data.bmId)

    if (! bm) {
        return null
    }

    return (
        <PropertiesContext.Provider value={[bm]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane {...props}>
                    <Text
                        label="业务域代码"
                        prop="bmNo"
                    />

                    <Text
                        label="业务域名称"
                        prop="bmName"
                    />

                    <div>
                        <ButtonImportProducts />
                    </div>
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}

export default PropertiesPaneCatRootBm
