export default options => {
    const watchers = {
        load() {
            for (const [panelId, option] of Object.entries(options)) {
                const {open} = option

                if (open) {
                    this.openSidePanel(panelId, open)
                }
            }
        },
    }

    return {watchers}
}
