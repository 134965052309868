import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canPasteTreeAfter, pasteTreeAfter} = map.commands
    const nodes = canPasteTreeAfter(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'paste-tree-after',
        label: '在其后粘贴',
        suffix: 'Ctrl+Shift+V',
        onClick: () => pasteTreeAfter(nodes),
    }
}
