import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    cdtUserCode: {
        name: () => '定制类型代码',
        required: () => true,
    },

    cdtName: {
        name: () => '定制类型名称',
        required: () => true,
    },

    bdtCode: {
        name: () => '基本数据类型',
        required: () => true,
    },

    cdtCat: {
        name: () => '类别',
    },

    cdtLen: {
        name: () => '长度',
        //hidden: ({isLen}) => '1' !== isLen,
        //required: ({isLen}) => '1' === isLen,
    },

    cdtPrecision: {
        name: () => '精度',
        //hidden: ({isPrecision}) => '1' !== isPrecision,
        //required: ({isPrecision}) => '1' === isPrecision,
    },

    isSeq: {
        name: () => '是否序列',
    },

    cdtDesc: {
        name: () => '说明',
    },

    memo: {
        name: () => '备注',
    },
})
