export function canInsertChild(node) {
    return true
}

export function canInsertSibling(node) {
    if (! node.parent) {
        this.logger.error('没有父节点的节点不能插入兄弟节点', [node])
        return false
    }

    return true
}

const insertNode = (map, nodes, insert) => {
    const nodesToSelect = new Set()

    for (const node of nodes) {
        const n = map.importTree()
        insert(node, n)
        map.logger.info('已插入节点', [node])
        nodesToSelect.add(n)
    }

    map.selectNodes(nodesToSelect)
}

export function appendChild(nodes) {
    return insertNode(this, nodes, this.appendChild.bind(this))
}

export function prependChild(nodes) {
    return insertNode(this, nodes, this.prependChild.bind(this))
}

export function insertSiblingAfter(nodes) {
    return insertNode(this, nodes, this.insertSiblingAfter.bind(this))
}

export function insertSiblingBefore(nodes) {
    return insertNode(this, nodes, this.insertSiblingBefore.bind(this))
}
