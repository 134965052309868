import _Menu from '../../Menu.jsx'
import MenuButtonEdit from '../../menuButtons/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProducts from '../../menuButtons/MenuButtonInsertProducts.jsx'
import MenuButtonMap from './MenuButtonMap.jsx'
import MenuButtonPublishMap from './MenuButtonPublishMap.jsx'
import MenuButtonReviseMap from './MenuButtonReviseMap.jsx'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertNode type="product" />
            <MenuButtonInsertProducts />
            <MenuButtonSaveMap />
            <MenuButtonPublishMap />
            <MenuButtonReviseMap />
        </_Menu>
    )
}
