import {useEffect, useRef} from 'react'
import {css} from '@emotion/react'
import {Input} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssInput = css({
    display: 'flex',
    width: 'auto',
    borderRadius: 4,
})

const onKeyDown = e => e.stopPropagation()

export const InputReplace = ({textSearch, ...props}) => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (! (
        textSearch &&
        permission.has('write')
    )) {
        return null
    }

    return (
        <Input
            css={cssInput}
            allowClear
            placeholder="替换"
            onKeyDown={onKeyDown}
            {...props}
        />
    )
}

export const InputSearch = ({onChange, ...props}) => {
    const refInput = useRef()

    useEffect(
        () => {
            refInput.current.focus()
        },

        []
    )

    const handleChange = (e) => {
        if (! e.nativeEvent.isComposing) {
            onChange(e)
        }
    }

    const handleCompositionEnd = (e) => {
        onChange(e)
    }

    return (
        <Input
            ref={refInput}
            css={cssInput}
            allowClear
            placeholder="搜索"
            onChange={handleChange}
            onCompositionEnd={handleCompositionEnd}
            onKeyDown={onKeyDown}
            {...props}
        />
    )
}
