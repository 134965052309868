import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'

export default function PropertiesControllerProductSn(props) {
    return (
        <Text
            label="模件编号"
            prop="dpSn"
            readOnly
            {...props}
        />
    )
}
