import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Modal, Row, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

/**
 * SelectPcdgDfDialog ：选择标准字段组字段【默认单选】
 * 
 * 使用场景：
 * 
 * 参数说明：
 * visible:     【必填】 当前Dialog是否展示 
 * setVisible:  【必填】 当前Dialog展示的操作方法 
 * onCall:      【必填】 回调方法 
 * prjId:       【必填】 prjId 项目ID
 * sVer:        【必填】 sVer  项目分支号
 * isSingle:    【可选】 true(单选、默认)  false(多选)  
 * 
 * @param {*} param
 * @returns 单选，返回对象；多选返回数组；
 **/
const SelectPcdgDfDialog = ({ visible, setVisible, onCall, prjId, sVer, isSingle = true, ...props }) => {
    const http = useHTTP();
    const [form] = Form.useForm();

    // 数据模型
    const [data, setData] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); // 选中的对象集合

    // 组件初始化
    useEffect(() => {
        if (visible) {
            // doSearch();
            setSelectedRows([]);
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.prjId = prjId;
            formField.sVer = sVer;
            formField.pcdgStsCode = 'RLS';
            formField.isRefDf = props.isRefDf;
            // formField.orderRule = 'updateTime desc';

            const data = await runAsync(
                async () => {
                    return await http.get(`/dmpcdgdfs`, formField);
                },
                {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                }
            );
            setData(data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = () => {
        doSearch();
        setSelectedRows([]);
        form.resetFields();
    }

    // 确认回调
    const doConfirm = async () => {
        if (isSingle) {
            if (selectedRows.length == 0) {
                onCall(null);
            } else {
                const data = selectedRows[0];
                onCall({
                    pcdgId: data.pcdgId,
                    pcdgCode: data.pcdgCode,
                    pcdgName: data.pcdgName,
                    pcdgUserCode: data.pcdgUserCode,
                    dfId: data.pkid,
                    dfCode: data.dfCode,
                    dfName: data.dfName,
                    dfUserCode: data.dfUserCode,
                    dfLen: data.dfLen,
                    dfPrecision: data.dfPrecision,
                    defVal: data.defVal,
                    dfDesc: data.dfDesc,
                    dfCat: data.dfCat,
                    dataTypeGrpCode: data.dataTypeGrpCode,
                    dataTypeCode: data.dataTypeCode,
                    dataTypeName: data.dataTypeName,
                    bdtId: data.bdtId,
                    bdtCode: data.bdtCode,
                    bdtName: data.bdtName,
                    cdtId: data.cdtId,
                    cdtCode: data.cdtCode,
                    cdtName: data.cdtName,
                    ddtId: data.ddtId,
                    ddtCode: data.ddtCode,
                    ddtName: data.ddtName,
                });
            }
        }
        doClose();
    }

    // 关闭Dialog
    const doClose = () => {
        form.resetFields();
        setVisible(false);
        setSelectedRows([]);
    }

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            title: 'SFG代码',
            dataIndex: 'pcdgUserCode',
        },
        {
            title: 'SFG名称',
            dataIndex: 'pcdgName',
        },
        {
            title: '标准字段代码',
            dataIndex: 'dfUserCode',
        },
        {
            title: '标准字段名称',
            dataIndex: 'dfName',
        },
    ];

    return (
        <>
            <Modal
                visible={visible}
                title="选择标准字段"
                width={1200}
                onOk={doConfirm}
                onCancel={() => setVisible(false)}
                bodyStyle={{ background: '#f0f2f5', padding: '12px' }}
            >
                {/* 查询条件 */}
                <div style={{ padding: '24px 24px 0 24px', overflow: 'hidden', background: '#fff' }}>
                    <Form form={form} onFinish={doSearch}>
                        <Row gutter={24}>
                            <Col span={5}>
                                <Form.Item name="pcdgUserCode" label="SFG代码">
                                    <Input size="middle" placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item name="pcdgName" label="SFG名称">
                                    <Input size="middle" placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item name="dfUserCode" label="标准字段代码">
                                    <Input size="middle" placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item name="dfName" label="标准字段名称">
                                    <Input size="middle" placeholder="请输入" allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={4} offset={expand ? 0 : 0} style={{ textAlign: 'right' }}>
                                <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 数据表格 */}
                <div style={{ marginTop: '12px', overflow: 'auto', height: '400px', background: '#fff' }}>
                    {/* 统计区 */}
                    {
                        selectedRows.length > 0 &&
                        <div className="smstable-body-statis">
                            <Space>
                                <span>已选择</span>
                                <span>{selectedRows.length}</span>
                                <span>项</span>
                            </Space>
                            <Space>
                                <a onClick={() => setSelectedRows([])}>取消选择</a>
                            </Space>
                        </div>
                    }

                    {/* 数据区 */}
                    <Table
                        size="middle"
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        pagination={Page.init(data)}
                        rowKey={record => record.pkid}
                        rowSelection={{ ...rowSelection }}
                        onRow={(record) => ({
                            onClick: () => {
                                setSelectedRows([record]);
                            }
                        })}
                    />
                </div>
            </Modal>
        </>
    );
}

export default SelectPcdgDfDialog;