import {useEffect, useRef} from 'react'

export default (refEl, onResize) => {
    const refLastWidth = useRef()
    const refLastHeight = useRef()

    useEffect(
        () => {
            const observer = new ResizeObserver(entries => {
                const {
                    borderBoxSize: [{
                        inlineSize: width,
                        blockSize: height,
                    }],
                } = entries[0]

                if (
                    width !== refLastWidth.current ||
                    height !== refLastHeight.current
                ) {
                    refLastWidth.current = width
                    refLastHeight.current = height
                    onResize({width, height})
                }
            })

            observer.observe(refEl.current)
            return () => observer.disconnect()
        },

        [onResize]
    )
}
