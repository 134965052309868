import {useOpenModal} from '@/components/Modal/Modal.jsx'
import Confirm from '@/components/Modal/Confirm.jsx'

export default () => {
    const openModal = useOpenModal()

    const open = async ({content, okText, onOk}) => {
        const isConfirmed = await openModal(
            <Confirm
                cancelText="我已了解"
                title="提示"
                okText={okText}
            >
                {content}
            </Confirm>
        )

        if (isConfirmed) {
            onOk()
        }
    }

    const generatingFile = () => open({
        content: '提交成功，文件生成中，请稍后通过“工作台-文件下载中心-下载”功能下载。',
        okText: '去下载',
        onOk: () => window.open('/Workbench')
    })

    return {
        generatingFile,
    }
}
