function moveDownTree() {
    const {
        commands: {canMoveDownTree, moveDownTree},
        selectedNodes,
    } = this

    const nodes = canMoveDownTree(selectedNodes)
    moveDownTree(nodes)
}

function moveUpTree() {
    const {
        commands: {canMoveUpTree, moveUpTree},
        selectedNodes,
    } = this

    const nodes = canMoveUpTree(selectedNodes)
    moveUpTree(nodes)
}

export default {
    'Ctrl+ArrowDown': moveDownTree,
    'Meta+ArrowDown': moveDownTree,
    'Ctrl+ArrowUp': moveUpTree,
    'Meta+ArrowUp': moveUpTree,
}
