import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDto.mjs'
import PropertiesPane from './PropertiesPaneDto.jsx'
import FormChoose from './FormChooseDto.jsx'
import TableChoose from './TableChooseDto.jsx'
import api from './apiDto.mjs'
import Model from './ModelDto.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CAT|DTF)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'DTF'
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DT"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {dtoDfList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.GREEN_YELLOW,
                }),

                shape: 'SingleBreakangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dtoDfList'])
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['DTF'],
            ]
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('DF' === bizNodeType) {
                map.BizNode.DTF.castFrom(map, child)
                return true
            }
            else {
                return ProductNode._fixChild.call(this, map, node, child)
            }
        },

        async onPull(map, node) {
            await ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },
    })
}
