import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canGrowNode, growNode} = map.commands
    const nodes = canGrowNode(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    return {
        key: 'grow-node',
        label: '加载',
        suffix: 'CTRL+L',
        onClick: () => growNode(nodes, false),
    }
}
