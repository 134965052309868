export const canReviseProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canReviseProduct(node)
        )
    }
}

export const reviseProduct = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.reviseProduct(nodes),
                {action: '修订模型制品'},
            )
        )
    }
}
