export default () => {
    const watchers = {
        load() {
            this.execute(async () => {
                const _n = this.nodeProxy(this.root)
                await _n.onImport()
            })
        },
    }

    return {watchers}
}
