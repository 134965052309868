export function canDuplicateTree(nodes) {
    return [...nodes].filter(
        node => this.actions.canDuplicateTree(node)
    )
}

export async function duplicateTree(nodes) {
    await this.cmd(
        () => this.actions.duplicateTree(nodes)
    )
}
