function insertParent() {
    const {
        commands: {canInsertParent, insertParent},
        selectedNodes,
    } = this

    const nodes = canInsertParent(selectedNodes)
    insertParent(nodes)
}

export default {
    'Ctrl+Enter': insertParent,
    'Meta+Enter': insertParent,
}
