import BaseMap from '../BaseMap.jsx'
import useMapData from './useMapData.mjs'

export default function ShareMap({id, ...props}) {
    const initData = useMapData(id)

    return (
        <BaseMap
            initData={initData}
            {...props}
        />
    )
}
