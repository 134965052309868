const IconStar = ({fill = '#000'}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <polygon
            fill={fill}
            points="63.74,3.8 83.25,43.33 126.87,49.67 95.3,80.44 102.76,123.89 63.74,103.37 24.72,123.89 32.17,80.44 0.6,49.67 44.23,43.33"
        />
    </svg>
)

export default IconStar
