import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCopyNodeUrl, copyNodeUrl} = map.commands
    const nodes = canCopyNodeUrl(selectedNodes)

    return {
        disabled: 1 !== nodes.length,
        key: 'copy-node-url',
        label: '复制链接',
        suffix: '',
        onClick: () => copyNodeUrl(nodes),
    }
}
