import {SearchOutlined} from '@ant-design/icons'
import SearchPane from './SearchPane.jsx'

export default () => {
    return {
        icon: <SearchOutlined />,
        key: 'search',
        hotkey: 'Ctrl+Shift+F',
        name: '搜索',
        pane: <SearchPane />,
    }
}
