import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSsMid.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(MID)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CAT'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['MID'],
            ]
        },
    })
}
