import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Bm from '../components/PropertiesControllerBm.jsx'
import BfType from './BfType.mjs'
import Model from './ModelBf.mjs'

const PropertiesPaneBf = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Bm />
                <Text prop="bfNo" />
                <Text prop="bfName" />

                <Select
                    options={BfType}
                    prop="bfTypeCode"
                />

                <LongText prop="bfDesc" />
                <LongText prop="bfMemo" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneBf
