import {PushpinOutlined} from '@ant-design/icons'
import MarkedNodes from './MarkedNodes.jsx'

export default () => {
    return {
        icon: <PushpinOutlined />,
        key: 'mark',
        hotkey: 'Ctrl+Shift+M',
        name: '标记模件',
        pane: <MarkedNodes />,
    }
}
