/* eslint-disable react/jsx-key */

import Color from '../Color.mjs'
import IconTriangle from '../icons/IconTriangle.jsx'
import IconCase from '../AR/icons/icon-case.svg?react'
import IconLoop from '../AR/icons/icon-loop.svg?react'

export default [
    ['PF', '程序段', <IconTriangle fill={Color.GREEN} rotate={90} />],
    ['SWITCH', '分支', <IconCase />],
    ['LOOP', '循环', <IconLoop />],
]
