import React, {useState} from 'react';
import {Radio, Row} from 'antd';
import {css} from "@emotion/react";
import SelCard from './SelCard.jsx'

const cssCardPane = css({
    overflowX: 'hidden',
    //padding: 24,
    flexGrow: 1,
})

const SelCardPane = ({loading = false, selectedRows, setSelectedRows, data = [], ...props}) => {
    return (
        <div css={[cssCardPane]}>
            <Row gutter={[24, 24]}>
                {
                    data.map(item => <SelCard data={item} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>)
                }
            </Row>
        </div>

    )
}
export default SelCardPane;
