import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bmName: {
        name: () => '业务域名称',
        required: () => true,
    },

    bmNo: {
        name: () => '业务域代码',
    },
})
