const IconFlag = ({
    color = '#000',
    fill = 'transparent',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <circle
            cx="50%"
            cy="50%"
            fill={fill}
            r="50%"
        />

        <path
            d="M100.9444445,38.4444445 L76.2777778,38.4444445 L76.2777778,28.07407404 C76.2777778,26.928594578 75.3574742,26 74.2222222,26 L31.05555552,26 C29.920303555,26 29,26.928594578 29,28.07407404 L29,107.925926 C29,109.0714054 29.920303555,110 31.05555552,110 L35.16666664,110 C35.71183388,110 36.23467248,109.7814821 36.62016394,109.3925178 C37.00565539,109.0035535 37.22222224,108.4760046 37.22222224,107.925926 L37.22222224,67.4814815 L61.8888889,67.4814815 L61.8888889,77.85185179999999 C61.8888889,78.40193049999999 62.1054557,78.92947939999999 62.4909472,79.3184437 C62.8764386,79.70740810000001 63.3992772,79.925926 63.9444445,79.925926 L100.9444445,79.925926 C101.4896117,79.925926 102.0124503,79.70740810000001 102.3979418,79.3184437 C102.7834332,78.92947939999999 103,78.40193049999999 103,77.85185179999999 L103,40.5185185 C103,39.373039 102.0796964,38.4444445 100.9444445,38.4444445 Z"
            fill={color}
        />
    </svg>
)

export default IconFlag
