import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    resume: {
        name: () => '简述',
    },

    ruleKey: {
        name: () => '处理任务关键字',
    },

    ruleTitle: {
        name: () => '处理任务标题',
        required: () => true,
    },

    ruleType: {
        name: () => '处理任务类型',
        required: () => true,
    },

    ruleUserCode: {
        name: () => '处理任务代码',
    },
})
