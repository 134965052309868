import {eventBus} from '@/script/eventBus.mjs'
import metaBizNode from '../metaBizNode.mjs'

export default () => {
    const watchers = {
        load() {
            eventBus.publish(this, 'map_load')
        },

        meta_change() {
            eventBus.publish(this, 'map_meta_change')
        },

        model_change(changeSet) {
            for (const [node, changes] of changeSet.entries()) {
                if (changes.has('data')) {
                    const {bizNodeType} = node.data

                    if (metaBizNode[bizNodeType]?.classes.includes('design')) {
                        eventBus.publish(node, 'dp_change')
                    }
                }
            }
        },
    }

    return {watchers}
}
