import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dtoUserCode: {
        name: () => 'DTO 代码',
        required: () => true,
    },

    dtoName: {
        name: () => 'DTO 名称',
        required: () => true,
    },

    dtoDesc: {
        name: () => '说明',
    },
})
