const FileUtils = {
    // 文件服务器【上传API，POST】，【下载API，GET】
    fileServer: import.meta.env.VITE_HTTP_BASE_URL,

    // SMS文件桶
    smsBucket: import.meta.env.VITE_BUCKET,

    CommonFile: '/commonfile',

    // 用户logo
    UserLogo: '/UserLogo',

    // 团队logo
    TeamLogo: '/TeamLogo',

    LayoutPic: '/LayoutPic',

    //-----------------------------------------------
    // 项目文档
    projectDoc: '/projectdoc/',

    // 界面图册
    uiPic: '/uipic/',

    // 服务图册
    funcPhotos: '/system/func/',

    // 变更图册
    dcPhoto: '/dcphoto',

    // 变更文档
    dcDoc: '/dcdoc',

    // license
    license: '/license',

    // 组件图标
    comIcon: '/comicon',

    //
    requirementForm: '/requirementForm',

    uvTemplateCover: '/uv/template/cover/',

    // 原型工具组件图标


    // 制品模板
    dpt: '/dpt',

    // 图片预览
    getFileUrl: (fileId) => {
        let server = import.meta.env.VITE_HTTP_BASE_URL;
        return server + `/files/${fileId}`;
    },

    // 文件下载
    download: (fileStream, fileName) => {
        try {
            const blob = new Blob([fileStream]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); // 模拟点击

            // 释放资源并删除标签a
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        } catch (err) {
            console.log(err);
        }
    }
}

export default FileUtils;
