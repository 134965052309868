export const canUpgradeProduct = () => () => {
    return function (node) {
        const _n = this.nodeProxy(node)
        return Boolean(_n.canUpgrade?.())
    }
}

export const upgradeProduct = () => () => {
    return async function (nodes) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        await _n.upgrade()
                        this.logger.info('升级模型制品成功', [node])
                    }
                    catch (err) {
                        this.logger.error(
                            `升级模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
