import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectFm from '../FM/SelectFm.jsx'
import SelectSs from '../SS/SelectSs.jsx'
import SelectVm from '../VM/SelectVm.jsx'

const FormItemFm = (props) => {
    return (
        <Form.Item
            label="功能模块"
            name="fms"
            {...props}
        >
            <SelectFm multiple />
        </Form.Item>
    )
}

const FormItemSs = (props) => {
    return (
        <Form.Item
            label="子系统"
            name="ss"
            {...props}
        >
            <SelectSs />
        </Form.Item>
    )
}

const FormItemVm = (props) => {
    const form = Form.useFormInstance()
    const {ssCode} = Form.useWatch('ss', form) ?? {}

    return (
        <Form.Item
            label="程序模块"
            name="vms"
            {...props}
        >
            <SelectVm
                multiple
                ssCode={ssCode}
            />
        </Form.Item>
    )
}

const FormItemFcName = (props) => {
    return (
        <Form.Item
            label="能力名称"
            name="fcName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemFcCode = (props) => {
    return (
        <Form.Item
            label="能力代码"
            name="fcUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseFc = (props) => {
    const [form] = Form.useForm()

    const brief = (
        <Row gutter={12}>
            <Col span={8}>
                <FormItemFm />
            </Col>

            <Col span={8}>
                <FormItemSs />
            </Col>

            <Col span={8}>
                <FormItemVm />
            </Col>

            <Col span={8}>
                <FormItemFcCode />
            </Col>

            <Col span={8}>
                <FormItemFcName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            form={form}
            {...props}
        />
    )
}

export default FormChooseFc
