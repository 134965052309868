import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmCode: {
        name: () => '功能模块',
    },

    sfNo: {
        name: () => '集成用例代码',
        required: () => true,
    },

    sfName: {
        name: () => '集成用例名称',
        required: () => true,
    },
})
