import {useLayoutEffect, useRef} from 'react'

export default (keys) => {
    const refDom = useRef()

    if (! refDom.current) {
        refDom.current = Object.fromEntries(
            keys.map(key => [key, new WeakMap])
        )
    }

    const extensions = () => ({
        get dom() {
            return refDom.current
        },

        useRegisterDom(id, key, ref) {
            useLayoutEffect(
                () => {
                    refDom.current[id].set(key, ref.current)
                    return () => refDom.current[id].delete(key)
                },

                []
            )
        },
    })

    return {extensions}
}
