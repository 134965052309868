import {useRef} from 'react'

export default () => {
    const refIsEnabled = useRef(true)

    const extensions = () => ({
        suppressCancelNodeSelectionOnPointerDown() {
            refIsEnabled.current = false
        },
    })

    const watchers = {
        canvas_pointer_down(e) {
            if (! refIsEnabled.current) {
                refIsEnabled.current = true
                return
            }

            if (
                1 === e.buttons &&
                ! e.ctrlKey &&
                ! e.metaKey &&
                ! e.shiftKey &&
                0 < this.selectedNodes.size
            ) {
                // 如果同步执行，可能会导致某些页面元素被销毁，
                // 从而无法触发其 blur 事件
                setTimeout(() => {
                    this.execute(
                        () => this.selectNodes([])
                    )
                })
            }
        },
    }

    return {extensions, watchers}
}
