import {css} from '@emotion/react'
import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import ButtonEditProduct from './PropertiesPaneButtonEditProduct.jsx'
import ProductSn from './PropertiesControllerProductSn.jsx'
import ProductStatus from './PropertiesControllerProductStatus.jsx'
import ProjectNumber from './PropertiesControllerProjectNumber.jsx'
import Version from './PropertiesControllerVersion/PropertiesControllerVersion.jsx'

const cssButtons = css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
})

export function BatchPropertiesPane({buttons, controllers, ...props}) {
    return (
        <NodePropertiesPane {...props}>
            {controllers}
            <div css={cssButtons}>{buttons}</div>
        </NodePropertiesPane>
    )
}

export function ComponentPropertiesPane({
    buttons,
    controllers,
    projectNumber = <ProjectNumber />,
    snSuffix,
    sn = <ProductSn labelSuffix={snSuffix} />,
    versionLabel = '版本号',
    version = <Version label={versionLabel} />,
    ...props
}) {
    return (
        <NodePropertiesPane {...props}>
            {projectNumber}
            {sn}
            {version}
            {controllers}
            <div css={cssButtons}>{buttons}</div>
        </NodePropertiesPane>
    )
}

export function ProductPropertiesPane({
    buttons,
    controllers,
    projectNumber = <ProjectNumber />,
    snSuffix,
    sn = <ProductSn labelSuffix={snSuffix} />,
    status = <ProductStatus />,
    versionLabel = '版本号',
    version = <Version label={versionLabel} />,
    ...props
}) {
    return (
        <NodePropertiesPane {...props}>
            {projectNumber}
            {sn}
            {version}
            {status}
            {controllers}

            <div css={cssButtons}>
                <ButtonEditProduct />
                {buttons}
            </div>
        </NodePropertiesPane>
    )
}
