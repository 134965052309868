export const unfoldDepth = () => () => {
    return function (nodes, depth) {
        const topNodes = this.topNodes(nodes)

        const unfold = (node, currentDepth) => {
            node.isFolded = depth < currentDepth

            for (const child of this.children(node)) {
                unfold(child, currentDepth + 1)
            }
        }

        for (const node of topNodes) {
            unfold(node, 1)
        }
    }
}
