export default async (map, node, trees) => {
    const nodesToSelect = new Set()
    const newNodes = []

    for (const tree of trees) {
        const child = map.importTree(tree)
        map.appendChild(node, child)
        newNodes.push(child)
    }

    await Promise.all(
        newNodes.map(async n => {
            const _n = map.nodeProxy(n)
            await _n.emitEventUp({type: 'insert'})

            if (! n.isDeleted) {
                nodesToSelect.add(n)
            }
        })
    )

    map.logger.info('插入模件成功', nodesToSelect)
    map.selectNodes(nodesToSelect)
}
