import {useMapContext} from '@/components/MindMap/index.mjs'

export default type => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canExportNode, exportNode} = map.commands
    const nodes = canExportNode(selectedNodes, type)

    if (1 !== nodes.length) {
        return null
    }

    const typeText = {
        image: '图片',
        pdf: 'PDF',
    }[type]

    return {
        disabled: 0 === nodes.length,
        key: `export-node-as-${type}`,
        label: `导出为${typeText}...`,
        suffix: '',
        onClick: () => exportNode(nodes, type),
    }
}
