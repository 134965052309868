import extendNode from '../extendNode.mjs'
import useCommentNode from '../_COMMENT/useCommentNode.jsx'
import IconQuote from '../icons/icon-quote.svg?react'
import meta from './metaMark.mjs'

export default () => {
    const CommentNode = useCommentNode()

    return extendNode(CommentNode, {
        ...meta,

        getIcons() {
            return [<IconQuote key="type" />]
        },

        getStyle(map, node) {
            return {
                ...CommentNode.getStyle.call(this, map, node),
                shape: 'Rectangle',
            }
        },
    })
}
