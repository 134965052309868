import { useState } from 'react';
import { Table as AntdTable } from 'antd';
import FileUtils from '@/script/FileUtils';

const Table = (props) => {
    const cssTable = {
        '.ant-table-cell': {
            whiteSpace: 'nowrap',
            textAlign: 'left',
        },
        // 增加表格头样式
        '.ant-table-thead > tr >th': {
            fontWeight: 'bold',
            // height: '60px',
        },
    };

    return (
        <AntdTable
            {...props}
            css={cssTable}
            scroll={{ x: true }}
        />
    );
};

Table.renderImage = (style) => {
    const cssImage = {
        objectFit: 'contain',
        ...style,
    };

    return (src) => {
        // TODO
        const _src = FileUtils.fileUrl + src;

        return (
            <img
                css={cssImage}
                alt=""
                src={_src}
            />
        );
    };
};

const Text = ({ truncated, ...props }) => {
    const [isTruncated, setIsTruncated] = useState(truncated);

    if (isTruncated) {
        const cssText = {
            cursor: 'pointer',
        };

        return (
            <span
                {...props}
                css={cssText}
                title={props.children}
                onClick={() => setIsTruncated(false)}
            >{props.children}</span>
        );
    }
    else {
        const cssText = {
            whiteSpace: 'normal',
        };

        return (
            <span
                {...props}
                css={cssText}
            >{props.children}</span>
        );
    }
};

Table.renderText = (style) => {
    const cssText = {
        display: 'inline-block',
        ...style,
    };

    return (text) => {
        return (
            <Text
                css={cssText}
                truncated={Boolean(cssText.textOverflow)}
            >{text}</Text>
        );
    };
}

export default Table;
