import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootFm.jsx'
import meta from './metaCatRootFm.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(SF|UI)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'SF'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['SF'],
                ['UI'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF'],
                ['UI'],
            ]
        },
    })
}
