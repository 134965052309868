import insertNode from './insertProduct.mjs'

export const canInsertProduct = () => () => {
    return function (node) {
        return this.actions.canInsertChild(node)
    }
}

export const insertProduct = () => () => {
    return async function (nodes, type) {
        if (0 === nodes.length) {
            return
        }

        if (1 < nodes.length) {
            this.logger.warn('只能对单一节点插入制品，忽略除第一个节点外的其余节点')
        }

        const [node] = nodes
        const trees = await this.BizNode[type].choose(this, node)

        if (0 < trees.length) {
            await insertNode(this, node, trees)
        }
    }
}
