const IconRedo = ({
    fill = '#000',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <path
            d="M125.13,57.65L104.4,78.71c-1.13,1.15-2.67,1.79-4.28,1.79c-1.61,0-3.15-0.65-4.28-1.79L75.11,57.65c-2.33-2.36-2.3-6.16,0.07-8.49c2.36-2.33,6.16-2.3,8.49,0.07L95.25,61c-1.5-21.79-19.39-39.05-41.17-39.05c-22.76,0-41.28,18.85-41.28,42.02s18.52,42.02,41.28,42.02c8.38,0,16.44-2.54,23.31-7.34c2.72-1.9,6.46-1.23,8.36,1.49c1.9,2.72,1.23,6.46-1.49,8.36c-8.9,6.21-19.34,9.5-30.19,9.5C24.7,117.99,0.8,93.76,0.8,63.97c0-29.79,23.9-54.03,53.29-54.03c27.67,0,50.48,21.5,53.04,48.88l9.45-9.6c2.33-2.36,6.13-2.39,8.49-0.07C127.43,51.49,127.46,55.29,125.13,57.65L125.13,57.65z"
            fill={fill}
        />
    </svg>
)

export default IconRedo
