import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootVm.jsx'
import meta from './metaCatRootVm.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FC'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FC'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },
    })
}
