import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaBf.mjs'
import Model from './ModelBf.mjs'
import PropertiesPane from './PropertiesPaneBf.jsx'
import FormChoose from './FormChooseBf.jsx'
import TableChoose from './TableChooseBf.jsx'
import api from './apiBf.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BF_DESC'],
        },

        canMountType(map, node, type) {
            return /^(BF_(BF|CHART|DATAFLOW|SU))$/.test(type)
        },

        async choose(map, node) {
            const getQuery = ({bms = [], ...q}) => ({
                ...q,
                bmIds: bms.map(({bmId}) => bmId),
            })

            return this._choose(map, node, {getQuery})
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                bdList: [],
                bmBfs: [],
                bmBjList: [],
                fileList: [],
                sfList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'Arrow',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'algList',
                'bdList',
                'bmBjList',
                'fileList',
            ])
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            if (void 0 === node.data.bfTypeCode) {
                node.data = {...node.data, bfTypeCode: 'TARGET'}
            }

            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = n.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        bmCode,
                        bmId,
                        bmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'bmBfs',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            for (const n of map.chainNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if (/^(CAT_ROOT_)?BM$/.test(bizNodeType)) {
                    for (const nn of [...node.children]) {
                        if ('BF_SU' === nn.data.bizNodeType) {
                            map.deleteTree(nn)
                        }
                    }
                }
                else {
                    break
                }
            }

            return newDepth
        },
    })
}
