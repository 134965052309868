import {useMidType} from '@/biz/bizTable.mjs'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'

export default function PropertiesControllerSelectMidType(props) {
    const {pairs: midTypePairs} = useMidType()

    return (
        <Select
            mapOption={{label: e => e.middlewareTypeName}}
            options={midTypePairs}
            prop="middlewareType"
            {...props}
        />
    )
}
