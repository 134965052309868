import _Menu from '../../Menu.jsx'
import MenuButtonEdit from '../../menuButtons/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProducts from '../../menuButtons/MenuButtonInsertProducts.jsx'
import MenuButtonMap from './MenuButtonMap.jsx'
import MenuButtonPublishProduct from './MenuButtonPublishProduct.jsx'
import MenuButtonPushProduct from './MenuButtonPushProduct.jsx'
import MenuButtonReviseProduct from './MenuButtonReviseProduct.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertNode type="product" />
            <MenuButtonInsertProducts />
            <MenuButtonPushProduct />
            <MenuButtonPublishProduct />
            <MenuButtonReviseProduct />
        </_Menu>
    )
}
