const modules = import.meta.glob(
    './hotkeys/*.mjs',
    {import: 'default', eager: true}
)

export default Object.fromEntries(
    Object.values(modules)
        .map((module) => Object.entries(module))
        .flat()
)
