export function canInsertParent(node) {
    if (! node.parent) {
        this.logger.error('没有父节点的节点不能插入父节点', [node])
        return false
    }

    return true
}

export function insertParent(nodes) {
    const nodesToSelect = new Set()

    const insert = (trees) => {
        for (const [, descendants] of trees) {
            insert(descendants)
        }

        const nodes = trees.map(([node]) => node)

        for (const [, childNodes] of this.groupByParent(nodes)) {
            const parent = this.importTree()
            this.insertParent(parent, childNodes)
            this.logger.info('已插入父节点', childNodes)
            nodesToSelect.add(parent)
        }
    }

    const trees = this.treelize(nodes)
    insert(trees)
    this.selectNodes(nodesToSelect)
}
