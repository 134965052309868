import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canClearNodeText, clearNodeText} = map.commands
    const nodes = canClearNodeText(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'clear-node-text',
        label: '清除文本',
        suffix: '',
        onClick: () => clearNodeText(nodes),
    }
}
