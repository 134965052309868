import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonExportXml from './PropertiesPaneButtonExportXml.jsx'

const PropertiesPaneBatchVm = (props) => (
    <BatchPropertiesPane
        buttons={<ButtonExportXml nodes={props.nodes} />}
        {...props}
    />
)

export default PropertiesPaneBatchVm
