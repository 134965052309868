import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canPullProduct, pullProduct} = map.commands
    const nodes = canPullProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    return {
        disabled: 0 === nodes.length,
        key: 'pull-product',
        label: '刷新制品',
        onClick: () => pullProduct([map.root]),
    }
}
