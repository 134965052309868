import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaCommentNode.mjs'

/**
 * 注释节点类型的基类
 *
 * 注释节点仅用于分类/说明，不改变父子的业务关系
 */
export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,

        canDeleteNode(map, node) {
            // 注释节点不改变父子的业务关系，在可编辑子树的情况下可删除自身
            return this.canWriteTree(map, node)
        },

        canLinkType(map, node, type) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canLinkType(map, p, type)
            }
            // 没有父节点则允许链接任意类型
            else {
                return true
            }
        },

        canMountType(map, node, type) {
            const p = node.parent

            // 如果有父节点，交由父节点判断
            if (p) {
                const {bizNodeType} = p.data
                return map.BizNode[bizNodeType].canMountType(map, p, type)
            }
            // 没有父节点则允许挂载任意类型
            else {
                return true
            }
        },

        defaultChildType(map, node) {
            const _n = map.nodeProxy(node.parent)

            // 如果有父节点，交由父节点处理
            if (_n) {
                return _n.defaultChildType()
            }
            // 没有父节点则没有默认子节点
            else {
                return ''
            }
        },

        menuItemsInsertCommon(map, node) {
            const p = node.parent

            if (p) {
                const _p = map.nodeProxy(p)
                return _p.menuItemsInsertCommon()
            }
            else {
                return CommonNode.menuItemsInsertCommon.call(this, map, node)
            }
        },

        menuItemsInsertConcept(map, node) {
            const p = node.parent

            if (p) {
                const _p = map.nodeProxy(p)
                return _p.menuItemsInsertConcept()
            }
            else {
                return CommonNode.menuItemsInsertConcept.call(this, map, node)
            }
        },

        menuItemsInsertProduct(map, node) {
            const p = node.parent

            if (p) {
                const _p = map.nodeProxy(p)
                return _p.menuItemsInsertProduct()
            }
            else {
                return CommonNode.menuItemsInsertProduct.call(this, map, node)
            }
        },

        _fixChild(map, node, child) {
            const p = node.parent

            if (p) {
                const _p = map.nodeProxy(p)
                return _p._fixChild(map, p, child)
            }
            else {
                return CommonNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
