import {useBdType} from '@/biz/bizTable.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Bm from '../components/PropertiesControllerBm.jsx'
import Ss from '../components/PropertiesControllerSs.jsx'
import Model from './ModelBd.mjs'

const PropertiesPaneBd = (props) => {
    const {pairs: bdTypePairs} = useBdType()

    return (
        <ProductPropertiesPane
            controllers={
                <>
                    <Ss />
                    <Bm />
                    <Text prop="bdNo" />
                    <Text prop="bdName" />

                    <Select
                        mapOption={{label: e => e.nameCn}}
                        options={bdTypePairs}
                        prop="bdType"
                    />

                    <LongText prop="memo" />
                </>
            }

            Model={Model}
            snSuffix={<Analyse modes={['Reference']} />}
            {...props}
        />
    )
}

export default PropertiesPaneBd
