import {css} from '@emotion/react'
import {message} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssList = css({
    overflow: 'auto',
})

const cssMessage = css({
    padding: 8,
    borderBottom: '1px solid',
})

const cssMessageLevel = {
    debug: css({
        color: '#3d4f5d',
        backgroundColor: '#ffffff',
        borderColor: '#f0f0f0',
    }),

    error: css({
        color: '#ff2a6c',
        backgroundColor: '#fff0f0',
        borderColor: '#ffd6d6',
    }),

    info: css({
        color: '#3d4f5d',
        backgroundColor: '#ffffff',
        borderColor: '#f0f0f0',
    }),

    warn: css({
        color: '#865768',
        backgroundColor: '#fffbe6',
        borderColor: '#fff5c2',
    }),
}

const cssMessageBody = css({
    fontSize: 12,
})

const cssMessageFoot = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 8,
    fontSize: 9,
    lineHeight: 1,
    color: '#5f6368',
    marginTop: 4,
})

const cssMessageTimestamp = css({
    flexShrink: 0,
})

const cssMessageNodes = css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    cursor: 'pointer',
})

const MessageNodes = ({nodes, ...props}) => {
    const map = useMapContext()

    if (! nodes?.length) {
        return null
    }

    const nodeTexts = nodes.map(
        (node) => map.getNodeText(node)
    )

    const handleClick = () => {
        const aliveNodes = nodes.filter(
            ({id}) => map.getNode(id)
        )

        if (aliveNodes.length < nodes.length) {
            message.warn('部分节点已被删除')
        }

        map.execute(
            () => map.selectNodes(aliveNodes)
        )
    }

    return (
        <div
            css={cssMessageNodes}
            title={nodeTexts.join('\n')}
            onClick={handleClick}
            {...props}
        >
            {nodeTexts[0]}
        </div>
    )
}

const Message = ({level, nodes, text, timestamp, ...props}) => {
    const csss = [cssMessage, cssMessageLevel[level]]

    return (
        <div
            css={csss}
            {...props}
        >
            <div css={cssMessageBody}>{text}</div>

            <div css={cssMessageFoot}>
                <div css={cssMessageTimestamp}>
                    {new Date(timestamp).toLocaleTimeString()}
                </div>

                <MessageNodes nodes={nodes} />
            </div>
        </div>
    )
}

export default function MessageList({messages, ...props}) {
    const items = messages.map((message) => (
        <Message
            key={message.timestamp}
            {...message}
        />
    ))

    return (
        <div
            css={cssList}
            {...props}
        >
            {items}
        </div>
    )
}
