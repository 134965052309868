import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const refEditingNode = useRef()

    const extensions = () => ({
        isNodeEditingText(node) {
            return refEditingNode.current === node
        },

        useIsEditingText(node) {
            const [isEditing, setIsEditing] = useState(
                refEditingNode.current === node
            )

            useEffect(
                () => {
                    const handleEditEnd = () => setIsEditing(false)
                    const handleEditStart = () => setIsEditing(true)
                    subscribe(node, 'edit_text_end', handleEditEnd)
                    subscribe(node, 'edit_text_start', handleEditStart)

                    return () => {
                        unsubscribe(node, 'edit_text_end', handleEditEnd)
                        unsubscribe(node, 'edit_text_start', handleEditStart)
                    }
                },

                [node]
            )

            return isEditing
        },
    })

    const watchers = {
        edit_node_text(node) {
            publish(refEditingNode.current, 'edit_text_end')
            refEditingNode.current = node
            publish(node, 'edit_text_start')
        },
    }

    return {extensions, watchers}
}
