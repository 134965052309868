import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchCatNode from '../_ARCH_CAT/useArchCatNode.jsx'
import meta from './metaCatLs.mjs'

export default () => {
    const ArchCatNode = useArchCatNode()

    return extendNode(ArchCatNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(LS)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT|MARK)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CAT'
        },

        getStyle(map, node) {
            return {
                ...ArchCatNode.getStyle.call(this, map, node),
                backgroundColor: Color.PURPLE,
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
                ['MARK'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['LS'],
            ]
        },
    })
}
