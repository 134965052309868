export default class NodeEvent {
    constructor({detail, target, type}) {
        this.#detail = detail
        this.#target = target
        this.#type = type
    }

    chain = []

    get detail() {
        return this.#detail
    }

    get isPropagationStopped() {
        return this.#isPropagationStopped
    }

    get target() {
        return this.#target
    }

    get type() {
        return this.#type
    }

    stopPropagation() {
        this.#isPropagationStopped = true
    }

    #detail
    #isPropagationStopped = false
    #target
    #type
}
