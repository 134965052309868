import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaAlgAlg.mjs'

export default () => {
    const CategroyNode = useCategoryNode(meta)

    return extendNode(CategroyNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(ALG_(INPUT|OUTPUT|SUMMARY))$/.test(type)
        },

        isHidden(map, node) {
            return /^(SF)_LVL$/.test(map.data.desgAcc)
        },

        async _atCreate(map, node) {
            const childTypes = [
                'ALG_INPUT',
                'ALG_OUTPUT',
                'ALG_SUMMARY',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await CategroyNode._atCreate.call(this, map, node)
        },
    })
}
