import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()

    return {
        key: 'select-all-nodes',
        label: '全选',
        suffix: 'Ctrl+A',
        onClick: () => map.commands.selectAllNodes(),
    }
}
