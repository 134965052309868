import {createContext, useContext, useEffect, useRef, useState} from 'react'
import {css} from '@emotion/react'
import useSWR from 'swr'
import {Empty} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {defer} from 'hopedove-dom'
import InputText from '@/components/Form/InputText.jsx'

const cssLauncher = css({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: '20%',
    right: 0,
    left: 0,
    zIndex: 9999,
    width: 400,
    maxHeight: 300,
    borderRadius: 4,
    boxShadow: '0 0 8px 0px #d9d9d9',
    margin: 'auto',
})

const cssInputHint = css({
    borderRadius: 0,
})

const cssList = css({
    listStyle: 'none',
    backgroundColor: '#fff',
    padding: 0,
    border: '1px solid #d9d9d9',
    borderTop: 'none',
    overflowY: 'auto',
    margin: 0,
})

const cssListItem = css({
    padding: '4px 12px',
})

const cssListItemActive = css({
    backgroundColor: '#f5f5f5',
})

const cssEmpty = css({
    backgroundColor: '#fff',
    padding: 12,
    border: '1px solid #d9d9d9',
    borderTop: 'none',
    margin: 0,
})

const Launcher = ({
    getItems,
    placeholder,
    onSelect,
}) => {
    const refListEl = useRef()
    const [hint, setHint] = useState('')
    const [activeIndex, setActiveIndex] = useState(-1)

    const {
        data: items = [],
        isLoading
    } = useSWR([hint], () => getItems(hint))

    useEffect(
        () => {
            setActiveIndex(-1)

            if (1 === items.length) {
                onSelect(items[0].key)
            }
        },

        [items]
    )

    useEffect(
        () => {
            if (-1 < activeIndex) {
                const itemEl = refListEl.current.children[activeIndex]
                itemEl.scrollIntoViewIfNeeded(false)
            }
        },

        [activeIndex]
    )

    const handleBlur = () => onSelect(null)

    const handleKeyDown = (e) => {
        ({

            ArrowDown: () => {
                setActiveIndex(
                    (i) => i + 1 < items.length ? i + 1 : 0
                )
            },

            ArrowUp : () => {
                setActiveIndex(
                    (i) => i - 1 < 0 ? items.length - 1 : i - 1
                )
            },

            Enter: () => {
                if (-1 < activeIndex) {
                    onSelect(items[activeIndex].key)
                }
                else {
                    onSelect(items[0].key)
                }
            },

            Escape: () => onSelect(null),
        })[e.key]?.()
    }

    const lis = items.map((item, i) => {
        const csss = [cssListItem]

        if (activeIndex === i) {
            csss.push(cssListItemActive)
        }

        const handleMouseDown = () => onSelect(items[i].key)
        const handleMouseEnter = () => setActiveIndex(i)

        return (
            <li
                key={item.key}
                css={csss}
                onMouseDown={handleMouseDown}
                onMouseEnter={handleMouseEnter}
            >
                {item}
            </li>
        )
    })

    return (
        <div
            css={cssLauncher}
            onBlur={handleBlur}
        >
            <InputText
                css={cssInputHint}
                allowClear
                changeDelay={400}
                focus
                placeholder={placeholder}
                prefix={isLoading ? <LoadingOutlined /> : null}
                value={hint}
                onChange={setHint}
                onKeyDown={handleKeyDown}
            />

            {
                0 < items.length ?
                    <ol
                        ref={refListEl}
                        css={cssList}
                    >
                        {lis}
                    </ol>
                    :
                    <Empty
                        css={cssEmpty}
                        description="未找到匹配的数据"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
            }
        </div>
    )
}

const LauncherContext = createContext()

export default () => useContext(LauncherContext)

export const LauncherProvider = (props) => {
    const [launcherProps, setLauncherProps] = useState({isOpen: false})

    const open = (props) => {
        const deferred = defer()

        setLauncherProps({
            ...props,
            isOpen: true,

            onSelect: (item) => {
                setLauncherProps({isOpen: false})
                deferred.resolve(item)
            }
        })

        return deferred.promise
    }

    return (
        <>
            {launcherProps.isOpen && <Launcher {...launcherProps} />}
            <LauncherContext.Provider value={open} {...props} />
        </>
    )
}
