export const canCopyNodeUrl = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canCopyNodeUrl(node)
        )
    }
}

export const copyNodeUrl = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.actions.copyNodeUrl(nodes)
        )
    }
}
