import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import Version from '../components/PropertiesControllerVersion/PropertiesControllerVersion.jsx'

const PropertiesPaneCatData = (props) => (
    <NodePropertiesPane {...props}>
        <Version label="业务结构版本" />
    </NodePropertiesPane>
)

export default PropertiesPaneCatData
