function editText() {
    const {
        behaviours: {startEditNodeText},
        selectedNodes,
    } = this

    if (1 === selectedNodes.size) {
        const [node] = [...selectedNodes]
        const text = this.getNodeText(node)
        startEditNodeText(node, text)
    }
}

export default {
    'F2': editText,
}
