import http from '@/biz/http.mjs'

export default {

    create: (data) => http.post('/dmbds', data),

    publish: (data) => http.put(
        '/dmbds/rlsandrevise',
        {...data, stsCode: 'RLS'}
    ),

    pull: ({dpType, pkid}) => http.get(`/dmbds/${pkid}/dps`, {dpType}),
    push: (data) => http.post('/dmbds/batchmodify', data),
    read: ({pkid, ...args}) => http.get(`/dmbds/${pkid}`, args),
    readList: (args) => http.get('/dmbds', args),

    revise: ({pkid}) => http.put(
        '/dmbds/rlsandrevise',
        {pkid, stsCode: 'REVISE'}
    ),

    update: (data) => http.put(`/dmbds/${data.pkid}`, data),

    updateMigrate: (data) => http.put(
        `/dmbds/${data.pkid}/migrate`,
        data
    ),

    updateMap: (data) => http.put(`/dmbds/${data.pkid}/map`, data),
}
