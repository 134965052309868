import {useRef} from 'react'
import getBoundingClientRects from '@/script/getBoundingClientRects'

export default () => {
    const refInitialSelectdNodes = useRef()
    const refSelection = useRef()
    const refBase = useRef()
    const refRects = useRef()

    const changeNodeSelection = (map) => {
        if (! (refRects.current && refSelection.current)) {
            return
        }

        const [x0, y0, x1, y1] = refSelection.current
        const cx = (x0 + x1) / 2
        const cy = (y0 + y1) / 2
        const w = Math.abs(x1 - x0)
        const h = Math.abs(y1 - y0)

        const isIterectedWithSelection = (rect) => {
            const {
                bottom,
                height,
                left,
                right,
                top,
                width,
            } = rect

            const {abs} = Math

            return (
                abs(cx - left) + abs(cx - right) < width + w &&
                abs(cy - top) + abs(cy - bottom) < height + h
            )
        }

        const nodesToSelect = new Set(refInitialSelectdNodes.current)

        const toggleNodeSelection = (node) => {
            if (nodesToSelect.has(node)) {
                nodesToSelect.delete(node)
            }
            else {
                nodesToSelect.add(node)
            }
        }

        const {baseX, baseY} = refBase.current

        const normalize = (rect) => {
            if (! rect.isNormalized) {

                const normalizeRect = (rect) => {
                    const {
                        bottom,
                        height,
                        left,
                        right,
                        top,
                        width,
                    } = rect

                    return {
                        bottom: bottom - baseY,
                        height,
                        left: left - baseX,
                        right: right - baseX,
                        top: top - baseY,
                        width,
                    }
                }

                rect.rectNode = normalizeRect(rect.rectNode)
                rect.rectTree = normalizeRect(rect.rectTree)
                rect.isNormalized = true
            }

            return rect
        }

        const walk = (rect) => {
            const {children, node, rectNode, rectTree} = normalize(rect)

            if (! isIterectedWithSelection(rectTree)) {
                return
            }

            if (isIterectedWithSelection(rectNode)) {
                toggleNodeSelection(node)
            }

            for (const childRect of children) {
                walk(childRect)
            }
        }

        walk(refRects.current)

        map.execute(
            () => map.selectNodes(nodesToSelect)
        )
    }

    const getRects = async (map) => {
        refRects.current = null

        const next = (chain) => {
            const {isFolded} = chain[0]
            const isHidden = map.isNodeHidden(chain[0])
            const yieldChildren = ! (isFolded || isHidden)
            const yieldNode = ! isHidden
            return {yieldChildren, yieldNode}
        }

        const nodes = [...map.walkDown(map.root, {next})]
        const elNodes = nodes.map(n => map.dom.nodes.get(n))
        const elTrees = nodes.map(n => map.dom.trees.get(n))

        const [[rectCanvas], rectNodes, rectTrees] = await Promise.all([
            getBoundingClientRects([map.canvas]),
            getBoundingClientRects(elNodes),
            getBoundingClientRects(elTrees),
        ])

        const {scrollLeft, scrollTop} = map.canvas

        refBase.current = {
            baseX: rectCanvas.left - scrollLeft,
            baseY: rectCanvas.top - scrollTop,
        }

        const node2data = new Map

        for (let i = 0; i < nodes.length; i += 1) {
            const node = nodes[i]
            const rectNode = rectNodes[i]
            const rectTree = rectTrees[i]
            node2data.set(nodes[i], {node, rectNode, rectTree})
        }

        const walk = (node) => {
            const data = node2data.get(node)

            if (! data) {
                return
            }

            data.children = []

            for (const child of map.children(node)) {
                const childData = walk(child)

                if (childData) {
                    data.children.push(childData)
                }
            }

            return data
        }

        refRects.current = walk(map.root)
        changeNodeSelection(map)
    }

    const watchers = {
        canvas_selection_change(selection) {
            refSelection.current = selection
            changeNodeSelection(this)
        },

        canvas_selection_change_end() {
            this.finishTask()
        },

        canvas_selection_change_start(e) {
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                refInitialSelectdNodes.current = new Set(
                    this.selectedNodes
                )
            }
            else {
                refInitialSelectdNodes.current = new Set()
            }

            getRects(this)
            this.startTask()
        },
    }

    return {watchers}
}
