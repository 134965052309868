import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBfDataflow.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canLinkType(map, node, type) {
            if ('BM' === map.data.prjTypeCode) {
                return /^(BDSUMMARY)$/.test(type)
            }
            else {
                return false
            }
        },

        canMountType(map, node, type) {
            return /^(BFDATAFLOW|CAT)$/.test(type)
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['BFDATAFLOW'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            if ('BM' === map.data.prjTypeCode) {
                return [
                    ['BDSUMMARY'],
                ]
            }
            else {
                return []
            }
        },
    })
}
