import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssListItem = css({
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    marginLeft: '0 !important',
})

const cssListItemProductName = css({
    fontSize: 16,
})

const cssListItemProductVersion = css({
})

const ListItem = ({latest, title, version, ...props}) => {
    return (
        <Checkbox
            css={cssListItem}
            {...props}
        >
            <div css={cssListItemProductName}>{title}</div>
            <div css={cssListItemProductVersion}>{version}→{latest}</div>
        </Checkbox>
    )
}

export default function ListUpgradable({
    items,
    selectedKeys,
    onSelect,
    ...props
}) {
    const map = useMapContext()

    const handleChangeItem = (key) => {
        return async ({target: {checked}}) => {
            const node = map.getNode(key)

            await map.execute(
                () => map.selectNodes([node])
            )

            const newSelectedKeys = new Set(selectedKeys)

            if (checked) {
                newSelectedKeys.add(key)
            }
            else {
                newSelectedKeys.delete(key)
            }

            onSelect(newSelectedKeys)
        }
    }

    const listItems = items.map(
        ({key, latest, title, version}) => (
            <ListItem
                key={key}
                latest={latest}
                title={title}
                version={version}
                onChange={handleChangeItem(key)}
            />
        )
    )

    return (
        <div
            {...props}
        >
            {listItems}
        </div>
    )
}
