export function canUpdateNodeData(nodes) {
    return [...nodes].filter(
        node => this.actions.canUpdateNodeData(node)
    )
}

export async function updateNodeData(nodes, transform) {
    await this.cmd(
        () => this.actions.updateNodeData(nodes, transform)
    )
}
