import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonMakeApi from './PropertiesPaneButtonMakeApi.jsx'

const PropertiesPaneAlg = (props) => (
    <BatchPropertiesPane
        buttons={
            <>
                <ButtonMakeApi />
            </>
        }

        {...props}
    />
)

export default PropertiesPaneAlg
