import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaScene.mjs'

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|FOPR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FOPR'
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.GOLD,
                shape: 'BreakangleRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FOPR'],
            ]
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('COPR' === bizNodeType) {
                map.BizNode.FOPR.castFrom(map, child)
                return true
            }
            else {
                return CommonNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
