function redoChange() {
    this.redoChange()
}

function undoChange() {
    this.undoChange()
}

function redoSelection() {
    this.redoSelection()
}

function undoSelection() {
    this.undoSelection()
}

export default {
    'Ctrl+Y': redoChange,
    'Meta+Y': redoChange,
    'Ctrl+Z': undoChange,
    'Meta+Z': undoChange,
    'Ctrl+ArrowRight': redoSelection,
    'Meta+ArrowRight': redoSelection,
    'Ctrl+ArrowLeft': undoSelection,
    'Meta+ArrowLeft': undoSelection,
}
