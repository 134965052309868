export function canFold(nodes) {
    return [...nodes].filter(
        node => this.actions.canFold(node)
    )
}

export async function foldNode(nodes) {
    await this.cmd(
        () => this.actions.foldNode(nodes)
    )
}

export async function foldTree(nodes) {
    await this.cmd(
        () => this.actions.foldTree(nodes)
    )
}
