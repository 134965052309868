function copyNodeStyle() {
    const {
        commands: {canCopyNodeStyle, copyNodeStyle},
        selectedNodes,
    } = this

    const nodes = canCopyNodeStyle(selectedNodes)
    copyNodeStyle(nodes)
}

export default {
    'Ctrl+Alt+C': copyNodeStyle,
    'Meta+Alt+C': copyNodeStyle,
}
