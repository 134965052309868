export function canInsertChild(nodes) {
    return [...nodes].filter(
        node => this.actions.canInsertChild(node)
    )
}

export async function appendChild(nodes) {
    await this.cmd(
        () => this.actions.appendChild(nodes)
    )
}

export async function prependChild(nodes) {
    await this.cmd(
        () => this.actions.prependChild(nodes)
    )
}
