import useKeystroke from '@/hooks/useKeystroke.mjs'
import {publish} from '@/script/event.mjs'
import {TreeDocContext} from './TreeDocContext.jsx'

const useKeystrokeEvents = (doc) => useKeystroke({
    onKeyDown: (e) => {
        publish(doc, 'key_down', e)
    },

    onKeyUp: (e) => {
        publish(doc, 'key_up', e)
    },
})

export default function TreeDocument({children, doc, ...props}) {
    const keystrokeEventHandlers = useKeystrokeEvents(doc)

    return (
        <div
            tabIndex="-1"
            {...keystrokeEventHandlers}
            {...props}
        >
            <TreeDocContext.Provider value={doc}>
                {children}
            </TreeDocContext.Provider>
        </div>
    )
}
