import {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {Tree} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const cssTree = css({
    fontSize: 12,
    overflow: 'auto',

    '.ant-tree-switcher': {
        width: 12,
    },

    '.ant-tree-list-holder-inner': {
        display: 'grid !important',
    },

    '.ant-tree-treenode': {
        padding: '2px 8px',

        '&:hover': {
            backgroundColor: '#f5f5f5',
        },

        '&:has(.ant-tree-node-selected)': {
            backgroundColor: '#0099CC',
        },
    },

    '.ant-tree-node-content-wrapper': {
        display: 'inline-flex',
        backgroundColor: 'transparent !important',
    },

    '.ant-tree-node-selected': {
        color: '#fff',
    },

    '.ant-tree-title': {
        display: 'inline-block',
        maxWidth: '16em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
})

const getTreeData = (map) => {
    const walk = (node) => {
        const text = map.getNodeText(node)
        const prefix = map.getNodeTextPrefix(node)
        const suffix = map.getNodeTextSuffix(node)
        const title = [prefix, text, suffix].join('') || '(空白)'

        const children = [...map.children(node)].filter(
            n => ! map.isNodeHidden(n)
        )

        return {
            children: children.map(walk),
            key: node.id,
            title,
        }
    }

    return [walk(map.root)]
}

const NavTree = (props) => {
    const map = useMapContext()
    const [treeData, setTreeData] = useState(() => getTreeData(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                map.logger.withOff(
                    () => setTreeData(getTreeData(map))
                )
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return (
        <Tree
            css={cssTree}
            blockNode
            defaultExpandAll
            treeData={treeData}
            {...props}
        />
    )
}

export default NavTree
