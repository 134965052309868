import {useState} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import MessageList from './MessageList.jsx'
import ToolBar from './ToolBar.jsx'

const cssPane = css({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
})

const cssToolBar = css({
    borderBottom: '1px solid #e8e8e8',
})

export default function MessagePane(props) {
    const map = useMapContext()
    const logs = map.useLogs()
    const [levels, setLevels] = useState(['error', 'info', 'warn'])

    const filteredLogs = logs.filter(
        ({level}) => levels.includes(level)
    )

    return (
        <div
            css={cssPane}
            {...props}
        >
            <ToolBar
                css={cssToolBar}
                levels={levels}
                onChangeLevels={setLevels}
            />

            <MessageList messages={filteredLogs} />
        </div>
    )
}
