import {Modal} from 'antd'

export const canDiscardProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canDiscardProduct(node)
        )
    }
}

export const discardProduct = () => () => {
    return async function (nodes) {
        await new Promise(resolve => {
            Modal.confirm({
                content: '确定要废弃制品吗？此操作不可逆',

                onOk: async () => {
                    await this.cmd(
                        () => this.runParallelAction(
                            () => this.actions.discardProduct(nodes),
                            {action: '废弃模型制品'},
                        )
                    )

                    resolve()
                },
            })
        })
    }
}
