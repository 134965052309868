function toggleFoldNode() {
    const {
        commands: {canFold, canUnfold, foldNode, unfoldNode},
        selectedNodes,
    } = this

    const nodesCanFold = canFold(selectedNodes)

    if (0 < nodesCanFold.length) {
        foldNode(nodesCanFold)
        return
    }

    const nodesCanUnfold = canUnfold(selectedNodes)

    if (0 < nodesCanUnfold.length) {
        unfoldNode(nodesCanUnfold)
    }
}

export default {
    'Ctrl+/': toggleFoldNode,
    'Meta+/': toggleFoldNode,
}
