import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelFopr.mjs'
import OprType from './PropertiesControllerOprType.jsx'

const PropertiesPaneFopr = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="oprTitle" />
                <OprType prop="oprType" />
            </>
        }

        Model={Model}
        noSn
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneFopr
