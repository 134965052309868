export const canPublishProduct = () => () => {
    return function (node) {
        const {bizNodeType, pkid, stsCode} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能发布', [node])
            return false
        }

        if (! /^(DRAFT|REVISE)$/.test(stsCode)) {
            this.logger.error('只有草稿/修订状态的制品才能发布', [node])
            return false
        }

        const bn = this.BizNode[bizNodeType]

        if (! bn.publish) {
            this.logger.error(`${bn.name}不支持发布`, [node])
            return false
        }

        return (
            ! node.parent ||
            bn.isMounted(this, node)
        )
    }
}

export const publishProduct = () => () => {
    return function (nodes, extra) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        await _n.publish(extra)
                        this.logger.info('发布模型制品成功', [node])
                    }
                    catch (err) {
                        this.logger.error(
                            `发布模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
