import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canUpdateNodeStyle, pasteNodeStyle} = map.commands
    const nodes = canUpdateNodeStyle(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'paste-node-style',
        label: '粘贴样式',
        suffix: 'Ctrl+Alt+V',
        onClick: () => pasteNodeStyle(nodes),
    }
}
