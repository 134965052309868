import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    vmUserCode: {
        name: () => '程序模块代码',
        required: () => true,
    },

    vmName: {
        name: () => '程序模块名称',
        required: () => true,
    },

    vmPack: {
        name: () => '程序包',
    },

    isClass: {
        name: () => '是否程序模块',
    },
})
