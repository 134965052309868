import {css} from '@emotion/react'
import ColorPicker from './ColorPicker.jsx'
import InputText from './InputText.jsx'
import cssInput from './cssInput.mjs'

const defaultOnChange = () => {}

const InputRgb = (props) => {
    const test = (s) => /^[0-9A-F]{1,6}$/i.test(s)
    const transform = (s) => s.toUpperCase()

    return (
        <InputText
            selectOnFocus
            test={test}
            title="RRGGBB"
            transform={transform}
            {...props}
        />
    )
}

const InputAlpha = (props) => {
    const test = (s) => /^(100|\d{1,2})(\.\d{0,2})?%?$/.test(s)

    return (
        <InputText
            selectOnFocus
            test={test}
            title="透明度"
            {...props}
        />
    )
}

const cssColorPicker = css({
    display: 'grid',
    minHeight: '100%',
    aspectRatio: '1',

    '&:not(:hover)': {
        borderColor: 'transparent',
        borderRadius: 0,
    },
})

const cssInputRgb = css({
    '&.ant-input-sm': {
        padding: '0 5px',
    },

    '&:not(:hover)': {
        borderColor: 'transparent',
        borderRightColor: '#d9d9d9',
        borderLeftColor: '#d9d9d9',
        borderRadius: 0,
    },
})

const cssInputAlpha = css({
    '&.ant-input-sm': {
        padding: '0 5px',
    },

    '&:not(:hover)': {
        borderColor: 'transparent',
        borderRadius: 0,
    },
})

const cssInputColor = css({
    ...cssInput,
    display: 'grid',
    gridTemplateColumns: 'auto 4fr 3fr',
    '&:focus': null,
    '&:hover': null,
})

export default function InputColor({
    disabled,
    readOnly,
    size,
    value,
    onChange = defaultOnChange,
    ...props
}) {
    const [rgb, alpha] = (() => {
        if (value) {
            {
                const matches = value.match(/^\s*rgba?\(\s*(\d|[1-9]\d\d?|2[0-5]{2})\s*,\s*(\d|[1-9]\d\d?|2[0-5]{2})\s*,\s*(\d|[1-9]\d\d?|2[0-5]{2})\s*(?:,\s*(1|0?\.\d+))?\s*\)\s*$/)

                if (matches) {
                    const [, r, g, b, a] = matches

                    return [
                        [parseInt(r), parseInt(g), parseInt(b)],
                        parseFloat(a),
                    ]
                }
            }

            {
                const matches = value.match(/^\s*#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})?\s*$/i)

                if (matches) {
                    const [, r, g, b, a] = matches
                    const parse = hex => parseInt(hex, 16)

                    return [
                        [parse(r), parse(g), parse(b)],
                        parse(a),
                    ]
                }
            }
        }

        return [[], 1]
    })()

    const rrggbb = rgb
        .map((d) => {
            if (d || 0 === d) {
                if (d < 16) {
                    return `0${d.toString(16)}`
                }
                else {
                    return d.toString(16)
                }
            }
            else {
                return ''
            }
        })
        .join('')
        .toUpperCase()

    const handleChangeRgb = (s) => {
        if (/^[0-9A-F]{6}$/.test(s)) {
            const rgbHex = s.match(/[0-9A-F]{2}/g)
            const rgbDec = rgbHex.map((h) => parseInt(h, 16))
            onChange(`rgba(${rgbDec.join()},${alpha})`)
        }
        else {
            onChange('')
        }
    }

    const handleChangeAlpha = (s) => {
        const alpha = parseFloat(s) / 100

        if (rrggbb) {
            onChange(`rgba(${rgb.join()},${alpha})`)
        }
        else {
            onChange(`rgba(0,0,0,${alpha})`)
        }
    }

    return (
        <div
            css={cssInputColor}
            {...props}
        >
            <ColorPicker
                css={cssColorPicker}
                disabled={disabled}
                readOnly={readOnly}
                size={size}
                title="选取颜色"
                value={value}
                onChange={onChange}
            />

            <InputRgb
                css={cssInputRgb}
                disabled={disabled}
                readOnly={readOnly}
                size={size}
                value={rrggbb}
                onChange={handleChangeRgb}
            />

            <InputAlpha
                css={cssInputAlpha}
                disabled={disabled}
                readOnly={readOnly}
                size={size}
                value={`${alpha * 100}%`}
                onChange={handleChangeAlpha}
            />
        </div>
    )
}
