import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const TableChooseVr = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '虚拟机名称',
            dataIndex: 'vsName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'vsEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'vsIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'vsInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/vrs"
            query={query}
            {...props}
        />
    )
}

export default TableChooseVr
