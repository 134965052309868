import {useRef} from 'react'

export default () => {
    const refCanvas = useRef(null)

    const extensions = () => {
        return {
            get canvas() {return refCanvas.current},
            set canvas(value) {refCanvas.current = value},
        }
    }

    return {extensions}
}
