export function canInsertSibling(nodes) {
    return [...nodes].filter(
        node => this.actions.canInsertSibling(node)
    )
}

export async function insertSiblingAfter(nodes) {
    await this.cmd(
        () => this.actions.insertSiblingAfter(nodes)
    )
}

export async function insertSiblingBefore(nodes) {
    await this.cmd(
        () => this.actions.insertSiblingBefore(nodes)
    )
}
