function deleteNode() {
    const {
        commands: {canDeleteNode, deleteNode},
        selectedNodes,
    } = this

    const nodes = canDeleteNode(selectedNodes)
    deleteNode(nodes)
}

export default {
    'Ctrl+Backspace': deleteNode,
    'Meta+Backspace': deleteNode,
    'Ctrl+Delete': deleteNode,
    'Meta+Delete': deleteNode,
}
