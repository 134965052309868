import {useUser} from "@/biz/user.mjs"

export default permission => {
    const user = useUser()

    if (permission) {
        return {}
    }

    function resetPermission() {
        this.permission.add('revise')

        if ('1' === user.isPlatformAdm) {
            this.permission.add('write')
        }
        else {
            const {
                crtId,
                mapStsCode,
                mapTypeCode,
                prjTypeCode,
                uptId,
            } = this.data

            if (
                (
                    (
                        'REVISE' === mapStsCode &&
                        uptId === user.pkid
                    ) ||
                    (
                        'DRAFT' === mapStsCode &&
                        crtId === user.pkid
                    )
                ) &&

                (
                    'DEP_MAP' !== mapTypeCode ||
                    'ONLINE' === prjTypeCode
                )
            ) {
                this.permission.add('write')
            }
            else {
                this.permission.delete('write')
            }
        }
    }

    const watchers = {
        load: resetPermission,
        meta_change: resetPermission,
    }

    return {watchers}
}
