import {memo} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeDeliveryVersion from './NodeDeliveryVersion.jsx'
import NodeDesc from './NodeDesc.jsx'
import NodeIcons from './NodeIcons.jsx'
import NodeOwner from './NodeOwner.jsx'
import NodeProject from './NodeProject.jsx'
import NodeTextCore from './NodeTextCore.jsx'
import NodeVersion from './NodeVersion.jsx'

const cssNodeText = css({
    display: 'grid',

    gridTemplate: `
        "icons desc owner ver dVer prj"
        "icons core core ver dVer prj" / auto auto 1fr auto auto auto
    `,

    placeItems: 'center start',
})

const cssNodeIcons = css({
    gridArea: 'icons',
})

const cssNodeDesc = css({
    gridArea: 'desc',
})

const cssNodeOwner = css({
    gridArea: 'owner',
})

const cssNodeTextCore = css({
    gridArea: 'core',
})

const cssNodeVersion = css({
    gridArea: 'ver',
    alignSelf: 'end',
})

const cssNodeDeliveryVersion = css({
    gridArea: 'dVer',
    alignSelf: 'end',
})

const cssNodeProject = css({
    gridArea: 'prj',
    alignSelf: 'end',
})

const NodeText = ({node, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])

    return (
        <div
            css={cssNodeText}
            {...props}
        >
            <NodeIcons
                css={cssNodeIcons}
                node={node}
            />

            <NodeDesc
                css={cssNodeDesc}
                node={node}
            />

            <NodeOwner
                css={cssNodeOwner}
                node={node}
            />

            <NodeTextCore
                css={cssNodeTextCore}
                node={node}
            />

            <NodeVersion
                css={cssNodeVersion}
                node={node}
            />

            <NodeDeliveryVersion
                css={cssNodeDeliveryVersion}
                node={node}
            />

            <NodeProject
                css={cssNodeProject}
                node={node}
            />
        </div>
    )
}

const MemorizedNodeText = memo(NodeText, () => true)
MemorizedNodeText.displayName = 'NodeText'

export default MemorizedNodeText
