import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    umUserCode: {
        name: () => '界面入口代码',
    },

    umName: {
        name: () => '界面入口名称',
        required: () => true,
    },
})
