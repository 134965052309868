import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaAttributeNode.mjs'

/**
 * 属性节点类型的基类
 *
 * 属性节点通常用于把其父节点的某个属性作为节点展示，
 * 其实际并不存在于地图模型中
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canCopyTree(map, node) {
            map.logger.error('不能拷贝属性节点', [node])
            return false
        },

        canDuplicate(map, node) {
            map.logger.error('不能复制属性节点', [node])
            return false
        },

        canWriteData(map, node) {
            // 可编辑性同父节点

            const {bizNodeType} = node.parent.data
            return map.BizNode[bizNodeType].canWriteData(map, node.parent)
        },

        exportTree(map, node) {
            return null
        },

        getDesc(map, node) {
            const {data} = node.parent
            const {Model} = map.BizNode[data.bizNodeType]
            const model = new Model(data)
            return model.name(this.prop)
        },

        isMounted(map, node) {
            const _p = map.nodeProxy(node.parent)
            return _p.isMounted()
        },

        async _atCreate(map, node) {
            const value = node.parent.data[this.prop]

            if (value) {
                node.data = {
                    ...node.data,
                    [this.textProp]: value,
                }

                await BaseNode._atCreate.call(this, map, node)
            }
            else {
                map.deleteTree(node)
            }
        },

        // TODO
        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail

                // 自身文本值改变时同步其父节点对应属性的值

                const newValue = node.data[this.textProp]
                const oldValue = oldData[this.textProp]

                if (newValue === oldValue) {
                    return
                }

                node.parent.data = {
                    ...node.parent.data,
                    [this.prop]: newValue,
                }
            }

            await BaseNode._onChange.call(this, map, node, event)
        },

        _onDelete(map, node, event) {
            if (node !== event.target) {
                return
            }

            // 删除节点时清空其父节点对应属性的值

            node.parent.data = {
                ...node.parent.data,
                [this.prop]: '',
            }
        },
    })
}
