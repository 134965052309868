import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default (initData) => {
    const refData = useRef(initData)

    const extensions = () => ({
        get data() {
            return refData.current
        },

        set data(data) {
            refData.current = data
            publish(this, 'meta_change', data)
        },

        useMetaData() {
            const [data, setData] = useState(refData.current)

            useEffect(
                () => {
                    subscribe(this, 'meta_change', setData)
                    return () => unsubscribe(this, 'meta_change', setData)
                },

                []
            )

            return data
        }
    })

    return {extensions}
}
