import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Number from '../../SidePanel/RightPanel/PropertiesControllerNumber.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'

export default function PropertiesControllerDefVal(props) {
    const [{dataTypeCode, dfLen, dfPrecision}] = useProperties()

    if (/^(INT|LONG|SHORT)$/.test(dataTypeCode)) {
        return (
            <Number
                int
                min={null}
                prop="defVal"
                {...props}
            />
        )
    }
    else if (/^(DOUBLE|FLOAT|NUMBER)$/.test(dataTypeCode)) {
        return (
            <Number
                min={null}
                precision={dfLen}
                prop="defVal"
                scale={dfPrecision}
                {...props}
            />
        )
    }
    else if (/^(BOOLEAN)$/.test(dataTypeCode)) {
        const options = [
            [1, 'TRUE'],
            [0, 'FALSE'],
        ]

        return (
            <Select
                options={options}
                prop="defVal"
                {...props}
            />
        )
    }
    else if (/CHAR/.test(dataTypeCode)) {
        return (
            <LongText
                maxLength={0 <= dfLen ? dfLen : null}
                prop="defVal"
                {...props}
            />
        )
    }
    else {
        return (
            <Text
                maxLength={0 <= dfLen ? dfLen : null}
                prop="defVal"
                {...props}
            />
        )
    }
}
