import insertNode from './insertNode.mjs'

export const insertBizNode = () => () => {
    return function (nodes, type) {
        return insertNode(
            this,
            nodes,
            this.appendChild.bind(this),
            () => type
        )
    }
}
