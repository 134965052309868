import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    address: {
        name: () => '超链接地址',
        required: () => true,
    },

    text: {
        name: () => '超链接名称',
    },
})
