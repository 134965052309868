function pasteNodeStyle() {
    const {
        commands: {canUpdateNodeStyle, pasteNodeStyle},
        selectedNodes,
    } = this

    const nodes = canUpdateNodeStyle(selectedNodes)
    pasteNodeStyle(nodes)
}

export default {
    'Ctrl+Alt+V': pasteNodeStyle,
    'Meta+Alt+V': pasteNodeStyle,
}
