export default [
    ['KEY', '通用'],
    ['NEW', '创建对象'],
    ['VERIFY', '数据校验'],
    ['FC', '调用程序'],
    ['LSI', '调用外联接口'],
    ['DT', '数据库操作'],
    ['FILE', '文件操作'],
    ['CACHE', '缓存操作'],
]
