import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canDuplicateTree, duplicateTree} = map.commands
    const nodes = canDuplicateTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'duplicate-tree',
        label: '复制',
        suffix: 'Ctrl+D',
        onClick: () => duplicateTree(nodes),
    }
}
