import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmCode: {
        name: () => '功能模块',
    },

    ssCode: {
        name: () => '子系统',
    },

    vmCode: {
        name: () => '程序模块',
    },

    fcUserCode: {
        name: () => '能力代码',
        required: () => true,
    },

    fcName: {
        name: () => '能力名称',
        required: () => true,
    },

    fcUg: {
        name: () => '需求描述',
    },

    isApi: {
        name: () => '是否 API',
    },

    perfDesc: {
        name: () => '性能要求',
    },
})
