function duplicateTree() {
    const {
        commands: {canDuplicateTree, duplicateTree},
        selectedNodes,
    } = this

    const nodes = canDuplicateTree(selectedNodes)
    duplicateTree(nodes)
}

export default {
    'Ctrl+D': duplicateTree,
    'Meta+D': duplicateTree,
}
