import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    arDesc: {
        name: () => '详述',
    },

    arName: {
        name: () => '描述标题',
    },

    arTypeCode: {
        name: () => '描述类型',
    },
})
