import {useBdt} from '@/biz/bizTable.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDataType from '../components/SelectDataType.jsx'

export default function PropertiesControllerBdt({
    className,
    style,
    ...props
}) {
    const map = useMapContext()
    const {prjId} = map.data
    const {dict: Bdt} = useBdt({prjId})
    const [values, update] = useProperties()
    const bdt = Bdt[values.bdtCode]
    const p = usePropertiesController({prop: 'bdtCode'})

    const handleChange = ({
        code,
        id,
        name,
    }) => {
        update({
            bdtCode: code,
            bdtId: id,
            bdtName: name,
        })
    }

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            <SelectDataType
                disabled={p.disabled}
                readOnly={p.readOnly}

                value={{
                    code: values?.bdtCode,
                    name: bdt?.bdtName,
                    type: 'BDT',
                }}

                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
