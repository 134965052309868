import React, { useEffect, useState } from 'react';
import { Input, Spin, Empty } from 'antd';
import Modal from '@/components/Modal/Modal';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Table from '@/biz/Table.jsx';

function containsAllKeywords(text, ...keywords) {
    const pattern = new RegExp(keywords.map(kw => `(?=.*?${kw})`).join(''), 'i');
    return pattern.test(text);
}

const Dialog = ({ params, value, onOk, multiple = false, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const prjId = params.prjId;
            const sVer = params.sVer;
            const dbId = params.dbId;
            const dbCode = params.dbCode;
            const stsCodes = ['RLS', 'REVISE'];

            const data = await runAsync(
                async () => {
                    return await http.get(`/dmdts`, { prjId, sVer, dbId, dbCode, stsCodes });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setData(data);
            setFilterData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleOk = () => {
        if (multiple) {
            onOk(selectedRows.map(v => { return { ...v, dtId: v.pkid } }));
        } else {
            if (selectedRows.length > 0) {
                const result = selectedRows[0];
                onOk({ ...result, dtId: result.pkid });
            }
        }
    }

    const handleSearch = (keyword) => {
        setFilterData(searchKeyword(keyword));
    }

    const searchKeyword = (keyword) => {
        const iterate = (item) => {
            if (keyword) {
                const keywords = keyword.trim().toLowerCase().split(' ');
                const { dtUserCode, dtName } = item;
                const text = dtUserCode?.toLowerCase() + dtName?.toLowerCase();
                const isMatch = containsAllKeywords(text, ...keywords);
                return isMatch ? { ...item } : null;
            }

            return { ...item };
        }

        return data.map(iterate).filter(v => v);
    }

    const rowSelection = {
        type: multiple ? 'checkbox' : 'radio',
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            title: '表代码',
            dataIndex: 'dtUserCode',
            width: 300,
            ellipsis: true,
        },
        {
            title: '表名称',
            dataIndex: 'dtName',
            width: 300,
            ellipsis: true,
        },
    ].filter(v => v);

    return (
        <Modal
            title={"选择数据表"}
            width={800}
            style={{ top: 20 }}
            bodyStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12 }}
            onOk={handleOk}
            {...props}
        >
            <div style={{ marginBottom: 12, }}>
                <Input.Search
                    enterButton="查询"
                    allowClear
                    size="small"
                    onSearch={handleSearch}
                    placeholder='可输入表代码/名称进行查询' />
            </div>

            {
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}><Spin /></div>
                    :
                    filterData.length > 0 ?
                        <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', background: '#fff', padding: 0, gap: 12, flexGrow: 1 }}>
                            <Table
                                size="middle"
                                loading={loading}
                                columns={columns}
                                dataSource={filterData}
                                rowKey={"pkid"}
                                rowSelection={{ ...rowSelection }}
                            />
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1', background: '#fff' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
            }
        </Modal>
    );
}

export default Dialog;