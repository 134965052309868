import {css} from '@emotion/react'
import { Space } from 'antd';

const cssLayout = css({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden',
})

// 布局画布
const TabLayout = (props) => {
    return (
        <section
            css={cssLayout}
            {...props}
        />
    )
}

const cssHeader = css({
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
    padding: '12px 24px',
    backgroundColor: '#fff',
})

// tab header
TabLayout.Header = (props) => {
    return (
        <header
            css={cssHeader}
            {...props}
        >
        </header>
    );
};

const cssFrameHeader = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: '0',
    padding: '12px 24px',
    background: '#fff',
    overflow: 'hidden',
})

const cssFrameTitle = css({
    fontSize: 20,
    fontWeight: 'bold',
})

// frame header
TabLayout.FrameHeader = (props) => {
    return (
        <header
            css={cssFrameHeader}
            {...props}
        >
            <div css={cssFrameTitle}>{props.title}</div>
            <Space>{props.children}</Space>
        </header>
    );
};

const cssContent = css({
    overflow: 'hidden auto',
    padding: '12px',
    background: '#f0f2f5',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
})

// 内容区背景色为灰色 #f0f2f5
TabLayout.Content = (props) => {
    return (
        <main
            css={cssContent}
            {...props}
        />
    );
};

export default TabLayout;
