import {css} from '@emotion/react'
import Checkbox from '@/components/Form/Checkbox.jsx'

import {
    PropertiesController,
    usePropertiesController,
} from './PropertiesController.jsx'

const cssCheckbox = css({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: 8,
})

export default function PropertiesControllerCheck({
    className,
    style,
    disabled,
    hidden,
    label,
    labelSuffix,
    prop,
    required,
    value,
    ...props
}) {
    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop,
        required,
        value,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            inline
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <Checkbox
                css={cssCheckbox}
                disabled={p.disabled}
                falseValue="0"
                trueValue="1"
                value={p.value}
                onChange={p.onChange}
                {...props}
            />
        </PropertiesController>
    )
}
