import {css} from '@emotion/react'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e8e8e8',
    padding: 12,
})

export default function ToolBar({children, menu, ...props}) {
    return (
        <div
            css={cssToolBar}
            {...props}
        >
            {children ?? menu}
        </div>
    )
}
