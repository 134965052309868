import {css} from '@emotion/react'
import {Button, Modal} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const useReplace = () => {
    const map = useMapContext()

    return (nodeIds, pattern, replacement) => {
        const replace = (s) => s.replaceAll(
            pattern,
            replacement.replaceAll('$', '$$$$'),
        )

        const nodes = nodeIds
            .map(
                (id) => map.getNode(id)
            )
            .filter(
                node => {
                    const n = map.nodeProxy(node)
                    return n.canWriteData()
                }
            )

        map.commands.updateNodeData(
            nodes,

            node => {
                const n = map.nodeProxy(node)
                return n.mapReplaceTextData(replace)
            }
        )
    }
}

const cssButtons = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
})

const ButtonReplace = ({
    nodeId,
    textReplace,
    textSearch,
    onFinish,
    ...props
}) => {
    const replace = useReplace()

    const handleClick = () => {
        replace([nodeId], textSearch, textReplace)
        onFinish()
    }

    return (
        <Button
            disabled={undefined === nodeId}
            size="small"
            onClick={handleClick}
            {...props}
        >
            替换
        </Button>
    )
}

const ButtonReplaceAll = ({
    matches,
    textReplace,
    textSearch,
    onFinish,
    ...props
}) => {
    const replace = useReplace()

    const handleClick = () => {
        const replaceAll = () => {
            const nodeIds = matches.map(([nodeId]) => nodeId)
            replace(nodeIds, textSearch, textReplace)
            onFinish()
        }

        Modal.confirm({
            content: '确定要全部替换？',
            onOk: replaceAll,
        })
    }

    return (
        <Button
            size="small"
            onClick={handleClick}
            {...props}
        >
            全部替换
        </Button>
    )
}

const ButtonsReplace = ({
    matches,
    nodeId,
    textReplace,
    textSearch,
    onFinish,
    ...props
}) => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (! (
        textSearch &&
        permission.has('write') &&
        textSearch !== textReplace
    )) {
        return null
    }

    return (
        <div
            css={cssButtons}
            {...props}
        >
            <ButtonReplace
                matches={matches}
                nodeId={nodeId}
                textReplace={textReplace}
                textSearch={textSearch}
                onFinish={onFinish}
            />

            <ButtonReplaceAll
                matches={matches}
                textReplace={textReplace}
                textSearch={textSearch}
                onFinish={onFinish}
            />
        </div>
    )
}

export default ButtonsReplace
