import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaSfDes.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            const tree = this.exportTree(map, node, {compact: true})
            const algMap = JSON.stringify(tree.children)
            data.algList.push({...tree.data, algMap})
        },
    })
}
