import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDgf from '../DGF/SelectDgf.jsx'

export default function PropertiesControllerDgf({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'cdgDfCode'})

    const {
        cdgCode,
        cdgDfCode,
        cdgDfName,
        cdgDfUserCode,
        cdgName,
        isCdgDf,
    } = values

    if ('1' !== isCdgDf) {
        return null
    }

    const handleChange = (cdg) => {
        const {
            dfCode,
            dfName,
            dfUserCode,
            pcdgCode,
            pcdgName,
            ...value
        } = cdg

        update({
            ...value,
            dfName,
            dfUserCode,
            cdgCode: pcdgCode,
            cdgDfCode: dfCode,
            cdgDfName: dfName,
            cdgDfUserCode: dfUserCode,
            cdgName: pcdgName,
        })
    }

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            <SelectDgf
                disabled={p.disabled}
                readOnly={p.readOnly}

                value={{
                    dfCode: cdgDfCode,
                    dfName: cdgDfName,
                    dfUserCode: cdgDfUserCode,
                    pcdgCode: cdgCode,
                    pcdgName: cdgName,
                }}

                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
