import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    name: '界面基础能力',
    ownerIdProp: 'uiId',
    ownerTextProp: 'uiName',
    ownerType: 'UI',
    type: 'UIALG',
}
