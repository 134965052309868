/**
 * 把数据库数据类型转为编程语言数据类型
 */
export default ({
    cdtDataTypeCode,
    dataTypeCode,
    dataTypeGrpCode,
    ddtDataTypeCode,
    dfLen,
    dfPrecision,
}) => {
    const convertDataType = (type, len, precision) => {
        if ('NUMBER' === type) {
            if (precision) {
                return 'BigDecimal'
            }
            else if (len <= 1) {
                return 'Boolean'
            }
            else if (len <= 2) {
                return 'Byte'
            }
            else if (len <= 3) {
                return 'Short'
            }
            else if (len <= 9) {
                return 'Integer'
            }
            else if (len <= 18) {
                return 'Long'
            }
            else {
                return 'BigDecimal'
            }
        }
        else {
            return {
                BLOB: 'Blob',
                BOOLEAN: 'Boolean',
                CHAR: 'String',
                DATE: 'LocalDate',
                DATETIME: 'LocalDateTime',
                INT: 'Integer',
                LOB: 'String',
                LONG: 'Long',
                SHORT: 'Short',
                VARCHAR: 'String',
            }[type]
        }
    }

    return {
        BDT: convertDataType(dataTypeCode, dfLen, dfPrecision),
        CDT: convertDataType(cdtDataTypeCode, dfLen, dfPrecision),
        DDT: convertDataType(ddtDataTypeCode, dfLen, dfPrecision),
    }[dataTypeGrpCode]
}
