const IconFile = ({fill, theme}) => {
    const color = {
        light: '#fff',
        dark: '#666',
    }[theme]

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128"
        >
            <circle
                cx="50%"
                cy="50%"
                fill={fill}
                r="50%"
            />

            <path
                d="
                    M 20,36
                    H 108
                    V 86
                    Q 86,72 64,86
                    T 20,86
                    Z
                "
                fill={color}
                stroke={color}
                strokeWidth="8"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconFile
