import BaseMap from '../BaseMap.jsx'
import hotkeys from './hotkeys.mjs'
import useMapData from './useMapData.mjs'
import useMindMapPlugins from './useMindMapPlugins.mjs'
import usePermission from './usePermission.mjs'
import ToolBar from './ToolBar/ToolBar.jsx'

const NavigatorMap = ({id, permission, ...props}) => {
    const initData = useMapData(id)
    const components = {ToolBar}

    const plugins = [
        useMindMapPlugins(),
        usePermission(permission),
    ]

    return (
        <BaseMap
            components={components}
            hotkeys={hotkeys}
            initData={initData}
            plugins={plugins}
            {...props}
        />
    )
}

export default NavigatorMap
