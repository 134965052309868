export default () => {
    const watchers = {
        canvas_double_click(e) {
            e.stopPropagation()

            this.execute(
                () => this.selectNodes([this.root])
            )
        },
    }

    return {watchers}
}
