import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelUm.mjs'

const PropertiesPaneUm = (props) => (
    <ModulePropertiesPane
        controllers={
            <>
                <Text prop="umUserCode" />
                <Text prop="umName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        versionLabel="界面地图版本号"
        {...props}
    />
)

export default PropertiesPaneUm
