import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    psName: {
        name: () => '物理机名称',
        required: () => true,
    },

    psDesc: {
        name: () => '物理机描述',
    },

    psMemo: {
        name: () => '备注',
    },

    psStatus: {
        name: () => '运行状态',
    },

    psBu: {
        name: () => 'BU',
    },

    psDm: {
        name: () => '开发负责人',
    },

    psOm: {
        name: () => '运维负责人',
    },

    psEnv: {
        name: () => '环境',
    },

    psHostname: {
        name: () => '主机名',
    },

    psIntranetIp: {
        name: () => '内网IP',
    },

    psInternetIp: {
        name: () => '公网IP',
    },

    psOs: {
        name: () => '操作系统',
    },

    psKernelVersion: {
        name: () => '内核版本',
    },

    psOpenPort: {
        name: () => '开放端口',
    },

    psSerialNumber: {
        name: () => '服务器序列号',
    },

    psModel: {
        name: () => '服务器型号',
    },

    psCpu: {
        name: () => 'CPU',
    },

    psMemory: {
        name: () => '内存',
    },

    psHardDisk: {
        name: () => '硬盘',
    },

    psMac: {
        name: () => 'MAC',
    },
})
