import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootBm.jsx'
import meta from './metaCatRootBm.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(BD|BF)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'BF'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['BD_1'],
                ['BD_2'],
                ['BF'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['BD_1'],
                ['BD_2'],
                ['BF'],
            ]
        },
    })
}
