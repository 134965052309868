export const readNodeStyleFromClipboard = async () => {
    try {
        const clipboardText = await navigator.clipboard.readText()

        if (clipboardText) {
            const {map, style} = JSON.parse(clipboardText)

            if (map && style) {
                return {map, style}
            }
        }

        throw new Error('读取剪贴板失败: 未找到节点样式')
    }
    catch (err) {
        throw new Error('读取剪贴板失败: 未找到节点样式')
    }
}

export async function pasteNodeStyle(nodes) {
    const clipboardData = await readNodeStyleFromClipboard()
    const {style} = clipboardData

    for (const node of nodes) {
        node.data = {...node.data, style}
    }

    this.logger.info('已粘贴节点样式', nodes)
}
