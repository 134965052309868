import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemVsName = (props) => {
    return (
        <Form.Item
            label="虚拟机名称"
            name="vsName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemIp = (props) => {
    return (
        <Form.Item
            label="IP"
            name="ip"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseVr = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemVsName />
            </Col>

            <Col span={6}>
                <FormItemIp />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseVr
