export const canInsertParent = () => defaultActions => {
    return function (node) {
        if (! defaultActions.canInsertParent(node)) {
            return false
        }

        const p = node.parent
        const {bizNodeType} = p.data
        return this.BizNode[bizNodeType].canMountType(this, p, 'CAT')
    }
}

export const insertParent = () => () => {
    return async function (nodes) {
        const nodesToSelect = new Set()

        const insert = async trees => {
            await Promise.all(
                trees.map(
                    ([, descendants]) => insert(descendants)
                )
            )

            const nodes = trees.map(([node]) => node)

            await Promise.all(
                [...this.groupByParent(nodes).values()].map(
                    async childNodes => {
                        const parent = this.createNode('CAT')
                        this.insertParent(parent, childNodes)

                        await this.BizNode.CAT.emitEventUp(
                            this, parent, {type: 'create'}
                        )

                        if (! parent.isDeleted) {
                            this.logger.info('已插入节点', [parent])
                            nodesToSelect.add(parent)
                        }
                    }
                )
            )
        }

        const trees = this.treelize(nodes)
        await insert(trees)
        this.selectNodes(nodesToSelect)
    }
}
