import {useState} from 'react'
import {SearchOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import SelectDataTypeDialog from '@/pages/MainPage/dialogs/SelectDataTypeDialog'
import {useMapContext} from '@/components/MindMap/index.mjs'

const defaultValue = {}

const SelectDataType = ({
    className,
    style,
    disabled,
    readOnly,
    value = defaultValue,
    onChange,
    ...props
}) => {
    const gReadOnly = useReadOnly()
    const map = useMapContext()
    const {prjId, sVer} = map.data
    const {dbmsCode} = map.root.data
    const [visible, setVisible] = useState(false)

    const handleClickInput = () => {
        if (! readOnly || gReadOnly) {
            setVisible(true)
        }
    }

    const icon = readOnly || gReadOnly ?
        null
        :
        <SearchOutlined
            disabled={disabled}
            onClick={handleClickInput}
        />

    const handleCall = (e) => {
        const {
            bdtCode,
            bdtName,
            cdtCode,
            cdtLen,
            cdtName,
            cdtPrecision,
            ddtCode,
            ddtLen,
            ddtName,
            ddtPrecision,
            isLen,
            isPrecision,
            isSeq,
            pkid,
            type,
        } = e

        const name = {
            bdt: bdtName,
            cdt: cdtName,
            ddt: ddtName,
        }[type]

        onChange({
            code: {
                bdt: bdtCode,
                cdt: cdtCode,
                ddt: ddtCode,
            }[type],

            dataType: bdtCode,
            id: pkid,
            isLen,
            isPrecision,
            isSeq,

            len: {
                bdt: '',
                cdt: cdtLen,
                ddt: ddtLen,
            }[type],

            name,

            precision: {
                bdt: '',
                cdt: cdtPrecision,
                ddt: ddtPrecision,
            }[type],

            type,
        })
    }

    const {name, type} = value

    const text = {
        BDT: name,
        CDT: `【CDT】${name}`,
        DDT: `【DDT】${name}`,
    }[type]

    return (
        <div
            className={className}
            style={style}
            onKeyDown={e => e.stopPropagation()}
        >
            <InputText
                addonAfter={icon}
                disabled={disabled}
                readOnly
                value={text}
                onClick={handleClickInput}
            />

            <SelectDataTypeDialog
                style={{display: 'block'}}
                dbmsCode={dbmsCode}
                prjId={prjId}
                setVisible={setVisible}
                sVer={sVer}
                visible={visible}
                onCall={handleCall}
                {...props}
            />
        </div>
    )
}

export default SelectDataType
