import {GlobalOutlined} from '@ant-design/icons'
import IconWithNotification from '../IconWithNotification.jsx'
import ListExternalNodes from './ListExternalNodes.jsx'
import useExternalNodes from './useExternalNodes.mjs'

export default () => {
    const nodes = useExternalNodes()

    return {
        icon: (
            <IconWithNotification notification={0 < nodes.length}>
                <GlobalOutlined />
            </IconWithNotification>
        ),

        key: 'external',
        hotkey: 'Ctrl+Shift+E',
        name: '外部模件',
        pane: <ListExternalNodes nodes={nodes} />,
    }
}
