//import http from '@/biz/http.mjs'
//import buildUrl from '@/script/buildUrl.mjs'
//import apiMap from '../../apis/apiMap.mjs'
//import apiSnapshot from '../../apis/apiSnapshot.mjs'
//import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaCatDep.mjs'
import PropertiesPane from './PropertiesPaneCatDep.jsx'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,
        PropertiesPane,

        //canChangeVersion(map, node) {
            //return true
        //},

        canMountType(map, node, type) {
            return /^DEP_(DB|MID|SS)$/.test(type)
        },

        canWriteTree(map, node) {
            return false
        },

        //async changeVersion(map, node, rev) {
            //const {data} = node
            //node.data = {...data, rev}
            //await this.onSetData(map, node, data)

            //if (node.firstChild) {
                //await this.grow(map, node)
            //}
        //},

        getRev(map, node) {
            return null
        },

        //getVersion(map, node) {
            //const rev = this.getRev(map, node)
            //const {sVer} = node.data
            //return (null !== rev && sVer) ? `${sVer}.${rev}` : ''
        //},

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: Color.DARK_GREEN,
                textColor: '#fff',
            }
        },

        //async shrink(map, node) {
            //map.deleteChildren(node)
        //},

        //async onDoubleClick(map, node, event) {
            //const {prjId, prjNo, sVer} = map.data
            //const url = buildUrl('/ProjectDplMap', {prjId, prjNo, sVer})
            //window.open(url)
        //},

        //async _grow(map, node, depth) {
            //const tree = await this._readGrowTree(map, node)

            //if (! tree) {
                //return depth
            //}

            //await this.replace(map, node, tree)
            //return depth + 1
        //},

        //async _readGrowTree(map, node) {
            //const {rev} = node.data

            //if (rev) {
                //const {[this.mapIdProp]: pkid} = node.data

                //const snapshot = await apiSnapshot.readByRev(
                    //{bizNodeType: this.mapTypeCode, pkid, rev}
                //)

                //const {root: {children}} = parseMap(snapshot)
                //return {children}
            //}
            //else {
                //const pkid = await (async () => {
                    //const {prjId, sVer} = map.data

                    //try {
                        //const {pkid} = await http.get(
                            //'/basmaps/depmap',
                            //{prjId, sVer}
                        //)

                        //return pkid
                    //}
                    //catch (err) {
                        //if ('01020026' === err.code) {
                            //const {pkid} = await http.post('/basmaps', {
                                //mapName: '部署地图',
                                //mapNo: '',
                                //mapTypeCode: 'DEP_MAP',
                                //prjId,
                            //})

                            //return pkid
                        //}
                        //else {
                            //throw err
                        //}
                    //}
                //})()

                //const mapData = await apiMap.read({pkid})

                //if (! mapData) {
                    //return null
                //}

                //const {
                    //mapModel,
                    //mapRev: rev,
                    //pkid: dataId,
                    //prjId,
                    //sVer,
                //} = mapData

                //const {root: {children}} = parseMap(mapModel)

                //const data = {
                    //...node.data,
                    //lastRev: rev,
                    //prjId,
                    //rev,
                    //sVer,
                    //[this.mapIdProp]: dataId,
                //}

                //return {children, data}
            //}
        //},
    })
}
