import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import api from './apiBdrule_1.mjs'
import meta from './metaBdrule_1.mjs'
import FormChoose from './FormChooseBdrule_1.jsx'
import TableChoose from './TableChooseBdrule_1.jsx'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, bdNo} = map.root.data

                if ('BD' === bizNodeType) {
                    return {bdNo, ...query}
                }
                else {
                    return query
                }
            }

            const items = await this._choose(map, node, {getQuery})

            return items.map(item => ({
                ...item,
                data: {...item.data, bizNodeType: 'BDRULE'}
            }))
        },
    })
}
