export const canDeleteNode = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDeleteNode(node) &&
            this.BizNode[bizNodeType].canDeleteNode(this, node)
        )
    }
}

export const deleteNode = () => defaultActions => {
    return async function (nodes) {
        await defaultActions.deleteNode(nodes)

        await Promise.all(
            nodes.map(async n => {
                const _n = this.nodeProxy(n)
                await _n.emitEventUp({type: 'delete'})
            })
        )
    }
}
