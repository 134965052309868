import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import meta from './metaUialg.mjs'
import Model from './ModelUialg.mjs'
import PropertiesPane from './PropertiesPaneUialg.jsx'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(ALG_(API|CORE|RULE))$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape: 'HorizontalHexagon',
            }
        },

        async _atCreate(map, node) {
            const childTypes = [
                'ALG_RULE',
                'ALG_CORE',
                'ALG_API',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await AlgNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                UI: 'cAlgList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node, {})
            data[key].push(pushData)
        },
    })
}
