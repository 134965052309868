function toggleFoldTree() {
    const {
        commands: {canFold, canUnfold, foldTree, unfoldTree},
        selectedNodes,
    } = this

    const nodesCanFold = canFold(selectedNodes)

    if (0 < nodesCanFold.length) {
        foldTree(nodesCanFold)
        return
    }

    const nodesCanUnfold = canUnfold(selectedNodes)

    if (0 < nodesCanUnfold.length) {
        unfoldTree(nodesCanUnfold)
    }
}

export default {
    'Ctrl+Alt+/': toggleFoldTree,
    'Meta+Alt+/': toggleFoldTree,
}
