import React, { useEffect, useState } from 'react';
import { Input, Tree, Spin, Tag, Empty } from 'antd';
import Modal from '@/components/Modal/Modal';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

const Dialog = ({ params, value, onOk, multiple = false, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const prjId = params.prjId;
            const sVer = params.sVer;
            const stsCodes = ['RLS', 'REVISE'];

            const data = await runAsync(
                async () => {
                    return await http.get(`/archdbs`, { prjId, sVer, stsCodes });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });


            data.sort((o1, o2) => o1.dbmsCode.localeCompare(o2.dbmsCode));
            const tree = data.map(mapNode);
            setData(tree);
            setFilterData(tree);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const mapNode = (obj) => {
        let type = '';
        let color = 'green';
        let dbms = obj.dbmsCode;

        if ('MYSQL' == dbms) {
            type = 'MySQL';
            color = '#2b5d80'; // #2b5d80
        } else if ('ORACLE' == dbms) {
            type = 'Oracle';
            color = '#c74634'; // #c74634
        } else if ('SQLSERVER' == dbms) {
            type = 'SQLServer';
            color = '#c74634'; // #c74634
        } else if ('POSTGRESQL' == dbms) {
            type = 'PostgreSQL';
            color = '#336791'; // #336791
        }

        return {
            key: obj.pkid,
            title: <span><Tag color={color} style={{ color: '#fff' }}>{type}</Tag>{obj.dbName}</span>,
            obj: { ...obj }
        }
    }

    const handleOk = () => {
        const dbs = [];
        for (const node of selectedNodes) {
            const db = node.obj;
            dbs.push({ dbId: db.pkid, dbCode: db.dbCode, dbName: db.dbName });
        }

        if (multiple) {
            onOk(dbs);
        } else {
            if (dbs.length > 0) {
                onOk(dbs[0]);
            }
        }
    }

    const handleSearch = (keyword) => {
        setFilterData(searchKeyword(keyword));
    }

    const searchKeyword = (keyword) => {
        const iterate = (item) => {
            const { obj } = item;
            const { dbName } = obj;
            const isMatch = () => (dbName && dbName.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
            return isMatch() ? { ...item } : null;
        }

        return data.map(iterate).filter(v => v);
    }

    const multipleProps = {
        selectable: false,
        checkable: true,
        checkedKeys: selectedKeys,
        onCheck: (selectedKeys, e) => {
            setSelectedNodes(e.checkedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    const singleProps = {
        selectable: true,
        checkable: false,
        selectedKeys: selectedKeys,
        onSelect: (selectedKeys, e) => {
            setSelectedNodes(e.selectedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    return (
        <Modal
            title={"选择数据库"}
            width={800}
            style={{ top: 20 }}
            bodyStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12 }}
            onOk={handleOk}
            {...props}
        >
            <div style={{ marginBottom: 12, }}>
                <Input.Search
                    enterButton="查询"
                    allowClear
                    size="small"
                    onSearch={handleSearch}
                    placeholder='可输入数据库名称进行查询' />
            </div>

            {
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}><Spin /></div>
                    :
                    filterData.length > 0 ?
                        <div style={{ overflow: 'hidden', border: '1px solid #f0f0f0', padding: 8 }}>
                            <Tree
                                height={430}
                                defaultExpandAll
                                showLine={{ showLeafIcon: false }}
                                showIcon={true}
                                treeData={filterData}
                                multiple={multiple}
                                {...(multiple ? multipleProps : singleProps)} />
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1', background: '#fff' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
            }
        </Modal>
    );
}

export default Dialog;