import {MindMapDoc} from '@/components/MindMap/index.mjs'

const modules = import.meta.glob('./shapes/*.mjs', {eager: true})

const shapes = Object.fromEntries(
    Object.values(modules)
        .map(Object.entries)
        .flat()
)

export class SMapDoc extends MindMapDoc {
    getDefaultNodeText(node) {
        const _n = this.nodeProxy(node)
        return _n.defaultText()
    }

    getNodeBoxStyle(node) {
        const _n = this.nodeProxy(node)

        const {
            innterBoxBackgroundColor,
            outerBoxBackgroundColor,
        } = {
            ..._n.getStyle(),
            ...node.data.style,
        }

        return {
            innterBoxBackgroundColor,
            outerBoxBackgroundColor,
        }
    }

    getNodeLineStyle(node) {
        const _n = this.nodeProxy(node)

        const {
            leadingLineColor,
            leadingLineWidth,
            trailingLineColor,
            trailingLineWidth,
        } = {
            ..._n.getStyle(),
            ...node.data.style,
        }

        return {
            leadingLineColor,
            leadingLineWidth,
            trailingLineColor,
            trailingLineWidth,
        }

    }

    getNodeShape(node, textRect) {
        const style = this.getNodeShapeStyle(node)
        return shapes[style.shape](textRect, style)
    }

    getNodeShapeStyle(node) {
        const _n = this.nodeProxy(node)

        const {
            backgroundColor,
            borderColor,
            borderWidth,
            shape,
        } = {
            ..._n.getStyle(),
            ...node.data.style,
        }

        return {
            backgroundColor,
            borderColor,
            borderWidth,
            shape,
        }

    }

    getNodeText(node) {
        const _n = this.nodeProxy(node)
        return _n.getText()
    }

    getNodeTextPrefix(node) {
        const _n = this.nodeProxy(node)
        return _n.getTextPrefix()
    }

    getNodeTextStyle(node) {
        const _n = this.nodeProxy(node)

        const {
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            textColor,
            textDecoration,
        } = {
            ..._n.getStyle(),
            ...node.data.style,
        }

        return {
            color: textColor,
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            textDecoration,
        }

    }

    getNodeTextSuffix(node) {
        const _n = this.nodeProxy(node)
        return _n.getTextSuffix()
    }

    getNodeTitle(node) {
        const _n = this.nodeProxy(node)
        return _n.getTitle()
    }

    isNodeHidden(node) {
        const _n = this.nodeProxy(node)
        return _n.isHidden()
    }

    createNode(bizNodeType) {
        return this.importTree({data: {bizNodeType}})
    }

    deleteChildren(node) {
        for (const n of node.children) {
            this.deleteTree(n)
        }
    }

    exportNode(node, transform) {
        const {id, isHidden, ...n} = node.export(transform)
        return n
    }

    queryNodes({algo = 'dfs', all, data, isMounted, isLinked, path}) {
        const isRestricted = node => {
            if (! node) {
                return false
            }

            const _n = this.nodeProxy(node)

            if (isMounted && ! _n.isMounted()) {
                return false
            }

            if (isLinked && ! _n.isLinked()) {
                return false
            }

            return true
        }

        const getNodesByPath = () => {
            const nodeTexts = path
                .slice(1, -1)
                .split('/')
                .map(s => decodeURIComponent(s))

            const match = (node, texts) => {
                const _n = this.nodeProxy(node)
                const text = _n.getTextRaw()

                if (text === texts[0]) {
                    if (1 < texts.length) {
                        for (const child of node.children) {
                            const matchedNode = match(child, texts.slice(1))

                            if (matchedNode) {
                                return matchedNode
                            }
                        }

                        return null
                    }
                    else {
                        return node
                    }
                }
                else {
                    return null
                }
            }

            const node = match(this.root, nodeTexts)

            if (isRestricted(node)) {
                return [node]
            }
            else {
                return []
            }
        }

        const getNodesByData = () => {
            const nodes = []
            const entries = Object.entries(data)

            const match = node => entries.every(
                ([key, value]) => {
                    return (
                        node.data[key] === value &&
                        isRestricted(node)
                    )
                }
            )

            const breadthFirst = 'bfs' === algo

            for (const node of this.walkDown(this.root, {breadthFirst})) {
                if (match(node)) {
                    nodes.push(node)

                    if (! all) {
                        break
                    }
                }
            }

            return nodes
        }

        if (data) {
            return getNodesByData()
        }

        if (path) {
            return getNodesByPath()
        }

        return []
    }

    *chainNoComment(node) {
        for (const n of this.chain(node)) {
            const {bizNodeType} = n.data

            if (! this.BizNode[bizNodeType].classes.includes('comment')) {
                yield n
            }
        }
    }
}
