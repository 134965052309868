import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseBd = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()

    const columns = [
        {
            title: '业务域',
            dataIndex: 'bmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象编号',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象类型',
            dataIndex: 'bdTypeName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bd_1s"
            query={query}
            {...props}
        />
    )
}

export default TableChooseBd
