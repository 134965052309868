import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconCircle from '../icons/IconCircle.jsx'
import meta from './metaBj.mjs'
import Model from './ModelBj.mjs'
import PropertiesPane from './PropertiesPaneBj.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BJ_POS'],
        },

        canMountType(map, node, type) {
            return /^(BJ_(DESC|REQ))$/.test(type)
        },

        getIcons(map, node) {
            const {onlineFlag, pkid} = node.data

            if (! pkid) {
                return []
            }
            else if ('1' === onlineFlag) {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GOLD}
                    />]
            }
            else {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GREY}
                    />
                ]
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW
                }),

                shape: 'Arrow'
            }
        },

        async _atCreate(map, node) {
            const bfTypeCode = (() => {
                for (const n of map.chain(node.parent)) {
                    const {
                        bfTypeCode = 'TARGET',
                        bizNodeType,
                    } = n.data

                    if ('BF' === bizNodeType) {
                        return bfTypeCode
                    }
                }

                return 'TARGET'
            })()

            const childTypes = {
                TARGET: ['BJ_DESC'],
                SITUATION: ['BJ_DESC', 'BJ_REQ'],
            }[bfTypeCode]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BF: 'bmBjList',
            }[type]

            if (! key) {
                return
            }

            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {compact: true}
            )

            const realChildren = tree.children.filter(
                (e) => ! /^ATTR_/.test(e.data.bizNodeType)
            )

            const pushData = this._getPushData(map, node, {sfCodes: []})

            data[key].push({
                ...pushData,
                bjMap: JSON.stringify(realChildren),
            })
        },
    })
}
