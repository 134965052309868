import {memo, useRef} from 'react'
import {css} from '@emotion/react'
import {
    Canvas as TreeDocCanvas,
    useTreeDocContext,
} from '@/components/TreeDoc/index.mjs'

const cssCanvas = css({
    containerType: 'size',
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    overflow: 'auto',
})

const cssTree = css({
    margin: '100cqh 100cqw',
})

const Canvas = (props) => {
    const map = useTreeDocContext()
    const refEl = useRef()

    return (
        <TreeDocCanvas
            ref={refEl}
            css={cssCanvas}
            {...props}
        >
            <map.components.Tree
                css={cssTree}
                node={map.root}
            />
        </TreeDocCanvas>
    )
}

const MemorizedCanvas = memo(Canvas, () => true)
MemorizedCanvas.displayName = 'Canvas'

export default MemorizedCanvas
