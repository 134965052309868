import {useState, useEffect} from 'react'
import {Button, Form, Space} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'

const ModalFullQuery = ({
    form,
    formContent,
    visible,
    onOk,
    ...props
}) => {
    const [value, setValue] = useState({})

    useEffect(
        () => {
            if (visible) {
                const value = form.getFieldsValue()
                setValue(value)
            }
        },

        [form, visible]
    )

    const handleCancel = () => {
        form.setFieldsValue(value)
        onOk()
    }

    const handleOk = () => onOk(true)
    const handleReset = () => form.resetFields()

    const footer = (
        <Space>
            <Button
                size="small"
                onClick={handleReset}
            >重置</Button>

            <Button
                size="small"
                onClick={handleCancel}
            >取消</Button>

            <Button
                size="small"
                type="primary"
                onClick={handleOk}
            >确定</Button>
        </Space>
    )

    return (
        <Modal
            footer={footer}
            title="高级搜索"
            visible={visible}
            width={1000}
            {...props}
        >
            <Form
                form={form}
                layout="vertical"
                size="small"
            >
                {formContent}
            </Form>
        </Modal>
    )
}

const ButtonSearchFull = ({form, formContent, onConfirm, ...props}) => {
    const openModal = useOpenModal()

    const handleClick = async () => {
        const ok = await openModal(
            <ModalFullQuery
                form={form}
                formContent={formContent}
            />
        )

        if (ok) {
            onConfirm()
        }
    }

    return (
        <Button
            ghost
            size="small"
            type="primary"
            onClick={handleClick}
            {...props}
        >
            高级搜索
        </Button>
    )
}

const ButtonSearch = (props) => {
    return (
        <Button
            icon={<SearchOutlined/>}
            size="small"
            type="primary"
            {...props}
        >
            搜索
        </Button>
    )
}

const QueryForm = ({
    brief,
    form: customForm,
    full,
    query,
    onChange,
    ...props
}) => {
    const [defaultForm] = Form.useForm()
    const form = customForm ?? defaultForm

    useEffect(
        () => form.setFieldsValue(query),
        [form, query]
    )

    const handleKeyPress = ({key}) => {
        if ('Enter' === key) {
            handleSearch()
        }
    }

    const handleSearch = () => {
        const query = form.getFieldsValue()
        onChange(query)
    }

    const css = {
        display: 'flex',
        backgroundColor: '#fff',
        padding: 12,

        '&>.ant-space-item:first-of-type': {
            flexGrow: 1,
        }
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Space
            css={css}
            align="end"
        >
            <Form
                css={cssForm}
                form={form}
                layout="vertical"
                size="small"
                onKeyPress={handleKeyPress}
                {...props}
            >
                {brief}
            </Form>

            {
                full ?
                    <ButtonSearchFull
                        form={form}
                        formContent={full}
                        onConfirm={handleSearch}
                    />
                    :
                    null
            }

            <ButtonSearch onClick={handleSearch} />
        </Space>
    )
}

export default QueryForm
