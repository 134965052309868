import {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import ButtonsReplace from './ButtonsReplace.jsx'
import {InputReplace, InputSearch} from './inputs.jsx'
import NavTree from './NavTree.jsx'
import SearchList from './SearchList.jsx'
import useMatches from './useMatches.mjs'

const cssPane = css({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '100%',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
})

const cssSearchReplace = css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    backgroundColor: '#fff',
    padding: 12,
    borderBottom: '1px solid #e8e8e8',
})

export default function SearchPane(props) {
    const map = useMapContext()
    const [textSearch, setTextSearch] = useState('')
    const [textReplace, setTextReplace] = useState('')
    const [selectedKeys, setSelectedKeys] = useState([])
    const matches = useMatches(textSearch)

    useEffect(
        () => {
            if (0 < selectedKeys.length) {
                const nodes = selectedKeys.map(id => map.getNode(id))
                map.execute(() => map.selectNodes(nodes))
            }
        },

        [selectedKeys]
    )

    const handleChangeReplace = ({target: {value}}) => {
        setTextReplace(value)
    }

    const handleChangeSearch = ({target: {value}}) => {
        setTextSearch(value)
    }

    const handleFinishReplace = () => {
        setSelectedKeys([])
    }

    const handleSelect = (keys) => {
        if (
            0 < keys.length &&
            keys[0] !== selectedKeys[0]
        ) {
            setSelectedKeys(keys)
        }
    }

    return (
        <div
            css={cssPane}
            {...props}
        >
            <div css={cssSearchReplace}>
                <InputSearch onChange={handleChangeSearch} />

                <InputReplace
                    textSearch={textSearch}
                    onChange={handleChangeReplace}
                />

                <ButtonsReplace
                    nodeId={selectedKeys[0]}
                    matches={matches}
                    textReplace={textReplace}
                    textSearch={textSearch}
                    onFinish={handleFinishReplace}
                />
            </div>

            {
                textSearch ?
                    <SearchList
                        matches={matches}
                        onSelect={handleSelect}
                    />
                    :
                    <NavTree
                        selectedKeys={selectedKeys}
                        onSelect={handleSelect}
                    />
            }
        </div>
    )
}
