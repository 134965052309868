import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canRefreshProduct, refreshProduct} = map.commands
    const nodes = canRefreshProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    return {
        key: 'refresh-product',
        label: '重载制品',
        onClick: () => refreshProduct(nodes),
    }
}
