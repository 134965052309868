import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canRedo} = map.useChangeHistory()

    return {
        disabled: ! canRedo,
        key: 'redo-change',
        label: '重做',
        suffix: 'Ctrl+Y',
        onClick: () => map.redoChange(),
    }
}
