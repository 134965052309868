import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canDeleteTree, deleteTree} = map.commands
    const nodes = canDeleteTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'delete-tree',
        label: '删除',
        suffix: 'Del',
        onClick: () => deleteTree(nodes),
    }
}
