import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canShrinkNode, shrinkNode} = map.commands
    const nodes = canShrinkNode(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    return {
        key: 'shrink-node',
        label: '卸载',
        suffix: 'CTRL+K',
        onClick: () => shrinkNode(nodes),
    }
}
