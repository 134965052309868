const moduleMap = {
    actions: import.meta.glob('./actions/*.(mjs|jsx)', {eager: true}),
    behaviours: import.meta.glob('./behaviours/*.(mjs|jsx)', {eager: true}),
    commands: import.meta.glob('./commands/*.(mjs|jsx)', {eager: true}),
}

export default () => {
    const extensions = map => ({
        cmd: async function (fn) {
            await this.logger.withOn(
                () => this.execute(fn)
            )
        },

        ...Object.fromEntries(
            Object.entries(moduleMap).map(([key, modules]) => [
                key,

                Object.fromEntries(
                    Object.values(modules)
                        .map(
                            module => Object.entries(module).map(
                                ([name, fn]) => [name, fn.bind(map)]
                            )
                        )
                        .flat()
                )

            ])
        ),
    })

    return {extensions}
}
