import {css} from '@emotion/react'
import {Radio} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'

const cssRadioGroup = css({
    'hr': {
        border: 'none',
        borderBottom: '1px solid #d9d9d9',
        margin: '12px 0',
    }
})

const defaultOptions = []
const defaultOnChange = () => {}

const RadioGroup = ({
    readOnly,
    options = defaultOptions,
    onChange = defaultOnChange,
    ...props
}) => {
    const gReadOnly = useReadOnly()
    const children = []

    for (const [index, group] of options.entries()) {
        children.push(<hr key={`hr-${index}`}/>)

        for (const option of group) {
            children.push(
                <Radio.Button
                    key={option.value}
                    value={option.value}
                >
                    {option.label ?? option.value}
                </Radio.Button>
            )
        }
    }

    const handleChange = readOnly || gReadOnly ?
        defaultOnChange
        :
        ({target: {value}}) => {
            onChange(value)
        }

    return (
        <Radio.Group
            css={cssRadioGroup}
            buttonStyle="solid"
            optionType="button"
            onChange={handleChange}
            {...props}
        >
            {children.slice(1)}
        </Radio.Group>
    )
}

export default RadioGroup
