function pushAndSave() {
    const {canPushProduct, canSaveMap, pushAndSave, saveMap} = this.commands
    const nodes = canPushProduct([this.root])

    if (0 < nodes.length) {
        pushAndSave()
    }
    else if (canSaveMap()) {
        saveMap()
    }
}

export default {
    'Ctrl+S': pushAndSave,
    'Meta+S': pushAndSave,
}
