export const canInsertProducts = () => () => {
    return function (nodes) {
        if ('_DES_MAP' !== this.data.mapTypeCode) {
            return []
        }

        const ns = [...nodes].filter(
            node => this.actions.canInsertProducts(node)
        )

        if (1 === ns.length) {
            return ns
        }
        else {
            return []
        }
    }
}

export const insertProducts = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.actions.insertProducts(nodes)
        )
    }
}
