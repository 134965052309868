import parseMap from '../../scripts/parseMap.mjs'
import useProject from '../useProject.mjs'

export default (data) => {
    const {data: project} = useProject(data.prjId)

    if (project) {
        const map = parseMap(data)
        Object.assign(map.data, project)
        return map
    }
    else {
        return void 0
    }
}
