import {Space} from 'antd';
import {css} from "@emotion/react";

// 布局画布
const TabLayout = (props) => {
    const cssLayout = {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        overflow: 'hidden',
    }

    return (
        <section
            css={cssLayout}
            {...props}
        />
    );
}

// frame header
TabLayout.Header = (props) => {
    const cssHeader = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: '0',
        padding: '12px 24px',
        background: '#fff',
        overflow: 'hidden',
    }

    const cssTitle = {
        fontSize: 20,
        fontWeight: 'bold',
    }

    const cssButtons = {}

    return (
        <header
            css={cssHeader}
            {...props}
        >
            <div css={cssTitle}>{props.title}</div>
            <Space css={cssButtons}>{props.children}</Space>
        </header>
    );
}

const cssContent = css({
    padding: '12px',
    overflow: 'auto',
})

// 内容区背景色为灰色 #f0f2f5
TabLayout.Content = ({gap= 0, overflowHidden, ...props}) => {
    console.log('overflowHidden', overflowHidden)
    return (
        <main css={[cssContent, overflowHidden ? {overflow:'hidden', display: 'flex', flexDirection: 'column', flexGrow: 1, gap} : {}]} {...props} />
    );
}

export default TabLayout;
