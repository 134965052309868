import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    archUrl: '/ProjectDbMap',
    codeProp: 'dbUserCode',
    detailUrl: '/DbDetail',
    detailIdProp: 'dbId',
    mapProp: 'dbMap',
    name: '数据库',
    textProp: 'dbName',
    type: 'DB',
}
