import {useEffect, useState} from 'react'
import {Button, Popover, Table} from 'antd'
import {css} from '@emotion/react'
import http from '@/biz/http.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDataType from '../components/SelectDataType.jsx'

const cssCode = css({
    fontSize: 9,
    userSelect: 'all',
})

const CDTValuesTable = ({values, ...props}) => {
    const columns = [
        {
            title: '序列码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    return (
        <div tabIndex="-1">
            <Table
                {...props}
                bordered
                columns={columns}
                dataSource={values}
                pagination={false}
                size="small"
            />
        </div>
    )
}

const CDTValuesPopover = ({typeId, ...props}) => {
    const [values, setValues] = useState([])

    useEffect(
        () => {
            if (! typeId) {
                return
            }

            (async () => {
                const res = await http.get(`/dmcdtseqvals/cdt/${typeId}`)

                const values = res.map(
                    ({cdtSeqVal, cdtSeqValName}) => ({
                        code: cdtSeqValName,
                        name: cdtSeqVal,
                    })
                )

                setValues(values)
            })()
        },

        [http, typeId]
    )

    return (
        <Popover
            {...props}
            content={<CDTValuesTable values={values} />}
            trigger="click"
        />
    )
}

const DisplayCDTValuesButton = ({typeId, ...props}) => {
    return (
        <CDTValuesPopover typeId={typeId}>
            <Button
                type="link"
                {...props}
            >值域</Button>
        </CDTValuesPopover>
    )
}

export default function PropertiesControllerDataType({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'dataTypeCode'})

    const {
        cdgDfUserCode,
        cdtIsSeq,
        dataTypeCode,
        dataTypeId,
        dataTypeName,
        dataTypeGrpCode,
    } = values

    const handleChange = ({
        code,
        id,
        isLen,
        isPrecision,
        isSeq,
        len,
        name,
        precision,
        defVal,
        dataType,
        type,
    }) => {
        update({
            cdtDataTypeCode: dataType,
            cdtIsSeq: isSeq,
            dataTypeGrpCode: type.toUpperCase(),
            dataTypeCode: code,
            dataTypeId: id,
            dataTypeName: name,
            defVal,
            dfLen: len || undefined,
            dfPrecision: precision || undefined, 
            isLen,
            isPrecision,
        })
    }

    const labelSuffix = (
        <>
            {
                '1' === cdtIsSeq &&
                <DisplayCDTValuesButton typeId={dataTypeId} />
            }

            {
                cdgDfUserCode &&
                <span css={cssCode}>{cdgDfUserCode}</span>
            }
        </>
    )

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectDataType
                disabled={p.disabled}
                readOnly={p.readOnly}

                value={{
                    type: dataTypeGrpCode,
                    code: dataTypeCode,
                    name: dataTypeName,
                }}

                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
