export const canEditNodeText = () => defaultBehaviours => {
    return function (node) {
        if (! defaultBehaviours.canEditNodeText(node)) {
            return false
        }

        const n = this.nodeProxy(node)
        return n.canWriteData()
    }
}

export const editNodeText = () => () => {
    return function (node, text) {
        const n = this.nodeProxy(node)
        const data = n.mapSetTextData(text)
        this.commands.updateNodeData([node], () => data)
    }
}

export const startEditNodeText = () => defaultBehaviours => {
    return function (node, text) {
        const n = this.nodeProxy(node)

        if (n.canWriteData()) {
            defaultBehaviours.startEditNodeText(node, text)
        }
    }
}
