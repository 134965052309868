import extendNode from '../extendNode.mjs'
import {chooseFiles, getExt} from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'
import http from '@/biz/http.mjs'
import Color from '../Color.mjs'
import {ValidationError} from '../Error.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaDoc.mjs'
import PropertiesPane from './PropertiesPaneDoc.jsx'

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
                shape: 'BottomWavyRectangle',
            }
        },

        async onInsertCommon(map, nodes) {
            const upload = async (file) => {
                const {prjId} = map.data
                const {fileId} = await http.upload({file, pathName: prjId})
                const bizNodeType = /^image\//.test(file.type) ? 'PIC' : 'DOC'
                const {name, type} = file
                const ext = getExt(name)

                return {
                    data: {
                        bizNodeType,
                        ext,
                        fileId,
                        text: file.name,
                        type,
                    }
                }
            }

            const files = await chooseFiles({multiple: true})

            const trees = await runAsync(
                () => Promise.all(
                    files.map(upload)
                ),

                {
                    error: {content: (err) => `上传文件失败: ${err.message}`},
                    loading: {content: '正在上传文件，请稍候'},
                }
            )

            await map.execute(() => {
                const nodesToSelect = new Set()

                for (const node of map.selectedNodes) {
                    const subNodes = trees.map(
                        tree => map.importTree(tree)
                    )

                    for (const subNode of subNodes) {
                        map.appendChild(node, subNode)
                        nodesToSelect.add(subNode)
                    }
                }

                map.selectNodes(nodesToSelect)
            })
        },

        async onDoubleClick(map, node, event) {
            const {fileId} = node.data

            if (fileId) {
                event.preventDefault()

                window.open(
                    `${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`,
                    '_blank'
                )
            }
        },

        onPush(map, node, type, data) {
            const dataKey = {
                BF: 'fileList',
                UI: 'demoList',
            }[type]

            if (! dataKey) {
                return
            }

            //if (node.data.fileId) {
                data[dataKey].push({
                    ...node.data,
                    id: node.id,
                })
            //}
            //else {
                //throw new ValidationError('没有上传文件', node)
            //}
        },
    })
}
