import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssListItem = css({
    padding: 8,
    cursor: 'pointer',
    borderBottom: '1px solid #e8e8e8',

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

const cssListItemNodeText = css({
    fontSize: 16,
})

const cssListItemPrjText = css({
    fontSize: 12,
})

const ListItem = ({node, ...props}) => {
    const map = useMapContext()
    const text = map.getNodeText(node)
    const prefix = map.getNodeTextPrefix(node)
    const suffix = map.getNodeTextSuffix(node)
    const nodeText = [prefix, text, suffix].join('') || '(空白)'
    const {prjNo, prjName, prjId} = node.data
    const prjText = `@${prjNo ?? prjName ?? prjId}`

    const handleClick = () => {
        map.execute(
            () => map.selectNodes([node])
        )
    }

    return (
        <div
            css={cssListItem}
            onClick={handleClick}
            {...props}
        >
            <div css={cssListItemNodeText}>{nodeText}</div>
            <div css={cssListItemPrjText}>{prjText}</div>
        </div>
    )
}

export default function ListExternalNodes({nodes, ...props}) {
    const listItems = nodes.map(n => (
        <ListItem
            key={n.id}
            node={n}
        />
    ))

    return (
        <div {...props}>
            {listItems}
        </div>
    )
}
