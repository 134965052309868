function forceDeleteTree() {
    const {
        commands: {deleteTree},
        root,
        selectedNodes,
    } = this

    const nodes = [...selectedNodes].filter(
        node => root !== node
    )

    deleteTree(nodes)
}

export default {
    'Shift+Backspace': forceDeleteTree,
    'Shift+Delete': forceDeleteTree,
}
