export default queries => {
    // 允许通过传 false 取消默认选中根节点的行为
    if (! queries) {
        return {}
    }

    const watchers = {
        load() {
            const nodes = queries
                .map(q => this.queryNodes(q))
                .flat()

            this.execute(() => {
                if (0 < nodes.length) {
                    this.selectNodes(nodes)
                }
                else {
                    this.selectNodes([this.root])
                }
            })
        },
    }

    return {watchers}
}
