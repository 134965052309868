import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemDbName = (props) => {
    return (
        <Form.Item
            label="数据库名称"
            name="dbName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDbInsName = (props) => {
    return (
        <Form.Item
            label="数据库实例名称"
            name="dbInsName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemIp = (props) => {
    return (
        <Form.Item
            label="IP"
            name="ip"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseDbins = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemDbName />
            </Col>

            <Col span={6}>
                <FormItemDbInsName />
            </Col>

            <Col span={6}>
                <FormItemIp />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseDbins
