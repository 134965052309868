import {memo} from 'react'
import {css} from '@emotion/react'
import inlineSvg from '@/script/inlineSvg.mjs'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const cssNode = css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
})

const useBackground = (node) => {
    const map = useTreeDocContext()

    map.useNodeChange(node, [
        'children',
        'nextSibling',
        'parent',
        'prevSibling',
    ])

    const lines = []
    const styleNode = map.getNodeLineStyle(node)

    if (node.parent) {
        lines.push(`
            <line
                x1="0"
                x2="50%"
                y1="50%"
                y2="50%"
                stroke="${styleNode.leadingLineColor}"
                stroke-width="${styleNode.leadingLineWidth}"
            />
        `)

        const styleParent = map.getNodeLineStyle(node.parent)

        {
            let cursor = node.prevSibling

            while (cursor) {
                const isHidden = map.isNodeHidden(cursor)

                if (! isHidden) {
                    lines.push(`
                        <line
                            x1="${styleParent.trailingLineWidth / 2}"
                            x2="${styleParent.trailingLineWidth / 2}"
                            y1="0"
                            y2="50%"
                            stroke="${styleParent.trailingLineColor}"
                            stroke-width="${styleParent.trailingLineWidth}"
                        />
                    `)

                    break
                }

                cursor = cursor.prevSibling
            }
        }

        {
            let cursor = node.nextSibling

            while (cursor) {
                const isHidden = map.isNodeHidden(cursor)

                if (! isHidden) {
                    lines.push(`
                        <line
                            x1="${styleParent.trailingLineWidth / 2}"
                            x2="${styleParent.trailingLineWidth / 2}"
                            y1="50%"
                            y2="100%"
                            stroke="${styleParent.trailingLineColor}"
                            stroke-width="${styleParent.trailingLineWidth}"
                        />
                    `)

                    break
                }

                cursor = cursor.nextSibling
            }
        }
    }

    const hasChildren = [...map.children(node)].some(
        n => ! map.isNodeHidden(n)
    )

    if (hasChildren) {
        lines.push(`
            <line
                x1="50%"
                x2="100%"
                y1="50%"
                y2="50%"
                stroke="${styleNode.trailingLineColor}"
                stroke-width="${styleNode.trailingLineWidth}"
            />
        `)
    }

    return inlineSvg(`
        <svg xmlns="http://www.w3.org/2000/svg">
            ${lines.join('\n')}
        </svg>
    `)
}

const Node = ({node, ...props}) => {
    const map = useTreeDocContext()
    const background = useBackground(node)

    const style = {
        backgroundImage: `url('${background}')`,
    }

    return (
        <div
            css={cssNode}
            style={style}
            {...props}
        >
            <map.components.NodeContent node={node} />
        </div>
    )
}

const MemorizedNode = memo(Node, () => true)
MemorizedNode.displayName = 'Node'

export default MemorizedNode
