import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonReviseProduct(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    map.useNodeChange(map.root, ['data'])
    const {canReviseProduct, reviseProduct} = map.commands
    const nodes = canReviseProduct([map.root])

    if (
        0 === nodes.length ||
        ! permission.has('revise')
    ) {
        return null
    }

    const handleClick = () => reviseProduct(nodes)

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            修订制品
        </MenuButton>
    )
}
