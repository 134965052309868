import {useMapContext} from '@/components/MindMap/index.mjs'
import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Version from '../components/PropertiesControllerVersion/PropertiesControllerVersion.jsx'

export default function ModulePropertiesPane(props) {
    const map = useMapContext()
    const {controllers, versionLabel, ...otherProps} = props

    if ('SA_MAP' === map.data.mapTypeCode) {
        return (
            <ComponentPropertiesPane
                controllers={
                    <>
                        {controllers}
                        <Version label={versionLabel} />
                    </>
                }

                version={null}
                {...otherProps}
            />
        )
    }
    else {
        return (
            <ComponentPropertiesPane
                controllers={controllers}
                {...otherProps}
            />
        )
    }
}
