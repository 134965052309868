import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const refAutoScroll = useRef()
    const refIsEnabled = useRef(true)
    const refSelection = useRef(null)

    const extensions = () => ({
        disableCanvasSelection() {
            refIsEnabled.current = false
        },

        enableCanvasSelection() {
            refIsEnabled.current = true
        },

        useCanvasSelection() {
            const [selection, setSelection] = useState(null)

            useEffect(
                () => {
                    const handleCanvasSelectionChangeEnd = () => {
                        setSelection(null)
                    }

                    subscribe(this, 'canvas_selection_change', setSelection)

                    subscribe(
                        this,
                        'canvas_selection_change_end',
                        handleCanvasSelectionChangeEnd
                    )

                    return () => {
                        unsubscribe(
                            this,
                            'canvas_selection_change',
                            setSelection
                        )

                        unsubscribe(
                            this,
                            'canvas_selection_change_end',
                            handleCanvasSelectionChangeEnd
                        )
                    }
                },

                []
            )

            return selection
        }
    })

    const watchers = {
        canvas_auto_scroll(e) {
            if (! refIsEnabled.current) {
                return
            }

            const [x0, y0, x1, y1] = refSelection.current

            refSelection.current = [
                x0,
                y0,
                x1 + e.left,
                y1 + e.top,
            ]

            refAutoScroll.current.left += e.left
            refAutoScroll.current.top += e.top
            publish(this, 'canvas_selection_change', refSelection.current)
        },

        canvas_swipe_start(e) {
            if (
                1 !== e.buttons ||
                ! refIsEnabled.current
            ) {
                return
            }

            refAutoScroll.current = {left: 0, top: 0}
            this.startAutoScroll()
            publish(this, 'canvas_selection_change_start', e)
        },

        canvas_swipe_move(e) {
            if (
                1 !== e.buttons ||
                ! refIsEnabled.current
            ) {
                return
            }

            const {left, top} = refAutoScroll.current

            refSelection.current = [
                e.initialX,
                e.initialY,
                e.relativeX + left,
                e.relativeY + top,
            ]

            publish(this, 'canvas_selection_change', refSelection.current)
        },

        canvas_swipe_end(e) {
            if (
                1 !== e.buttons ||
                ! refIsEnabled.current
            ) {
                return
            }

            this.stopAutoScroll()
            publish(this, 'canvas_selection_change_end')
        },
    }

    return {extensions, watchers}
}
