import extendNode from '../extendNode.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaBd.mjs'
import Model from './ModelBd.mjs'
import PropertiesPane from './PropertiesPaneBd.jsx'
import FormChoose from './FormChooseBd.jsx'
import TableChoose from './TableChooseBd.jsx'
import api from './apiBd.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(UI)$/.test(type)
            }
            else {
                false
            }
        },

        canMountType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(CAT|MARK)$/.test(type)
            }
            else {
                return /^(BD_(BASE|DEVELOP|EVENT|LOGIC|RELATION|RULE|STRUCT|SU|SUMMARY|TERM))$/.test(type)
            }
        },

        async choose(map, node) {
            const getQuery = ({bms = [], ss, ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
                ssId: ss?.ssId,
            })

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return 'UI'
            }
            else {
                return ''
            }
        },

        getIcons(map, node) {
            const {bdType} = node.data

            if ('1' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="A"
                        textColor="#000"
                    />
                ]
            }
            else if ('3' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="D"
                        textColor="#000"
                    />
                ]
            }
            else {
                return []
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                eventList: [],
                ruleList: [],
                sfList: [],
                termList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'SingleBreakangle',
            }
        },

        getTitle(map, node) {
            const {dpType} = node.data

            return {
                BD: '业务对象',
                SVR: '业务服务',
            }[dpType]
        },

        menuItemsInsertCommon(map, node) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return [
                    ['CAT'],
                    ['MARK'],
                ]
            }
            else {
                return []
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,
                ['algList', 'eventList', 'ruleList', 'termList']
            )
        },

        menuItemsInsertProduct(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return [
                    ['UI'],
                ]
            }
            else {
                return []
            }
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'bdList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            if (
                void 0 === node.data.bdNo &&
                void 0 === node.data.bdType
            ) {
                node.data = {
                    ...node.data,
                    bdNo: 'BO',
                    bdType: '2',
                }
            }

            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = n.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        bmCode,
                        bmId,
                        bmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            for (const n of map.chainNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if (/^(CAT_ROOT_)?BM$/.test(bizNodeType)) {
                    const {bdType} = node.data

                    for (const nn of [...node.children]) {
                        const {bizNodeType} = nn.data

                        if (
                            (
                                '3' === bdType &&
                                'BD_DEVELOP' === bizNodeType
                            ) ||
                            (
                                '3' !== bdType &&
                                'BD_SUMMARY' === bizNodeType
                            )
                        ) {
                            map.deleteNode(nn)
                        }
                        else {
                            map.deleteTree(nn)
                        }
                    }
                }
                else {
                    break
                }
            }

            return newDepth
        },

        async _onChange(map, node, event) {
            await ProductNode._onChange.call(this, map, node, event)

            if (event.target === node) {
                const oldData = event.detail

                if (node.data.bdType !== oldData.bdType) {
                    // 强制子节点重新渲染

                    node.change('children')

                    for (const n of map.children(node)) {
                        n.change('data')
                        n.change('parent')
                    }
                }
            }
        },

        async _readGrowTree(map, node) {
            const {mapTypeCode} = map.data

            if (
                'IS_MAP' === mapTypeCode ||

                (
                    'SA_MAP' === mapTypeCode &&

                    (() => {
                        for (const n of map.chainNoComment(node.parent)) {
                            const {bizNodeType} = n.data

                            if (bizNodeType !== 'BM') {
                                return 'CAT_DATA' === bizNodeType
                            }
                        }

                        return false
                    })()
                )
            ) {
                const {pkid} = node.data
                const rev = this.getRev(map, node)
                return this.readTree({pkid, rev, briefType: 'IS'})
            }
            else {
                return ProductNode._readGrowTree.call(this, map, node)
            }
        },
    })
}
