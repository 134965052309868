const moveSelection = async (map, move) => {
    if (0 === map.selectedNodes.size) {
        return
    }

    const node = [...map.selectedNodes].at(-1)

    await map.cmd(
        () => move(node)
    )
}

export async function moveDownSelection() {
    await moveSelection(this, (node) => {
        if (! node.parent) {
            return
        }

        const isNodeHidden = n => this.isNodeHidden(n)
        let cursor = node

        while (cursor.nextSibling) {
            cursor = cursor.nextSibling

            if (! isNodeHidden(cursor)) {
                this.selectNodes([cursor])
                return
            }
        }

        cursor = node.parent.firstChild

        while (cursor !== node) {
            if (! isNodeHidden(cursor)) {
                this.selectNodes([cursor])
                return
            }

            cursor = cursor.nextSibling
        }
    })
}

export async function moveLeftSelection() {
    await moveSelection(this, (node) => {
        if (node.parent) {
            this.selectNodes([node.parent])
        }
    })
}

export async function moveRightSelection() {
    await moveSelection(this, (node) => {
        if (! node.firstChild) {
            return
        }

        const isNodeHidden = n => this.behaviours.isNodeHidden(n)
        let cursor = node.firstChild

        while (cursor) {
            if (! isNodeHidden(cursor)) {
                node.isFolded = false
                this.selectNodes([cursor])
                return
            }

            cursor = cursor.nextSibling
        }
    })
}

export async function moveUpSelection() {
    await moveSelection(this, (node) => {
        if (! node.parent) {
            return
        }

        const isNodeHidden = n => this.behaviours.isNodeHidden(n)
        let cursor = node

        while (cursor.prevSibling) {
            cursor = cursor.prevSibling

            if (! isNodeHidden(cursor)) {
                this.selectNodes([cursor])
                return
            }
        }

        cursor = node.parent.lastChild

        while (cursor !== node) {
            if (! isNodeHidden(cursor)) {
                this.selectNodes([cursor])
                return
            }

            cursor = cursor.prevSibling
        }
    })
}
