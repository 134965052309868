import {css} from '@emotion/react'
import {Button, Select} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
    padding: 4,
})

const cssSelectLevel = css({
    minWidth: 90,
})

const options = [
    {label: '调试', value: 'debug'},
    {label: '信息', value: 'info'},
    {label: '警告', value: 'warn'},
    {label: '错误', value: 'error'},
]

export default function ToolBar({levels, onChangeLevels, ...props}) {
    const map = useMapContext()
    const handleClickClear = () => map.logger.clear()

    return (
        <div
            css={cssToolBar}
            {...props}
        >
            <Select
                css={cssSelectLevel}
                bordered={false}
                mode="multiple"
                options={options}
                placeholder="过滤级别"
                showArrow
                showSearch={false}
                size="small"
                value={levels}
                onChange={onChangeLevels}
            />

            <Button
                icon={<DeleteOutlined />}
                size="small"
                title="清除消息队列"
                type="text"
                onClick={handleClickClear}
            />
        </div>
    )
}
