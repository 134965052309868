import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelDm.mjs'

const PropertiesPaneDm = (props) => (
    <ModulePropertiesPane
        controllers={
            <>
                <Text prop="dmUserCode" />
                <Text prop="dmName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        versionLabel="库表地图版本号"
        {...props}
    />
)

export default PropertiesPaneDm
