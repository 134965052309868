function copyTree() {
    const {
        commands: {canCopyTree, copyTree},
        selectedNodes,
    } = this

    const nodes = canCopyTree(selectedNodes)
    copyTree(nodes)
}

export default {
    'Ctrl+C': copyTree,
    'Meta+C': copyTree,
}
