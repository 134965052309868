import {css} from '@emotion/react'
import SelectedNodes from './SelectedNodes.jsx'
import SystemMessage from './SystemMessage.jsx'

const cssStatusBar = css({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
    lineHeight: 1,
    backgroundColor: '#fff',
    borderTop: '1px solid #e8e8e8',
    padding: 8,
})

export default function StatusBar(props) {
    return (
        <div
            css={cssStatusBar}
            {...props}
        >
            <SystemMessage />
            <SelectedNodes />
        </div>
    )
}
