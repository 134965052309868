import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import SidePane from '../../SidePanel/SidePane.jsx'
import {PropertiesContext} from '../../SidePanel/RightPanel/Properties.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import ProjectDetail from './PropertiesControllerProjectDetail.jsx'

const PropertiesPaneCatRootPrj = () => {
    const map = useMapContext()

    return (
        <PropertiesContext.Provider value={[map.data]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane>
                    <Text
                        label="项目编号"
                        labelSuffix={<ProjectDetail />}
                        prop="prjNo"
                    />

                    <Text
                        label="项目名称"
                        prop="prjName"
                    />

                    <Text
                        label="项目类型"
                        prop="typeName"
                    />

                    <Text
                        label="更新人"
                        prop="uptName"
                    />

                    <Text
                        label="更新时间"
                        prop="uptTime"
                    />

                    <Text
                        label="创建人"
                        prop="crtName"
                    />

                    <Text
                        label="创建时间"
                        prop="crtTime"
                    />
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}

export default PropertiesPaneCatRootPrj
