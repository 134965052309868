import {memo} from 'react'
import {css} from '@emotion/react'
import inlineSvg from '@/script/inlineSvg.mjs'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

// TODO: 读取样式
const LINE_COLOR = '#666'

const cssJointButton = css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 'calc(100% + 1px)',
    width: 32,
    height: '100%',
    fontSize: 9,
    lineHeight: 1,
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    appearance: 'none',
    cursor: 'pointer',
    margin: 'auto',
})

const backgroundFold = inlineSvg(`
    <svg xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="50%"
            cy="50%"
            stroke="${LINE_COLOR}"
            fill="#fff"
            r="8"
        />

        <line
            x1="35%"
            x2="65%"
            y1="50%"
            y2="50%"
            stroke="${LINE_COLOR}"
            stroke-width="1"
        />
    </svg>
`)

const cssJointButtonFold = css({
    backgroundImage: `url('${backgroundFold}')`,
    opacity: 0,

    'div:hover>&': {
        opacity: 100,
    },
})

const JointFold = ({descendantCount, ...props}) => {
    return (
        <button
            css={[cssJointButton, cssJointButtonFold]}
            tabIndex="-1"
            title={descendantCount}
            {...props}
        ></button>
    )
}

const backgroundUnfold = inlineSvg(`
    <svg xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="50%"
            cy="50%"
            stroke="${LINE_COLOR}"
            fill="#fff"
            r="8"
        />
    </svg>
`)

const cssJointButtonUnfold = css({
    backgroundImage: `url('${backgroundUnfold}')`,
    opacity: 100,
})

const JointUnfold = ({descendantCount, ...props}) => {
    const text = 99 < descendantCount ? '⋯' : descendantCount

    return (
        <button
            css={[cssJointButton, cssJointButtonUnfold]}
            tabIndex="-1"
            title={descendantCount}
            {...props}
        >{text}</button>
    )
}

const TreeJoint = ({node, ...props}) => {
    const map = useTreeDocContext()
    map.useNodeChange(node, ['descendantCount', 'isFolded'])

    const hasChildren = [...map.children(node)].some(
        n => ! map.isNodeHidden(n)
    )

    if (! hasChildren) {
        return null
    }

    const handleClick = (e) => {
        map.behaviours.onClickJoint(node, e)
    }

    const Component = node.isFolded ? JointUnfold : JointFold

    return (
        <Component
            descendantCount={node.descendantCount}
            onClick={handleClick}
            {...props}
        />
    )
}

const MemorizedTreeJoint = memo(TreeJoint, () => true)
MemorizedTreeJoint.displayName = 'TreeJoint'

export default MemorizedTreeJoint
