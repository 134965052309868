const doGrow = (map, recursive) => {
    const {
        commands: {canGrowNode, growNode},
        selectedNodes,
    } = map

    const nodes = canGrowNode(selectedNodes)

    if (0 < nodes.length) {
        growNode(nodes, recursive)
    }
}

function growNode() {
    doGrow(this, false)
}

function growNodeRecursive() {
    doGrow(this, true)
}

export default {
    'Ctrl+L': growNode,
    'Ctrl+Alt+L': growNodeRecursive,
    'Meta+L': growNode,
    'Meta+Alt+L': growNodeRecursive,
}
