import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canDeleteNode, deleteNode} = map.commands
    const nodes = canDeleteNode(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'delete-node',
        label: '删除单个节点',
        suffix: 'Ctrl+Del',
        onClick: () => deleteNode(nodes),
    }
}
