const Page = {
    pageSize: 20,
    init: function (rows, _size) {
        let total = rows ? rows.length : 0;
        let page = {
            total: total,
            pageSize: _size ?? this.pageSize,
            showSizeChanger: true,
            // showQuickJumper: true,
            // pageSizeOptions: ['5', '10'],
            showTotal: () => '共 ' + total + ' 条数据',
            onShowSizeChange: (current, size) => {
                this.pageSize = size;
                page.pageSize = size;
            }
        }
        return page;
    },
    // page: function (rows, ref) {
    //     let total = rows ? rows.length : 0;
    //     let page = {
    //         total: total,
    //         showSizeChanger: true,
    //         showTotal: () => '共 ' + total + ' 条数据',
    //         onShowSizeChange: (current, size) => {
    //             page.pageSize = size;

    //             if (ref) {
    //                 ref.current.pageSize = size;
    //             }
    //         }
    //     }
    //     page.pageSize = ref ? ref.current.pageSize : this.pageSize;
    //     return page;
    // },
    pagination: function (total, pageSize, currentPage, onPage) {
        const page = {
            current: currentPage,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showTotal: () => '共 ' + total + ' 条数据',
            onChange: (page, pageSize) => {
                onPage(page, pageSize);
            }
        }
        return page;
    }
}

export default Page;