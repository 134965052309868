import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canRedo} = map.useSelectionHistory()

    return {
        disabled: ! canRedo,
        key: 'redo-selection',
        label: '下次选择',
        suffix: 'Ctrl+→',
        onClick: () => map.redoSelection(),
    }
}
