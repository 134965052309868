import {Col, Form, Input, Select, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemAlgTitle = (props) => {
    return (
        <Form.Item
            label="方法标题"
            name="algTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAlgType = (props) => {
    return (
        <Form.Item
            label="方法类型"
            name="algType"
            {...props}
        >
            <Select
                allowClear

                options={[
                    {label: '基础', value: 'BASE'},
                    {label: '事件', value: 'DEVELOP'}
                ]}
            />
        </Form.Item>
    )
}

const FormItemBdName = (props) => {
    return (
        <Form.Item
            label="业务对象/服务名称"
            name="bdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdNo = (props) => {
    return (
        <Form.Item
            label="业务对象/服务代码"
            name="bdNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseBdalg = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBdName />
            </Col>

            <Col span={6}>
                <FormItemBdNo />
            </Col>

            <Col span={6}>
                <FormItemAlgTitle />
            </Col>

            <Col span={6}>
                <FormItemAlgType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseBdalg
