export function canCopyNodeStyle(nodes) {
    return [...nodes].filter(
        node => this.actions.canCopyNodeStyle(node)
    )
}

export async function copyNodeStyle(nodes) {
    await this.cmd(
        () => this.actions.copyNodeStyle(nodes)
    )
}
