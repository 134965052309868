import {css} from '@emotion/react'
import {Radio} from 'antd'
import {SearchOutlined} from '@ant-design/icons'

const cssList = css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',

    'label': {
        height: 'auto',
        border: 'none !important',
    },
})

const cssLabel = css({
    fontSize: 12,
})

const cssMatched = css({
    backgroundColor: '#fbc02d',
})

const Label = ({children, indexes, ...props}) => {
    const slices = []
    let l = 0

    for (const [i, j] of indexes) {
        slices.push(children.slice(l, i))
        slices.push(<span css={cssMatched}>{children.slice(i, j)}</span>)
        l = j
    }

    slices.push(children.slice(l))

    return (
        <div
            css={cssLabel}
            title={children}
            {...props}
        >
            {slices}
        </div>
    )
}

const cssNotFound = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    gap: 12,
    fontSize: 14,
    color: '#afafaf',

    'svg': {
        fontSize: '4em',
    },
})

const NotFound = (props) => {
    return (
        <div
            css={cssNotFound}
            {...props}
        >
            <SearchOutlined />
            未找到结果
        </div>
    )
}

const SearchList = ({matches, onSelect, ...props}) => {
    const options = matches.map(
        ([nodeId, text, indexes]) => ({
            label: <Label indexes={indexes}>{text}</Label>,
            value: nodeId,
        })
    )

    const handleChange = ({target: {value}}) => {
        onSelect([value])
    }

    return 0 < options.length ?
        <Radio.Group
            css={cssList}
            buttonStyle="solid"
            optionType="button"
            options={options}
            onChange={handleChange}
            {...props}
        />
        :
        <NotFound />
}

export default SearchList
