import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssNodeOwner = css({
})

export default function NodeDesc({node, ...props}) {
    const map = useMapContext()
    const _n = map.nodeProxy(node)
    const ownerText = _n.getOwnerText?.()

    if (! ownerText) {
        return null
    }

    return (
        <NodeExtraText
            css={cssNodeOwner}
            node={node}
            text={`【${ownerText}】`}
            {...props}
        />
    )
}
