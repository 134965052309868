import {TreeNode} from '@/script/Tree.mjs'

export default class TreeDocNode extends TreeNode {
    constructor(
        doc,

        {
            isFolded,
            isHidden,
            ...nodeData
        },
    ) {
        super(nodeData)
        this.#doc = doc
        this.#isFolded = isFolded
        this.#isHidden = isHidden
    }

    get data() {
        return super.data
    }

    set data(data) {
        this.#setTreeNodeProp('data', data)
    }

    get descendantCount() {
        return this.#descendantCount
    }

    set descendantCount(count) {
        const oldCount = this.#descendantCount

        if (oldCount === count) {
            return
        }

        const action = () => {
            this.#descendantCount = count
            this.change('descendantCount', count, oldCount)
        }

        const revert = () => {
            this.#descendantCount = oldCount
            this.change('descendantCount', oldCount, count)
        }

        this.#doc._transaction.do(action, revert)

        if (this.parent) {
            this.parent.descendantCount += count - oldCount
        }
    }

    get doc() {
        return this.#doc
    }

    get firstChild() {
        return super.firstChild
    }

    set firstChild(node) {
        this.#setTreeNodeProp('firstChild', node)
    }

    get isDeleted() {
        return super.isDeleted
    }

    set isDeleted(isDeleted) {
        this.#setTreeNodeProp('isDeleted', isDeleted)

        if (this.parent) {
            this.parent.descendantCount += isDeleted ? -1 : 1
        }
    }

    get isFolded() {
        return (
            null !== this.firstChild &&
            this.#isFolded
        )
    }

    set isFolded(isFolded) {
        if (isFolded && ! this.firstChild) {
            return
        }

        const oldIsFolded = this.#isFolded

        if (oldIsFolded === isFolded) {
            return
        }

        const action = () => {
            this.#isFolded = isFolded
            this.change('isFolded', isFolded, oldIsFolded)
        }

        const revert = () => {
            this.#isFolded = oldIsFolded
            this.change('isFolded', oldIsFolded, isFolded)
        }

        this.#doc._transaction.do(action, revert)
    }

    get isHidden() {
        return this.#isHidden
    }

    set isHidden(isHidden) {
        const oldIsHidden = this.#isHidden

        if (oldIsHidden === isHidden) {
            return
        }

        const action = () => {
            this.#isHidden = isHidden
            this.change('isHidden', isHidden, oldIsHidden)
        }

        const revert = () => {
            this.#isHidden = oldIsHidden
            this.change('isHidden', oldIsHidden, isHidden)
        }

        this.#doc._transaction.do(action, revert)
    }

    get lastChild() {
        return super.lastChild
    }

    set lastChild(node) {
        this.#setTreeNodeProp('lastChild', node)
    }

    get nextSibling() {
        return super.nextSibling
    }

    set nextSibling(node) {
        this.#setTreeNodeProp('nextSibling', node)
    }

    get parent() {
        return super.parent
    }

    set parent(node) {
        if (this.parent) {
            this.parent.descendantCount -= this.descendantCount + 1
        }

        this.#setTreeNodeProp('parent', node)

        if (this.parent) {
            this.parent.descendantCount += this.descendantCount + 1
        }
    }

    get prevSibling() {
        return super.prevSibling
    }

    set prevSibling(node) {
        this.#setTreeNodeProp('prevSibling', node)
    }

    change(type, newValue, oldValue) {
        this.#doc.change({newValue, node: this, oldValue, type})
    }

    export(transform = a => a) {
        const {isFolded, isHidden} = this

        return transform({
            ...super.export(),
            isFolded,
            isHidden,
        })
    }

    #descendantCount = 0
    #doc
    #isFolded = false
    #isHidden = false

    #setTreeNodeProp(prop, newValue) {
        const oldValue = this[prop]

        if (oldValue === newValue) {
            return
        }

        const action = () => {
            super[prop] = newValue
            this.change(prop, newValue, oldValue)
        }

        const revert = () => {
            super[prop] = oldValue
            this.change(prop, oldValue, newValue)
        }

        this.#doc._transaction.do(action, revert)
    }
}
