function resetNodeStyle() {
    const {
        commands: {canUpdateNodeStyle, resetNodeStyle},
        selectedNodes,
    } = this

    const nodes = canUpdateNodeStyle(selectedNodes)
    resetNodeStyle(nodes)
}

export default {
    'Ctrl+Alt+0': resetNodeStyle,
    'Meta+Alt+0': resetNodeStyle,
}
