import Select from '@/components/Form/Select.jsx'

const options = [
    ['String'],
    ['Integer'],
    ['Boolean'],
    ['BigDecimal'],
    ['LocalDate'],
    ['LocalDateTime'],
    ['Date'],
    ['Short'],
    ['Long'],
    ['Byte'],
    ['Character'],
    ['Float'],
    ['Double'],
]

const SelectBasicDataType = (props) => {
    return (
        <Select
            style={{display: 'block'}}
            options={options}
            {...props}
        />
    )
}

export default SelectBasicDataType
