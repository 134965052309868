import {InfoOutlined} from '@ant-design/icons'
import MessagePane from './MessagePane.jsx'

export default () => {
    return {
        icon: <InfoOutlined />,
        key: 'message',
        hotkey: 'Ctrl+Shift+L',
        name: '消息',
        pane: <MessagePane />,
    }
}
