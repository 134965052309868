import insertNode from './insertNode.mjs'

export const canInsertSibling = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canInsertSibling(node) &&
            this.BizNode[bizNodeType].canDuplicate(this, node)
        )
    }
}

const getDefaultType = (map, node) => {
    const _n = map.nodeProxy(node.parent)
    return _n.defaultChildType()
}

export const insertSiblingAfter = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.insertSiblingAfter.bind(this),
            getDefaultType
        )
    }
}

export const insertSiblingBefore = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.insertSiblingBefore.bind(this),
            getDefaultType
        )
    }
}
