import {useMapContext} from '@/components/MindMap/index.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonJumpStruct from '../_ARCH/PropertiesPaneButtonJumpStruct.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'

export default function PropertiesPaneBa(props) {
    const map = useMapContext()
    const isSaMap = 'SA_MAP' === map.data.mapTypeCode

    return (
        <ProductPropertiesPane
            buttons={
                <>
                    <ButtonExportModel
                        global
                        type="bm"
                    />

                    <ButtonImportModules
                        global
                        type="bm"
                    />

                    <ButtonJumpStruct />
                </>
            }

            sn={isSaMap ? null : void 0}
            status={isSaMap ? null : void 0}
            versionLabel="业务结构版本"
            {...props}
        />
    )
}
