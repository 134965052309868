import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default permission => {
    const refPermission = useRef(new Set(permission))

    const extensions = () => ({
        get permission() {
            return {
                add: perm => {
                    const perms = refPermission.current

                    if (! perms.has(perm)) {
                        perms.add(perm)
                        publish(this, 'permission_change')
                    }
                },

                delete: perm => {
                    const perms = refPermission.current

                    if (perms.has(perm)) {
                        perms.delete(perm)
                        publish(this, 'permission_change')
                    }
                },

                has: perm => refPermission.current.has(perm),
            }
        },

        usePermission() {
            const [, setFlag] = useState(false)

            useEffect(
                () => {
                    const handlePermissionChange = () => {
                        setFlag(f => ! f)
                    }

                    subscribe(
                        this,
                        'permission_change',
                        handlePermissionChange
                    )

                    return () => {
                        unsubscribe(
                            this,
                            'permission_change',
                            handlePermissionChange
                        )
                    }
                },

                []
            )

            return this.permission
        }
    })

    return {extensions}
}
