import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const markedNodes = map.useMarkedNodes()
    const selectedNodes = map.useSelectedNodes()

    const item = {
        key: 'mark-node',
        suffix: 'Ctrl+M',
    }

    if (0 === selectedNodes.size) {
        return {
            ...item,
            disabled: true,
            label: '标记',
        }
    }

    for (const n of selectedNodes) {
        if (markedNodes.has(n)) {
            return {
                ...item,
                label: '取消标记',
                onClick: () => map.unmarkNodes(selectedNodes)
            }
        }
    }

    return {
        ...item,
        label: '标记',
        onClick: () => map.markNodes(selectedNodes)
    }
}
