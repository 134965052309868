import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canPasteTreeAppend, pasteTreeAppend} = map.commands
    const nodes = canPasteTreeAppend(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'paste-tree',
        label: '粘贴',
        suffix: 'Ctrl+V',
        onClick: () => pasteTreeAppend(nodes),
    }
}
