import Modal from './Modal.jsx'

const Confirm = ({
    onOk,
    ...props
}) => (
    <Modal
        onOk={() => onOk(true)}
        onCancel={() => onOk(false)}
        {...props}
    />
)

export default Confirm
