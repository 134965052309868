import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonPushProduct(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    const {canPushProduct, canSaveMap, pushAndSave, saveMap} = map.commands
    const nodes = canPushProduct([map.root])

    const handleClick = (() => {
        if (! permission.has('write')) {
            return void 0
        }

        if (0 < nodes.length) {
            return pushAndSave
        }
        else if (canSaveMap()) {
            return saveMap
        }
    })()

    if (! handleClick) {
        return null
    }

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            更新制品
        </MenuButton>
    )
}
