import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCutTree, cutTree} = map.commands
    const nodes = canCutTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'cut-tree',
        label: '剪切',
        suffix: 'Ctrl+X',
        onClick: () => cutTree(nodes),
    }
}
