import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssListItem = css({
    fontSize: 16,
    padding: 8,
    cursor: 'pointer',
    borderBottom: '1px solid #e8e8e8',

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

export default function ListInvalid({items, ...props}) {
    const map = useMapContext()

    const listItems = items.map(({key, title}) => {
        const handleClick = () => {
            const node = map.getNode(key)
            map.execute(() => map.selectNodes([node]))
        }

        return (
            <div
                key={key}
                css={cssListItem}
                onClick={handleClick}
                {...props}
            >
                {title}
            </div>
        )
    })

    return (
        <div {...props}>
            {listItems}
        </div>
    )
}
