import {memo} from 'react'
import {css} from '@emotion/react'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const cssChildren = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})

const Children = ({node, ...props}) => {
    const map = useTreeDocContext()
    map.useNodeChange(node, ['children', 'isFolded'])

    if (node.isFolded) {
        return null
    }

    const children = [...node.children]
        .map(n => {
            const isHidden = map.isNodeHidden(n)

            if (isHidden) {
                return null
            }

            return (
                <map.components.Tree
                    key={n.id}
                    node={n}
                />
            )
        })
        .filter(a => a)

    return (
        <div
            css={cssChildren}
            {...props}
        >
            {children}
        </div>
    )
}

const MemorizedChildren = memo(Children, () => true)
MemorizedChildren.displayName = 'Children'

export default MemorizedChildren
