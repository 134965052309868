export const canUpgradeProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canUpgradeProduct(node)
        )
    }
}

export const upgradeProduct = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.upgradeProduct(nodes),
                {action: '升级模型制品'},
            )
        )
    }
}
