export function canUpdateNodeStyle(nodes) {
    return [...nodes].filter(
        node => this.actions.canUpdateNodeStyle(node)
    )
}

export async function updateNodeStyle(nodes, transform) {
    await this.cmd(
        () => this.actions.updateNodeStyle(nodes, transform)
    )
}
