import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDgf from './SelectDgf.jsx'

export default function PropertiesControllerRefCdgDf({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'refCdgDfCode'})

    const {
        isRefDf,
        refCdgCode,
        refCdgDfCode,
        refCdgDfId,
        refCdgDfName,
        refCdgDfUserCode,
        refCdgId,
        refCdgName,
        refCdgUserCode,
    } = values

    if ('1' !== isRefDf) {
        return null
    }

    const handleChange = (cdg) => {
        const {
            dfCode,
            dfId,
            dfName,
            dfUserCode,
            pcdgCode,
            pcdgUserCode,
            pcdgId,
            pcdgName,
            ...value
        } = cdg

        update({
            ...value,
            dfName,
            dfUserCode,
            refCdgCode: pcdgCode,
            refCdgDfCode: dfCode,
            refCdgDfId: dfId,
            refCdgDfName: dfName,
            refCdgDfUserCode: dfUserCode,
            refCdgId: pcdgId,
            refCdgName: pcdgName,
            refCdgUserCode: pcdgUserCode,
        })
    }

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            <SelectDgf
                disabled={p.disabled}
                isRefDf="0"
                pcdgId={refCdgId}
                readOnly={p.readOnly}

                value={{
                    dfCode: refCdgDfCode,
                    dfName: refCdgDfName,
                    dfUserCode: refCdgDfUserCode,
                    pcdgCode: refCdgCode,
                    pcdgId: refCdgId,
                    pcdgName: refCdgName,
                    pcdgUserCode: refCdgUserCode,
                    pkid: refCdgDfId,
                }}

                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
