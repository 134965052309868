import runAsync from '@/script/runAsync.mjs'
import useBizNode from '../../useBizNode.mjs'
import useProject from '../useProject.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (pkid, type) => {
    const BizNode = useBizNode()

    const {data: mapData} = useSWR(
        `/maps/${pkid}`,

        () => runAsync(
            () => BizNode[type].readMap({pkid}),
            {action: '读取地图'}
        ),
    )

    const {data: project} = useProject(mapData?.data.prjId)

    if (mapData && project) {
        Object.assign(mapData.data, project)
        return mapData
    }
    else {
        return void 0
    }
}
