import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useCommentNode from '../_COMMENT/useCommentNode.jsx'
import {IconArrowRight} from '../icons/IconArrow.jsx'
import meta from './metaCat.mjs'

export default () => {
    const CommentNode = useCommentNode()

    return extendNode(CommentNode, {
        ...meta,

        getIcons() {
            return [
                <IconArrowRight
                    key="type"
                    fill={Color.ORANGE}
                    color="#fff"
                />
            ]
        },
    })
}
