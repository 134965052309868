import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import RuleType from '../BDRULE/RuleType.jsx'

const FormItemBdName = (props) => {
    return (
        <Form.Item
            label="业务对象/服务名称"
            name="bdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdNo = (props) => {
    return (
        <Form.Item
            label="业务对象/服务代码"
            name="bdNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemRuleTitle = (props) => {
    return (
        <Form.Item
            label="规则标题"
            name="ruleTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemRuleType = (props) => {
    const options = RuleType.map(([value, label]) => ({label, value}))

    return (
        <Form.Item
            label="规则类型"
            name="ruleTypes"
            {...props}
        >
            <Select
                allowClear
                mode="multiple"
                options={options}
            />
        </Form.Item>
    )
}

const FormChooseBdrule = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBdName />
            </Col>

            <Col span={6}>
                <FormItemBdNo />
            </Col>

            <Col span={6}>
                <FormItemRuleTitle />
            </Col>

            <Col span={6}>
                <FormItemRuleType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseBdrule
