import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonReviseMap(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    map.useMetaData()
    const {canReviseMap, reviseMap} = map.commands

    if (! (
        canReviseMap() &&
        permission.has('revise')
    )) {
        return null
    }

    return (
        <MenuButton
            onClick={reviseMap}
            {...props}
        >
            修订
        </MenuButton>
    )
}
