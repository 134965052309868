export const canGrowNode = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canGrowNode(node)
        )
    }
}

export const growNode = () => () => {
    return async function (nodes, recursive) {
        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.growNode(nodes, recursive),
                {action: '加载节点'},
            )
        )
    }
}
