import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssText = css({
    color: 'red !important',
})

export default function NodeProject({node, ...props}) {
    const map = useMapContext()
    const {prjNo} = node.data
    const _n = map.nodeProxy(node)

    if (! _n.isExternal()) {
        return null
    }

    return (
        <NodeExtraText
            css={cssText}
            node={node}
            text={` @${prjNo}`}
            {...props}
        />
    )
}
