import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    progTitle: {
        name: () => '关键逻辑标题',
    },

    progTypeCode: {
        name: () => '关键逻辑类型',
    },

    progDesc: {
        name: () => '关键逻辑描述',
    },

    memo: {
        name: () => '备注',
    },
})
