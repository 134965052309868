import {useUser} from "@/biz/user.mjs"

export default permission => {
    const user = useUser()

    if (permission) {
        return {}
    }

    function resetPermission() {
        this.permission.add('revise')

        if ('1' === user.isPlatformAdm) {
            this.permission.add('write')
        }
        else {
            const {crtId, stsCode, uptId} = this.root.data

            if (
                (
                    'REVISE' === stsCode &&
                    uptId === user.pkid
                ) ||
                (
                    'DRAFT' === stsCode &&
                    crtId === user.pkid
                )
            ) {
                this.permission.add('write')
            }
            else {
                this.permission.delete('write')
            }
        }
    }

    const watchers = {
        load: resetPermission,
        model_change: resetPermission,
    }

    return {watchers}
}
