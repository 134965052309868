import {memo, useRef} from 'react'
import {css} from '@emotion/react'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const cssTree = css({
    display: 'flex',
    //alignItems: 'center',
    width: 'max-content',
    pointerEvents: 'none',
})

const cssNode = css({
    flexShrink: 0,
})

const cssChildren = css({
    //flexShrink: 0,
})

const Tree = ({node, ...props}) => {
    const map = useTreeDocContext()
    const refEl = useRef()
    map.useNodeChange(node, ['data'])
    map.useRegisterDom('trees', node, refEl)
    const {outerBoxBackgroundColor} = map.getNodeBoxStyle(node)

    const {
        backgroundColor,
        borderColor,
        borderWidth,
    } = map.getNodeShapeStyle(node)

    const style = {
        backgroundColor: outerBoxBackgroundColor,
        fill: backgroundColor,
        strokeWidth: borderWidth,
        stroke: borderColor,
    }

    return (
        <div
            ref={refEl}
            css={cssTree}
            style={style}
            {...props}
        >
            <map.components.Node
                css={cssNode}
                node={node}
            />

            <map.components.Children
                css={cssChildren}
                node={node}
            />
        </div>
    )
}

const MemorizedTree = memo(Tree, () => true)
MemorizedTree.displayName = 'Tree'

export default MemorizedTree
