export function canDeleteTree(node) {
    if (this.root === node) {
        this.logger.error('不能删除根节点', [node])
        return false
    }
    else {
        return true
    }
}

export function deleteTree(nodes) {
    const nodesToDelete = new Set(nodes)
    const nodesToSelect = new Set()

    for (const node of this.topNodes(nodes)) {
        const isNodeHidden = n => this.isNodeHidden(n)

        ;(() => {
            let cursor = node

            while (cursor.nextSibling) {
                cursor = cursor.nextSibling

                if (! (
                    nodesToDelete.has(cursor) ||
                    isNodeHidden(cursor)
                )) {
                    nodesToSelect.add(cursor)
                    return
                }
            }

            cursor = node

            while (cursor.prevSibling) {
                cursor = cursor.prevSibling

                if (! (
                    nodesToDelete.has(cursor) ||
                    isNodeHidden(cursor)
                )) {
                    nodesToSelect.add(cursor)
                    return
                }
            }

            if (node.parent) {
                nodesToSelect.add(node.parent)
            }
        })()

        this.deleteTree(node)
        this.logger.info('已删除分支', [node])
    }

    this.selectNodes(nodesToSelect)
}
