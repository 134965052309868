import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dataTypeCode: {
        name: () => '数据类型',
        required: () => true,
        ignoreRequiredInValidation: true,

        validate: ({dataTypeCode, dfDtoCode}) => {
            if (! (dataTypeCode || dfDtoCode)) {
                return '数据类型不能为空'
            }
        }
    },

    dataTypeGrpCode: {
        name: () => '类型组别',
        required: () => true,
    },

    dtDfCode: {
        name: () => '源表字段',
    },

    isDtDf: {
        name: () => '引用表字段',
    },

    dfUserCode: {
        name: () => '字段代码',
        required: () => true,
    },

    dfName: {
        name: () => '字段名称',
        required: () => true,
    },

    dfDesc: {
        name: () => '字段说明',
    },
})
