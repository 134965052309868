export function canUpdateNodeStyle(node) {
    return true
}

export function updateNodeStyle(nodes, transform) {
    for (const node of nodes) {
        const style = transform(node)

        node.data = {
            ...node.data,
            style: {...node.data.style, ...style},
        }
    }

    this.logger.info('已设置节点样式', nodes)
}
