import SelectDbDialog from '@/pages/MainPage/dialogs/SelectDbDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectDb({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({dbName}) => dbName).join(',') :
        (value ?? {}).dbName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectDbDialog}
            {...props}
        />
    )
}
