import {useRef} from 'react'
import {publish} from '@/script/event.mjs'
import {getScrollbarSize} from '@/script/scrollbar.mjs'

export default (step = 5) => {
    const refCanvas = useRef()
    const refIsActive = useRef(false)
    const refRafId = useRef()

    const extensions = () => ({
        startAutoScroll() {
            refIsActive.current = true
        },

        stopAutoScroll() {
            cancelAnimationFrame(refRafId.current)
            refIsActive.current = false
        },
    })

    const watchers = {
        canvas_swipe_move(e) {
            if (! refIsActive.current) {
                return
            }

            cancelAnimationFrame(refRafId.current)

            const autoScroll = () => {
                refRafId.current = requestAnimationFrame(() => {
                    const {
                        clientHeight,
                        clientWidth,
                        scrollHeight,
                        scrollLeft,
                        scrollTop,
                        scrollWidth,
                        top,
                        right,
                        bottom,
                        left,
                        scrollbarWidth,
                        scrollbarHeight,
                    } = refCanvas.current

                    const scrollRight = scrollWidth - clientWidth -
                        scrollLeft

                    const scrollBottom = scrollHeight - clientHeight -
                        scrollTop

                    const scrollOptions = {
                        behavior: 'instant',
                        left: 0,
                        top: 0,
                    }

                    if (e.clientX <= left) {
                        if (0 < scrollLeft) {
                            scrollOptions.left = - Math.min(
                                step, scrollLeft
                            )
                        }
                    }
                    else if (right - scrollbarWidth <= e.clientX) {
                        if (0 < scrollRight) {
                            scrollOptions.left = Math.min(
                                step, scrollRight
                            )
                        }
                    }

                    if (e.clientY <= top) {
                        if (0 < scrollTop) {
                            scrollOptions.top = - Math.min(
                                step, scrollTop
                            )
                        }
                    }
                    else if (bottom - scrollbarHeight <= e.clientY) {
                        if (0 < scrollBottom) {
                            scrollOptions.top = Math.min(
                                step, scrollBottom
                            )
                        }
                    }

                    if (scrollOptions.left || scrollOptions.top) {
                        refCanvas.current.scrollTop += scrollOptions.top
                        refCanvas.current.scrollLeft += scrollOptions.left
                        this.canvas.scrollBy(scrollOptions)
                        publish(this, 'canvas_auto_scroll', scrollOptions)
                        autoScroll()
                    }
                })
            }

            autoScroll()
        },

        canvas_swipe_start() {
            const {
                clientHeight,
                clientWidth,
                scrollHeight,
                scrollLeft,
                scrollTop,
                scrollWidth,
            } = this.canvas

            const {
                top,
                right,
                bottom,
                left,
            } = this.canvas.getBoundingClientRect()

            const {
                width: scrollbarWidth,
                height: scrollbarHeight,
            } = getScrollbarSize(this.canvas)

            refCanvas.current = {
                clientHeight,
                clientWidth,
                scrollHeight,
                scrollLeft,
                scrollTop,
                scrollWidth,
                top,
                right,
                bottom,
                left,
                scrollbarWidth,
                scrollbarHeight,
            }
        },
    }

    return {extensions, watchers}
}
