import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaUa.mjs'
import PropertiesPane from './PropertiesPaneUa.jsx'
import api from './apiUa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(UM)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'UM'
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archUmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_BLUE,
            })
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archUmList'])
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['UM'],
            ]
        },
    })
}
