import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import runAsync from '@/script/runAsync.mjs'
import apiSnapshot from '../../../apis/apiSnapshot.mjs'
import parseMap from '../../../scripts/parseMap.mjs'
import ModalVersions from './ModalVersions.jsx'

export default function PropertiesPaneButtonOldVersion(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (
        1 !== selectedNodes.size ||
        'SA_MAP' !== map.data.mapTypeCode
    ) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType, prjId} = node.data
    const BizNode = map.BizNode[bizNodeType]

    const handleClick = async () => {
        const {mapIdProp, mapTypeCode} = BizNode

        const modalProps = (() => {
            if (mapIdProp) {
                const {[mapIdProp]: dataId} = node.data
                return {dataId, dataType: mapTypeCode}
            }
            else {
                const {pkid} = node.data

                return {
                    dataId: pkid,
                    dataType: bizNodeType,
                }
            }
        })()

        const version = await openModal(
            <ModalVersions
                prjId={prjId}
                {...modalProps}
            />
        )

        if (! version) {
            return
        }

        map.cmd(async () => {
            const {dataVer} = version
            const oldData = node.data
            node.data = {...node.data, rev: dataVer}
            await BizNode.onSetData(map, node, oldData)
            await BizNode.grow(map, node)
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}
