import extendNode from '../extendNode.mjs'
import {Modal} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaArchCatNode.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        async onDoubleClick(map, node, event) {
            const {childType, detailUrl} = this

            for (const n of map.walkDown(node)) {
                const {bizNodeType} = n.data

                if (childType === bizNodeType) {
                    const {prjId, prjNo, sVer} = map.data
                    const url = buildUrl(detailUrl, {prjId, prjNo, sVer})
                    window.open(url)
                    return
                }
            }

            const {name} = map.BizNode[childType]

            Modal.warn({
                content: `请先插入${name}制品，并保存地图后，才能进入${this.name}`,

                onOk: async () => {
                    if (! this.canWriteTree(map, node)) {
                        return
                    }

                    const p = (() => {
                        for (
                            const n of
                            map.walkDown(node, {breadthFirst: true})
                        ) {
                            const _n = map.nodeProxy(n)

                            if (
                                _n.canLinkType(childType) ||
                                _n.canMountType(childType)
                            ) {
                                return n
                            }
                        }
                    })()

                    if (p) {
                        map.BizNode[childType].onInsertProduct(map, [p])
                    }
                },
            })
        },
    })
}
