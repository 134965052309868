import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    algKey: {
        name: () => '能力关键字',
    },

    algTitle: {
        name: ({algType}) => 'CONCEPT' === algType ? '变量名称' : '能力标题',
    },

    algUserCode: {
        name: () => '能力代码',
    },
})
