import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fjTitle: {
        name: () => '关键逻辑标题',
    },

    fjTypeCode: {
        name: () => '关键逻辑类型',
        required: () => true,
    },

    dtOprCode: {
        name: () => '表操作',
    },

    txNo: {
        name: () => '事务号',
    },

    fjDesc: {
        name: () => '关键逻辑描述',
    },

    memo: {
        name: () => '备注',
    },
})
