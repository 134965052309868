import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelAlg.mjs'

const PropertiesPaneAlg = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />
                <Text prop="algUserCode" />

                {
                    'CONCEPT' !== props.nodes[0].data.algType &&
                        <TagSelect prop="algKey" />
                }
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneAlg
