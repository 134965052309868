export function canInsertParent(nodes) {
    return [...nodes].filter(
        node => this.actions.canInsertParent(node)
    )
}

export async function insertParent(nodes) {
    await this.cmd(
        () => this.actions.insertParent(nodes)
    )
}
