export const canShrinkNode = () => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return this.BizNode[bizNodeType].canShrink(this, node)
    }
}

export const shrinkNode = () => () => {
    return function (nodes) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        await _n.shrink()
                        this.logger.info('卸载节点成功', [node])
                    }
                    catch (err) {
                        this.logger.error(
                            `卸载节点失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
