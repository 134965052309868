import {createContext, useContext} from 'react'

export class NodePropModel {
    static create(nodeDef, prop, data) {
        const propDef = nodeDef?.[prop]

        if (! propDef || ! data) {
            return null
        }

        const PropModel = {
            key: NodePropKeyModel,
            string: NodePropStringModel,
            text: NodePropStringModel,
            number: NodePropNumberModel,
            boolean: NodePropBooleanModel,
        }[propDef.type]

        return new PropModel(propDef, prop, data)
    }

    constructor(definition, prop, data) {
        this._data = data
        this._definition = definition
        this.#prop = prop
    }

    get isDisabled() {
        return this._definition.disabled?.(this._data) ?? false
    }

    get isHidden() {
        return this._definition.hidden?.(this._data) ?? false
    }

    get isReadOnly() {
        return this._definition.readOnly?.(this._data) ?? false
    }

    get isRequired() {
        return this._definition.required?.(this._data) ?? false
    }

    get name() {
        return this._definition.name?.(this._data) ?? ''
    }

    get raw() {
        return this._data[this.#prop]
    }

    get type() {
        return this._definition.type
    }

    get value() {
        return this.raw
    }

    validate() {
        if (
            this.isRequired &&
            ! this.isHidden &&
            ! this.isDisabled &&
            ! this.isReadOnly &&
            ! this._definition.ignoreRequiredInValidation &&
            ! this.raw &&
            0 !== this.raw
        ) {
            return `${this.name}不能为空`
        }
        else {
            return this._definition.validate?.(this._data)
        }
    }

    _definition = null
    _data = null
    #prop = ''
}

class NodePropBooleanModel extends NodePropModel {
    get value() {
        const {
            raw,
            _definition: {trueValue = '1'}
        } = this

        return raw === trueValue
    }
}

class NodePropNumberModel extends NodePropModel {
    get value() {
        const {raw} = this

        if (raw || 0 === raw) {
            return Number(this.raw)
        }
        else {
            return NaN
        }
    }
}

class NodePropStringModel extends NodePropModel {
    get value() {
        return this.raw ?? ''
    }
}

class NodePropKeyModel extends NodePropStringModel {
    get text() {
        return this._definition.text?.(this._data) ?? ''
    }
}

const bizNodeModel = (definition = {}) => {
    return class NodeModel {
        static get definition() {
            return definition
        }

        constructor(data) {
            this.#data = data
        }

        disabled(prop) {
            return definition[prop]?.disabled?.(this.#data)
        }

        hidden(prop) {
            return definition[prop]?.hidden?.(this.#data)
        }

        name(prop) {
            return definition[prop]?.name?.(this.#data)
        }

        readOnly(prop) {
            return definition[prop]?.readOnly?.(this.#data)
        }

        required(prop) {
            return definition[prop]?.required?.(this.#data)
        }

        validate(prop) {
            const validateProp = (prop) => {
                if (
                    this.required(prop) &&
                    ! this.hidden(prop) &&
                    ! this.disabled(prop) &&
                    ! this.readOnly(prop) &&
                    ! definition[prop]?.ignoreRequiredInValidation &&
                    ! this.#data[prop] &&
                    0 !== this.#data[prop]
                ) {
                    return `${this.name(prop)}不能为空`
                }
                else {
                    return definition[prop]?.validate?.(this.#data)
                }
            }

            if (prop) {
                return validateProp(prop)
            }
            else {
                for (const prop of Object.keys(definition)) {
                    const errMsg = validateProp(prop)

                    if (errMsg) {
                        return errMsg
                    }
                }
            }
        }

        #data
    }
}

export const ModelContext = createContext(bizNodeModel())
export const useModel = () => useContext(ModelContext)
export default bizNodeModel
