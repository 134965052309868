import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmCode: {
        name: () => '功能模块',
    },

    sfNo: {
        name: () => '系统功能代码',
        required: () => true,
    },

    sfName: {
        name: () => '系统功能名称',
        required: () => true,
    },

    sfTypeCode: {
        disabled: ({pkid}) => pkid,
        name: () => '系统功能类型',
        required: () => true,
    },

    sfKey: {
        name: () => '功能简述',
    },

    sfMemo: {
        name: () => '备注',
    },
})
