import {css} from '@emotion/react'

const cssMenu = css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 8,
})

export default function Menu(props) {
    return (
        <div
            css={cssMenu}
            {...props}
        >
        </div>
    )
}
