import {useEffect, useRef} from 'react'

export default () => {
    const refHotkeys = useRef(new Map)

    const extensions = () => ({
        useRegisterHotkeys(pairs) {
            useEffect(
                () => {
                    for (
                        const [combination, callback]
                        of Object.entries(pairs)
                    ) {
                        refHotkeys.current.set(
                            combination, callback.bind(this)
                        )
                    }

                    return () => {
                        for (const combination of Object.keys(pairs)) {
                            refHotkeys.current.delete(combination)
                        }
                    }
                },

                [pairs]
            )
        },
    })

    const watchers = {
        key_down(e) {
            const callback = refHotkeys.current.get(e.combination)?.bind(this)

            if (! callback) {
                return
            }

            e.preventDefault()
            e.stopPropagation()
            callback(this)
        }
    }

    return {extensions, watchers}
}
