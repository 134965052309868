import bizNodeModel from '../../bizNodeModel.mjs'
import ModelAlg from '../_ALG/ModelAlg.mjs'

export default bizNodeModel({
    ...ModelAlg.definition,

    algTitle: {
        name: () => '场景流程标题',
    },
})
