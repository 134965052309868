import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()

    return {
        key: 'select-root-node',
        label: '选择根节点',
        suffix: 'Home',
        onClick: () => map.commands.selectRoot(),
    }
}
