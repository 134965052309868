import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaArchNode.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,

        canChangeVersion(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return true
            }
            else {
                return ProductNode.canChangeVersion.call(this, map, node)
            }
        },

        canDeleteTree(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return false
            }
            else {
                return ProductNode.canDeleteTree.call(this, map, node)
            }
        },

        getText(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return this.alias
            }
            else {
                return ProductNode.getText.call(this, map, node)
            }
        },

        getDesc(map, node) {
            return ''
        },

        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {prjId, prjNo, rev, sVer} = node.data
            const url = buildUrl(this.archUrl, {prjId, prjNo, rev, sVer})
            window.open(url)
        },

        showVersion(map, node) {
            return false
        },

        async _readGrowTree(map, node) {
            const {pkid} = node.data
            const {rev} = await this.read({pkid})

            if (! rev) {
                throw new Error(`请先发布${this.name}`)
            }

            return this.readTree({pkid, rev})
        },
    })
}
