export default class Transaction {
    #transaction

    constructor() {
        this.#transaction = this.#createTransaction()
    }

    commit() {
        const transaction = this.#transaction
        this.#transaction = this.#createTransaction()
        return transaction
    }

    do(action, revert) {
        action()
        const {actions, reverts} = this.#transaction
        actions.push(action)
        reverts.unshift(revert)
    }

    rollback() {
        for (const revert of this.#transaction.reverts) {
            revert()
        }

        this.#transaction = this.#createTransaction()
    }

    #createTransaction() {
        return {actions: [], reverts: []}
    }
}
