import {message, Modal} from 'antd'
import runAsync from '@/script/runAsync.mjs'

export default () => {
    const extensions = () => ({
        async runAction(fn, options) {
            try {
                const result = await runAsync(fn, options)
                this.logger.info(`${options.action}成功`)
                return result
            }
            catch (err) {
                this.logger.error(`${options.action}失败: ${err.message}`)
                throw err
            }
        },

        async runParallelAction(
            fn,

            {
                action = '',
                showError = true,
                showLoading = true,
                showSuccess = true,
                succussDuration = 2,
            } = {},
        ) {
            const hideLoading = showLoading ?
                message.loading(`正在${action}，请稍候`, 0)
                :
                () => {}

            try {
                const results = await fn()
                hideLoading()
                const errors = []

                for (const {reason, status} of results) {
                    if ('rejected' === status) {
                        errors.push(reason)
                    }
                }

                for (const err of errors) {
                    console.error(err)
                }

                if (1 === errors.length) {
                    if (showError) {
                        Modal.error({content: `${action}失败: ${errors[0].message}`})
                    }
                }
                else if (1 < errors.length) {
                    if (showError) {
                        Modal.error({content: `${action}失败，查看消息面板以获取详细信息`})
                    }
                }
                else {
                    if (showSuccess) {
                        message.success(`${action}成功`, succussDuration)
                    }
                }

                return results
            }
            catch (err) {
                hideLoading()
                const message = `${action}失败: ${err.message}`
                this.logger.error(message)

                if (showError) {
                    Modal.error({content: message})
                }

                throw err
            }
        }
    })

    return {extensions}
}
