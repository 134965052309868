import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bjName: {
        name: () => '作业名称',
        required: () => true,
    },

    bjNo: {
        name: () => '作业编号',
    },

    bjPos: {
        name: () => '作业岗位',
    },

    onlineFlag: {
        name: () => '是否线上',
    },
})
