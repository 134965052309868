const canMoveTree = (map, nodes, initCursor, nextCursor) => {
    const movableNodes = []

    const groups = map.groupByParent(
        [...nodes].filter(
            node => map.actions.canMoveTree(node)
        )
    )

    for (const [parent, childNodes] of groups) {
        const children = new Set(childNodes)
        const isNodeHidden = n => map.isNodeHidden(n)
        let cursor = initCursor(parent)

        while (cursor) {
            if (children.has(cursor)) {
                children.delete(cursor)
            }
            else if (! isNodeHidden(cursor)) {
                break
            }

            cursor = nextCursor(cursor)
        }

        movableNodes.push(...children)
    }

    return movableNodes
}

export function canMoveDownTree(nodes) {
    return canMoveTree(
        this,
        nodes,
        ({lastChild}) => lastChild,
        ({prevSibling}) => prevSibling,
    )
}

export function canMoveUpTree(nodes) {
    return canMoveTree(
        this,
        nodes,
        ({firstChild}) => firstChild,
        ({nextSibling}) => nextSibling,
    )
}

export async function moveDownTree(nodes) {
    await this.cmd(
        () => this.actions.moveDownTree(nodes)
    )
}

export async function moveUpTree(nodes) {
    await this.cmd(
        () => this.actions.moveUpTree(nodes)
    )
}
