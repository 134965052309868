import {css} from '@emotion/react'
import {BorderOutlined} from '@ant-design/icons'
import {useTreeDocContext} from './TreeDocContext.jsx'

const cssPanel = css({
    display: 'flex',
    height: '100%',
    backgroundColor: '#fff',
    overflow: 'hidden',

    '& .ant-tabs': {
        height: '100%',
    },

    '& .ant-tabs-nav': {
        margin: 0,
    },

    '& .ant-tabs-content': {
        height: '100%',
    },

    '& .ant-tabs-tabpane': {
        height: '100%',
    },
})

const cssTabsHead = css({
    width: 32,
})

const cssTabsHeadLtr = css({
    order: 0,
    borderRight: '1px solid #e8e8e8',
})

const cssTabsHeadRtl = css({
    order: 1,
    borderLeft: '1px solid #e8e8e8',
})

const cssTabsBody = css({
    width: 300,
    flexGrow: 1,
    overflowY: 'auto',
})

const cssTabsBodyLtr = css({
    order: 1,
    borderRight: '1px solid #e8e8e8',
})

const cssTabsBodyRtl = css({
    order: 0,
    borderLeft: '1px solid #e8e8e8',
})

const cssIcon = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '1',
    color: '#98999a',
    cursor: 'pointer',
})

const cssIconCurrent = css({
    color: '#000',
})

const Icon = ({current, ...props}) => {
    const csss = [cssIcon]

    if (current) {
        csss.push(cssIconCurrent)
    }

    return <div css={csss} {...props}></div>
}

const TabsHead = ({current, direction, items, onChange, ...props}) => {
    const icons = items.map(
        ({icon, key, label}) => {
            const handleClick = () => {
                if (current === key) {
                    onChange('')
                }
                else {
                    onChange(key)
                }
            }

            return (
                <Icon
                    key={key}
                    current={current === key}
                    title={label}
                    onClick={handleClick}
                >
                    {icon}
                </Icon>
            )
        }
    )

    const csss = [
        cssTabsHead,

        {
            ltr: cssTabsHeadLtr,
            rtl: cssTabsHeadRtl,
        }[direction]
    ]

    return (
        <div
            css={csss}
            {...props}
        >
            {icons}
        </div>
    )
}

const TabsBody = ({current, direction, items, ...props}) => {
    if (! current) {
        return null
    }

    const item = items.find(({key}) => current === key)

    const csss = [
        cssTabsBody,

        {
            ltr: cssTabsBodyLtr,
            rtl: cssTabsBodyRtl,
        }[direction]
    ]

    return (
        <div
            css={csss}
            {...props}
        >
            {item?.pane}
        </div>
    )
}

export default function SidePanel({direction, panelId, panes, ...props}) {
    const doc = useTreeDocContext()
    const paneId = doc.useCurrentSidePane(panelId)

    doc.useRegisterHotkeys(
        Object.fromEntries(panes.map(
            ({hotkey, key}) => [
                hotkey,

                () => {
                    if (key === paneId) {
                        doc.closeSidePanel(panelId)
                    }
                    else {
                        doc.openSidePanel(panelId, key)
                    }
                }
            ]
        ))
    )

    const items = 0 < panes.length ?
        panes.map(
            ({hotkey, name, ...other}) => ({
                label: `${name} ${hotkey}`,
                ...other,
            })
        )
        :
        [
            {
                icon: <BorderOutlined />,
                key: 'default',
                label: '切换面板',
                pane: null,
            },
        ]

    const current = (() => {
        if (
            '' === paneId ||
            items.some(e => e.key === paneId)
        ) {
            return paneId
        }
        else {
            return items[0].key
        }
    })()

    const handleChangePane = paneId => doc.openSidePanel(panelId, paneId)

    return (
        <div
            css={cssPanel}
            {...props}
        >
            <TabsHead
                current={current}
                direction={direction}
                items={items}
                onChange={handleChangePane}
            />

            <TabsBody
                current={current}
                direction={direction}
                items={items}
            />
        </div>
    )
}
