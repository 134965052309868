const IconPrinter = ({fill, theme}) => {
    const color = {
        light: '#fff',
        dark: '#666',
    }[theme]

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128"
        >
            <circle
                cx="50%"
                cy="50%"
                fill={fill}
                r="50%"
            />

            <polygon
                fill={color}
                stroke={color}
                strokeWidth="8"
                strokeLinejoin="round"
                points="38,36 88,36 110,91 17,91"
            />
        </svg>
    )
}

export default IconPrinter
