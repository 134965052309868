import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const dictBool = {0: '否', 1: '是'}

const TableChooseDto = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: 'DTO代码',
            dataIndex: 'dtoUserCode',
            component: <Table.ViewText />,
        },

        {
            title: 'DTO名称',
            dataIndex: 'dtoName',
            component: <Table.ViewText />,
        },

        {
            title: '是否DR',
            dataIndex: 'isDr',
            component: <Table.ViewDict dict={dictBool} />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/dtos"
            query={query}
            {...props}
        />
    )
}

export default TableChooseDto
