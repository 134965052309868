import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    memo: {
        name: () => '备注',
    },

    pcdgName: {
        name: () => '组名称',
        required: () => true,
    },

    pcdgUserCode: {
        name: () => '组代码',
        required: () => true,
    },
})
