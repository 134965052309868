import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    cdtSeqValName: {
        name: () => '序列码',
        required: () => true,
    },

    cdtSeqVal: {
        name: () => '名称',
        required: () => true,
    },

    cdtSeqValEn: {
        name: () => '英文名称',
    },

    cdtSeqValDesc: {
        name: () => '说明',
    },
})
