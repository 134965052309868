import _Menu from '../../Menu.jsx'
import MenuButtonNextDiff from './MenuButtonNextDiff.jsx'
import MenuButtonPrevDiff from './MenuButtonPrevDiff.jsx'
import MenuButtonCompareProduct from './MenuButtonCompareProduct.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonPrevDiff />
            <MenuButtonNextDiff />
            <MenuButtonCompareProduct />
        </_Menu>
    )
}
