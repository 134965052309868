import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaDpProps.mjs'

export default () => {
    const CategroyNode = useCategoryNode()

    return extendNode(CategroyNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(DPPROP)$/.test(type)
        },

        canWriteTree(map, node) {
            return false
        },

        getText(map, node) {
            return this.name
        },
    })
}
