function quickInsertProduct() {
    const {
        commands: {canInsertProduct, quickInsertProduct},
        selectedNodes,
    } = this

    const nodes = canInsertProduct(selectedNodes)

    if (0 < nodes.length) {
        quickInsertProduct(nodes)
    }
}

export default {
    'Ctrl+I': quickInsertProduct,
    'Meta+I': quickInsertProduct,
}
