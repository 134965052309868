import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import plFromDb from '../scripts/plFromDb.mjs'
import meta from './metaDf.mjs'
import Model from './ModelDf.mjs'
import PropertiesPane from './PropertiesPaneDf.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchDf.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        attrNodes: {
            bottom: ['ATTR_DF_DESC'],
        },

        canGrow(map, node) {
            return true
        },

        canLinkType(map, node, type) {
            return 'DT' === type
        },

        canMountType(map, node, type) {
            return 'FK' === type
        },

        castTo(map, node, bizNodeType) {
            ComponentNode.castTo.call(this, map, node, bizNodeType)

            if ('DGF' === bizNodeType) {
                node.data = {...node.data, isCdgDf: undefined}
            }
            else if ('DTF' === bizNodeType) {
                const {
                    cdtDataTypeCode,
                    ddtDataTypeCode,
                    dataTypeCode,
                    dataTypeGrpCode,
                    dfCode,
                    dfLen,
                    dfPrecision,
                    dfUserCode,
                    ...data
                } = node.data

                const convertCode = (s = '') => {
                    const cs = []
                    let toUpper = false

                    for (const c of s) {
                        if ('_' === c) {
                            toUpper = true
                        }
                        else if (toUpper) {
                            cs.push(c.toUpperCase())
                            toUpper = false
                        }
                        else {
                            cs.push(c.toLowerCase())
                        }
                    }

                    return cs.join('')
                }

                node.data = {
                    ...data,
                    bdtCode: '',
                    dataTypeGrpCode: 'Basic',

                    dataTypeCode: plFromDb({
                        cdtDataTypeCode,
                        dataTypeCode,
                        dataTypeGrpCode,
                        ddtDataTypeCode,
                        dfLen,
                        dfPrecision,
                    }),

                    dfDesc: '',
                    dfUserCode: convertCode(dfUserCode),
                    dtDfCode: dfCode,
                    isDtDf: '1',
                }
            }
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DF"
                        textColor="#666"
                    />
                ]
            }
        },

        getStyle(map, node) {
            const {isCdgDf} = node.data

            return this._getStyle(map, node, {
                borderColor: '1' === isCdgDf ? '#f0f2f5' : Color.CYAN
            })
        },

        getTextSuffix(map, node) {
            const {
                dfAlias,
                pkid,
                [this.codeProp]: code,
            } = node.data

            if (pkid) {
                if (dfAlias) {
                    return ` ${code} (${dfAlias})`
                }
                else {
                    return ` ${code}`
                }
            }
            else if (dfAlias) {
                return ` (${dfAlias})`
            }
            else {
                return ComponentNode.getTextSuffix.call(this, map, node)
            }
        },

        async grow(map, node) {
            await this.shrink(map, node)

            await this._growProps(map, node, [
                'isCdgDf',
                'cdgDfCode',
                'dfUserCode',
                'dfName',
                'dfAlias',
                'dataTypeCode',
                'dfLen',
                'dfPrecision',
                'isPk',
                'isBk',
                'isNull',
                'defVal',
                'dfDesc',
            ])
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            if (
                undefined !== data.isCdgDf &&
                oldData.isCdgDf !== data.isCdgDf
            ) {
                Object.assign(d, {
                    cdgCode: '',
                    cdgDfCode: '',
                    cdgDfName: '',
                    cdgDfUserCode: '',
                    cdgName: '',
                })
            }

            if (
                'defVal' in data ||
                'dfLen' in data ||
                'dfPrecision' in data
            ) {
                const {
                    dataTypeCode,
                    defVal,
                    dfLen,
                    dfPrecision,
                } = {...oldData, ...data}

                if (
                    dfLen &&
                    dfPrecision &&
                    Number(dfLen) < Number(dfPrecision)
                ) {
                    d.dfPrecision =  dfLen - 1
                }
                else {
                    d.dfPrecision = dfPrecision
                }

                if (defVal) {
                    if (/^(DOUBLE|FLOAT|NUMBER)$/.test(dataTypeCode)) {
                        d.defVal = Number(String(defVal).replace(
                            /^(-?)(\d*)(\.?)(\d*)$/,

                            (match, p1, p2, p3, p4) => {
                                if (dfLen && d.dfPrecision) {
                                    p2 = p2.slice(0, dfLen)

                                    p4 = p4.slice(
                                        0,

                                        Math.min(
                                            dfLen - p2.length,
                                            d.dfPrecision
                                        )
                                    )
                                }
                                else if (dfLen) {
                                    p2 = p2.slice(0, dfLen)
                                    p4 = p4.slice(0, dfLen - p2.length)
                                }
                                else if (d.dfPrecision) {
                                    p4 = p4.slice(0, d.dfPrecision)
                                }

                                return [p1, p2, p3, p4].join('')
                            }
                        ))
                    }
                    else if (dfLen) {
                        d.defVal = defVal.slice(0, dfLen)
                    }
                }
            }

            if ('1' === data.isPk) {
                d.isNull = '0'
            }

            return d
        },

        async _atAttach(map, node, event) {
            if (event.target === node) {
                for (const n of map.chainNoComment(node.parent)) {
                    const {bizNodeType} = n.data
                    const BizNode = map.BizNode[bizNodeType]

                    if (
                        'AR' === bizNodeType ||
                        BizNode.classes.includes('ar_category') ||
                        BizNode.algType
                    ) {
                        const {
                            [this.ownerTextProp]: ownerText,
                            [this.textProp]: text,
                        } = node.data

                        if (ownerText) {
                            node.data = {
                                ...node.data,
                                [this.textProp]: `【${ownerText}】${text}`,
                            }
                        }

                        map.deleteChildren(node)
                    }

                    break
                }
            }

            await ComponentNode._atAttach.call(this, map, node, event)
        },

        async _atCreate(map, node) {
            if (void 0 === node.data.isNull) {
                node.data = {...node.data, isNull: '1'}
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            const key = {
                DT: 'dmDfList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        async shrink(map, node) {
            await this._shrinkProps(map, node)
        },
    })
}
