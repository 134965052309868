import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaPseudoNode.mjs'

export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canDuplicate(map, node) {
            return false
        },

        canWriteData(map, node) {
            return false
        },

        canWriteTree(map, node) {
            return false
        },

        getDesc(map, node) {
            return ''
        },

        _onAttach(map, node, event) {
            event.stopPropagation()
            map.deleteTree(node)
        },
    })
}
