import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectBm from '../BM/SelectBm.jsx'

const PropertiesControllerBm = ({
    className,
    style,
    codeProp = 'bmCode',
    disabled,
    hidden,
    idProp = 'bmId',
    label,
    labelSuffix,
    nameProp = 'bmName',
    readOnly,
    required,
    ...props
}) => {
    const [values, update] = useProperties()

    const {
        [codeProp]: bmCode,
        [idProp]: bmId,
        [nameProp]: bmName,
    } = values

    const handleChange = bm => {
        const {
            bmCode = '',
            bmId = '',
            bmName = '',
        } = bm ?? {}

        update({
            [codeProp]: bmCode,
            [idProp]: bmId,
            [nameProp]: bmName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectBm
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{bmCode, bmId, bmName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}

export default PropertiesControllerBm
