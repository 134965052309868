import {css} from '@emotion/react'
import {SearchOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssInputTextWritable = css({
    '.ant-input[readonly]+.ant-input-suffix .ant-input-clear-icon': {
        display: 'block',
    },
})

export default function InputModalSelect({
    className,
    style,
    disabled,
    getText,
    Modal,
    multiple,
    readOnly,
    onChange,
    ...props
}) {
    const gReadOnly = useReadOnly()
    const openModal = useOpenModal()
    const map = useMapContext()
    const text = getText(multiple)

    const handleChangeInput = text => {
        if (! text) {
            onChange(null)
        }
    }

    const handleClickInput = async () => {
        if (readOnly || gReadOnly) {
            return
        }

        const {prjId, sVer} = map.data
        const params = {prjId, sVer, ...props}

        const result = await openModal(
            <Modal
                multiple={multiple}
                params={params}
            />
        )

        if (result) {
            onChange(result)
        }
    }

    const icon = readOnly || gReadOnly ?
        null
        :
        <SearchOutlined
            disabled={disabled}
            onClick={handleClickInput}
        />

    const csss = readOnly || gReadOnly ? [] : [cssInputTextWritable]

    return (
        <InputText
            className={className}
            style={style}
            css={csss}
            addonAfter={icon}
            allowClear
            disabled={disabled}
            readOnly
            value={text}
            title={text}
            onChange={handleChangeInput}
            onClick={handleClickInput}
        />
    )
}
