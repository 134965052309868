export default {
    BLUE: '#287ef6',
    BROWN: '#923931',
    CYAN: '#7dffff',
    DARK_CYAN: '#2febd8',
    DARK_GOLD: '#ffbd30',
    DARK_GREEN: '#006400',
    GOLD: '#fdd834',
    GREEN: '#50cb56',
    GREEN_YELLOW: '#d5fb61',
    GREY: '#d0d0d0',
    LIGHT_BLUE: '#50c3f7',
    LIGHT_GREEN: '#66ff66',
    LIGHT_GREY: '#f7f7f7',
    LIGHT_YELLOW: '#ffff99',
    ORANGE: '#f69853',
    PURPLE: '#ba69c8',
    RED: '#f54b3b',
    SKY_BLUE: '#bdd7ee',
}
