const IconCircle = ({
    borderWidth = 0,
    borderColor = 'transparent',
    fill = '#000',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <circle
            cx="50%"
            cy="50%"
            fill={borderColor}
            r="50%"
        />

        <circle
            cx="50%"
            cy="50%"
            fill={fill}
            r={64 - borderWidth}
        />
    </svg>
)

export default IconCircle
