import {createShape} from '@/components/MindMap/index.mjs'

const RADIAN = Math.PI * (60 / 180)

export const Parallelogram = (textRect, style) => {
    const bw = style.borderWidth
    const h = textRect.height
    const ph = h / Math.tan(RADIAN)
    const paddings = [bw, bw + ph, bw, bw + ph]

    const draw = (w, h) => {
        const x = h / Math.tan(RADIAN)

        return `
            <path
                d="
                    M ${x + bw / 2} ${bw / 2}
                    H ${w - bw / 2}
                    L ${w - x - bw / 2} ${h - bw}
                    H ${bw / 2}
                    Z
                "
            />
        `
    }

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
