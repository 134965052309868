import {css} from '@emotion/react'

const cssIcon = css({
    position: 'relative',
})

const cssIconNotification = css({
    '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        width: 4,
        height: 4,
        backgroundColor: 'red',
        borderRadius: '50%',
    },
})

export default function IconWithNotification({notification, ...props}) {
    const csss = [cssIcon]

    if (notification) {
        csss.push(cssIconNotification)
    }

    return (
        <div
            css={csss}
            {...props}
        />
    )
}
