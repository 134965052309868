export const canCopyTree = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canCopyTree(node) &&
            this.BizNode[bizNodeType].canCopyTree(this, node)
        )
    }
}

export const copyTree = () => () => {
    return async function copyTree(nodes) {
        if (0 === nodes.length) {
            return
        }

        const trees = []
        const packedNodes = this.topNodes(nodes)

        for (const [parent, childNodes] of this.groupByParent(packedNodes)) {
            const sortedNodes = parent ?
                this.sortSiblings(childNodes) :
                childNodes

            for (const node of sortedNodes) {
                const _n = this.nodeProxy(node)
                const tree = _n.exportTree()
                trees.push(tree)
            }
        }

        const data = {trees, map: this.data}
        const json = JSON.stringify(data)
        await navigator.clipboard.writeText(json)
        this.logger.info(`${trees.length}个节点已拷贝到剪贴板`, nodes)
    }
}
