import {useEffect} from 'react'

const combinationFromEvent = (e) => {
    const {altKey, ctrlKey, key, metaKey, shiftKey} = e
    const keys = []

    if (ctrlKey) {
        keys.push('Ctrl')
    }

    if (altKey) {
        keys.push('Alt')
    }

    if (shiftKey) {
        keys.push('Shift')
    }

    if (metaKey) {
        keys.push('Meta')
    }

    if (/^[a-z]$/.test(key)) {
        keys.push(key.toUpperCase())
    }
    else if (! /^(Alt|Control|Meta|Shift)$/.test(key)) {
        keys.push(key)
    }

    return keys.join('+')
}

const extendEvent = (e) => {
    const combination = combinationFromEvent(e)
    return Object.assign(e, {combination})
}

export default ({lockedKeys, ...handlers} = {}) => {
    useEffect(
        () => {
            navigator.keyboard?.lock(lockedKeys)
            return () => navigator.keyboard?.unlock()
        },

        []
    )

    const onKeyDown = (e) => {
        handlers.onKeyDown?.(extendEvent(e))
    }

    const onKeyUp = (e) => {
        handlers.onKeyUp?.(extendEvent(e))
    }

    return {onKeyDown, onKeyUp}
}
