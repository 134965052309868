import {useRef} from 'react'
import Modal from '@/components/Modal/Modal.jsx'
import TableVersions from './TableVersions.jsx'

const bodyStyle = {
    //overflow: 'hidden',
}

export default function ModalVersions({
    dataId,
    dataType,
    prjId,
    onOk,
    ...props
}) {
    const refSelectedItem = useRef()
    const handleOk = () => onOk(refSelectedItem.current)
    const handleChange = item => refSelectedItem.current = item

    return (
        <Modal
            title="选择版本"
            width={1200}
            bodyStyle={bodyStyle}
            onOk={handleOk}
            {...props}
        >
            <TableVersions
                dataId={dataId}
                dataType={dataType}
                prjId={prjId}
                onChange={handleChange}
            />
        </Modal>
    )
}
