import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaPhys_.mjs'
import FormChoose from '../PHYS/FormChoosePhys.jsx'
import TableChoose from '../PHYS/TableChoosePhys.jsx'
import api from './apiPhys_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async choose(map, node) {
            const {prjId} = map.data
            const getQuery = q => ({prjId, ...q})
            const items = await this._choose(map, node, {getQuery})

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'PHYS'},
            }))
        },
    })
}
