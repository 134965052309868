import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'
import StoryType from '../BDSUMMARY/StoryType.jsx'

const TableChooseBdsummary = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '用户故事类型',
            dataIndex: 'storyType',
            component: <Table.ViewDict dict={Object.fromEntries(StoryType)} />,
        },

        {
            title: '用户故事标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdsummaries"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableChooseBdsummary
