import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'

const PropertiesPaneButtonSaveProduct = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const readOnly = useReadOnly()

    if (readOnly) {
        return null
    }

    const {commands: {canSaveProduct, saveProduct}} = map
    const nodes = canSaveProduct(selectedNodes)
    const handleClick = () => saveProduct(selectedNodes)

    return (
        <Button
            disabled={0 === nodes.length}
            type="primary"
            onClick={handleClick}
            {...props}
        >保存</Button>
    )
}

export default PropertiesPaneButtonSaveProduct
