function deleteTree() {
    const {
        commands: {canDeleteTree, deleteTree},
        selectedNodes,
    } = this

    const nodes = canDeleteTree(selectedNodes)
    deleteTree(nodes)
}

export default {
    'Backspace': deleteTree,
    'Delete': deleteTree,
}
