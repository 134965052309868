export function canCopyTree(nodes) {
    return [...nodes].filter(
        node => this.actions.canCopyTree(node)
    )
}

export async function copyTree(nodes) {
    await this.cmd(
        () => this.actions.copyTree(nodes)
    )
}
