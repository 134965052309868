import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dbCode: {
        name: () => '数据库',
    },

    dmCode: {
        name: () => '库表域',
    },

    dvUserCode: {
        name: () => '视图代码',
        required: () => true,
    },

    dvName: {
        name: () => '视图名称',
        required: () => true,
    },
})
