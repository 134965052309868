import {useEffect, useRef} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'
import TreeDoc from './TreeDoc.mjs'
import useAutoScrollCanvas from './plugins/useAutoScrollCanvas.mjs'
import useCancelNodeSelectionOnPointerDownCanvas from './plugins/useCancelNodeSelectionOnPointerDownCanvas.mjs'
import useCanvas from './plugins/useCanvas.mjs'
import useCanvasSelection from './plugins/useCanvasSelection.mjs'
import useDragCanvas from './plugins/useDragCanvas.mjs'
import useGodMode from './plugins/useGodMode.mjs'
import useNodeChange from './plugins/useNodeChange.mjs'
import useNodesChange from './plugins/useNodesChange.mjs'
import useSelectNodes from './plugins/useSelectNodes.mjs'

const installExtentions = (doc, extensions) => {
    for (const install of extensions) {
        const ext = install(doc)

        for (const key of Object.keys(ext)) {
            const desc = Reflect.getOwnPropertyDescriptor(ext, key)

            if (Object.hasOwn(desc, 'value')) {
                if (void 0 === desc.value) {
                    Reflect.deleteProperty(doc, key)
                    continue
                }

                if ('function' === typeof desc.value) {
                    desc.value = desc.value.bind(doc)
                }
            }
            else {
                if ('function' === typeof desc.get) {
                    desc.get = desc.get.bind(doc)
                }

                if ('function' === typeof desc.set) {
                    desc.set = desc.set.bind(doc)
                }
            }

            Reflect.defineProperty(doc, key, desc)
        }
    }
}

const useInstallWatchers = (doc, watchers) => {
    useEffect(
        () => {
            const listeners = watchers
                .map(watcher => {
                    return Object.entries(watcher).map(
                        ([eventType, handler]) => [
                            eventType,
                            handler.bind(doc)
                        ]
                    )
                })
                .flat()

            for (const [eventType, listener] of listeners) {
                subscribe(doc, eventType, listener)
            }

            return () => {
                for (const [eventType, listener] of listeners) {
                    unsubscribe(doc, eventType, listener)
                }
            }
        },

        [watchers]
    )
}

const useInstallPlugins = (doc, plugins) => {
    const extensions = []
    const watchers = []

    for (const {extensions: e, watchers: w} of plugins) {
        if (e) {
            // 允许后安装的扩展覆盖先安装的扩展
            extensions.push(e)
        }

        if (w) {
            // 允许后安装的监听器取消事件以避免触发先安装的监听器
            watchers.unshift(w)
        }
    }

    installExtentions(doc, extensions)
    useInstallWatchers(doc, watchers)
}

export default ({
    Doc = TreeDoc,
    initData,
    plugins = [],
}) => {
    const refDoc = useRef()
    refDoc.current ??= new Doc

    useInstallPlugins(
        refDoc.current,

        [
            useAutoScrollCanvas(),
            useCancelNodeSelectionOnPointerDownCanvas(),
            useAutoScrollCanvas(),
            useCanvas(),
            useCanvasSelection(),
            useDragCanvas(),
            useGodMode(),
            useNodeChange(),
            useNodesChange(),
            useSelectNodes(),
            ...plugins
        ]
    )

    useEffect(
        () => {
            const doc = refDoc.current

            // 等待事件监听注册结束
            setTimeout(() => {
                doc.init(initData.root)
                publish(doc, 'load')
            })
        },

        []
    )

    return refDoc.current
}
