import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBdsummary.mjs'
import StoryType from './StoryType.jsx'

const PropertiesPaneSummary = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />

                <Select
                    options={StoryType}
                    prop="storyType"
                />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSummary
