import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssVersionText = css({
})

const cssVersionTextOutdated = css({
    color: 'red !important',
})

export default function NodeVersion({node, ...props}) {
    const map = useMapContext()
    const _n = map.nodeProxy(node)

    if (! _n.showVersion()) {
        return null
    }

    const version = _n.getVersion()

    if (! version) {
        return null
    }

    const csss = [cssVersionText]

    if (_n.isOutdated()) {
        csss.push(cssVersionTextOutdated)
    }

    return (
        <NodeExtraText
            css={csss}
            node={node}
            text={`【${version}】`}
            {...props}
        />
    )
}
