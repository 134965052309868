import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    termKey: {
        name: () => '术语关键字',
    },

    termTitle: {
        name: () => '术语标题',
        required: () => true,
    },

    termType: {
        name: () => '术语类型',
    },

    termUserCode: {
        name: () => '术语代码',
    },
})
