import {useMapContext} from '@/components/MindMap/index.mjs'
import useMenuItemToggleUnfoldDepth from './useMenuItemToggleUnfoldDepth.jsx'
import useMenuItemToggleFoldNode from './useMenuItemToggleFoldNode.jsx'
import useMenuItemToggleFoldTree from './useMenuItemToggleFoldTree.jsx'

export default () => {
    const children = [
        useMenuItemToggleFoldNode(),
        useMenuItemToggleFoldTree(),
        {divider: true},
        useMenuItemToggleUnfoldDepth(0),
        useMenuItemToggleUnfoldDepth(1),
        useMenuItemToggleUnfoldDepth(2),
        useMenuItemToggleUnfoldDepth(3),
        useMenuItemToggleUnfoldDepth(4),
        useMenuItemToggleUnfoldDepth(5),
        useMenuItemToggleUnfoldDepth(6),
        useMenuItemToggleUnfoldDepth(7),
        useMenuItemToggleUnfoldDepth(8),
        useMenuItemToggleUnfoldDepth(9),
    ]

    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    const {
        commands: {canFold, canUnfold},
    } = map

    const nodesCanFold = canFold(selectedNodes)
    const nodesCanUnfold = canUnfold(selectedNodes)

    const disabled = (
        0 === nodesCanFold.length &&
        0 === nodesCanUnfold.length
    )

    return {
        children,
        disabled,
        key: 'fold',
        label: '折叠/展开',
    }
}
