import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelCdg.mjs'

const PropertiesPaneCdg = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="pcdgUserCode" />
                <Text prop="pcdgName" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneCdg
