import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    ruleKey: {
        name: () => '规则关键字',
    },

    ruleTitle: {
        name: () => '规则标题',
        required: () => true,
    },

    ruleType: {
        name: () => '规则类型',
        required: () => true,
    },

    ruleUserCode: {
        name: () => '规则代码',
    },
})
