import {css} from '@emotion/react'
import InputText from '@/components/Form/InputText.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {PropertiesController} from '../../../SidePanel/RightPanel/PropertiesController.jsx'
import ButtonChangeVersion from './ButtonChangeVersion.jsx'
import ButtonEditDesign from './ButtonEditDesign.jsx'
import ButtonLastRev from './ButtonLastRev.jsx'
import ButtonOnlineRev from './ButtonOnlineRev.jsx'
import ButtonUpgrade from './ButtonUpgrade.jsx'

const cssVersion = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'end',
    gap: 4,
})

const Version = ({node, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const _n = map.nodeProxy(node)
    const version = _n.getVersion()

    if (! version) {
        return null
    }

    return (
        <PropertiesController
            labelSuffix={
                <div>
                    <ButtonOnlineRev node={node} />
                    <ButtonLastRev node={node} />
                    <ButtonUpgrade node={node} />
                    <ButtonEditDesign node={node} />
                </div>
            }

            {...props}
        >
            <div css={cssVersion}>
                <InputText
                    readOnly
                    value={version}
                />

                <ButtonChangeVersion node={node} />
            </div>
        </PropertiesController>
    )
}

export default function PropertiesControllerVersion(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 < selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes
    return <Version node={node} {...props} />
}
