import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    refCdgDfCode: {
        name: () => '源标准字段',
    },

    dataTypeCode: {
        name: () => '数据类型',
        required: () => true,
    },

    isRefDf: {
        name: () => '引用标准字段组字段',
    },

    dfUserCode: {
        name: () => '字段代码',
        required: () => true,
    },

    dfName: {
        name: () => '字段名称',
        required: () => true,
    },

    dfLen: {
        disabled: ({dataTypeGrpCode, isCdgDf, isRefDf}) => (
            'BDT' !== dataTypeGrpCode ||
            '1' === isCdgDf ||
            '1' === isRefDf
        ),

        hidden: ({dataTypeCode}) => {
            return ! /^((N?VAR)?CHAR|NUMBER)$/.test(dataTypeCode)
        },

        name: () => '字段长度',

        required: ({dataTypeCode}) => {
            return /^((N?VAR)?CHAR|NUMBER)$/.test(dataTypeCode)
        },
    },

    dfPrecision: {
        disabled: ({dataTypeGrpCode, isCdgDf, isRefDf}) => (
            'BDT' !== dataTypeGrpCode ||
            '1' === isCdgDf ||
            '1' === isRefDf
        ),

        hidden: ({dataTypeCode}) => 'NUMBER' !== dataTypeCode,
        name: () => '字段精度',
    },

    isNull: {
        name: () => '是否可空',
    },

    defVal: {
        name: () => '默认值',
        hidden: ({dataTypeCode}) => /^B?LOB$/.test(dataTypeCode),
    },

    dfDesc: {
        name: () => '字段说明',
    },
})
