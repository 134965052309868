const unfold = (depth) => {
    return function () {
        const {
            commands: {unfoldDepth},
            selectedNodes,
        } = this

        unfoldDepth(selectedNodes, depth)
    }
}

export default Object.fromEntries(
    new Array(10).fill().map(
        (_, i) => [`Ctrl+Alt+${i}`, unfold(i)]
    )
)
