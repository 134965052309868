import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Select, Modal, Transfer, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import SelectDtDialog from '@/pages/MainPage/dialogs/SelectDtDialogNew';
import { useOpenModal } from '@/components/Modal/Modal.jsx';

export const ModalDfkAdd = ({ dbId, dtId, prjId, sVer, fieldRows, visible, onOk, ...props }) => {
    const http = useHTTP()
    const [form] = Form.useForm();
    const openModal = useOpenModal();

    // 数据模型
    const ref = useRef({});
    const [fields, setFields] = useState([]);

    // 组件初始化
    useEffect(() => {
        if (visible) {
            // 排序
            fieldRows.sort(sortId);
            form.resetFields();
            initTitle();
        }
    }, [visible]);

    // 排序
    const sortId = (a, b) => {
        return a.seqNo - b.seqNo; // 由低到高
    }

    const initTitle = () => {
        let _dfkTitle = '[FK]';
        fieldRows.forEach(function (ele, index) {
            if (index == 0) {
                _dfkTitle = _dfkTitle.concat(ele.dfName);
            } else {
                _dfkTitle = _dfkTitle.concat('+' + ele.dfName);
            }
        });
        form.setFieldsValue({ fkTitle: _dfkTitle });
    }

    const doAdd = async () => {
        try {
            let formField = await form.validateFields();

            // 当前创建外键的表ID
            formField.dtId = dtId;
            formField.refDtId = ref.current.dtId; // 引用表ID

            let fks = [];
            fieldRows.forEach(function (ele, index) {
                let fkObj = {};
                fkObj.fkDfId = ele.pkid; // 外键字段ID
                fkObj.refDtId = ref.current.dtId; // 引用表ID
                fkObj.refDfId = formField["refDfId_" + index]; // 引用字段ID
                fks.push(fkObj);
            });

            // 表外键字段
            formField.dmFkDfs = fks;

            // 新增外键
            const fk = await runAsync(
                async () => {
                    return await http.post(`/dmfks`, formField);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            const dfs = new Map(
                fields.map(e => [e.pkid, e])
            )

            const refDfs = new Map(
                fks.map(
                    ({ fkDfId, refDfId }) => [fkDfId, dfs.get(refDfId)]
                )
            )

            onOk({ fk, refDfs })
        } catch (err) {
            console.error(err);
        }
    }

    // 数据表
    // const callDt = (value) => {
    //     if (value) {
    //         queryFields(value.dtId);
    //         ref.current.dtId = value.dtId;
    //         ref.current.dtCode = value.dtCode;
    //         ref.current.dtName = value.dtName;
    //         ref.current.dtUserCode = value.dtUserCode;
    //         ref.current.dtRev = value.rev;
    //         form.setFieldsValue({ dtName: value.dtName });
    //     } else {
    //         setFields([]);
    //         ref.current.dtId = null;
    //         ref.current.dtCode = null;
    //         ref.current.dtName = null;
    //         ref.current.dtUserCode = null;
    //         ref.current.dtRev = null;
    //         form.setFieldsValue({ dtName: '' });
    //     }

    //     // refDfId_index
    //     form.setFieldsValue({ refDfId_0: null });
    //     form.setFieldsValue({ refDfId_1: null });
    //     form.setFieldsValue({ refDfId_2: null });
    //     form.setFieldsValue({ refDfId_3: null });
    // }

    const callDt = async () => {
        const result = await openModal(<SelectDtDialog params={{ prjId, sVer, dbId }} />);

        if (result) {
            queryFields(result.dtId);
            ref.current.dtId = result.dtId;
            ref.current.dtCode = result.dtCode;
            ref.current.dtName = result.dtName;
            ref.current.dtUserCode = result.dtUserCode;
            ref.current.dtRev = result.rev;
            form.setFieldsValue({ dtName: result.dtName });
        }

        // refDfId_index
        form.setFieldsValue({ refDfId_0: null });
        form.setFieldsValue({ refDfId_1: null });
        form.setFieldsValue({ refDfId_2: null });
        form.setFieldsValue({ refDfId_3: null });
    }

    // 获取表ID，查询表字段
    const queryFields = async (dtId) => {
        try {
            let data = await runAsync(
                async () => {
                    return await http.get(`/dmdfs`, { dtId });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            if (data) {
                data = data.filter(v => '1' == v.isPk || '1' == v.isBk);
                setFields(data);

                if (data.length == 1) {
                    form.setFieldsValue({ refDfId_0: data[0].pkid });
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Modal
            open={visible}
            title="新建表外键"
            okText="保存"
            cancelText="取消"
            onOk={doAdd}
            {...props}
        >
            <Form form={form} onFinish={doAdd} layout="vertical">
                <Form.Item label="名称" name="fkTitle">
                    <Input disabled />
                </Form.Item>

                <Col >
                    <Form.Item name="dtName" label="引用表" rules={[{ required: true, message: '请选择引用表' }]}>
                        <Input placeholder="请选择数据表" readOnly addonAfter={<SearchOutlined onClick={callDt} />} />
                    </Form.Item>
                </Col>

                <Form.Item label={<span><label className='field-required'>*</label>引用字段</span>}>
                    {
                        fieldRows.length && fieldRows.map((item, index) => (
                            <div>
                                <label>{item.dfUserCode}({item.dfName}) {'引用的字段：'} </label>

                                <Form.Item name={"refDfId_" + index} rules={[{ required: true, message: '请选择外键字段' }]}>
                                    <Select defaultValue={fields.length > 0 ? fields[0].pkid : null} >
                                        {
                                            fields.length && fields.map((item, index) => (
                                                <Select.Option key={index} value={item.pkid}>{item.dfUserCode}({item.dfName})</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        ))
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}

const DfkAdd = ({ onRefresh, dbId, dtId, prjId, sVer, fieldRows }) => {
    // 加载显示
    const [visible, setVisible] = useState(false);

    const toAdd = () => {
        if (fieldRows.length > 0) {
            setVisible(true);
        } else {
            Modal.info({ title: '提示信息', content: '请选择至少一个字段' });
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => toAdd()} disabled={!dbId}>生成外键</Button>

            <ModalDfkAdd
                dbId={dbId}
                dtId={dtId}
                fieldRows={fieldRows}
                prjId={prjId}
                sVer={sVer}
                visible={visible}
                onOk={() => { onRefresh(); setVisible(false) }}
                onCancel={() => setVisible(false)}
            />
        </>
    );
}

export default DfkAdd;