import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    ueName: {
        name: () => '事件能力名称',
    },

    ueTypeCode: {
        name: () => '事件能力类型',
    },
})
