import {publish} from '@/script/event.mjs'

export default class Task {
    constructor(doc) {
        this.#doc = doc
    }

    async execute(fn) {
        if (this.#isRunning) {
            try {
                await fn()
                publish(this.#doc, 'task_execute')
            }
            catch (err) {
                this.#isRunning = false
                publish(this.#doc, 'task_failed')
                throw err
            }
        }
        else {
            this.startTask()

            try {
                await fn()
                publish(this.#doc, 'task_execute')
            }
            catch (err) {
                publish(this.#doc, 'task_failed')
                throw err
            }
            finally {
                this.finishTask()
            }
        }
    }

    finishTask() {
        if (this.#isRunning) {
            this.#isRunning = false
            publish(this.#doc, 'task_finish')
        }
        else {
            throw new Error('Task is not running.')
        }
    }

    startTask() {
        if (this.#isRunning) {
            throw new Error('Task is already running.')
        }
        else {
            this.#isRunning = true
            publish(this.#doc, 'task_start')
        }
    }

    #doc
    #isRunning = false
}
