import {SearchOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function SelectBd({value, onChange, ...props}) {
    const map = useMapContext()

    const handleClick = async () => {
        const items = await map.BizNode.BD.choose(map)

        if (items) {
            onChange(items[0].data)
        }
    }

    const addonAfter = <SearchOutlined onClick={handleClick} />

    return (
        <InputText
            addonAfter={addonAfter}
            readOnly
            value={value?.bdName}
            onClick={handleClick}
            onKeyDown={e => e.stopPropagation()}
            {...props}
        />
    )
}
