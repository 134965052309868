import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemCat = (props) => {
    return (
        <Form.Item
            label="类别"
            name="cdtCat"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemCdtCode = (props) => {
    return (
        <Form.Item
            label="CDT代码"
            name="cdtUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemCdtName = (props) => {
    return (
        <Form.Item
            label="CDT名称"
            name="cdtName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemLock = (props) => {
    return (
        <Form.Item
            label="是否锁定"
            name="lockFlag"
            {...props}
        >
            <Select options={[
                {label: '否', value: '0'},
                {label: '是', value: '1'},
            ]} />
        </Form.Item>
    )
}

const FormItemSeq = (props) => {
    return (
        <Form.Item
            label="是否序列"
            name="isSeq"
            {...props}
        >
            <Select options={[
                {label: '否', value: '0'},
                {label: '是', value: '1'},
            ]} />
        </Form.Item>
    )
}

const FormChooseCdt = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemCat />
            </Col>

            <Col span={6}>
                <FormItemCdtCode />
            </Col>

            <Col span={6}>
                <FormItemCdtName />
            </Col>

            <Col span={3}>
                <FormItemLock />
            </Col>

            <Col span={3}>
                <FormItemSeq />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseCdt
