import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBdDevelop.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(BDALG|CAT)$/.test(type)
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '2' === bdType
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['BDALG'],
            ]
        },

        async _atCreate(map, node) {
            if (void 0 === node.data.algType) {
                node.data = {...node.data, algType: 'DEVELOP'}
            }

            await AlgCatNode._atCreate.call(this, map, node)
        },
    })
}
