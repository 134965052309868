import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'SUMMARY',
    name: '用户故事',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    type: 'BDSUMMARY',
}
