import {publish} from '@/script/event.mjs'

export function canEditNodeText(node) {
    return true
}

export function editNodeText(node, text) {
    this.execute(() => node.text = text)
}

export function finishEditNodeText(node) {
    publish(this, 'edit_node_text', null)
    this.finishTask()
}

export function startEditNodeText(node, text) {
    this.startTask()
    publish(this, 'edit_node_text', node)
    this.behaviours.editNodeText(node, text)
}
