export const canExportNode = () => () => {
    return function (nodes, type) {
        return [...nodes].filter(
            node => this.actions.canExportNode(node, type)
        )
    }
}

export const exportNode = () => () => {
    return async function (nodes, type) {
        this.runAction(
            () => this.cmd(
                () => this.actions.exportNode(nodes, type)
            ),

            {action: '导出节点'}
        )
    }
}
