import normalizeNumber from './normalizeNumber.mjs'

const VIEW_SIZE = 128

export default function IconConnect({
    borderWidth = 0,
    borderColor = 'transparent',
    fill = '#000',
}) {
    const strokeWidth = normalizeNumber(VIEW_SIZE, borderWidth)
    const s = VIEW_SIZE
    const w = strokeWidth / 2

    const d = `
        M ${w},${w}
        L ${s - w},${w}
        L ${s - w},${s * 0.5}
        L ${s * 0.5},${s - w}
        L ${w},${s * 0.5}
        Z
    `

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${s} ${s}`}
        >
            <path
                d={d}
                fill={fill}
                strokeWidth={strokeWidth}
                stroke={borderColor}
            />
        </svg>
    )
}
