import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaLs.mjs'
import Model from './ModelLs.mjs'
import PropertiesPane from './PropertiesPaneLs.jsx'
import FormChoose from './FormChooseLs.jsx'
import TableChoose from './TableChooseLs.jsx'
import api from './apiLs.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(LSI|SF)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT|DOC)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        defaultChildType(map, node) {
            return 'LSI'
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="L"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: '#fff',
                borderColor: Color.PURPLE,
            })
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
                ['DOC'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['LSI'],
                ['SF'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['LSI'],
                ['SF'],
            ]
        },
    })
}
