import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()
    const {canSaveMap, saveMap} = map.commands

    return {
        disabled: ! canSaveMap(),
        key: 'save-map',
        label: '保存',
        suffix: 'Ctrl+S',
        onClick: saveMap,
    }
}
