import TagSelect from '@/components/Form/TagSelect.jsx'

import {
    PropertiesController,
    usePropertiesController,
} from './PropertiesController.jsx'

export default function PropertiesControllerTagSelect({
    className,
    style,
    disabled,
    hidden,
    label,
    labelSuffix,
    prop,
    required,
    value,
    ...props
}) {
    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop,
        required,
        value,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <TagSelect
                disabled={p.disabled}
                value={p.value}
                onBlur={p.onBlur}
                onChange={p.onChange}
                onFocus={p.onFocus}
                {...props}
            />
        </PropertiesController>
    )
}
