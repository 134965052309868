import {Col, Row} from 'antd'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import DtOprType from './DtOprType.mjs'
import FjType from './FjType.mjs'

const ModalPropertiesFj = () => {
    const [values] = useProperties()

    return (
        <Row gutter={[16, 16]}>
            <Col span="6">
                <LongText prop="fjTitle" />
            </Col>

            <Col span="6">
                <Select
                    options={FjType}
                    prop="fjTypeCode"
                />
            </Col>

            {
                {
                    DT: (
                        <>
                            <Col span="6">
                                <Select
                                    options={DtOprType}
                                    prop="dtOprCode"
                                />
                            </Col>

                            <Col span="6">
                                <Text prop="txNo" />
                            </Col>
                        </>
                    ),

                    LSI: (
                        <Col span="6">
                            <Text prop="txNo" />
                        </Col>
                    ),
                }[values.fjTypeCode]
            }

            <Col span="24">
                <LongText prop="fjDesc" />
            </Col>

            <Col span="24">
                <LongText prop="memo" />
            </Col>
        </Row>
    )
}

export default ModalPropertiesFj
