export function canCopyTree(node) {
    return true
}

export async function copyTree(nodes) {
    if (0 === nodes.length) {
        return
    }

    const getTree = node => this.exportTree(
        node,
        ({id, ...n}) => n
    )

    const trees = []
    const packedNodes = this.topNodes(nodes)

    for (const [parent, childNodes] of this.groupByParent(packedNodes)) {
        const sortedNodes = parent ?
            this.sortSiblings(childNodes) :
            childNodes

        for (const node of sortedNodes) {
            const tree = getTree(node)
            trees.push(tree)
        }
    }

    const data = {trees, map: this.data}
    const json = JSON.stringify(data)
    await navigator.clipboard.writeText(json)
    this.logger.info(`${trees.length}个节点已拷贝到剪贴板`, nodes)
}
