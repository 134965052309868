import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Fm from '../components/PropertiesControllerFm.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import ButtonExport from './PropertiesPaneButtonExport.jsx'
import Model from './ModelUc.mjs'

const PropertiesPaneUc = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                {/*<ButtonDemo />*/}
                <ButtonExport nodes={props.nodes} />
            </>
        }

        controllers={
            <>
                <Fm />
                <Text prop="sfNo" />
                <Text prop="sfName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneUc
