import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    middlewareInsName: {
        name: () => '中间件实例名称',
        required: () => true,
    },

    middlewareInsDesc: {
        name: () => '中间件实例描述',
    },

    middlewareInsMemo: {
        name: () => '中间件实例备注',
    },

    middlewareInsVersion: {
        name: () => '中间件版本',
    },

    middlewareInsSchema: {
        name: () => '中间件实例名/模式',
    },

    middlewareInsIntranetIp: {
        name: () => '内网IP',
    },

    middlewareInsInternetIp: {
        name: () => '公网IP',
    },

    middlewareInsPort: {
        name: () => '中间件端口',
    },

    middlewareInsStatus: {
        name: () => '运行状态',
    },

    middlewareInsBu: {
        name: () => '归属BU',
    },

    middlewareInsDm: {
        name: () => '开发负责人',
    },

    middlewareInsOm: {
        name: () => '运维负责人',
    },

    middlewareInsEnv: {
        name: () => '环境',
    },
})
