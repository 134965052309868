import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const TableChoosePhys = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '物理机名称',
            dataIndex: 'psName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'psEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'psIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'psInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/physs"
            query={query}
            {...props}
        />
    )
}

export default TableChoosePhys
