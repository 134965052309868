export default async (map, nodes, insert, getType) => {
    const nodesToSelect = new Set

    await Promise.all(
        [...nodes].map(async node => {
            const type = getType(map, node)

            if (! type) {
                return
            }

            const n = map.createNode(type)
            insert(node, n)
            await map.BizNode[type].emitEventUp(map, n, {type: 'create'})

            if (n.isDeleted) {
                return
            }

            map.logger.info('已插入节点', [n])
            nodesToSelect.add(n)
        })
    )

    map.selectNodes(nodesToSelect)
}
