import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canFold, canUnfold, foldTree, unfoldTree} = map.commands

    const item = {
        key: 'toggle-fold-tree',
        suffix: 'Ctrl+Alt+/',
    }

    const nodesCanFold = canFold(selectedNodes)

    if (0 < nodesCanFold.length) {
        return {
            ...item,
            label: '折叠所有子分支',
            onClick: () => foldTree(nodesCanFold),
        }
    }

    const nodesCanUnfold = canUnfold(selectedNodes)

    if (0 < nodesCanUnfold.length) {
        return {
            ...item,
            label: '展开所有子分支',
            onClick: () => unfoldTree(nodesCanUnfold),
        }
    }

    return {
        ...item,
        disabled: true,
        label: '折叠所有子分支',
    }
}
