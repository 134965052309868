function insertSiblingAfter() {
    const {
        commands: {canInsertSibling, insertSiblingAfter},
        selectedNodes,
    } = this

    const nodes = canInsertSibling(selectedNodes)
    insertSiblingAfter(nodes)
}

function insertSiblingBefore() {
    const {
        commands: {canInsertSibling, insertSiblingBefore},
        selectedNodes,
    } = this

    const nodes = canInsertSibling(selectedNodes)
    insertSiblingBefore(nodes)
}

export default {
    'Enter': insertSiblingAfter,
    'Shift+Enter': insertSiblingBefore,
}
