import {TreeDoc, TreeDocNode} from '@/components/TreeDoc/index.mjs'
import createShape from './createShape.mjs'

export class MindMapNode extends TreeDocNode {
    get children() {
        return this.doc.children(this)
    }
}

export class MindMapDoc extends TreeDoc {
    static TreeNode = MindMapNode

    getDefaultNodeText(node) {
        return `Node ${node.id}`
    }

    getNodeBoxStyle(node) {
        return {}
    }

    getNodeLineStyle(node) {
        return {
            leadingLineColor: '#666',
            leadingLineWidth: 2,
            trailingLineColor: '#666',
            trailingLineWidth: 2,
        }
    }

    getNodeShape(node, textRect) {
        const style = this.getNodeShapeStyle(node)
        const paddings = new Array(4).fill(style.borderWidth)

        const draw = (w, h) => `
        <rect
            x="${style.borderWidth / 2}"
            y="${style.borderWidth / 2}"
            width="${w - style.borderWidth}"
            height="${h - style.borderWidth}"
        />
    `

        const shape = createShape(draw, textRect, paddings, style)
        return [shape, paddings]
    }

    getNodeShapeStyle(node) {
        return {
            borderWidth: 1,
            backgroundColor: '#eee',
            borderColor: '#333',
        }
    }

    getNodeText(node) {
        return node.data.text ?? this.getDefaultNodeText(node)
    }

    getNodeTextStyle(node) {
        return {}
    }

    getNodeTitle(node) {
        return null
    }

    isNodeHidden(node) {
        return node.isHidden
    }
}
