import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSfMain.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|FOPR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FOPR'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FOPR'],
            ]
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('COPR' === bizNodeType) {
                map.BizNode.FOPR.castFrom(map, child)
                return true
            }
            else {
                return CategoryNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
