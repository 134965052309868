import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import PropertiesPane from '../BDALG/PropertiesPaneBdalg.jsx'
import PropertiesPaneBatch from '../BDALG/PropertiesPaneBatchBdalg.jsx'
import meta from './metaBdbase.mjs'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return map.BizNode.BDALG.canMountType.call(this, map, node, type)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        async _atCreate(map, node) {
            const childTypes = [
                'ALG_RULE',
                'ALG_ALG',
                'ALG_API',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await AlgNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            return map.BizNode.BDALG.onPush.call(this, map, node, type, data)
        },
    })
}
