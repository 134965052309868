export function canUnfold(nodes) {
    return [...nodes].filter(
        node => this.actions.canUnfold(node)
    )
}

export async function unfoldNode(nodes) {
    await this.cmd(
        () => this.actions.unfoldNode(nodes)
    )
}

export async function unfoldTree(nodes) {
    await this.cmd(
        () => this.actions.unfoldTree(nodes)
    )
}
