// 自定义函数，将 searchParams 转换为对象，其中值兼容数组传参
export const getSearchParamsAsObject = (searchParams) => {
    const paramsObject = {};

    for (const [key, value] of searchParams.entries()) {
        if (!paramsObject[key]) {
            paramsObject[key] = value;
        }
        else if(paramsObject[key] instanceof Array){
            paramsObject[key].push(value);
        }
        else {
            const frtV = paramsObject[key];
            paramsObject[key] = [];
            paramsObject[key].push(frtV);
            paramsObject[key].push(value);
        }
    }
    return paramsObject;
};

// 获取模型成品版本号
export const getPrjPatternRevText = (pattern) => {
    const {sVer, patternMapRev, patternMapSmallRev, patternRev} = pattern
    // if(patternRev === 0){
    //     return '';
    // }
    if(!patternMapRev){
        return '';
    }
    return `${sVer}.${patternMapRev}`
}

// 获取发布版本版本号
export const getMapRevText = (mapVer) => {
    const { dataVer, smallVer, verFlag } = mapVer
    const v = dataVer ? dataVer : Number.parseInt(verFlag)
    return smallVer != null && smallVer != undefined ? `${v}.${smallVer}` : String(v)
}