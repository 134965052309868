import {Button} from 'antd'
import http from '@/biz/http.mjs'
import useExistedUrl from '@/hooks/useExistedUrl.mjs'
import {getExt, writeFileToDisk} from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonDownload = (props) => {
    const [{fileId, text}] = useProperties()
    const src = useExistedUrl(fileId && `/files/${fileId}`)

    if (! src) {
        return null
    }

    const handleClick = async () => {
        const {blob, fileName} = await runAsync(
            () => http.get(src),
            {action: '下载'}
        )

        const ext = getExt(fileName)
        const suggestedName = getExt(text) === ext ? text : `${text}.${ext}`
        writeFileToDisk(blob, {suggestedName})
    }

    return (
        <Button
            {...props}
            onClick={handleClick}
        >
            下载
        </Button>
    )
}

export default PropertiesPaneButtonDownload
