import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '业务模型',
    archUrl: '/ProjectBizMap',
    detailUrl: '/BaDetail',
    detailIdProp: 'baId',
    mapProp: 'baMap',
    name: '业务结构',
    type: 'BA',
}
