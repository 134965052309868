function shrinkNode() {
    const {
        commands: {canShrinkNode, shrinkNode},
        selectedNodes,
    } = this

    const nodes = canShrinkNode(selectedNodes)

    if (0 < nodes.length) {
        shrinkNode(nodes)
    }
}

export default {
    'Ctrl+K': shrinkNode,
    'Meta+K': shrinkNode,
}
