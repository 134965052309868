import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import meta from './metaVm.mjs'
import Model from './ModelVm.mjs'
import PropertiesPane from './PropertiesPaneVm.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchVm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return (
                '1' !== node.data.isClass &&
                ModuleNode.canMountType.call(this, map, node, type)
            )
        },

        defaultChildType(map, node) {
            if ('1' === node.data.isClass) {
                return ''
            }
            else {
                return ModuleNode.defaultChildType.call(this, map, node)
            }
        },

        getIcons(map, node) {
            const icons = ModuleNode.getIcons.call(this, map, node)

            if ('1' === node.data.isClass) {
                icons.push(
                    <IconLetters
                        fill={Color.BLUE}
                        letters="C"
                        textColor="#fff"
                    />
                )
            }

            return icons
        },

        getStyle(map, node) {
            const borderColor = (() => {
                for (const ancestor of map.chain(node.parent)) {
                    const {bizNodeType} = ancestor.data

                    if ('SS' === bizNodeType) {
                        const style = map.BizNode.SS.getStyle(map, ancestor)
                        return style.borderColor
                    }
                }
            })()

            return {
                ...this._getStyle(map, node),
                borderColor,
                shape: 'EllipseRectangle',
            }
        },

        getTextSuffix(map, node) {
            const {
                pkid,
                [this.codeProp]: code,
            } = node.data

            if (pkid) {
                return ` ${code}`
            }
            else {
                return ModuleNode.getTextSuffix.call(this, map, node)
            }
        },

        menuItemsInsertConcept(map, node) {
            if ('1' === node.data.isClass) {
                return []
            }
            else {
                return ModuleNode.menuItemsInsertConcept.call(this, map, node)
            }
        },

        onPush(map, node, type, data) {
            const key = {
                SS: 'archVmList',
                VM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },
    })
}
