import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBjDesc.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async _atCreate(map, node) {
            const bfTypeCode = (() => {
                for (const n of map.chain(node.parent)) {
                    const {
                        bfTypeCode = 'TARGET',
                        bizNodeType,
                    } = n.data

                    if ('BF' === bizNodeType) {
                        return bfTypeCode
                    }
                }

                return 'TARGET'
            })()

            const childNodeTexts = {
                TARGET: ['场景描述', '注意事项'],
                SITUATION: ['场景描述', '数据处理'],
            }[bfTypeCode]

            const {textProp} = map.BizNode.AR

            for (const text of childNodeTexts) {
                const n = map.createNode('AR')
                map.appendChild(node, n)

                n.data = {
                    ...n.data,
                    arTypeCode: 'CAT',
                    [textProp]: text,
                }
            }

            await ArCatNode._atCreate.call(this, map, node)
        },
    })
}
