export const canDeleteTree = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDeleteTree(node) &&
            this.BizNode[bizNodeType].canDeleteTree(this, node)
        )
    }
}

export const deleteTree = () => defaultActions => {
    return async function (nodes) {
        const confirmedNodes = []

        for (const n of this.topNodes(nodes)) {
            const _n = this.nodeProxy(n)
            const isCancelled = await _n.beforeDeleteTree()

            if (! isCancelled) {
                confirmedNodes.push(n)
            }
        }

        await defaultActions.deleteTree(confirmedNodes)

        await Promise.all(
            confirmedNodes.map(async n => {
                const _n = this.nodeProxy(n)
                await _n.emitEventUp({type: 'delete'})
            })
        )
    }
}
