import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonExport from './PropertiesPaneButtonExport.jsx'

const PropertiesPaneBatchUc = (props) => (
    <BatchPropertiesPane
        buttons={<ButtonExport nodes={props.nodes} />}
        {...props}
    />
)

export default PropertiesPaneBatchUc
