import {ToTopOutlined} from '@ant-design/icons'
import IconWithNotification from '../IconWithNotification.jsx'
import UpgradePane from './UpgradePane.jsx'
import useUpgradableItems from './useUpgradableItems.mjs'

export default () => {
    const items = useUpgradableItems()

    return {
        icon: (
            <IconWithNotification notification={0 < items.length}>
                <ToTopOutlined />
            </IconWithNotification>
        ),

        key: 'upgrade',
        hotkey: 'Ctrl+Shift+U',
        name: '升级模件',
        pane: <UpgradePane items={items} />,
    }
}
