import {useLayoutEffect, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const extensions = () => ({
        useNodeChange(node, props) {
            const [, setFlag] = useState(false)

            useLayoutEffect(
                () => {
                    const handleChange = (changes) => {
                        for (const p of props) {
                            if (changes.has(p)) {
                                setFlag(f => ! f)
                                break
                            }
                        }
                    }

                    subscribe(node, 'change', handleChange)

                    return () => {
                        unsubscribe(node, 'change', handleChange)
                    }
                },

                [node]
            )
        }
    })

    return {extensions}
}
