import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../MenuButton.jsx'

export default function MenuButtonInsertProducts(props) {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const {canInsertProducts, insertProducts} = map.commands
    const nodes = canInsertProducts(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    const handleClick = () => insertProducts(nodes)

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            插入最新模件
        </MenuButton>
    )
}
