export function canDeleteNode(nodes) {
    return [...nodes].filter(
        node => this.actions.canDeleteNode(node)
    )
}

export async function deleteNode(nodes) {
    await this.cmd(
        () => this.actions.deleteNode(nodes)
    )
}
