export function canPasteTreeAfter(nodes) {
    return [...nodes].filter(
        node => this.actions.canPasteTreeAfter(node)
    )
}

export function canPasteTreeAppend(nodes) {
    return [...nodes].filter(
        node => this.actions.canPasteTreeAppend(node)
    )
}

export async function pasteTreeAfter(nodes) {
    await this.cmd(
        () => this.actions.pasteTreeAfter(nodes)
    )
}

export async function pasteTreeAppend(nodes) {
    await this.cmd(
        () => this.actions.pasteTreeAppend(nodes)
    )
}
