import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    lsName: {
        name: () => '外联系统名称',
        required: () => true,
    },

    infDomainName: {
        name: () => '接口域名',
    },

    infIp: {
        name: () => '接口 IP',
    },

    memo: {
        name: () => '备注',
    },
})
