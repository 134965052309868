import SelectSsDialog from '@/pages/MainPage/dialogs/SelectSsDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectSs({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({ssName}) => ssName).join(',') :
        (value ?? {}).ssName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectSsDialog}
            {...props}
        />
    )
}
