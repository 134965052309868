import {css} from '@emotion/react'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectFm from '../FM/SelectFm.jsx'

const cssPath = css({
    fontSize: 13,
    color: '#666',
    marginTop: 4,
})

export default function PropertiesControllerFm({
    className,
    style,
    codeProp = 'fmCode',
    disabled,
    hidden,
    idProp = 'fmId',
    label,
    labelSuffix,
    nameProp = 'fmName',
    pathProp = 'fmNamePath',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: fmCode,
        [idProp]: fmId,
        [nameProp]: fmName,
        [pathProp]: fmNamePath,
    } = values

    const handleChange = fm => {
        const {
            fmCode = '',
            fmId = '',
            fmName = '',
            fmNamePath = '',
        } = fm ?? {}

        update({
            [codeProp]: fmCode,
            [idProp]: fmId,
            [nameProp]: fmName,
            [pathProp]: fmNamePath,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectFm
                disabled={p.disabled}
                isLeaf={false}
                readOnly={p.readOnly}
                value={{fmCode, fmId, fmName, fmNamePath}}
                onChange={handleChange}
                {...props}
            />

            <div css={cssPath}>{fmNamePath}</div>
        </PropertiesController>
    )
}
