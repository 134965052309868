import SelectBmDialog from '@/pages/MainPage/dialogs/SelectBmDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectBm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({bmName}) => bmName).join(',') :
        (value ?? {}).bmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectBmDialog}
            {...props}
        />
    )
}
