import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchCatNode from '../_ARCH_CAT/useArchCatNode.jsx'
import meta from './metaCatDb.mjs'

export default () => {
    const ArchCatNode = useArchCatNode()

    return extendNode(ArchCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|DB|MARK)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CAT'
        },

        getStyle(map, node) {
            return {
                ...ArchCatNode.getStyle.call(this, map, node),
                backgroundColor: Color.CYAN,
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
                ['MARK'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DB'],
            ]
        },
    })
}
