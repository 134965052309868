import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import usePropertiesModal from '../hooks/usePropertiesModal.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import IconStar from '../icons/IconStar.jsx'
import meta from './metaIprog.mjs'
import Model from './ModelIprog.mjs'
import PropertiesPane from './PropertiesPaneIprog.jsx'
import ModalProperties from './ModalPropertiesIprog.jsx'

export default () => {
    const ComponentNode = useComponentNode()
    const openPropertiesModal = usePropertiesModal(ModalProperties)

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },

        onPush(map, node, type, data) {
            if ('LSI' !== type) {
                return
            }

            const d = {
                ...this._getPushData(map, node),
                fcCode: '',
                ssCode: '',
            }

            if ('FC' === node.data.progTypeCode) {
                for (const child of map.children(node)) {
                    const {bizNodeType, fcCode, ssCode} = child.data

                    if ('FC' === bizNodeType) {
                        Object.assign(d, {fcCode, ssCode})
                        break
                    }
                }
            }

            data.progList.push(d)
        },

        getIcons(map, node) {
            const icons = []
            const {fcCode, progTypeCode} = node.data

            if ('FC' === progTypeCode) {
                icons.push(
                    <IconLetters
                        fill={Color.GREEN_YELLOW}
                        letters="A"
                        textColor="#666"
                    />
                )

                if (fcCode) {
                    icons.push(<IconStar fill={Color.RED} />)
                }
                else {
                    icons.push(<IconStar fill={Color.GREY} />)
                }
            }

            return icons
        },

        getPushData(map, node) {
            const d = {
                ...this._getPushData(map, node),
                fcCode: '',
                ssCode: '',
            }

            for (const child of map.children(node)) {
                const {bizNodeType, fcCode, ssCode} = child.data

                if ('FC' === bizNodeType) {
                    Object.assign(d, {fcCode, ssCode})
                    break
                }
            }

            return d
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.GREEN_YELLOW
                }),

                shape: 'Parallelogram'
            }
        },

        async _atCreate(map, node) {
            if (
                void 0 === node.data[this.textProp] &&
                void 0 === node.data.progTypeCode
            ) {
                node.data = {
                    ...node.data,
                    [this.textProp]: '',
                    progTypeCode: 'FC',
                }
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        async onDoubleClick(map, node, event) {
            // eslint-disable-next-line react/no-is-mounted
            if (this.isMounted(map, node)) {
                event.preventDefault()
                openPropertiesModal(map, node, Model)
            }
            else {
                await ComponentNode.onDoubleClick.call(this, map, node, event)
            }
        },
    })
}
