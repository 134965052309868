import {cloneElement} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssIcons = css({
    display: 'flex',

    'svg': {
        width: '1em',
        height: '1em',
    }
})

const NodeIcons = ({node, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const isEditingText = map.useIsEditingText(node)
    const showNodeDesc = map.useShowNodeDesc()
    const _n = map.nodeProxy(node)
    const icons = _n.getIcons()

    if (! icons?.length) {
        return null
    }

    const {fontSize} = map.getNodeTextStyle(node)
    const style = {fontSize}
    const desc = _n.getDesc()
    const owner = _n.getOwnerText?.()
    const prefix = _n.getTextPrefix()
    const suffix = _n.getTextSuffix()
    const text = _n.getText()

    if (
        (showNodeDesc && desc) ||
        isEditingText ||
        owner ||
        prefix ||
        suffix ||
        text
    ) {
        style.marginRight = '.5em'
    }

    return (
        <div
            css={cssIcons}
            style={style}
            {...props}
        >
            {icons.map((icon, key) => cloneElement(icon, {key}))}
        </div>
    )
}

export default NodeIcons
