import Modal from './Modal.jsx'

const Alert = (props) => {
    return (
        <Modal
            cancelText="关闭"
            okButtonProps={{style: {display: 'none'}}}
            {...props}
        />
    )
}

export default Alert
