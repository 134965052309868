import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonSaveMap(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    map.useMetaData()
    const {canSaveMap, saveMap} = map.commands

    if (! (
        canSaveMap() &&
        permission.has('write')
    )) {
        return null
    }

    return (
        <MenuButton
            onClick={saveMap}
            {...props}
        >
            保存
        </MenuButton>
    )
}
