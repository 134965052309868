import {useMapContext} from '@/components/MindMap/index.mjs'

export default (depth) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {unfoldDepth} = map.commands

    return {
        key: `unfold-${depth}`,
        suffix: `Ctrl+Alt+${depth}`,
        label: `展开${depth}层`,
        onClick: () => unfoldDepth(selectedNodes, depth),
    }
}
