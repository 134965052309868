function saveMap() {
    const {canSaveMap, saveMap} = this.commands

    if (canSaveMap()) {
        saveMap()
    }
}

export default {
    'Ctrl+S': saveMap,
    'Meta+S': saveMap,
}
