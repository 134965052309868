function markNode() {
    if (0 === this.selectedNodes.size) {
        return
    }

    for (const n of this.selectedNodes) {
        if (this.markedNodes.has(n)) {
            this.unmarkNodes(this.selectedNodes)
            return
        }
    }

    this.markNodes(this.selectedNodes)
}

export default {
    'Ctrl+M': markNode,
    'Meta+M': markNode,
}
