export const canInsertProduct = () => () => {
    return function (nodes) {
        const ns = [...nodes].filter(
            node => this.actions.canInsertProduct(node)
        )

        if (1 === ns.length) {
            return ns
        }
        else {
            return []
        }
    }
}

export const insertProduct = () => () => {
    return async function (nodes, type) {
        await this.cmd(
            () => this.actions.insertProduct(nodes, type)
        )
    }
}
