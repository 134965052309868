export function canUpdateNodeData(node) {
    return true
}

export async function updateNodeData(nodes, transform) {
    for (const node of nodes) {
        const {data} = node
        const updates = transform(node)
        node.data = {...node.data, ...updates}
        await this.behaviours.onSetNodeData(node, data)
        this.logger.info('已设置节点数据', [node])
    }
}
