import {css} from '@emotion/react'

const cssMapInfo = css({
    display: 'grid',
    placeItems: 'center',
})

export default function MapInfo({map, ...props}) {
    if (! map) {
        return null
    }

    const {memo, rev, rls} = map.data

    return (
        <div
            css={cssMapInfo}
            {...props}
        >
            <div>【{rev}】{rls}</div>
            <div>{memo}</div>
        </div>
    )
}
