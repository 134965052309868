import extendMindMapPlugins from '../scripts/extendMindMapPlugins.mjs'

const moduleMap = {
    actions: import.meta.glob(
        './actions/use*.(mjs|jsx)', {eager: true}
    ),

    behaviours: import.meta.glob(
        './behaviours/use*.(mjs|jsx)', {eager: true}
    ),

    commands: import.meta.glob(
        './commands/use*.(mjs|jsx)', {eager: true}
    ),
}

export default extendMindMapPlugins(moduleMap)
