import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default (defaultShowNodeDesc) => {
    const refShowNodeDesc = useRef(defaultShowNodeDesc)

    const extensions = () => ({
        get showNodeDesc() {
            return refShowNodeDesc.current
        },

        set showNodeDesc(value) {
            refShowNodeDesc.current = value
            publish(this, 'show_node_desc_change', value)
        },

        useShowNodeDesc() {
            const [showNodeDesc, setShowNodeDesc] = useState(
                refShowNodeDesc.current
            )

            useEffect(
                () => {
                    subscribe(this, 'show_node_desc_change', setShowNodeDesc)

                    return () => {
                        unsubscribe(
                            this,
                            'show_node_desc_change',
                            setShowNodeDesc
                        )
                    }
                },

                []
            )

            return showNodeDesc
        },
    })

    return {extensions}
}
