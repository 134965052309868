import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaCommonNode.mjs'

/**
 * 通用节点类型的基类
 *
 * 通用节点可通过「插入通用节点」的方式插入地图
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        async onInsertCommon(map, nodes) {
            await map.commands.insertBizNode(nodes, this.type)
        },
    })
}
