import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonEditProduct(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        // 快照
        map.data.dataId &&
        1 === selectedNodes.size
    )) {
        return null
    }

    const handleClick = async () => {
        const [node] = selectedNodes
        const _n = map.nodeProxy(node)
        await _n.gotoCurrent()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            编辑
        </Button>
    )
}
