import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssListItem = css({
    fontSize: 16,
    padding: 8,
    cursor: 'pointer',
    borderBottom: '1px solid #e8e8e8',

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

const ListItem = ({node, ...props}) => {
    const map = useMapContext()
    const _n = map.nodeProxy(node)
    const path = _n.getPath()

    const handleClick = () => {
        map.execute(
            () => map.selectNodes([node])
        )
    }

    return (
        <div
            css={cssListItem}
            onClick={handleClick}
            {...props}
        >
            {path}
        </div>
    )
}

export default function MarkedNodes(props) {
    const map = useMapContext()
    const nodes = [...map.useMarkedNodes()]

    const listItems = nodes.map(n => (
        <ListItem
            key={n.id}
            node={n}
        />
    ))

    return (
        <div {...props}>
            {listItems}
        </div>
    )
}
