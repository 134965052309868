import {createShape} from '@/components/MindMap/index.mjs'

const LEG = 12

export const HorizontalHexagon = (textRect, style) => {
    const bw = style.borderWidth
    const paddings = [bw, bw + LEG, bw, bw + LEG]

    const draw = (w, h) => `
        <path
            d="
                M ${bw / 2} ${h / 2}
                L ${bw / 2 + LEG} ${bw / 2}
                H ${w - bw / 2 - LEG}
                L ${w - bw / 2} ${h / 2}
                L ${w - bw / 2 - LEG} ${h - bw / 2}
                H ${bw / 2 + LEG}
                Z
            "
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
