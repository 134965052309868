import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Fm from '../components/PropertiesControllerFm.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import SfType from './SfType.mjs'
import Model from './ModelSf.mjs'

const PropertiesPaneSf = (props) => (
    <ProductPropertiesPane
        //buttons={<ButtonDemo/>}

        controllers={
            <>
                <Fm />
                <Text prop="sfNo" />
                <Text prop="sfName" />

                <Select
                    options={SfType}
                    prop="sfTypeCode"
                />

                <LongText prop="sfKey" />
                <LongText prop="sfMemo" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSf
