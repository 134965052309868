function cutTree() {
    const {
        commands: {canCutTree, cutTree},
        selectedNodes,
    } = this

    const nodes = canCutTree(selectedNodes)
    cutTree(nodes)
}

export default {
    'Ctrl+X': cutTree,
    'Meta+X': cutTree,
}
