export function canDuplicateTree(node) {
    if (node.parent) {
        return true
    }
    else {
        this.logger.error('不能复制没有父节点的节点', [node])
        return false
    }
}

export function duplicateTree(nodes) {
    const nodesToSelect = new Set()

    const duplicate = (trees) => {
        for (const [node, descendants] of trees) {
            duplicate(descendants)

            const tree = this.exportTree(
                node,
                ({id, ...n}) => n
            )

            const newNode = this.importTree(tree)
            this.insertSiblingAfter(node, newNode)
            this.logger.info('已复制分支', [node])
            nodesToSelect.add(newNode)
        }
    }

    const trees = this.treelize(nodes)
    duplicate(trees)
    this.selectNodes(nodesToSelect)
}
