import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaBdalg_.mjs'
import FormChoose from './FormChooseBdalg_.jsx'
import TableChoose from './TableChooseBdalg_.jsx'
import api from './apiBdalg_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, bdNo} = map.root.data

                if ('BD' === bizNodeType) {
                    return {bdNo, ...query}
                }
                else {
                    return query
                }
            }

            const items = await this._choose(map, node, {getQuery})

            return items.map((item) => {
                const {algType} = item.data

                const bizNodeType = {
                    BASE: 'BDBASE',
                    DEVELOP: 'BDALG',
                }[algType]

                return {...item, data: {...item.data, bizNodeType}}
            })
        },
    })
}
