export const IconArrowRight = ({
    color = '#000',
    fill = 'transparent',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <circle
            cx="50%"
            cy="50%"
            fill={fill}
            r="50%"
        />

        <path
            d="M16,48 L72,48 L72,32 L108,60 L72,92 L72,76 L16,76 Z"
            fill={color}
        />
    </svg>
)
