import {useLayoutEffect, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const extensions = () => ({
        useNodesChange(nodes) {
            const [, setFlag] = useState(false)

            useLayoutEffect(
                () => {
                    const handleChange = () => setFlag(flag => ! flag)

                    for (const node of nodes) {
                        subscribe(node, 'change', handleChange)
                    }

                    return () => {
                        for (const node of nodes) {
                            unsubscribe(node, 'change', handleChange)
                        }
                    }
                },

                [nodes]
            )
        }
    })

    return {extensions}
}
