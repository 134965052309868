import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonPublishMap(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    map.useMetaData()
    const {canPublishMap, publishMap} = map.commands

    if (! (
        canPublishMap() &&
        permission.has('write')
    )) {
        return null
    }

    return (
        <MenuButton
            onClick={publishMap}
            {...props}
        >
            发布
        </MenuButton>
    )
}
