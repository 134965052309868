export const canSaveMap = () => () => {
    return function () {
        return this.actions.canSaveMap()
    }
}

export const saveMap = () => () => {
    return function () {
        return this.runAction(
            () => this.actions.saveMap(),

            {
                action: '保存地图',
                success: {show: true},
            },
        )
    }
}
