import {createContext, useContext, useState} from 'react'
import {Image} from 'antd'

const PreviewImagesContext = createContext()

const PreviewImages = ({children}) => {
    const [srcs, setSrcs] = useState([])

    const preview = {
        visible: 0 < srcs.length,

        onVisibleChange: (visible) => {
            if (! visible) {
                setSrcs([])
            }
        }
    }

    const images = srcs.map(
        (src) => <Image key={src} src={src} />
    )

    return (
        <>
            <PreviewImagesContext.Provider value={setSrcs}>
                {children}
            </PreviewImagesContext.Provider>

            <div style={{display: 'none'}}>
                <Image.PreviewGroup preview={preview}>
                    {images}
                </Image.PreviewGroup>
            </div>
        </>
    )
}

export default PreviewImages

export const usePreviewImages = () => useContext(PreviewImagesContext)
