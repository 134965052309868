import _Menu from '../../Menu.jsx'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

export default function Menu(props) {
    return (
        <_Menu {...props}>
            <MenuButtonSaveMap />
        </_Menu>
    )
}
