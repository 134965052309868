import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaCategoryRootNode.mjs'

/**
 * 预置根分类节点
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|DOC|MARK|PIC|URL)$/.test(type)
        },

        canWriteData() {
            return false
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: '#f6212d',
                textColor: '#fff',
            }
        },

        async getUrl(map, node, childPath = []) {
            const {[this.textProp]: text = ''} = node.data
            const path = [encodeURIComponent(text), ...childPath]

            const mapInits = {
                selectedNodes: [{path: `/${path.join('/')}/`}],
            }

            return buildUrl(
                '/SoftwareMap',

                {
                    id: map.data.pkid,
                    mapInits,
                }
            )
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
                ['MARK'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },
    })
}
