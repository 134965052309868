import {useRef} from 'react'

const CORRECT_SPELL = 'IAMGOD'

const MESSAGE = `
上帝模式！风险自负！

确保你清楚自己在做什么，如果不清楚请点击取消，一切还来得及。

地图原始数据已复制到系统剪贴板，当点击确定按钮后，剪贴板中数据会替换地图原始数据，请小心修改！
`

const exportDoc = async doc => {
    const {data} = doc
    const root = doc.exportTree(doc.root, ({id, ...n}) => n)
    const text = JSON.stringify({data, root})
    await navigator.clipboard.writeText(text)
}

const importDoc = async doc => {
    const text = await navigator.clipboard.readText()
    const {data, root: {children, ...rootData}} = JSON.parse(text)

    doc.execute(() => {
        doc.data = data

        for (const n of doc.children(doc.root)) {
            doc.deleteTree(n)
        }

        for (const tree of children) {
            const n = doc.importTree(tree)
            doc.appendChild(doc.root, n)
        }

        Object.assign(doc.root, rootData)
    })
}

export default () => {
    const refSpell = useRef('')

    const watchers = {
        async key_down(e) {
            const spell = refSpell.current + e.key

            if (CORRECT_SPELL === spell) {
                refSpell.current = ''
                await exportDoc(this)
                const isConfirmed = window.confirm(MESSAGE)

                if (isConfirmed) {
                    await importDoc(this)
                }
            }
            else if (CORRECT_SPELL.startsWith(spell)) {
                refSpell.current = spell
            }
            else {
                refSpell.current = ''
            }
        },

        pointer_move() {
            refSpell.current = ''
        },
    }

    return {watchers}
}
