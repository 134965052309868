import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmCode: {
        name: () => '功能模块',
    },

    ssCode: {
        name: () => '子系统',
    },

    uiUserCode: {
        name: () => '界面代码',
        required: () => true,
    },

    uiName: {
        name: () => '界面名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },
})
