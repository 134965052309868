import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const permission = map.usePermission()
    const selectedNodes = map.useSelectedNodes()

    if (! permission.has('write')) {
        return null
    }

    const {canMakeProduct, makeProduct} = map.commands
    const nodes = canMakeProduct(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    return {
        key: 'make-product',
        label: '生成模型制品',
        onClick: () => makeProduct(nodes),
    }
}
