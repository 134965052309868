import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaDpprop.mjs'

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,

        getTextPrefix(map, node) {
            const {propName} = node.data
            return `${propName}: `
        },
    })
}
