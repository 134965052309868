import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiBdlogic.mjs'
import meta from './metaBdlogic.mjs'
import Model from './ModelBdlogic.mjs'
import RuleType from './RuleType.jsx'
import PropertiesPane from './PropertiesPaneBdlogic.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdlogic.jsx'
import FormChoose from './FormChooseBdlogic.jsx'
import TableChoose from './TableChooseBdlogic.jsx'

const DEFAULT_RESUME = '在此录入简述'

const ruleTypes = new Map(
    RuleType.map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        attrNodes: {
            top: ['ATTR_BDLOGIC_RESUME'],
        },

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getDesc(map, node) {
            const {ruleType} = node.data

            if (! ruleType) {
                return this.name
            }

            const {name = ''} = ruleTypes.get(ruleType) ?? {}
            return name
        },

        getIcons(map, node) {
            const {ruleType} = node.data
            const {icon} = ruleTypes.get(ruleType) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        async _atCreate(map, node) {
            if (
                void 0 === node.data.ruleType &&
                void 0 === node.data.resume
            ) {
                node.data = {
                    ...node.data,
                    ruleType: 'BT',
                    resume: DEFAULT_RESUME,
                }
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'ruleList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail
                const {resume, ruleType} = node.data

                if (ruleType !== oldData.ruleType) {
                    if (
                        'BL' === ruleType &&
                        resume === DEFAULT_RESUME
                    ) {
                        node.data = {...node.data, resume: ''}
                    }
                    else if (
                        'BT' === ruleType &&
                        resume === ''
                    ) {
                        node.data = {...node.data, resume: DEFAULT_RESUME}
                    }
                }
            }

            await ComponentNode._onChange.call(this, map, node, event)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {compact: true}
            )

            const ruleMap = JSON.stringify(tree.children)
            return {...data, ruleMap}
        },

        async _readGrowTree(map, node) {
            return map.BizNode.BDRULE._readGrowTree.call(this, map, node)
        }
    })
}
