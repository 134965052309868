import {useRef} from 'react'

// TODO: scroll bar
const isFullyVisible = (container, el) => {
    const isVisible = (viewSize, scroll, offset, size) => {
        if (size <= viewSize) {
            return (
                scroll <= offset &&
                offset + size <= scroll + viewSize
            )
        }
        else {
            return (
                offset < scroll &&
                scroll + viewSize < offset + size
            )
        }
    }

    return (
        isVisible(
            container.clientWidth,
            container.scrollLeft,
            el.offsetLeft,
            el.clientWidth,
        )

        &&

        isVisible(
            container.clientHeight,
            container.scrollTop,
            el.offsetTop,
            el.clientHeight,
        )
    )
}

const getScrollSize = ({
    nodeSize,
    offsetNodeToCanvas,
    offsetTreeToCanvas,
    treeSize,
    viewSize,
}) => {
    // 树小于等于可视区时，使树相对可视区居中
    if (treeSize <= viewSize) {
        const offsetTreeToView = (viewSize - treeSize) / 2
        return offsetTreeToCanvas - offsetTreeToView
    }
    // 树大于可视区时，在保证不留白的情况下尽量使被选中节点居中
    else {
        const offsetNodeToView = (viewSize - nodeSize) / 2
        const offsetNodeToTree = offsetNodeToCanvas - offsetTreeToCanvas

        // 左边有留白
        if (offsetNodeToTree < offsetNodeToView) {
            // 使树左侧与可视区左侧重合
            return offsetTreeToCanvas
        }

        const offsetNodeToTreeRight = treeSize - nodeSize - offsetNodeToTree

        // 右边有留白
        if (offsetNodeToTreeRight < offsetNodeToView) {
            // 使树右侧与可视区右侧重合
            return offsetTreeToCanvas + treeSize - viewSize
        }

        // 没有留白
        return offsetNodeToCanvas - offsetNodeToView
    }
}

const scrollIntoViewIfNeeded = (canvas, tree, node) => {
    if (isFullyVisible(canvas, node)) {
        return
    }

    canvas.scrollLeft = getScrollSize({
        nodeSize: node.clientWidth,
        offsetNodeToCanvas: node.offsetLeft,
        offsetTreeToCanvas: tree.offsetLeft,
        treeSize: tree.clientWidth,
        viewSize: canvas.clientWidth,
    })

    canvas.scrollTop = getScrollSize({
        nodeSize: node.clientHeight,
        offsetNodeToCanvas: node.offsetTop,
        offsetTreeToCanvas: tree.offsetTop,
        treeSize: tree.clientHeight,
        viewSize: canvas.clientHeight,
    })
}

export default () => {
    const refRect = useRef({})
    const refLastNode = useRef()
    const refIsActive = useRef(true)

    const handleChange = function () {
        if (! refIsActive.current) {
            return
        }

        setTimeout(() => {
            const {nodes, trees} = this.dom
            const lastNode = [...this.selectedNodes].at(-1)

            const rect = (() => {
                const {
                    clientHeight,
                    clientWidth,
                    scrollHeight,
                    scrollWidth,
                } = this.canvas

                const {
                    clientHeight: lastClientHeight,
                    clientWidth: lastClientWidth,
                    scrollHeight: lastScrollHeight,
                    scrollWidth: lastScrollWidth,
                } = refRect.current

                if (
                    clientHeight !== lastClientHeight ||
                    clientWidth !== lastClientWidth ||
                    scrollHeight !== lastScrollHeight ||
                    scrollWidth !== lastScrollWidth
                ) {
                    return {
                        clientHeight,
                        clientWidth,
                        scrollHeight,
                        scrollWidth,
                    }
                }
                else {
                    return refRect.current
                }
            })()

            if (
                lastNode &&

                (
                    rect !== refRect.current ||
                    lastNode !== refLastNode.current
                )
            ) {
                const tree = trees.get(this.root)
                const node = nodes.get(lastNode)

                // 避免位置变动导致双击失效
                setTimeout(
                    () => scrollIntoViewIfNeeded(this.canvas, tree, node),
                    200
                )
            }

            refRect.current = rect
            refLastNode.current = lastNode
        })
    }

    const watchers = {
        canvas_swipe_end() {
            refIsActive.current = true
        },

        canvas_swipe_start() {
            refIsActive.current = false
        },

        canvas_resize: handleChange,
        model_change: handleChange,
        selected_nodes_change: handleChange,
    }

    return {watchers}
}
