import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    oprTitle: {
        name: () => '用户操作',
    },

    oprType: {
        name: () => '操作类型',
    },
})
