import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canUndo} = map.useChangeHistory()

    return {
        disabled: ! canUndo,
        key: 'undo-change',
        label: '撤销',
        suffix: 'Ctrl+Z',
        onClick: () => map.undoChange(),
    }
}
