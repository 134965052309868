import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import plFromDb from '../scripts/plFromDb.mjs'
import SelectDf from '../DF/SelectDf.jsx'

export default function PropertiesControllerDf({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'dtDfCode'})

    const {
        dtCode,
        dtDfCode,
        dtDfName,
        dtDfUserCode,
        dtName,
        dtUserCode,
        isDtDf,
    } = values

    if ('1' !== isDtDf) {
        return null
    }

    const handleChange = (df) => {
        const {
            cdtDataTypeCode,
            dataTypeCode,
            dataTypeGrpCode,
            ddtDataTypeCode,
            dfCode,
            dfLen,
            dfName,
            dfPrecision,
            dfUserCode,
            dtCode,
            dtName,
            dtUserCode,
        } = df

        update({
            dataTypeGrpCode: 'Basic',

            dataTypeCode: plFromDb({
                cdtDataTypeCode,
                dataTypeCode,
                dataTypeGrpCode,
                ddtDataTypeCode,
                dfLen,
                dfPrecision,
            }),

            dtCode,
            dtDfCode: dfCode,
            dtDfName: dfName,
            dtDfUserCode: dfUserCode,
            dtName,
            dtUserCode,
        })
    }

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            <SelectDf
                disabled={p.disabled}
                readOnly={p.readOnly}

                value={{
                    dfCode: dtDfCode,
                    dfName: dtDfName,
                    dfUserCode: dtDfUserCode,
                    dtCode,
                    dtName,
                    dtUserCode,
                }}

                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
