import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const refKeys = useRef(new Map)

    const extensions = () => ({
        closeSidePanel(panelId) {
            this.openSidePanel(panelId, '')
        },

        currentSidePane(panelId) {
            return refKeys.current.get(panelId) ?? ''
        },

        openSidePanel(panelId, paneId) {
            refKeys.current.set(panelId, paneId)
            publish(this, 'side_panel_change', {panelId, paneId})
        },

        useCurrentSidePane(panelId) {
            const [paneId, setPaneId] = useState(
                () => this.currentSidePane(panelId)
            )

            useEffect(
                () => {
                    const handleSidePanelChange = e => {
                        if (e.panelId === panelId) {
                            setPaneId(e.paneId)
                        }
                    }

                    subscribe(
                        this,
                        'side_panel_change',
                        handleSidePanelChange
                    )

                    return () => {
                        unsubscribe(
                            this,
                            'side_panel_change',
                            handleSidePanelChange
                        )
                    }
                },

                [panelId]
            )

            return paneId
        },
    })

    const watchers = {
        model_change() {
        },
    }

    return {extensions, watchers}
}
