import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseBdalg = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()

    const columns = [
        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '方法标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },

        {
            title: '方法类型',
            dataIndex: 'algType',
            component: <Table.ViewDict dict={{BASE: '基础', DEVELOP: '事件'}} />,
        },
    ].filter(a => a)

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdalg_s"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableChooseBdalg
