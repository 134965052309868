import {useEffect} from 'react'

export default refEl => {
    useEffect(
        () => {
            const handleFocusOutWindow = e => {
                if (null === e.relatedTarget) {
                    refEl.current?.focus()
                }
            }

            window.addEventListener('focusout', handleFocusOutWindow)

            return () => {
                window.removeEventListener('focusout', handleFocusOutWindow)
            }
        },

        []
    )
}
