import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaUv.mjs'
import Model from './ModelUv.mjs'
import PropertiesPane from './PropertiesPaneUv.jsx'
import FormChoose from './FormChooseUv.jsx'
import TableChoose from './TableChooseUv.jsx'
import api from './apiUv.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(PIC)$/.test(type)
        },

        canWriteTree(map, node) {
            return false
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_BLUE,
                }),

                shape: 'Parallelogram',
            }
        },

        //async grow(map, node, growedNodes) {
            //if (! this.canGrow(map, node)) {
                //return
            //}

            //if (growedNodes) {
                //const {pkid, [this.ownerIdProp]: ownerId} = node.data

                //if (growedNodes.has(ownerId) && node.firstChild) {
                    //await this._growChildren(map, node, growedNodes)
                    //return
                //}

                //if (growedNodes.has(pkid)) {
                    //map.logger.warn(
                        //'为避免无限加载，跳过已加载过的节点',
                        //[node]
                    //)

                    //return
                //}
            //}

            //const rev = this.getRev(map, node)
            //const ownerTree = await this._readOwnerTree(map, node, rev)

            //if (! ownerTree) {
                //const msg = '加载失败，无法读取所属制品'

                //if (0 < growedNodes?.size) {
                    //map.logger.warn(msg, [node])
                    //return
                //}
                //else {
                    //throw new Error(msg)
                //}
            //}

            //const next = (chain) => {
                //const node = chain[0]
                //const {bizNodeType} = node.data
                //const bn = map.BizNode[bizNodeType]
                //const yieldChildren = bn.isMounted(ownerTree, node)
                //const yieldNode = bn.isMounted(ownerTree, node)
                //return {yieldChildren, yieldNode}
            //}

            //const {pkid: tid} = node.data
            //let uvTree
            //let ueTree

            //for (
                //const n of
                //ownerTree.walkDown(
                    //ownerTree.root,
                    //{excludeTarget: true, next}
                //)
            //) {
                //const {bizNodeType, pkid, ueTypeCode} = n.data

                //if (pkid === tid) {
                    //const {
                        //prjId,
                        //prjNo,
                        //rev: rev2,
                        //sVer,
                    //} = ownerTree.root.data

                    //n.data = {
                        //...n.data,
                        //prjId,
                        //prjNo,
                        //rev: (! rev || 0 === rev) ? rev2 : rev,
                        //sVer,
                    //}

                    //uvTree = exportTree(ownerTree, n)
                //}
                //else if (
                    //'UE' === bizNodeType &&
                    //'INI' === ueTypeCode
                //) {
                    //ueTree = exportTree(ownerTree, n)
                //}

                //if (uvTree && ueTree) {
                    //break
                //}
            //}

            //if (! uvTree) {
                //const msg = '加载失败，部件已被其制品删除'

                //if (0 < growedNodes?.size) {
                    //map.logger.warn(msg, [node])
                    //return
                //}
                //else {
                    //throw new Error(msg)
                //}
            //}

            //if (ueTree) {
                //uvTree.children.push(ueTree)
            //}

            //{
                //const {lastRev, rev, sVer} = node.data
                //replaceWithTree(map, node, uvTree)
                //node.isFolded = 0 < growedNodes?.size

                //if (0 < rev) {
                    //node.data = {...node.data, lastRev, rev, sVer}
                //}
            //}

            //for (const n of map.walkDown(node, {excludeTarget: true})) {
                //const {bizNodeType} = n
                //map.BizNode[bizNodeType].onPull(map, n)
            //}

            //await this._growChildren(map, node, growedNodes)
        //},

        menuItemsInsertConcept(map, node) {
            return [
                ['PIC'],
            ]
        },

        async _atCreate(map, node) {
            if (
                void 0 === node.data.demoType &&
                void 0 === node.data.pageType &&
                void 0 === node.data.uvType
            ) {
                const uvType = (() => {
                    for (const n of map.chain(node)) {
                        const {bizNodeType, terminalType} = n.data

                        if ('UI' === bizNodeType) {
                            return terminalType
                        }
                    }
                })()

                node.data = {
                    ...node.data,
                    demoType: 'IMG',
                    pageType: 'PAGE',
                    uvType,
                }
            }

            const n = map.createNode('PIC')
            map.appendChild(node, n)
            await ComponentNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            ({
                UI: () => {
                    const pushData = this._getPushData(map, node)
                    const tree = this.exportTree(map, node, {compact: true})
                    const demoMap = JSON.stringify(tree.children)
                    data.uvList.push({...pushData, demoMap})
                }
            })[type]?.()
        },

        onPushDone(map, node) {
            const {demoType, pkid} = node.data

            if ('DEMO' === demoType) {
                const [pic] = node.children

                pic.data = {
                    ...pic.data,
                    fileId: pkid,
                }
            }
        },

        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail
                const {uvType} = node.data

                if (
                    uvType !== oldData.uvType &&
                    'MOBILE' === uvType
                ) {
                    node.data = {...node.data, pageType: 'PAGE'}
                }
            }

            await ComponentNode._onChange.call(this, map, node, event)
        },
    })
}
