import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'
import Number from '../../SidePanel/RightPanel/PropertiesControllerNumber.jsx'

export default function PropertiesControllerDfPrecision(props) {
    const [{dfLen}] = useProperties()
    const max = dfLen ? dfLen - 1 : null

    return (
        <Number
            int
            min={1}
            max={max}
            prop="dfPrecision"
            {...props}
        />
    )
}
