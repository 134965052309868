import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const {canUndo} = map.useSelectionHistory()

    return {
        disabled: ! canUndo,
        key: 'undo-selection',
        label: '上次选择',
        suffix: 'Ctrl+←',
        onClick: () => map.undoSelection(),
    }
}
