import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBfdataflow.mjs'

const PropertiesPaneBfdataflow = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneBfdataflow
