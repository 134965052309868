export const pushAndSave = () => () => {
    return async function () {
        await this.cmd(
            async () => {
                const results = await this.runParallelAction(
                    () => this.actions.pushProduct([this.root]),

                    {
                        action: '更新制品',
                        success: {show: true},
                    },
                )

                const hasError = results.some(
                    e => 'rejected' === e.status
                )

                if (hasError) {
                    return
                }

                await this.runAction(
                    () => this.actions.saveMap(),

                    {
                        action: '保存地图',
                        success: {show: true},
                    },
                )
            }
        )
    }
}
