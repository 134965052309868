import SidePanel from '@/components/TreeDoc/SidePanel.jsx'
import useExternalPane from './ExternalPane/useExternalPane.jsx'
import useInvalidPane from './InvalidPane/useInvalidPane.jsx'
import useMarkPane from './MarkPane/useMarkPane.jsx'
import useMessagePane from './MessagePane/useMessagePane.jsx'
import useSearchPane from './SearchPane/useSearchPane.jsx'
import useUpgradePane from './UpgradePane/useUpgradePane.jsx'

export default function LeftPanel(props) {
    const panes = [
        useSearchPane(),
        useMarkPane(),
        useMessagePane(),
        useUpgradePane(),
        useInvalidPane(),
        useExternalPane(),
    ]

    return (
        <SidePanel
            direction="ltr"
            panelId="left"
            panes={panes}
            {...props}
        />
    )
}
