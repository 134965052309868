import SelectVmDialog from '@/pages/MainPage/dialogs/SelectVmDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectVm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({vmName}) => vmName).join(',') :
        (value ?? {}).vmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectVmDialog}
            {...props}
        />
    )
}
