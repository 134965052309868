import {useMapContext} from '@/components/MindMap/index.mjs'

const useMenuItemCopyNodeText = ({label, key, seperator, suffix = ''}) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCopyNodeText, copyNodeText} = map.commands
    const nodes = canCopyNodeText(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key,
        label,
        suffix,
        onClick: () => copyNodeText(nodes, seperator),
    }
}

export const useMenuItemCopyNodeTextNewLine = () => {
    return useMenuItemCopyNodeText({
        key: 'copy-node-text-new-line',
        label: '复制文本',
        seperator: '\n',
    })
}

export const useMenuItemCopyNodeTextChineseSerialComma = () => {
    return useMenuItemCopyNodeText({
        key: 'copy-node-text-chinese-serial-comma',
        label: '复制文本（单行输出）',
        seperator: '、',
    })
}
