import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()
    const {canReviseMap, reviseMap} = map.commands

    return {
        disabled: ! canReviseMap(),
        key: 'revise-map',
        label: '修订',
        onClick: reviseMap,
    }
}
