import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Radio, Checkbox, Select, Button, Table, Space, Modal, Tree, TreeSelect, Tabs } from 'antd';
import { SearchOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import TabLayout from '@/pages/MainPage/components/TabLayout';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';
/*
* 使用场景：
* 1：选择数据类型
* 
* 参数说明：
* visible ：   【必填】 当前Dialog是否展示 
* setVisible ：【必填】 当前Dialog展示的操作方法 
* onCall ：    【必填】 回调函数 
* single = false【单选】
* typeId => ddt、cdt、bdt
* dbmsCode: MYSQL、ORACLE、SQLSERVER、POSTGRESQL
* 参考数据表字段选择
*/
const SelectDataTypeDialog = ({ visible, setVisible, onCall, single = false, typeId, prjId, sVer, dbmsCode }) => {
    const http = useHTTP()
    const [bdtForm] = Form.useForm();
    const [cdtForm] = Form.useForm();
    const [ddtForm] = Form.useForm();
    const { TabPane } = Tabs;
    const [cdtData, setCdtData] = useState([])
    const [bdtData, setBdtData] = useState([])
    const [ddtData, setDdtData] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [key1, setkey1] = useState(true);
    const [key2, setkey2] = useState(true);
    const [key3, setkey3] = useState(true);
    const [key, setkey] = useState("bdt");

    // 组件初始化
    useEffect(() => {
        // 默认查询基本数据类型
        if (visible) {
            if (typeId == "bdt" || key == 'bdt') {
                setkey1(false);
                doBdtSearch();
            }

            // 领域数据类型
            if (typeId == "ddt") {
                setkey2(false);
                doDdtSearch();
            }

            // 定制数据类型
            if (typeId == "cdt") {
                setkey3(false);
                doCdtSearch();
            }
        } else {
            setkey1(false);
            setkey2(false);
            setkey3(false);
            // doBdtSearch();
        }
    }, [visible]);

    // 关闭Dialog
    const doClose = () => {
        setVisible(false);
    }

    // 确认回调
    const doConfirm = () => {
        if (selectedRows.length == 0) {
            onCall([]);
            doClose();
        } else {
            let data = selectedRows[0];
            let obj = {};
            obj = { ...data };
            obj.type = key;
            onCall(obj);
            doClose();
        }
        setSelectedRows([]);
    }

    // 切换事件
    const tabOnChange = (key) => {
        // 基本数据类型
        if (key == "bdt") {
            doBdtSearch();
            setkey('bdt');
        }
        // 领域数据类型
        if (key == "ddt") {
            doDdtSearch();
            setkey('ddt');
        }
        // 定制数据类型
        if (key == "cdt") {
            doCdtSearch();
            setkey('cdt');
        }
    }

    // 查询：基本数据类型
    const doBdtSearch = async () => {
        const formField = await bdtForm.validateFields();
        const param = {
            bdtName: formField.bdtName ? formField.bdtName : '',
            bdtCode: formField.bdtCode ? formField.bdtCode : '',
            prjId,
            dbmsCode,
            stsCode: 'ENABLE',
        }

        const bdtData = await runAsync(
            async () => {
                return await http.get(`/basbdts`, param);
            }, {
            error: { content: (err) => `查询失败: ${err.message}` },
            loading: { show: false },
            success: { show: false },
        });
        setBdtData(bdtData);
    }

    // 查询：CDT
    const doCdtSearch = async () => {
        const formField = await cdtForm.validateFields();
        const param = {
            cdtUserCode: formField.cdtUserCode ? formField.cdtUserCode : '',
            cdtName: formField.cdtName ? formField.cdtName : '',
            isSeq: formField.isSeq ? formField.isSeq : '',
            prjId: prjId,
            sVer: sVer,
        }

        const cdtData = await runAsync(
            async () => {
                return await http.get(`/dmcdts`, param);
            }, {
            error: { content: (err) => `查询失败: ${err.message}` },
            loading: { show: false },
            success: { show: false },
        });
        setCdtData(cdtData);
    }

    const doDdtSearch = async () => {
        const formField = await ddtForm.validateFields();
        const param = {
            ddtName: formField.ddtName ? formField.ddtName : '',
            ddtCode: formField.ddtCode ? formField.ddtCode : '',
            isSeq: formField.isSeq ? formField.isSeq : '',
            prjId: prjId,
        }

        const ddtData = await runAsync(
            async () => {
                return await http.get(`/basddts`, param);
            }, {
            error: { content: (err) => `查询失败: ${err.message}` },
            loading: { show: false },
            success: { show: false },
        });
        setDdtData(ddtData);
    }

    const rowSelection = {
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    // 基本数据类型
    const bdtColumns = [
        {
            title: '代码',
            dataIndex: 'bdtCode',
            key: 'bdtCode'
        },
        {
            title: '名称',
            dataIndex: 'bdtName',
            key: 'bdtName',
        },
        {
            title: '需要长度',
            dataIndex: 'isLen',
            key: 'isLen',
            render: (text, record) => {
                if ('1' == text) {
                    return '是';
                }
                return "否";
            },
        },
        {
            title: '需要精度',
            dataIndex: 'isPrecision',
            key: 'isPrecision',
            render: (text, record) => {
                if ('1' == text) {
                    return '是';
                }
                return "否";
            },
        },
    ];

    // 定制数据类型
    const cdtColumns = [
        {
            title: '代码',
            dataIndex: 'cdtUserCode',
            key: 'cdtUserCode'
        },
        {
            title: '名称',
            dataIndex: 'cdtName',
            key: 'cdtName',
        },
        {
            title: '说明',
            dataIndex: 'cdtDesc',
            key: 'cdtDesc',
        },

        {
            title: '基本数据类型代码',
            dataIndex: 'bdtCode',
            key: 'bdtCode',
        },
        {
            title: '基本数据类型名称',
            dataIndex: 'bdtName',
            key: 'bdtName',
        },
        {
            title: '长度',
            dataIndex: 'cdtLen',
            key: 'cdtLen',
        },
        {
            title: '精度',
            dataIndex: 'cdtPrecision',
            key: 'cdtPrecision',
        },
        {
            title: '是否序列',
            dataIndex: 'isSeq',
            key: 'isSeq',
            render: (text, record, index) => {
                switch (text) {
                    case "1":
                        return "是";
                    case "0":
                        return "否";
                }
            },
        },
        {
            title: '备注',
            dataIndex: 'memo',
            key: 'memo',
        },
    ];

    // 领域数据类型
    const ddtColumns = [
        {
            title: '代码',
            dataIndex: 'ddtCode',
            key: 'ddtCode'
        },
        {
            title: '名称',
            dataIndex: 'ddtName',
            key: 'ddtName',
        },
        {
            title: '领域CODE',
            dataIndex: 'ddtDpmainCode',
            key: 'ddtDpmainCode',
        },
        {
            title: '长度',
            dataIndex: 'ddtLen',
            key: 'ddtLen',
        },
        {
            title: '精度',
            dataIndex: 'ddtPrecision',
            key: 'ddtPrecision',
        },
        {
            title: '是否序列',
            dataIndex: 'isSeq',
            key: 'isSeq',
            render: (text, record, index) => {
                switch (text) {
                    case "1":
                        return "是";
                    case "0":
                        return "否";
                }
            },
        },
        {
            title: '备注',
            dataIndex: 'memo',
            key: 'memo',
        },
    ];

    const cssTable = {
        // 增加表格头样式
        '.ant-table-thead > tr >th': {
            fontWeight: 'bold',
        },
    };

    return (
        <Modal
            width={1000}
            visible={visible}
            title='选择数据类型'
            okText="确认"
            cancelText="取消"
            onOk={() => doConfirm()}
            onCancel={() => doClose()}
            bodyStyle={{ background: '#fff', padding: '2px 0px 0px 0px', height: '480px' }}
        >
            <Tabs type="card" onTabClick={tabOnChange}>
                <TabPane tab="基本数据类型" disabled={key1} key="bdt">
                    <div className="smstable">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            {/* 统计区 */}
                            {/* 数据区 */}
                            <div style={{ margin: '0px', overflow: 'auto', height: '420px', background: '#fff' }}>
                                <Table
                                    size="small"
                                    columns={bdtColumns}
                                    dataSource={bdtData}
                                    rowKey={record => record.pkid}
                                    rowSelection={{ type: 'radio', ...rowSelection }}
                                    pagination={Page.init(bdtData, 100)}
                                    scroll={{ y: 'calc(100vh - 420px)' }}
                                    css={cssTable}
                                />
                            </div>
                        </div>
                    </div>
                </TabPane>

                <TabPane tab="定制数据类型" disabled={key3} key="cdt">
                    <div className="smstable">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            <div style={{
                                padding: '24px 24px 0 24px',
                                overflow: 'hidden',
                                background: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                            }}>
                                <Form
                                    form={cdtForm}
                                    className="ant-advanced-search-form"
                                    onFinish={doCdtSearch}
                                >
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Form.Item
                                                name='cdtUserCode'
                                                label='代码'
                                            >
                                                <Input placeholder="请输入代码" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item
                                                name='cdtName'
                                                label='名称'
                                            >
                                                <Input placeholder="请输入名称" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item name="isSeq" label="是否序列">
                                                <Select placeholder="请选择">
                                                    <Option></Option>
                                                    <Option value="0">否</Option>
                                                    <Option value="1">是</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} style={{ textAlign: 'right' }}>
                                            <Button style={{ margin: '0 8px' }} onClick={() => { cdtForm.resetFields() }}>重置</Button>
                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {/* 统计区 */}

                            {/* 数据区 */}
                            <div style={{ marginTop: '0px', overflow: 'auto', height: '340px', background: '#fff' }}>
                                <Table
                                    size="small"
                                    columns={cdtColumns}
                                    dataSource={cdtData}
                                    rowKey={record => record.pkid}
                                    rowSelection={{
                                        type: 'radio',
                                        ...rowSelection,
                                    }}
                                    pagination={Page.init(cdtData)}
                                />
                            </div>

                        </div>
                    </div>
                </TabPane>

                <TabPane tab="领域数据类型" disabled={key2} key="ddt">
                    <div className="smstable">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            <div style={{
                                padding: '24px 24px 0 24px',
                                overflow: 'hidden',
                                background: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                            }}>
                                <Form
                                    form={ddtForm}
                                    className="ant-advanced-search-form"
                                    onFinish={doDdtSearch}
                                >
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Form.Item
                                                name='ddtName'
                                                label='名称'
                                            >
                                                <Input placeholder="请输入名称" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item
                                                name='ddtCode'
                                                label='代码'
                                            >
                                                <Input placeholder="请输入代码" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item name="isSeq" label="是否序列">
                                                <Select placeholder="请选择">
                                                    <Option></Option>
                                                    <Option value="0">否</Option>
                                                    <Option value="1">是</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} style={{ textAlign: 'right' }}>
                                            <Button style={{ margin: '0 8px' }} onClick={() => { ddtForm.resetFields() }}>重置</Button>
                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {/* 统计区 */}

                            {/* 数据区 */}
                            <div style={{ marginTop: '0px', overflow: 'auto', height: '340px', background: '#fff' }}>
                                <Table
                                    size="small"
                                    columns={ddtColumns}
                                    dataSource={ddtData}
                                    rowKey={record => record.pkid}
                                    rowSelection={{
                                        type: 'radio',
                                        ...rowSelection,
                                    }}
                                    pagination={Page.init(ddtData)}
                                />
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </Modal>
    )
}

export default SelectDataTypeDialog;

