import {css} from '@emotion/react'

const cssSidePane = css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    height: '100%',
    padding: 16,
    overflowY: 'auto',
})

const SidePane = (props) => {
    return (
        <div
            css={cssSidePane}
            {...props}
        />
    )
}

export default SidePane
