import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaPhys.mjs'
import Model from './ModelPhys.mjs'
import PropertiesPane from './PropertiesPanePhys.jsx'
import FormChoose from './FormChoosePhys.jsx'
import TableChoose from './TableChoosePhys.jsx'
import api from './apiPhys.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(PHYS_(DEPLOYED_ON))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="|||"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.DARK_GREEN,
                }),
            }
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ProductNode.getTextPrefix.call(
                this, map, node
            )

            const {psEnv} = node.data

            if (psEnv) {
                return `${defaultPrefix}【${psEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        onPush(map, node, type, data) {
            const {pkid} = node.data

            if (! pkid) {
                return
            }

            const key = {
                DBINS: 'archDbInsDeploys',
                MIDINS: 'archMiddlewareInsDeploys',
                SSINS: 'archSsInsDeploys',
                VR: 'depVsDeploys',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)

            data[key].push({
                ...pushData,

                ...({
                    DBINS: () => {
                        const {prjId, psCode, sVer} = node.data

                        return {
                            deployType: 'PS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: psCode,
                        }
                    },

                    MIDINS: () => {
                        const {prjId, psCode, sVer} = node.data

                        return {
                            deployType: 'PS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: psCode,
                        }
                    },

                    SSINS: () => {
                        const {prjId, psCode, sVer} = node.data

                        return {
                            deployType: 'PS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: psCode,
                        }
                    },

                    VR: () => {
                        const {prjId, sVer} = node.data

                        return {
                            psPrjId: prjId,
                            psSVer: sVer,
                        }
                    },
                }[type])(),
            })
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            for (const n of map.chainNoComment(node.parent)) {
                if ('CAT_ROOT_PRJ_DEP' !== n.data.bizNodeType) {
                    break
                }

                for (const n of [...node.children]) {
                    if ('PHYS_DEPLOYED_ON' === n.data.bizNodeType) {
                        map.deleteNode(n)
                    }
                    else {
                        map.deleteTree(n)
                    }
                }
            }

            return newDepth
        },
    })
}
