import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import {intersection} from '@/script/object.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import bizNodeModel, {ModelContext} from '../../bizNodeModel.mjs'
import SidePane from '../SidePane.jsx'
import {PropertiesContext} from './Properties.mjs'
import Check from './PropertiesControllerCheck.jsx'
import Number from './PropertiesControllerNumber.jsx'
import Color from './PropertiesControllerColor.jsx'

export const NodePropertiesPane = ({
    Model = bizNodeModel(),
    nodes,
    ...props
}) => {
    const map = useMapContext()
    map.useNodesChange(nodes)

    const canWriteData = nodes.every(node => {
        const _n = map.nodeProxy(node)
        return _n.canWriteData()
    })

    const values = intersection(
        nodes.map(e => e.data)
    )

    const update = (updates) => {
        map.commands.updateNodeData(nodes, () => updates)
    }

    return (
        <PropertiesContext.Provider value={[values, update]}>
            <ModelContext.Provider value={Model}>
                <ReadOnlyContext.Provider value={! canWriteData}>
                    <SidePane {...props} />
                </ReadOnlyContext.Provider>
            </ModelContext.Provider>
        </PropertiesContext.Provider>
    )
}

export const NodeStylePane = ({nodes, ...props}) => {
    const map = useMapContext()
    map.useNodesChange(nodes)

    const canWriteStyle = nodes.every(node => {
        const _n = map.nodeProxy(node)
        return _n.canWriteStyle()
    })

    const values = intersection(
        nodes.map(e => e.data.style)
    )

    const update = (updates) => {
        map.commands.updateNodeStyle(nodes, () => updates)
    }

    return (
        <PropertiesContext.Provider value={[values, update]}>
            <ReadOnlyContext.Provider value={! canWriteStyle}>
                <SidePane {...props}>
                    <Number
                        label="字体大小"
                        prop="fontSize"
                    />

                    <Check
                        falseValue="normal"
                        label="斜体"
                        prop="fontStyle"
                        trueValue="italic"
                    />

                    <Check
                        falseValue="normal"
                        label="粗体"
                        prop="fontWeight"
                        trueValue="bold"
                    />

                    <Color
                        label="文字颜色"
                        prop="textColor"
                    />

                    <Color
                        label="背景颜色"
                        prop="backgroundColor"
                    />
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}
