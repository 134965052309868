export const canCopyNodeText = () => () => {
    return function (node) {
        return true
    }
}

export const copyNodeText = () => () => {
    return async function (nodes, seperator) {
        if (0 === nodes.length) {
            return
        }

        const text = [...nodes]
            .map(node => {
                const {bizNodeType} = node.data
                return this.BizNode[bizNodeType].getText(this, node)
            })
            .join(seperator)

        await navigator.clipboard.writeText(text)
        this.logger.info('节点文本已拷贝到剪贴板', nodes)
    }
}
