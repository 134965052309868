import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseUc = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()

    const columns = [
        {
            title: '功能模块',
            dataIndex: 'fmNamePath',
            component: <Table.ViewText />,
        },

        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '集成用例代码',
            dataIndex: 'sfNo',
            component: <Table.ViewText />,
        },

        {
            title: '集成用例名称',
            dataIndex: 'sfName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/ucs"
            query={query}
            {...props}
        />
    )
}

export default TableChooseUc
