import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelSsins.mjs'

const PropertiesPaneSsins = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="ssInsName" />
                <LongText prop="ssInsDesc" />
                <LongText prop="ssInsMemo" />
                <Text prop="ssInsPort" />

                <Select
                    options={[
                        ['NORMAL', '正常'],
                        ['DAMAGED', '损坏'],
                        ['REPAIR', '修理'],
                        ['MAINTAIN', '保养'],
                        ['UNKNOWN', '未知'],
                    ]}

                    prop="ssInsStatus"
                />

                <Text prop="ssInsBu" />
                <Text prop="ssInsDm" />
                <Text prop="ssInsOm" />
                <Text prop="ssInsEnv" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSsins
