import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    psName: {
        name: () => '程序结构标题',
    },

    psTypeCode: {
        name: () => '程序结构类型',
    },

    psDesc: {
        name: () => '详述',
    },
})
