import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    name: {
        name: () => '参数名称',
        required: () => true,
    },

    dataTypeGrpCode: {
        name: () => '类型组别',
        required: () => true,
    },

    dataTypeCode: {
        name: () => '数据类型',
        required: () => true,
        ignoreRequiredInValidation: true,

        validate: ({dataTypeCode, dfDtoCode}) => {
            if (! (dataTypeCode || dfDtoCode)) {
                return '数据类型不能为空'
            }
        }
    },

    remark: {
        name: () => '参数说明',
    },
})
