import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectLs from '../LS/SelectLs.jsx'

export default function PropertiesControllerLs({
    className,
    style,
    codeProp = 'lsCode',
    disabled,
    hidden,
    idProp = 'lsId',
    label,
    labelSuffix,
    nameProp = 'lsName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        //[codeProp]: lsCode,
        [idProp]: lsId,
        //[nameProp]: lsName,
    } = values

    const handleChange = ({lsCode, lsId, lsName}) => {
        update({
            [codeProp]: lsCode,
            [idProp]: lsId,
            [nameProp]: lsName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: idProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectLs
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={lsId}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
