import {OBJECT} from "swr/_internal"

export const clean = (object) => {
    return Object.fromEntries(
        Object.entries(object).filter(
            ([, v]) => undefined !== v
        )
    )
}

export const intersection = (objects) => {
    const os = objects.filter(e => 'object' === typeof e)

    if (0 === os.length) {
        return {}
    }

    const [firstObject, ...restObjects] = os
    const entries = Object.entries(firstObject)

    for (const o of restObjects) {
        for (const entry of entries) {
            const [k, v, isExcluded] = entry

            if (isExcluded) {
                continue
            }

            if (! Object.prototype.hasOwnProperty.call(o, k)) {
                // mark as excluded
                entry[2] = true
                continue
            }

            const vv = o[k]

            if (vv === v) {
                continue
            }

            if (
                isPureObject(v) &&
                isPureObject(vv)
            ) {
                entry[1] = intersection([v, vv])
            }
            else {
                // mark as excluded
                entry[2] = true
            }
        }
    }

    return Object.fromEntries(
        entries.filter(([k, v, isExcluded]) => ! isExcluded)
    )
}

export const isPureObject = (x) => (
    x &&

    (
        ! x.constructor ||
        Object === x.constructor
    )
)

export const merge = (objects) => {
    const kvs = new Map()

    for (const object of objects) {
        for (const [k, v] of Object.entries(object)) {
            if (kvs.has(k)) {
                const v0 = kvs.get(k)

                if (undefined === v) {
                    kvs.delete(k)
                }
                else if (
                    isPureObject(v0) &&
                    isPureObject(v)
                ) {
                    kvs.set(k, merge([v0, v]))
                }
                else {
                    kvs.set(k, v)
                }
            }
            else if (undefined !== v) {
                kvs.set(k, v)
            }
        }
    }

    return Object.fromEntries(kvs)
}

const splitPath = (path) => path ? path.split('.') : []

export const read = (object, propPath) => {
    let o = object

    for (const p of splitPath(propPath)) {
        o = o[p]

        if (undefined === o) {
            break
        }
    }

    return o
}

export const write = (object, propPath, value) => {
    let o = object

    const props = splitPath(propPath)
    const lastProp = props.pop()

    for (const p of props) {
        if (! Object.hasOwnProperty.call(o, p)) {
            o[p] = {}
        }

        o = o[p]
    }

    o[lastProp] = value
    return object
}
