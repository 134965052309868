import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const TableChooseBfdataflow = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '业务流程名称',
            dataIndex: 'bfName',
            component: <Table.ViewText />,
        },

        {
            title: '业务流程代码',
            dataIndex: 'bfNo',
            component: <Table.ViewText />,
        },

        {
            title: '场景流程标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bfdataflows"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableChooseBfdataflow
