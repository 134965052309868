import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ArTypeCode from './PropertiesControllerArTypeCode.jsx'
import Model from './ModelAr.mjs'

const PropertiesPaneBatchAr = (props) => (
    <BatchPropertiesPane
        controllers={
            <>
                <ArTypeCode prop="arTypeCode" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchAr
