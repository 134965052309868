export function canCutTree(nodes) {
    const {canCopyTree, canDeleteTree} = this.actions

    return [...nodes].filter(
        node => (
            canCopyTree(node) &&
            canDeleteTree(node)
        )
    )
}

export async function cutTree(nodes) {
    const {copyTree, deleteTree} = this.actions

    await this.cmd(async () => {
        await copyTree(nodes)
        await deleteTree(nodes)
    })
}
