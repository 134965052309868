import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function ButtonOnlineRev({node, ...props}) {
    const map = useMapContext()

    if ('_DES_MAP' !== map.data.mapTypeCode) {
        return null
    }

    const {bizNodeType, onlineRev, rev, sVer} = node.data

    if (! onlineRev) {
        return null
    }

    const handleClick = async () => {
        await map.BizNode[bizNodeType].gotoDiff(map, node, rev, onlineRev)
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            线上：{sVer}.{onlineRev}
        </Button>
    )
}
