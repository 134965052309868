export class TreeNode {
    constructor({id = crypto.randomUUID(), data = {}} = {}) {
        this.#data = data
        this.#id = id
    }

    get data() {
        return this.#data
    }

    set data(data) {
        this.#data = data
    }

    get firstChild() {
        return this.#firstChild
    }

    set firstChild(node) {
        this.#firstChild = node
    }

    get id() {
        return this.#id
    }

    get isDeleted() {
        return this.#isDeleted
    }

    set isDeleted(isDeleted) {
        this.#isDeleted = isDeleted
    }

    get lastChild() {
        return this.#lastChild
    }

    set lastChild(node) {
        this.#lastChild = node
    }

    get nextSibling() {
        return this.#nextSibling
    }

    set nextSibling(node) {
        this.#nextSibling = node
    }

    get parent() {
        return this.#parent
    }

    set parent(node) {
        this.#parent = node
    }

    get prevSibling() {
        return this.#prevSibling
    }

    set prevSibling(node) {
        this.#prevSibling = node
    }

    export(transform = a => a) {
        const {data, id} = this
        return transform({data, id})
    }

    #data
    #firstChild = null
    #id
    #isDeleted = false
    #lastChild = null
    #nextSibling = null
    #parent = null
    #prevSibling = null
}

export default class Tree {
    static TreeNode = TreeNode

    constructor(treeData) {
        if (treeData) {
            this.init(treeData)
        }
    }

    /**
     * 初始化
     */
    init(treeData) {
        this.#root = this.importTree(treeData)
    }

    /**
     * 获取根节点
     */
    get root() {
        return this.#root
    }

    /**
     * 获取节点
     */
    getNode(id) {
        return this.#nodes.get(id)
    }

    /**
     * 导出树
     */
    exportTree(node, transform) {
        const nodeData = node.export(transform)

        const children = [...this.children(node)].map(
            node => this.exportTree(node, transform)
        )

        return {...nodeData, children}
    }

    /**
     * 导入树
     */
    importTree(treeData = {}, transform = a => a) {
        const {children, ...nodeData} = treeData
        const node = this._createNode(transform(nodeData))
        this._addNode(node)

        if (0 < children?.length) {
            const childNodes = children.map(
                treeData => this.importTree(treeData, transform)
            )

            node.firstChild = childNodes.at(0)
            node.lastChild = childNodes.at(-1)

            for (let i = 1; i < childNodes.length; i += 1) {
                childNodes[i - 1].nextSibling = childNodes[i]
                childNodes[i].prevSibling = childNodes[i - 1]
                childNodes[i].parent = node
            }

            childNodes[0].parent = node
        }

        return node
    }

    /**
     * 在节点的子节点集合的最后插入子节点
     */
    appendChild(node, child) {
        if (node.lastChild) {
            child.prevSibling = node.lastChild
            node.lastChild.nextSibling = child
        }
        else {
            child.prevSibling = null
            node.firstChild = child
        }

        child.parent = node
        child.nextSibling = null
        node.lastChild = child
    }

    /**
     * 在节点的子节点集合的最前插入子节点
     */
    prependChild(node, child) {
        if (node.firstChild) {
            child.nextSibling = node.firstChild
            node.firstChild.prevSibling = child
        }
        else {
            node.lastChild = child
            child.nextSibling = null
        }

        child.parent = node
        child.prevSibling = null
        node.firstChild = child
    }

    /**
     * 在节点之后插入同级节点
     */
    insertSiblingAfter(node, sibling) {
        if (! node.parent) {
            throw new Error(`Node ${node.id} has no parent, can't insert sibling.`)
        }

        if (node.nextSibling) {
            node.nextSibling.prevSibling = sibling
            sibling.parent = node.parent
            sibling.nextSibling = node.nextSibling
            sibling.prevSibling = node
            node.nextSibling = sibling
        }
        else {
            this.appendChild(node.parent, sibling)
        }
    }

    /**
     * 在节点之前插入同级节点
     */
    insertSiblingBefore(node, sibling) {
        if (! node.parent) {
            throw new Error(`Node ${node.id} has no parent, can't insert sibling.`)
        }

        if (node.prevSibling) {
            node.prevSibling.nextSibling = sibling
            sibling.parent = node.parent
            sibling.prevSibling = node.prevSibling
            sibling.nextSibling = node
            node.prevSibling = sibling
        }
        else {
            this.prependChild(node.parent, sibling)
        }
    }

    /**
     * 在单个节点或多个兄弟节点之前插入父节点
     */
    insertParent(parent, nodes) {
        const nodeList = [...nodes]

        if (0 === nodeList.length) {
            return
        }

        const grandparent = nodeList[0].parent

        const sameParent = nodeList.every(
            n => n.parent === grandparent
        )

        if (! sameParent) {
            throw new Error('Nodes have different parents, can\'t insert parent.')
        }

        if (grandparent) {
            const children = this.sortSiblings(nodes)

            for (const node of nodes) {
                this.unlinkTree(node)
            }


            for (let i = 1; i < children.length; i += 1) {
                children[i - 1].nextSibling = children[i]
                children[i].prevSibling = children[i - 1]
                children[i].parent = parent
            }

            const firstChild = children.at(0)
            firstChild.parent = parent
            const {prevSibling} = firstChild
            firstChild.prevSibling = null
            const lastChild = children.at(-1)
            lastChild.nextSibling = null
            parent.firstChild = firstChild
            parent.lastChild = lastChild

            if (prevSibling) {
                this.insertSiblingAfter(prevSibling, parent)
            }
            else {
                this.prependChild(grandparent, parent)
            }
        }
        else {
            for (const node of nodes) {
                this.appendChild(parent, node)
            }
        }
    }

    /**
     * 交换两个相邻节点的位置
     */
    swap(nodeA, nodeB) {
        const doSwap = (a, b) => {
            a.nextSibling = b.nextSibling
            b.prevSibling = a.prevSibling
            a.prevSibling = b
            b.nextSibling = a

            if (a.nextSibling) {
                a.nextSibling.prevSibling = a
            }
            else {
                a.parent.lastChild = a
            }

            if (b.prevSibling) {
                b.prevSibling.nextSibling = b
            }
            else {
                b.parent.firstChild = b
            }
        }

        if (nodeA.nextSibling === nodeB) {
            doSwap(nodeA, nodeB)
        }
        else if (nodeB.nextSibling === nodeA) {
            doSwap(nodeB, nodeA)
        }
        else {
            throw new Error(`Can not swap two nodes which are not adjacent to each other: #${nodeA.id} and #${nodeB.id}.`)
        }
    }

    /**
     * 从树中移除子树但并不删除
     */
    unlinkTree(node) {
        const {
            nextSibling,
            parent,
            prevSibling,
        } = node

        if (prevSibling) {
            prevSibling.nextSibling = nextSibling
        }
        else if (parent) {
            parent.firstChild = nextSibling
        }

        if (nextSibling) {
            nextSibling.prevSibling = prevSibling
        }
        else if (parent) {
            parent.lastChild = prevSibling
        }
    }

    /**
     * 删除节点，其子节点取代它原本的位置
     */
    deleteNode(node) {
        const {
            firstChild,
            lastChild,
            nextSibling,
            parent,
            prevSibling,
        } = node

        if (firstChild) {
            for (const child of this.children(node)) {
                child.parent = parent
            }

            if (prevSibling) {
                prevSibling.nextSibling = firstChild
                firstChild.prevSibling = prevSibling
            }
            else if (parent) {
                parent.firstChild = firstChild
            }

            if (nextSibling) {
                nextSibling.prevSibling = lastChild
                lastChild.nextSibling = nextSibling
            }
            else if (parent) {
                parent.lastChild = lastChild
            }

            this._deleteNode(node)
        }
        else {
            this.deleteTree(node)
        }
    }

    /**
     * 删除树
     */
    deleteTree(node) {
        this.unlinkTree(node)

        for (const n of this.walkDown(node)) {
            this._deleteNode(n)
        }
    }

    /**
     * 迭代自身和祖先节点
     */
    *chain(node) {
        let cursor = node

        while (cursor) {
            yield cursor
            cursor = cursor.parent
        }
    }

    /**
     * 迭代子节点
     */
    *children(node) {
        let cursor = node.firstChild

        while (cursor) {
            yield cursor
            cursor = cursor.nextSibling
        }
    }

    /**
     * 迭代节点及其子树，广度优先
     */
    *bfs(start, next) {
        function* search(chains) {
            const queue = []

            for (const chain of chains) {
                let cursor = chain[0].firstChild

                while (true) {
                    const nextChain = [cursor, ...chain]

                    const {
                        yieldNode = true,
                        yieldChildren = true,
                        yieldSibling = true,
                    } = next?.(nextChain) ?? {}

                    if (yieldNode) {
                        yield cursor
                    }

                    if (yieldChildren && cursor.firstChild) {
                        queue.push(nextChain)
                    }

                    if (yieldSibling && cursor.nextSibling) {
                        cursor = cursor.nextSibling
                    }
                    else {
                        break
                    }
                }
            }

            if (0 < queue.length) {
                yield* search(queue)
            }
        }

        if (start[Symbol.iterator]) {
            const nodes = [...start]

            if (0 < nodes.length) {
                const parentChain = [...this.chain(nodes[0].parent)]

                for (const node of nodes) {
                    yield* search(node, parentChain)
                }
            }
        }
        else {
            const parentChain = [...this.chain(start.parent)]
            yield* search(start, parentChain)
        }
    }

    /**
     * 迭代节点及其子树，深度优先
     */
    *dfs(start, next) {
        const that = this

        function* searchChildren(children, chain) {
            for (const n of children) {
                const newChain = [n, ...chain]

                const {
                    yieldChildren = true,
                    yieldNode = true,
                    yieldSibling = true,
                } = next?.(newChain) ?? {}

                if (yieldNode) {
                    yield n
                }

                if (yieldChildren) {
                    yield* searchChildren(that.children(n), newChain)
                }

                if (! yieldSibling) {
                    break
                }
            }
        }

        if (start[Symbol.iterator]) {
            const children = [...start]

            if (0 < children.length) {
                const {parent} = children[0]
                const chain = [...this.chain(parent)]
                yield* searchChildren(children, chain)
            }

        }
        else {
            const chain = [...this.chain(start)]

            const {
                yieldChildren = true,
                yieldNode = true,
            } = next?.(chain) ?? {}

            if (yieldNode) {
                yield start
            }

            if (yieldChildren) {
                const children = this.children(start)
                yield* searchChildren(children, chain)
            }
        }
    }

    /**
     * 从指定节点开始向下遍历
     */
    *walkDown(
        node,

        {
            /**
             * 广度优先
             */
            breadthFirst = false,

            /**
             * 排除起始节点
             */
            excludeTarget = false,

            next,
        } = {}
    ) {

        function* walkBreadthFirst(chains) {
            const queue = []

            for (const chain of chains) {
                let cursor = chain[0].firstChild

                while (true) {
                    const nextChain = [cursor, ...chain]

                    const {
                        yieldNode = true,
                        yieldChildren = true,
                        yieldSibling = true,
                    } = next?.(nextChain) ?? {}

                    if (yieldNode) {
                        yield cursor
                    }

                    if (yieldChildren && cursor.firstChild) {
                        queue.push(nextChain)
                    }

                    if (yieldSibling && cursor.nextSibling) {
                        cursor = cursor.nextSibling
                    }
                    else {
                        break
                    }
                }
            }

            if (0 < queue.length) {
                yield* walkBreadthFirst(queue)
            }
        }

        function* walkDepthFirst(node, chain) {
            const newChain = [node, ...chain]

            const {
                yieldNode = true,
                yieldChildren = true,
                yieldSibling = true,
            } = next?.(newChain) ?? {}

            if (yieldNode) {
                yield node
            }

            if (yieldChildren && node.firstChild) {
                yield* walkDepthFirst(node.firstChild, newChain)
            }

            if (yieldSibling && node.nextSibling) {
                yield* walkDepthFirst(node.nextSibling, chain)
            }
        }

        const chain = [...this.chain(node)]

        if (! excludeTarget) {
            yield node
        }

        if (node.firstChild) {
            yield* breadthFirst ?
                walkBreadthFirst([chain]) :
                walkDepthFirst(node.firstChild, chain)
        }
    }

    /**
     * 从节点集中最下层节点开始遍历，保证每个节点只遍历一次
     */
    //*traverseFromBottom(nodes) {
        //const that = this

        //const traverse = function* (nodes) {
            //const nextNodes = new Set

            //for (const node of that.bottomNodes(nodes)) {
                //yield node

                //if (node.parent) {
                    //nextNodes.add(node.parent)
                //}
            //}

            //if (0 < nextNodes.size) {
                //yield* (nextNodes)
            //}
        //}

        //yield* traverse(new Set(nodes))
    //}

    /**
     * 按父节点对节点集分组
     */
    groupByParent(nodes) {
        const groups = new Map

        for (const node of nodes) {
            let children = groups.get(node.parent)

            if (! children) {
                children = new Set
                groups.set(node.parent, children)
            }

            children.add(node)
        }

        return groups
    }

    /**
     * 节点集中如果有多个节点处于同一个分支上，仅保留离根节点最近的节点
     */
    topNodes(nodes) {
        const tops = new Set
        const nodeSet = new Set(nodes)

        for (const node of nodes) {
            let hasAncestor = false

            for (const ancestor of this.chain(node.parent)) {
                if (nodeSet.has(ancestor)) {
                    hasAncestor = true
                    break
                }
            }

            if (! hasAncestor) {
                tops.add(node)
            }
        }

        return tops
    }

    /**
     * 节点集中如果有多个节点处于同一个分支上，仅保留离根节点最远的节点
     */
    bottomNodes(nodes) {
        const bottoms = new Set
        const nodeSet = new Set(nodes)

        for (const node of nodes) {
            if (nodeSet.has(node)) {
                for (const ancestor of this.chain(node.parent)) {
                    nodeSet.delete(ancestor)

                    if (bottoms.has(ancestor)) {
                        bottoms.delete(ancestor)
                        break
                    }
                }

                bottoms.add(node)
            }
        }

        return bottoms
    }

    /**
     * 对同一父节点下的节点集排序
     */
    sortSiblings(nodes, reversed = false) {
        const nodeList = [...nodes]

        if (0 < nodeList.length) {
            const {parent} = nodeList[0]
            const children = [...this.children(parent, reversed)]

            return nodeList
                .map(n => children.indexOf(n))
                .sort((a, b) => a - b)
                .map(i => children.at(i))
        }
        else {
            return nodeList
        }
    }

    /**
     * 将节点集转为树集
     */
    treelize(nodes) {
        const tree = []

        const node2descendants = new Map(
            [...nodes].map((node) => [node, []])
        )

        loop_nodes:
        for (const node of nodes) {
            for (const ancestor of this.chain(node.parent)) {
                if (node2descendants.has(ancestor)) {
                    const descendants = node2descendants.get(ancestor)

                    descendants.push([
                        node,
                        node2descendants.get(node),
                    ])

                    continue loop_nodes
                }
            }

            tree.push([
                node,
                node2descendants.get(node),
            ])
        }

        return tree
    }

    /**
     * 添加节点
     */
    _addNode(node) {
        node.isDeleted = false
        this.#nodes.set(node.id, node)
    }

    /**
     * 创建节点
     */
    _createNode(nodeData) {
        const {TreeNode} = this.constructor
        return new TreeNode(nodeData)
    }

    /**
     * 删除节点
     */
    _deleteNode(node) {
        node.isDeleted = true
        this.#nodes.delete(node.id)
    }

    #nodes = new Map()
    #root = null
}
