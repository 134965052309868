import extendNode from '../extendNode.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaCdg.mjs'
import Model from './ModelCdg.mjs'
import PropertiesPane from './PropertiesPaneCdg.jsx'
import FormChoose from './FormChooseCdg.jsx'
import TableChoose from './TableChooseCdg.jsx'
import api from './apiCdg.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CAT|DGF)$/.test(type)
        },

        async choose(map, node) {
            const getQuery = ({dms = [], ...q}) => ({
                ...q,
                dmIds: dms.map(({dmId}) => dmId),
            })

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            return 'DGF'
        },

        getIcons(map, node) {
            return map.BizNode.DT.getIcons.call(this, map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {pcdgDfList: []})
        },

        getStyle(map, node) {
            return map.BizNode.DT.getStyle.call(this, map, node)
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['pcdgDfList'])
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['DGF'],
            ]
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = n.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        dmCode,
                        dmId,
                        dmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('DF' === bizNodeType) {
                map.BizNode.DGF.castFrom(map, child)
                return true
            }
            else {
                return ProductNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
