import {Select} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'

const defaultOnChange = () => {}

const TagSelect = ({
    readOnly,
    value,
    onChange = defaultOnChange,
    ...props
}) => {
    // TODO
    const gReadOnly = useReadOnly()

    const handleChange = (value) => {
        onChange(value.join(','))
    }

    return (
        <Select
            style={{display: 'block'}}
            mode="tags"
            value={value ? value.split(',') : []}
            onChange={handleChange}
            {...props}
        />
    )
}

export default TagSelect
