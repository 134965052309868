import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default () => {
    const refMarkedNodes = useRef(new Set)
    const markedNodes = refMarkedNodes.current

    const extensions = () => ({
        markedNodes,

        markNodes(nodes) {
            let isChanged = false

            for (const node of nodes) {
                if (! markedNodes.has(node)) {
                    markedNodes.add(node)
                    isChanged = true

                    if (! node.isDeleted) {
                        publish(node, 'marked_change', true)
                    }
                }
            }

            if (isChanged) {
                publish(this, 'marked_nodes_change', markedNodes)
            }
        },

        unmarkNodes(nodes) {
            let isChanged = false

            for (const node of nodes) {
                if (markedNodes.has(node)) {
                    markedNodes.delete(node)
                    isChanged = true

                    if (! node.isDeleted) {
                        publish(node, 'marked_change', false)
                    }
                }
            }

            if (isChanged) {
                publish(this, 'marked_nodes_change', markedNodes)
            }
        },

        useIsNodeMarked(node) {
            const [isMarked, setIsMarked] = useState(
                () => markedNodes.has(node)
            )

            useLayoutEffect(
                () => {
                    subscribe(node, 'marked_change', setIsMarked)

                    return () => {
                        unsubscribe(node, 'marked_change', setIsMarked)
                    }
                },

                [node]
            )

            return isMarked
        },

        useMarkedNodes() {
            const [, setFlag] = useState(false)

            useEffect(
                () => {
                    const handleChange = () => {
                        setTimeout(
                            () => setFlag(flag => ! flag)
                        )
                    }

                    subscribe(this, 'marked_nodes_change', handleChange)

                    return () => unsubscribe(
                        this,
                        'marked_nodes_change',
                        handleChange
                    )
                },

                []
            )

            return markedNodes
        },
    })

    const watchers = {
        model_change() {
            let isChanged = false

            for (const node of markedNodes) {
                if (node.isDeleted) {
                    if (markedNodes.has(node)) {
                        isChanged = true
                        markedNodes.delete(node)
                    }
                }
            }

            if (isChanged) {
                publish(this, 'marked_nodes_change', markedNodes)
            }
        },
    }

    return {extensions, watchers}
}
