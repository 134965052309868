const VIEW_SIZE = 128

const Wrapper = ({children, fill}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    >
        <line
            x1="0"
            y1="10%"
            x2="0"
            y2="90%"
            stroke={fill}
            strokeWidth={40}
        />

        {children}
    </svg>
)

const TriangleUp = ({
    fill = '#000',
    rotate = 0,
}) => {
    const s = VIEW_SIZE

    const d = `
        M ${s * 0.5},0
        L ${s * 0.933},${s * 0.75}
        L ${s * 0.067},${s * 0.75}
        Z
    `

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${s} ${s}`}
        >
            <path
                d={d}
                fill={fill}
                transform={`rotate(${rotate})`}
                transform-origin="center"
            />
        </svg>
    )
}

export default function IconAsyncExec({fill}) {
    return (
        <Wrapper fill={fill}>
            <TriangleUp
                fill={fill}
                rotate={90}
            />
        </Wrapper>
    )
}
