import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    ssUserCode: {
        name: () => '子系统代码',
        required: () => true,
    },

    ssName: {
        name: () => '子系统名称',
        required: () => true,
    },

    isUi: {
        name: () => '子系统类型',
        required: () => true,
        disabled: ({pkid}) => pkid,
    },

    ssDesc: {
        name: () => '子系统描述',
    },

    terminalType: {
        name: () => '终端类型',
        disabled: ({pkid}) => pkid,
        hidden: ({isUi}) => '1' !== isUi,
        required: ({isUi}) => '1' === isUi,
    },

    devLang: {
        name: () => '开发语言',
    },

    devTechDesc: {
        name: () => '开发技术说明',
    },

    softwareDesc: {
        name: () => '软件环境说明',
    },

    hardwareDesc: {
        name: () => '硬件环境说明',
    },

    networkDesc: {
        name: () => '网络环境说明',
    },

    deployIp: {
        name: () => '部署 IP',
    },

    deployDesc: {
        name: () => '部署环境说明',
    },
})
