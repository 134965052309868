function pasteTreeAfter() {
    const {
        commands: {canPasteTreeAfter, pasteTreeAfter},
        selectedNodes,
    } = this

    const nodes = canPasteTreeAfter(selectedNodes)
    pasteTreeAfter(nodes)
}

function pasteTreeAppend() {
    const {
        commands: {canPasteTreeAppend, pasteTreeAppend},
        selectedNodes,
    } = this

    const nodes = canPasteTreeAppend(selectedNodes)
    pasteTreeAppend(nodes)
}

export default {
    'Ctrl+V': pasteTreeAppend,
    'Meta+V': pasteTreeAppend,
    'Ctrl+Shift+V': pasteTreeAfter,
    'Meta+Shift+V': pasteTreeAfter,
}
