import normalizeNumber from './normalizeNumber.mjs'

const VIEW_SIZE = 128

export default function IconTriangle({
    borderWidth = 0,
    borderColor = 'transparent',
    fill = '#000',
    rotate = 0,
}) {
    const strokeWidth = normalizeNumber(VIEW_SIZE, borderWidth)
    const s = VIEW_SIZE
    const w = strokeWidth / 2

    const d = `
        M ${s * 0.5},${w}
        L ${s * 0.933 - w},${s * 0.75 - w}
        L ${s * 0.067 + w},${s * 0.75 - w}
        Z
    `

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${s} ${s}`}
        >
            <path
                d={d}
                fill={fill}
                strokeWidth={strokeWidth}
                stroke={borderColor}
                transform={`rotate(${rotate})`}
                transform-origin="center"
            />
        </svg>
    )
}
