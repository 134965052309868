import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getExternalNodes = (map) => {
    const next = (chain) => {
        const [node] = chain
        const _n = map.nodeProxy(node)
        const isExternal = _n.isExternal()
        const isHidden = map.isNodeHidden(node)

        return {
            yieldChildren: ! isHidden,
            yieldNode: ! isHidden && isExternal,
        }
    }

    return [...map.walkDown(map.root, {excludeTarget: true, next})]
}

export default () => {
    const map = useMapContext()
    const [nodes, setNodes] = useState(() => getExternalNodes(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                setNodes(getExternalNodes(map))
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return nodes
}
