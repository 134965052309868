import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaDepDb.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(DB)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        //defaultChildType(map, node) {
            //return 'CAT'
        //},

        //menuItemsInsertCommon(map, node) {
            //return [
                //['CAT'],
            //]
        //},

        //menuItemsInsertProduct(map, node) {
            //return [
                //['DB'],
            //]
        //},
    })
}
