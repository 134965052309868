import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaArCatNode.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return 'AR' === type
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
            ]
        },

        async onPull(map, node) {
            await CategoryNode.onPull.call(this, map, node)
            node.isFolded = true
        },

        _fixChild(map, node, child) {
            return map.BizNode.AR._fixChild(map, node, child)
        },

        _getPushData(map, node) {
            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {compact: true}
            )

            const mapJson = JSON.stringify(tree.children)

            return {
                ...tree.data,
                id: node.id,
                [this.mapProp]: mapJson,
            }
        },
    })
}
