import {publish, subscribe} from '@/script/event.mjs'
import Tree from '@/script/Tree.mjs'
import TreeDocNode from './Node.mjs'
import Task from './Task.mjs'
import Transaction from './Transaction.mjs'

export default class TreeDoc extends Tree {
    static TreeNode = TreeDocNode

    constructor() {
        super()
        this.#task = new Task(this)

        subscribe(this, 'task_execute', () => {
            if (0 === this.#changes.size) {
                return
            }

            const changeChildren = (node) => {
                if (! node) {
                    return
                }

                let changes = this.#changes.get(node)

                if (! changes) {
                    changes = new Map
                    this.#changes.set(node, changes)
                }

                const type = 'children'
                changes.set(type, {node, type})
            }

            for (const [node, changes] of this.#changes) {
                const isDeletedChange = changes.get('isDeleted')
                const parentChange = changes.get('parent')

                if (parentChange) {
                    changeChildren(parentChange.oldValue)
                    changeChildren(parentChange.newValue)
                }
                else if (isDeletedChange) {
                    changeChildren(node.parent)
                }
                else if (
                    changes.has('prevSibling') ||
                    changes.has('nextSibling')
                ) {
                    changeChildren(node.parent)
                }
            }

            for (const [node, changes] of this.#changes) {
                if (! node.isDeleted) {
                    publish(node, 'change', changes)
                }
            }

            publish(this, 'model_change', this.#changes)
            this.#changes = new Map
        })

        subscribe(this, 'task_finish', () => {
            this.lastChange = this._transaction.commit()
        })

        subscribe(this, 'task_failed', () => {
            this._transaction.rollback()
        })
    }

    change(detail) {
        const {node, type} = detail

        if (! this.#changes.has(node)) {
            this.#changes.set(node, new Map)
        }

        const changes = this.#changes.get(node)
        // TODO: change same type multiple times
        changes.set(type, detail)
    }

    init(tree) {
        super.init(tree)
        this._transaction.commit()
    }

    _addNode(node) {
        const action = () => super._addNode(node)
        const revert = () => super._deleteNode(node)
        this._transaction.do(action, revert)
    }

    _createNode(nodeData) {
        const {TreeNode} = this.constructor
        return new TreeNode(this, nodeData)
    }

    _deleteNode(node) {
        const action = () => super._deleteNode(node)
        const revert = () => super._addNode(node)
        this._transaction.do(action, revert)
    }

    #changes = new Map
    #task
    _transaction = new Transaction

    execute(...args) {
        return this.#task.execute(...args)
    }

    finishTask(...args) {
        return this.#task.finishTask(...args)
    }

    startTask(...args) {
        return this.#task.startTask(...args)
    }
}
