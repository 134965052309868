const IconMonitor = ({
    color = '#000',
    fill = 'transparent',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
    >
        <circle
            cx="50%"
            cy="50%"
            fill={fill}
            r="50%"
        />

        <path
            d="M81.55,103 L46.45,103 C45.3730447,103 44.5,102.1281335 44.5,101.0526316 C44.5,99.9771297 45.3730447,99.1052631 46.45,99.1052631 L49.5346563,99.1052631 C51.276250000000005,97.2990789 53.7826094,94.1479934 54.1921094,87.4210526 L29.875000059999998,87.4210526 C27.18386481,87.4180343 25.00302242974,85.2401351 25,82.5526316 L25,33.86842102 C25.00302242974,31.18091753 27.18386481,29.0030183005 29.875000059999998,29 L98.1249999,29 C100.8161352,29.0030183005 102.9969776,31.18091753 103,33.86842102 L103,82.5526316 C102.9969776,85.2401351 100.8161352,87.4180343 98.1249999,87.4210526 L73.8109375,87.4210526 C74.2204375,94.1479934 76.7267968,97.2990789 78.46839059999999,99.1052631 L81.55,99.1052631 C82.62695529999999,99.1052631 83.5,99.9771297 83.5,101.0526316 C83.5,102.1281335 82.62695529999999,103 81.55,103 Z M54.5595625,99.1052631 L73.4404375,99.1052631 C71.8012188,96.6771381 70.2046562,93.033125 69.90118749999999,87.4210526 L58.0988125,87.4210526 C57.7953438,93.033125 56.2,96.6771381 54.5595625,99.1052631 Z M98.1249999,83.5263157 C98.6634776,83.5263157 99.1,83.0903825 99.1,82.5526316 L99.1,75.73684209999999 L28.90000003,75.73684209999999 L28.90000003,82.5526316 C28.90000003,83.0903825 29.33652245,83.5263157 29.875000059999998,83.5263157 L98.1249999,83.5263157 Z M28.90000003,71.84210519999999 L99.1,71.84210519999999 L99.1,33.86842102 C99.1,33.3306701 98.6634776,32.89473687 98.1249999,32.89473687 L29.875000059999998,32.89473687 C29.33652245,32.89473687 28.90000003,33.3306701 28.90000003,33.86842102 L28.90000003,71.84210519999999 Z"
            fill={color}
        />
    </svg>
)

export default IconMonitor
