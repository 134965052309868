import {createShape} from '@/components/MindMap/index.mjs'

const Rect = (textRect, style, borderRadius) => {
    const bw = style.borderWidth
    const paddings = [bw, bw, bw, bw]

    const draw = (w, h) => `
        <rect
            x="${bw / 2}"
            y="${bw / 2}"
            width="${w - bw}"
            height="${h - bw}"
            rx="${borderRadius}"
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}

export const Rectangle = (textRect, style) => Rect(textRect, style, 0)
export const RoundedRectangle = (textRect, style) => Rect(textRect, style, 8)
