import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaLsiSf.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(SF)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF_'],
            ]
        },

        onPush(map, node, type, data) {
            if ('LSI' !== type) {
                return
            }

            Object.assign(
                data,

                {
                    openPrjId: '',
                    openSVer: '',
                    sfCode: '',
                    sfRev: '',
                }
            )

            for (const child of map.children(node)) {
                const {
                    bizNodeType,
                    sfCode,
                    prjId: openPrjId,
                    rev: sfRev,
                    sVer: openSVer,
                } = child.data

                if ('SF' === bizNodeType) {
                    Object.assign(data, {openPrjId, openSVer, sfCode, sfRev})
                    break
                }
            }
        },
    })
}
