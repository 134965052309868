import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import apiMap from '../../apis/apiMap.mjs'
import apiSnapshot from '../../apis/apiSnapshot.mjs'
import Color from '../Color.mjs'
import IconFlag from '../icons/IconFlag.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import meta from './metaModuleNode.mjs'

export default () => {
    const DesignNode = useDesignNode()
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,

        canChangeVersion(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                const rev = this.getRev(map, node)
                return null !== rev
            }
            else {
                return ComponentNode.canChangeVersion.call(this, map, node)
            }
        },

        //canCreateModuleMap(map, node) {
            //const {[this.mapIdProp]: mapId, pkid} = node.data

            //if (! pkid) {
                //map.logger.error('只有制品才能创建地图', [node])
                //return false
            //}

            //if (mapId) {
                //map.logger.error('已创建过地图，不能重复创建', [node])
                //return false
            //}

            //return true
        //},

        canDeleteNode(map, node) {
            return this.canWriteTree(map, node)
        },

        canGrow(map, node) {
            if (! DesignNode.canGrow.call(this, map, node)) {
                return false
            }

            if ('SA_MAP' !== map.data.mapTypeCode) {
                return false
            }

            if (! node.data[this.mapIdProp]) {
                map.logger.error('未创建地图，不能加载/卸载', [node])
                return false
            }

            return true
        },

        canMountType(map, node, type) {
            return type === this.type
        },

        //async createModuleMap(map, node) {
            //const {prjId, sVer} = map.data

            //const {
                //pkid,
                //[this.codeProp]: code,
                //[this.textProp]: mapName,
            //} = node.data

            //const {
                //mapStsCode,
                //pkid: mapId,
            //} = await apiMap.create({
                //mapName,
                //mapNo: `${code}_MAP`,
                //mapTypeCode: this.mapTypeCode,
                //prjId,
                //rNodeDataId: pkid,
                //sVer,
            //})

            //node.data = {
                //...node.data,
                //[this.mapIdProp]: mapId,
                //[this.mapStsCodeProp]: mapStsCode,
            //}
        //},

        defaultChildType(map, node) {
            return this.type
        },

        getIcons(map, node) {
            if ('SA_MAP' !== map.data.mapTypeCode) {
                return []
            }

            const {
                [this.mapIdProp]: mapId,
                [this.mapStsCodeProp]: mapStsCode,
            } = node.data

            if (! mapId) {
                return []
            }

            if ('RLS' === mapStsCode) {
                return [
                    <IconFlag
                        key="status"
                        color={Color.RED}
                    />
                ]
            }
            else {
                return [
                    <IconFlag
                        key="status"
                        color={Color.ORANGE}
                    />
                ]
            }
        },

        showVersion(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return true
            }
            else {
                return ComponentNode.showVersion.call(this, map, node)
            }
        },

        async gotoCurrent(map, node) {
            const {[this.mapIdProp]: mapId} = node.data

            if (! mapId) {
                map.logger.error('没有创建地图，不能跳转', [node])
                return
            }

            const url = buildUrl('/SoftwareMap', {id: mapId})
            window.open(url)
        },

        async gotoDiff(map, node, leftRev, rightRev) {
            const {[this.mapIdProp]: mapId} = node.data
            const text = this.getTextRaw(map, node)

            const url = buildUrl(
                '/DiffMap',

                {
                    id: mapId,
                    leftRev,
                    rightRev,
                    title: `${this.name}【${text}】`,
                    type: this.mapTypeCode,
                }
            )

            window.open(url)
        },

        async gotoRev(map, node, rev) {
            const {[this.mapIdProp]: pkid} = node.data

            const id = await apiSnapshot.fetchIdByRev(
                {bizNodeType: this.mapTypeCode, pkid, rev}
            )

            const url = buildUrl('/SoftwareMap', {id, mode: 'snapshot'})
            window.open(url)
        },

        menuItemsInsertConcept(map, node) {
            return [
                [this.type],
            ]
        },

        async onDoubleClick(map, node, event) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                event.preventDefault()
                const rev = this.getRev(map, node)

                if (rev && this.showVersion(map, node)) {
                    this.gotoRev(map, node, rev)
                }
                else {
                    this.gotoCurrent(map, node)
                }
            }
            else {
                await ComponentNode.onDoubleClick.call(this, map, node, event)
            }
        },

        async shrink(map, node) {
            if (! this.canShrink(map, node)) {
                return
            }

            await this.emitEventUp(map, node, {type: 'shrink'})

            for (const n of [...node.children]) {
                const _n = map.nodeProxy(n)

                if (! _n.isMounted()) {
                    map.deleteTree(n)
                }
            }
        },

        async _grow(map, node, depth) {
            const _n = map.nodeProxy(node)

            if (! _n.canGrow()) {
                return depth
            }

            const {children} = await this._readGrowTree(map, node)
            await _n.shrink()

            await Promise.all(
                children.map(async data => {
                    const n = map.importTree(data)
                    map.appendChild(node, n)
                    const _n = map.nodeProxy(n)
                    await _n.onImport()
                })
            )

            return depth + 1
        },

        async _readGrowTree(map, node) {
            const {[this.mapIdProp]: mapId} = node.data
            const rev = this.getRev(map, node)

            const mapData = await (() => {
                if (null !== rev && this.showVersion(map, node)) {
                    return apiSnapshot.readByRev({
                        bizNodeType: this.mapTypeCode,
                        pkid: mapId,
                        rev,
                    })
                }
                else {
                    return apiMap.read({pkid: mapId})
                }
            })()

            const {root: {children}} = parseMap(mapData)
            return {children}
        },

        _toConcept(map, node) {
            ComponentNode._toConcept.call(this, map, node)

            const {
                [this.mapIdProp]: _,
                ...data
            } = node.data

            node.data = data
        },
    })
}
