import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDm from '../DM/SelectDm.jsx'

export default function PropertiesControllerDm({
    className,
    style,
    codeProp = 'dmCode',
    dbCodeProp = 'dbCode',
    dbIdProp = 'dbId',
    dbNameProp = 'dbName',
    disabled,
    hidden,
    label,
    labelSuffix,
    idProp = 'dmId',
    nameProp = 'dmName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: dmCode,
        [dbCodeProp]: dbCode,
        [dbIdProp]: dbId,
        [idProp]: dmId,
        [nameProp]: dmName,
    } = values

    const handleChange = dm => {
        if (dm) {
            const {
                dbCode,
                dbId,
                dbName,
                dmCode,
                dmId,
                dmName,
            } = dm

            update({
                [codeProp]: dmCode,
                [dbCodeProp]: dbCode,
                [dbIdProp]: dbId,
                [dbNameProp]: dbName,
                [idProp]: dmId,
                [nameProp]: dmName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectDm
                dbCode={dbCode}
                dbId={dbId}
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{dmCode, dmId, dmName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
