export const canSaveProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canSaveProduct(node)
        )
    }
}

export const saveProduct = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.runParallelAction(
                () => this.actions.saveProduct(nodes),
                {action: ' 保存模型制品'},
            )
        )
    }
}
