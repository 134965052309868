import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCopyTree, copyTree} = map.commands
    const nodes = canCopyTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'copy-tree',
        label: '拷贝',
        suffix: 'Ctrl+C',
        onClick: () => copyTree(nodes),
    }
}
