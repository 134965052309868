import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaUc.mjs'
import Model from './ModelUc.mjs'
import PropertiesPane from './PropertiesPaneUc.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchUc.jsx'
import FormChoose from './FormChooseUc.jsx'
import TableChoose from './TableChooseUc.jsx'
import api from './apiUc.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(ROLE)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'ROLE'
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill="#fff"
                    letters="M"
                    textColor="#000"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {ucOprList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.GOLD}),
                shape: 'BreakangleRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['ROLE'],
            ]
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['ucOprList'])
        },

        async onPull(map, node) {
            await ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BD: 'sfList',
                BF: 'sfList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },
    })
}
