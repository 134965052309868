import {createShape} from '@/components/MindMap/index.mjs'

const L = 12

export const Arrow = (textRect, style) => {
    const bw = style.borderWidth
    const paddings = [bw, bw + L, bw, bw + L]

    const draw = (w, h) => `
        <path
            d="
                M ${bw / 2} ${bw / 2}
                H ${w - L - bw / 2}
                L ${w - bw / 2} ${h / 2}
                L ${w - L - bw / 2} ${h - bw / 2}
                H ${bw / 2}
                L ${bw / 2 + L} ${h / 2}
                Z
            "
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
