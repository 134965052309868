import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const TableChooseLsi = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            dataIndex: 'lsName',
            title: '外联系统名称',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'lsiNo',
            title: '外联接口代码',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'lsiName',
            title: '外联接口名称',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/lsis"
            query={query}
            {...props}
        />
    )
}

export default TableChooseLsi
