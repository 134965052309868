import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canMoveDownTree, moveDownTree} = map.commands
    const nodes = canMoveDownTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'move-down-tree',
        label: '向下移动',
        suffix: 'Ctrl+Down',
        onClick: () => moveDownTree(nodes),
    }
}
