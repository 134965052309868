import insertNode from './insertNode.mjs'

const getDefaultType = (map, node) => {
    const _n = map.nodeProxy(node)
    return _n.defaultChildType()
}

export const canInsertChild = () => defaultActions => {
    return function (node) {
        const _n = this.nodeProxy(node)

        return (
            defaultActions.canInsertChild(node) &&
            _n.canWriteTree()
        )
    }
}

export const appendChild = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.appendChild.bind(this),
            getDefaultType
        )
    }
}

export const prependChild = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.prependChild.bind(this),
            getDefaultType
        )
    }
}
