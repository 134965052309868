import Table from '@/components/Table/Table.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import TableChooseDesignItems from '../../nodes/components/TableChooseDesignItems.jsx'

const BizNodeType = ({row, ...props}) => {
    const map = useMapContext()
    const value = map.BizNode[row.mapTypeCode].name

    return (
        <Table.ViewText
            value={value}
            {...props}
        />
    )
}

const Text = ({row, ...props}) => {
    const map = useMapContext()
    const {textProp} = map.BizNode[row.mapTypeCode]
    const value = row[textProp]

    return (
        <Table.ViewText
            value={value}
            {...props}
        />
    )
}

const Code = ({row, ...props}) => {
    const map = useMapContext()
    const {codeProp} = map.BizNode[row.mapTypeCode]
    const value = row[codeProp]

    return (
        <Table.ViewText
            value={value}
            {...props}
        />
    )
}

export default function TableChooseProducts({
    fetch,
    fetchArgs,
    query,
    ...props
}) {
    const columns = [
        {
            title: '制品类型',
            dataIndex: 'bizNodeType',
            render: (value, row) => <BizNodeType row={row} />,
        },

        {
            title: '制品代码',
            dataIndex: 'code',
            render: (value, row) => <Code row={row} />,
        },

        {
            title: '制品名称',
            dataIndex: 'name',
            render: (value, row) => <Text row={row} />,
        },

        {
            title: '制品状态',
            dataIndex: 'stsName',
            component: <Table.ViewText />,
        },

        {
            title: '更新人',
            dataIndex: 'uptName',
            component: <Table.ViewText />,
        },

        {
            title: '更新时间',
            dataIndex: 'uptTime',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/products"
            query={query}
            {...props}
        />
    )
}
