import _ToolBar from '../../ToolBar.jsx'
import Menu from './Menu.jsx'

export default function ToolBar(props) {
    return (
        <_ToolBar
            menu={<Menu />}
            {...props}
        />
    )
}
