import {
    useTreeDoc,
    useChangeHistory,
    useDom,
    useHotkey,
    useLogger,
    useMetaData,
    useScale,
    useScaleCanvasByWheel,
    useSelectionHistory,
    useUnfoledAncestorsWhenNodeIsSelected,
} from '@/components/TreeDoc/index.mjs'

import {MindMapDoc} from './MindMapDoc.mjs'
import useEditNodeText from './plugins/useEditNodeText.mjs'
import useMindMapPlugins from './plugins/useMindMapPlugins.mjs'
import useScrollNodeIntoView from './plugins/useScrollNodeIntoView.mjs'
import useSelectNodesByMouse from './plugins/useSelectNodesByMouse.mjs'
import useSelectRootOnDoubleClickCanvas from './plugins/useSelectRootOnDoubleClickCanvas.mjs'

export default ({
    components,
    Doc = MindMapDoc,
    initData,
    plugins = [],
}) => {
    const treeDoc = useTreeDoc({
        Doc,
        initData,

        plugins: [
            useChangeHistory(),
            useDom(['nodes', 'trees']),
            useEditNodeText(),
            useHotkey(),
            useLogger(),
            useMetaData(initData.data),
            useMindMapPlugins(),
            //useScale(),
            //useScaleCanvasByWheel(),
            useScrollNodeIntoView(),
            useSelectionHistory(),
            useSelectNodesByMouse(),
            useSelectRootOnDoubleClickCanvas(),
            useUnfoledAncestorsWhenNodeIsSelected(),
            ...plugins,
        ],
    })

    treeDoc.components = components
    return treeDoc
}
