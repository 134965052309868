import {useState} from 'react'
import Modal from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const bodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}

export default function ModalChooseDesignItems({
    fetch,
    getQuery = a => a,
    multiple = true,
    FormChoose,
    TableChoose,
    onOk,
    ...props
}) {
    const map = useMapContext()
    const {prjId, sVer} = map.data

    const [query, setQuery] = useState(
        () => ({
            prjId,
            sortMode: 'UPT_TIME',
            sVer,
            ...getQuery({})
        })
    )

    const [selectedItems, setSelectedItems] = useState([])

    const handleChangeQuery = values => setQuery({
        prjId,
        sortMode: 'UPT_TIME',
        sVer,
        ...getQuery(values)
    })

    const handleSelect = item => onOk([item])
    const handleOk = () => onOk(selectedItems)

    return (
        <Modal
            bodyStyle={bodyStyle}
            closable={false}
            maskClosable={false}
            onOk={handleOk}
            {...props}
        >
            <FormChoose
                query={query}
                onChange={handleChangeQuery}
            />

            <TableChoose
                fetch={fetch}
                multiple={multiple}
                query={query}
                selectRowOnClick
                onChange={setSelectedItems}
                onSelect={handleSelect}
            />
        </Modal>
    )
}
