import {WarningOutlined} from '@ant-design/icons'
import IconWithNotification from '../IconWithNotification.jsx'
import ListInvalid from './ListInvalid.jsx'
import useInvalidItems from './useInvalidItems.mjs'

export default () => {
    const items = useInvalidItems()

    return {
        icon: (
            <IconWithNotification notification={0 < items.length}>
                <WarningOutlined />
            </IconWithNotification>
        ),

        key: 'invalid',
        hotkey: 'Ctrl+Shift+D',
        name: '无效模件',
        pane: <ListInvalid items={items} />,
    }
}
