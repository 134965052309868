import {css} from '@emotion/react'
import {Space} from 'antd'
import RadioGroup from '../../SidePanel/RightPanel/PropertiesControllerRadioGroup.jsx'
import OprType from './OprType.jsx'

const cssIcon = css({
    width: 16,
    height: 16,

    '&>svg': {
        verticalAlign: 'top',
    },
})

const Label = ({icon, text}) => {
    return (
        <Space>
            {icon && <div css={cssIcon}>{icon}</div>}
            {text}
        </Space>
    )
}

const PropertiesControllerOprType = (props) => {
    const options = OprType.map(
        (group) => group.map(
            ([value, text, icon]) => ({
                label: (
                    <Label
                        icon={icon}
                        text={text}
                    />
                ),

                value,
            })
        )
    )

    return (
        <RadioGroup
            options={options}
            {...props}
        />
    )
}

export default PropertiesControllerOprType
