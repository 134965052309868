import {useOpenModal} from '@/components/Modal/Modal.jsx'
import http from '@/biz/http.mjs'
import {MapContext} from '@/components/MindMap/index.mjs'
import ModalChooseDesignItems from '../../nodes/components/ModalChooseDesignItems.jsx'
import FormChoose from './FormChooseProducts.jsx'
import TableChoose from './TableChooseProducts.jsx'
import insertNode from './insertProduct.mjs'

export const canInsertProducts = () => () => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            this.actions.canInsertChild(node) &&
            /^(AR|DES_(DES|OTH|TASK|TEST))$/.test(bizNodeType)
        )
    }
}

const readProducts = data => http.get('/dps/all', data)

export const insertProducts = () => () => {
    const openModal = useOpenModal()

    return async function (nodes) {
        if (0 === nodes.length) {
            return
        }

        if (1 < nodes.length) {
            this.logger.warn('只能对单一节点插入制品，忽略除第一个节点外的其余节点')
        }

        const [node] = nodes

        const items = await openModal(
            <MapContext.Provider value={this}>
                <ModalChooseDesignItems
                    fetch={readProducts}
                    FormChoose={FormChoose}
                    TableChoose={TableChoose}
                    title="选择模件"
                    width="80%"
                />
            </MapContext.Provider>
        )

        if (0 < items?.length) {
            const trees = items.map(
                ({mapTypeCode: bizNodeType, ...e}) => ({
                    data: {...e, bizNodeType}
                })
            )

            await insertNode(this, node, trees)
        }
    }
}
