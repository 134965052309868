function moveDownSelection() {
    this.commands.moveDownSelection()
}

function moveLeftSelection() {
    this.commands.moveLeftSelection()
}

function moveRightSelection() {
    this.commands.moveRightSelection()
}

function moveUpSelection() {
    this.commands.moveUpSelection()
}

export default {
    'ArrowDown': moveDownSelection,
    'ArrowLeft': moveLeftSelection,
    'ArrowRight': moveRightSelection,
    'ArrowUp': moveUpSelection,
    // TODO
    //'Shift+ArrowDown': moveDownSelection,
    //'Shift+ArrowLeft': moveLeftSelection,
    //'Shift+ArrowRight': moveRightSelection,
    //'Shift+ArrowUp': moveUpSelection,
}
