import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Model from './ModelFm.mjs'

const PropertiesPaneFm = (props) => (
    <ModulePropertiesPane
        buttons={
            <>
                <ButtonExportModel type="fm" />
                <ButtonExportModel format="excel" type="fm" />
            </>
        }

        controllers={
            <>
                <Text prop="fmUserCode" />
                <Text prop="fmName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        versionLabel="功能地图版本号"
        {...props}
    />
)

export default PropertiesPaneFm
