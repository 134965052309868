import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {NodePropModel, useModel} from '../../bizNodeModel.mjs'
import {useProperties} from './Properties.mjs'

const cssLabelWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
})

const cssLabel = css({
    '&[data-required="1"]::before': {
        content: '"*"',
        color: '#ff4d4f',
        marginRight: 4,
    }
})

const Label = ({
    children,
    required,
    suffix,
    ...props
}) => {
    return (
        <div css={cssLabelWrapper}>
            <div
                css={cssLabel}
                data-required={required ? '1' : '0'}
                {...props}
            >
                {children}
            </div>

            {suffix}
        </div>
    )
}

const cssController = css({
    display: 'flex',
    flexDirection: 'column',
})

const cssControllerInline = css({
    flexDirection: 'row',
    gap: 8,
})

export function PropertiesController({
    children,
    hidden,
    inline,
    label,
    labelSuffix,
    required,
    ...props
}) {
    if (hidden) {
        return null
    }

    const onKeyDown = e => e.stopPropagation()
    const csss = [cssController]

    if (inline) {
        csss.push(cssControllerInline)
    }

    return (
        <div
            css={csss}
            onKeyDown={onKeyDown}
            {...props}
        >
            <Label
                required={required}
                suffix={labelSuffix}
            >
                {label}
            </Label>

            {children}
        </div>
    )
}

export const usePropertiesController = ({
    disabled,
    hidden,
    label,
    prop,
    readOnly,
    required,
    value,
}) => {
    const map = useMapContext()
    const [values, update] = useProperties()
    const Model = useModel()

    const p = {
        disabled,
        hidden,
        label,
        prop,
        readOnly,
        required,
        value,
    }

    if (prop) {
        //const npm = NodePropModel.create(Model.definition, prop, values)
        //p.disabled ??= npm.isDisabled
        //p.hidden ??= npm.isHidden
        //p.label ??= npm.name
        //p.readOnly ??= npm.isReadOnly
        //p.required ??= npm.isRequired
        const model = new Model(values)
        p.disabled ??= model.disabled(prop)
        p.hidden ??= model.hidden(prop)
        p.label ??= model.name(prop)
        p.readOnly ??= model.readOnly(prop)
        p.required ??= model.required(prop)
        p.value = values[prop]

        if (! (p.disabled || p.hidden || p.readOnly)) {
            p.onChange = newValue => {
                const oldValue = values[prop]

                if (undefined === oldValue || null === oldValue) {
                    if (newValue || 0 === newValue) {
                        update({[prop]: newValue})
                    }
                }
                else {
                    if (oldValue !== newValue) {
                        update({[prop]: newValue})
                    }
                }
            }
        }
    }

    if (! (p.disabled || p.hidden || p.readOnly)) {
        const onBlur = () => map.finishTask()
        const onFocus = () => map.startTask()
        Object.assign(p, {onBlur, onFocus})
    }

    return p
}
