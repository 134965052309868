import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootPrj.jsx'
import meta from './metaCatRootPrj.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^([BFU]A|CAT_(DATA|DB|DEP|DOC|LS|SS))$/.test(type)
        },

        menuItemsInsertCommon(map, node) {
            return []
        },

        async onEvent(map, node, event) {
            if (! /^(attach|change|create|delete|insert)$/.test(event.type)) {
                return
            }

            const Src2Dst = {
                CAT_DB: 'DEP_DB',
                SS_MID: 'DEP_MID',
                SS_SS: 'DEP_SS',
            }

            const [src, bizNodeType] = (() => {
                for (const n of event.chain) {
                    const {bizNodeType} = n.data
                    const dstType = Src2Dst[bizNodeType]

                    if (dstType) {
                        return [n, dstType]
                    }
                }

                return []
            })()

            if (! src) {
                return
            }

            const [dst] = map.queryNodes({algo: 'bfs', data: {bizNodeType}})

            if (! dst) {
                return
            }

            const _s = map.nodeProxy(src)
            const {children} = _s.exportTree({compact: true})
            const _d = map.nodeProxy(dst)
            _d.replace({children})
            await _d.shrink()
        },
    })
}
