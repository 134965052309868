import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const dictBool = {0: '否', 1: '是'}

const TableChooseCdt = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '类别',
            dataIndex: 'cdtCat',
            component: <Table.ViewText />,
        },

        {
            title: 'CDT代码',
            dataIndex: 'cdtUserCode',
            component: <Table.ViewText />,
        },

        {
            title: 'CDT名称',
            dataIndex: 'cdtName',
            component: <Table.ViewText />,
        },

        {
            title: '是否锁定',
            dataIndex: 'lockFlag',
            component: <Table.ViewDict dict={dictBool} />,
        },

        {
            title: '是否序列',
            dataIndex: 'isSeq',
            component: <Table.ViewDict dict={dictBool} />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/cdts"
            query={query}
            {...props}
        />
    )
}

export default TableChooseCdt
