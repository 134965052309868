import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canMoveUpTree, moveUpTree} = map.commands
    const nodes = canMoveUpTree(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'move-up-tree',
        label: '向上移动',
        suffix: 'Ctrl+Up',
        onClick: () => moveUpTree(nodes),
    }
}
