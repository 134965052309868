import {useEffect, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import History from '@/script/History.mjs'

export default () => {
    const refHistory = useRef(new History)
    const history = refHistory.current

    const getValidNodes = (doc, nodeIds) => {
        return [...nodeIds]
            .map(id => doc.getNode(id))
            .filter(a => a)
    }

    const extensions = () => ({
        get canRedoSelection() {
            return (
                history.cursor < history.end &&
                history.peek(history.cursor + 1)
            )
        },

        get canUndoSelection() {
            return 0 < history.cursor
        },

        redoSelection() {
            if (! this.canRedoSelection) {
                return
            }

            history.forward()
            const nodeIds = history.peek()
            const nodes = getValidNodes(this, nodeIds)
            this.execute(() => this.selectNodes(nodes))
        },

        undoSelection() {
            if (! this.canUndoSelection) {
                return
            }

            history.back()
            const nodeIds = history.peek()
            const nodes = getValidNodes(this, nodeIds)
            this.execute(() => this.selectNodes(nodes))
        },

        useSelectionHistory() {
            const getHistoryStates = () => {
                const canUndo = this.canUndoSelection
                const canRedo = this.canRedoSelection
                return {canRedo, canUndo}
            }

            const [states, setStates] = useState(getHistoryStates)

            useEffect(
                () => {
                    const handleHistoryChange = () => {
                        setStates(getHistoryStates())
                    }

                    subscribe(history, 'change', handleHistoryChange)

                    return () => {
                        unsubscribe(history, 'change', handleHistoryChange)
                    }
                },

                []
            )

            return states
        }
    })

    const watchers = {
        task_finish() {
            if (0 < this.selectedNodes.size) {
                const ids1 = new Set(
                    [...this.selectedNodes].map(e => e.id)
                )

                if (-1 < history.cursor) {
                    if (! history.peek()) {
                        history.back()
                    }

                    const ids2 = history.peek()

                    if (! (
                        ids1.isSubsetOf(ids2) &&
                        ids1.isSupersetOf(ids2)
                    )) {
                        history.push(ids1)
                    }
                }
                else {
                    history.push(ids1)
                }
            }
            else if (history.cursor < history.end) {
                history.forward()
            }
            else {
                if (! history.peek()) {
                    history.push(null)
                }
            }
        },
    }

    return {extensions, watchers}
}
