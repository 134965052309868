import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    vsName: {
        name: () => '虚拟机名称',
        required: () => true,
    },

    vsDesc: {
        name: () => '虚拟机描述',
    },

    vsMemo: {
        name: () => '备注',
    },

    vsStatus: {
        name: () => '运行状态',
    },

    vsBu: {
        name: () => 'BU',
    },

    vsDm: {
        name: () => '开发负责人',
    },

    vsOm: {
        name: () => '运维负责人',
    },

    vsEnv: {
        name: () => '环境',
    },

    vsHostname: {
        name: () => '主机名',
    },

    vsIntranetIp: {
        name: () => '内网IP',
    },

    vsInternetIp: {
        name: () => '公网IP',
    },

    vsOs: {
        name: () => '操作系统',
    },

    vsKernelVersion: {
        name: () => '内核版本',
    },

    vsOpenPort: {
        name: () => '开放端口',
    },

    vsCpu: {
        name: () => 'CPU',
    },

    vsMemory: {
        name: () => '内存',
    },

    vsHardDisk: {
        name: () => '硬盘',
    },

    vsMac: {
        name: () => 'MAC',
    },
})
