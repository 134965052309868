import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootDm.jsx'
import meta from './metaCatRootDm.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(CDG|CDT|DT|DV)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'DT'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['DT'],
                ['DV'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DT'],
                ['DV'],
                ['CDT'],
                ['CDG'],
            ]
        },
    })
}
