import {publish} from '@/script/event.mjs'

export default class History {
    #cursor = -1
    #end = -1
    #states = []

    get cursor() {
        return this.#cursor
    }

    set cursor(cursor) {
        this.#cursor = cursor
        publish(this, 'change', this)
    }

    get end() {
        return this.#end
    }

    get states() {
        return this.#states.slice(0, this.#end)
    }

    back() {
        const state = this.peek()
        this.cursor -= 1
        return state
    }

    forward() {
        const state = this.peek()
        this.cursor += 1
        return state
    }

    peek(cursor = this.#cursor) {
        return this.#states[cursor]
    }

    push(state) {
        this.cursor += 1
        this.#end = this.#cursor
        this.#states[this.#cursor] = state
    }
}
