export default (base, n) => {
    const type = typeof n

    if ('number' === type) {
        return n
    }
    else if (
        'string' === type &&
        n.endsWith('%')
    ) {
        return Number(n.slice(0, -1)) * base / 100
    }
    else {
        return 0
    }
}
