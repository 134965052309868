import {Button} from 'antd'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {chooseFiles} from '@/script/file.mjs'
import {getExt} from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'
import http from '@/biz/http.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonUpload = (props) => {
    const readOnly = useReadOnly()
    const [values, update] = useProperties()
    const map = useMapContext()
    const {prjId} = map.data

    if (readOnly) {
        return null
    }

    const handleClick = async () => {
        const [file] = await chooseFiles.image()

        if (file) {
            const {fileId} = await runAsync(
                () => http.upload({file, pathName: prjId}),
                {action: '上传图片'}
            )

            const {name, type} = file
            const ext = getExt(name)

            const text = values.text === map.BizNode.PIC.name ?
                name : values.text.replace(/\.[^.]*$/, `.${ext}`)

            update({ext, fileId, text, type})
        }
    }

    return (
        <Button
            {...props}
            onClick={handleClick}
        >上传</Button>
    )
}

export default PropertiesPaneButtonUpload
