import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

export default function MenuButtonPublishProduct(props) {
    const map = useMapContext()
    const permission = map.usePermission()
    map.useNodeChange(map.root, ['data'])
    const {canPublishProduct, publishProduct} = map.commands
    const nodes = canPublishProduct([map.root])

    if (
        0 === nodes.length ||
        ! permission.has('write')
    ) {
        return null
    }

    const handleClick = () => publishProduct(nodes)

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            发布制品
        </MenuButton>
    )
}
