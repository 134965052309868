import runAsync from '@/script/runAsync.mjs'
import apiSnapshot from '../../apis/apiSnapshot.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default pkid => {
    const {data} = useSWR(
        `/maps/${pkid}/snapshot`,

        () => runAsync(
            () => apiSnapshot.read({pkid}),
            {action: '读取地图'}
        )
    )

    if (data) {
        return parseMap(data)
    }
    else {
        return void 0
    }
}
