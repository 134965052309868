import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemUiName = (props) => {
    return (
        <Form.Item
            label="界面名称"
            name="uiName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemUiCode = (props) => {
    return (
        <Form.Item
            label="界面代码"
            name="uiUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAlgCode = (props) => {
    return (
        <Form.Item
            label="能力代码"
            name="algUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAlgTitle = (props) => {
    return (
        <Form.Item
            label="能力名称"
            name="algTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAlgType = (props) => {
    return (
        <Form.Item
            label="能力类型"
            name="algType"
            {...props}
        >
            <Select
                allowClear

                options={[
                    {label: '基础', value: 'GENERAL'},
                    {label: '事件', value: 'UE'}
                ]}
            />
        </Form.Item>
    )
}

const FormChooseUe_ = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={5}>
                <FormItemUiName />
            </Col>

            <Col span={5}>
                <FormItemUiCode />
            </Col>

            <Col span={5}>
                <FormItemAlgTitle />
            </Col>

            <Col span={5}>
                <FormItemAlgCode />
            </Col>

            <Col span={4}>
                <FormItemAlgType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseUe_
