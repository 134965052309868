import useSWR from 'swr/immutable'
import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import SidePane from '../../SidePanel/SidePane.jsx'
import {PropertiesContext} from '../../SidePanel/RightPanel/Properties.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'

const useUm = (umId) => {

    const fetchUm = (umId) => runAsync(
        () => http.get(`/archums/${umId}`),

        {
            error: {
                content: (err) => `读取界面入口失败: ${err.message}`,
            },

            loading: {
                content: '正在读取界面入口，请稍候',
            },
        }
    )

    const {data} = useSWR(umId, fetchUm)
    return data
}

const PropertiesPaneCatRootUm = ({nodes, ...props}) => {
    const um = useUm(nodes[0].data.umId)

    if (! um) {
        return null
    }

    return (
        <PropertiesContext.Provider value={[um]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane {...props}>
                    <Text
                        label="界面入口代码"
                        prop="umUserCode"
                    />

                    <Text
                        label="界面入口名称"
                        prop="umName"
                    />
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}

export default PropertiesPaneCatRootUm
