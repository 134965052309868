import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dbCode: {
        name: () => '数据库',
    },

    dmCode: {
        name: () => '库表域',
    },

    dtUserCode: {
        name: () => '表代码',
        required: () => true,
    },

    dtName: {
        name: () => '表名称',
        required: () => true,
    },

    dtAlias: {
        name: () => '别名',
    },

    dtDesc: {
        name: () => '表说明',
    },

    memo: {
        name: () => '备注',
    },
})
