import http from '@/biz/http.mjs'

export default () => {
    return (map, nodes) => {
        if (1 < nodes.size) {
            return []
        }

        const onClick = async () => {
            const [node] = nodes
            const lines = []
            const {bizNodeType, desNo, taskNo} = node.data

            const url = await (async () => {
                const url = await map.BizNode[bizNodeType].getUrl(map, node)
                const long = location.origin + url

                try {
                    const {shortUrl} = await http.post(
                        '/createBasSu', {suOlu: long}
                    )

                    return shortUrl
                }
                catch (err) {
                    map.logger.warn(`生成短链接失败: ${err.message}`, nodes)
                    return long
                }
            })()

            lines.push(url)
            const text = map.BizNode[bizNodeType].getTextRaw(map, node)
            lines.push(`md: ${desNo}/${text}`)
            lines.push(`req: ${taskNo}`)

            const bdsummaries = new Map
            const sfs = new Map

            const next = (chain) => {
                const _n = map.nodeProxy(chain[0])
                const yieldChildren = _n.isMounted()
                const yieldNode = _n.isLinked()
                return {yieldChildren, yieldNode}
            }

            for (const n of map.walkDown(node, {excludeTarget: true, next})) {
                const {bizNodeType, pkid} = n.data

                if ('BDSUMMARY' === bizNodeType) {
                    bdsummaries.set(pkid, n)
                }
                else if ('SF' === bizNodeType) {
                    sfs.set(pkid, n)
                }
            }

            const extractLines = (key, nodeMap) => {
                const extractNode = node => {
                    const {bizNodeType, dpSn, rev, sVer} = node.data
                    const text = map.BizNode[bizNodeType].getTextRaw(map, node)
                    return `${dpSn}/${sVer}.${rev}/${text}`
                }

                return [...nodeMap.values()]
                    .sort((a, b) => a.data.dpSn - b.data.dpSn)
                    .map(n => `${key}: ${extractNode(n)}`)
            }

            const bdsummaryLines = extractLines('story', bdsummaries)
            lines.push(...bdsummaryLines)
            const sfLines = extractLines('feat', sfs)
            lines.push(...sfLines)
            const message = lines.join('\n')
            await navigator.clipboard.writeText(message)
            map.logger.info('节点提交信息已拷贝到剪贴板', [node])
        }

        return [
            {
                key: 'extract-commit',
                label: '提取提交信息',
                onClick,
            }
        ]
    }
}
