import bizNodeModel from '../../bizNodeModel.mjs'

const typeName = dpType => 'SVR' === dpType ? '服务' : '对象'

export default bizNodeModel({
    bmCode: {
        name: () => '业务域',
    },

    bdName: {
        name: ({dpType}) => `业务${typeName(dpType)}名称`,
        required: () => true,
    },

    bdNo: {
        name: ({dpType}) => `业务${typeName(dpType)}代码`,
        required: () => true,
    },

    bdType: {
        name: ({dpType}) => `业务${typeName(dpType)}类型`,
        required: () => true,
    },

    dpType: {
        name: () => '类别',
    },

    memo: {
        name: () => '备注',
    },

    ssCode: {
        name: () => '子系统',
    },
})
