import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getItems = (map) => {
    const next = (chain) => {
        const [node] = chain
        const {delFlag, stsCode} = node.data

        const isInvalid = (
            '1' === delFlag ||
            'DISCARD' === stsCode
        )

        const isHidden = map.isNodeHidden(node)

        return {
            yieldChildren: ! isInvalid && ! isHidden,
            yieldNode: isInvalid && ! isHidden,
        }
    }

    return [...map.walkDown(map.root, {excludeTarget: true, next})].map(
        node => {
            const text = map.getNodeText(node)
            const prefix = map.getNodeTextPrefix(node)
            const suffix = map.getNodeTextSuffix(node)
            const title = [prefix, text, suffix].join('') || '(空白)'
            return {key: node.id, title}
        }
    )
}

export default () => {
    const map = useMapContext()
    const [items, setItems] = useState(() => getItems(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                map.logger.withOff(
                    () => setItems(getItems(map))
                )
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return items
}
