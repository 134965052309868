import {useMapContext} from '@/components/MindMap/index.mjs'
import {toTree} from '../../compatibility.mjs'

export const getDataId = (map) => {
    if (map.data.pkid) {
        return map.data.pkid
    }
    else if (map.data.id) {
        return map.data.id
    }
    else {
        return map.root.data.pkid
    }
}

export const useMakeSnapshot = () => {
    const map = useMapContext()

    return ({mapName, memo}) => {
        const _n = map.nodeProxy(map.root)
        const tree = _n.exportTree()

        const mapData = {
            data: {...map.data},
            root: toTree(tree),
        }

        const {mapNo, mapTypeCode, prjId, sVer} = map.data
        const dataId = getDataId(map)
        const args = {dataId, memo, prjId, sVer}

        if (mapName) {
            mapData.data.mapName = mapName
        }

        const dataType = mapTypeCode.replace(/^_([A-Z]+)_MAP/, '$1')

        return Object.assign(args, {
            dataMap: JSON.stringify(mapData),
            dataName: mapData.data.mapName,
            dataNo: mapNo,
            dataType,
        })
    }
}
