import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    kmTitle: {
        name: () => '标题',
    },

    kmKey: {
        name: () => '关键字',
    },

    memo: {
        name: () => '备注',
    },
})
