import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function ButtonUpgrade({node, ...props}) {
    const map = useMapContext()
    const permission = map.usePermission()
    const _n = map.nodeProxy(node)

    if (! (
        permission.has('write') &&
        _n.canUpgrade?.()
    )) {
        return null
    }

    const handleClick = () => map.cmd(() => _n.upgrade())

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            升级
        </Button>
    )
}
