import {useEffect, useRef} from 'react'
import {Alert, useOpenModal} from '@/components/Modal/Modal.jsx'
import Snapshots from '@/pages/MainPage/frames/MapVer/MapVer.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const ModalSnapshots = ({id, prjId, type, ...props}) => {
    const refEl = useRef()

    useEffect(
        () => {
            refEl.current.querySelector('header').remove()
        },

        []
    )

    return (
        <Alert
            title="发布版本"
            width={1200}
            {...props}
        >
            <div ref={refEl}>
                <Snapshots
                    id={id}
                    prjId={prjId}
                    type={type}
                />
            </div>
        </Alert>
    )
}

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()

    const {
        mapTypeCode,
        pkid,
        prjId,
    } = map.data

    return {
        key: 'list-snapshots',
        label: '发布版本',

        onClick: () => openModal(
            <ModalSnapshots
                id={pkid}
                prjId={prjId}
                type={mapTypeCode}
            />
        ),
    }
}
