function selectAllNodes() {
    this.commands.selectAllNodes()
}

function selectRoot() {
    this.commands.selectRoot()
}

export default {
    'Home': selectRoot,
    'Ctrl+A': selectAllNodes,
    'Meta+A': selectAllNodes,
}
