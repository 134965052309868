export default [
    ['INI', '界面初始化'],
    ['CLOSE', '界面关闭'],
    ['BTN', '点击按钮'],
    ['LINK', '点击链接'],
    ['ROW', '点击列表行'],
    ['DBLROW', '双击列表行'],
    ['FOCUS', '获取焦点'],
    ['MENU', '导航菜单'],
    ['PUA', '父界面能力'],
    ['OTH', '其他'],
]
