import {Col, Form, Input, Row} from 'antd'
import {useMidType} from '@/biz/bizTable.mjs'
import Select from '@/components/Form/Select.jsx'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemMiddlewareName = (props) => {
    return (
        <Form.Item
            label="中间件名称"
            name="middlewareName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemMiddlewareType = (props) => {
    const {pairs: midTypePairs} = useMidType()

    return (
        <Form.Item
            label="中间件类型"
            name="middlewareType"
            {...props}
        >
            <Select
                allowClear
                mapOption={{label: e => e.middlewareTypeName}}
                options={midTypePairs}
            />
        </Form.Item>
    )
}

const FormItemMiddlewareInsName = (props) => {
    return (
        <Form.Item
            label="中间件实例名称"
            name="middlewareInsName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemIp = (props) => {
    return (
        <Form.Item
            label="IP"
            name="ip"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseMidins = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemMiddlewareName />
            </Col>

            <Col span={6}>
                <FormItemMiddlewareType />
            </Col>

            <Col span={6}>
                <FormItemMiddlewareInsName />
            </Col>

            <Col span={6}>
                <FormItemIp />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseMidins
