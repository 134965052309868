import runAsync from '@/script/runAsync.mjs'
import useProject from '../useProject.mjs'
import apiMap from '../../apis/apiMap.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (pkid) => {
    const {data: mapData} = useSWR(
        `/maps/${pkid}`,

        () => runAsync(
            () => apiMap.read({pkid}),
            {action: '读取地图'}
        )
    )

    const {data: project} = useProject(mapData?.prjId)

    if (mapData && project) {
        const map = parseMap(mapData)
        Object.assign(map.data, project)
        return map
    }
    else {
        return void 0
    }
}
