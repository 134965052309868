import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dbUserCode: {
        name: () => '数据库代码',
        required: () => true,
    },

    dbName: {
        name: () => '数据库名称',
        required: () => true,
    },

    dbmsCode: {
        name: () => '数据库管理系统',
        required: () => true,
    },

    dbDesc: {
        name: () => '数据库说明',
    },

    softwareDesc: {
        name: () => '软件环境说明',
    },

    hardwareDesc: {
        name: () => '硬件环境说明',
    },

    networkDesc: {
        name: () => '网络环境说明',
    },

    deployIp: {
        name: () => '部署IP',
    },

    deployDesc: {
        name: () => '部署说明',
    },
})
