import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()
    const {canPublishMap, publishMap} = map.commands

    return {
        disabled: ! canPublishMap(),
        key: 'publish-map',
        label: '发布',
        onClick: publishMap,
    }
}
