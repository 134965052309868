import {useEffect, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

class Logger {
    constructor(doc, limit = 100) {
        this.#doc = doc
        this.#limit = limit
    }

    get logs() {
        return this.#logs
    }

    clear() {
        this.#logs.length = 0
        publish(this.#doc, 'logs_change', this.logs)
    }

    debug(text, nodes) {
        this.#write('debug', text, nodes)
    }

    error(text, nodes) {
        this.#write('error', text, nodes)
    }

    info(text, nodes) {
        this.#write('info', text, nodes)
    }

    warn(text, nodes) {
        this.#write('warn', text, nodes)
    }

    async withOff(fn) {
        return this.#with(false, fn)
    }

    async withOn(fn) {
        return this.#with(true, fn)
    }

    #doc
    #isOn = false
    #limit
    #logs = []

    async #with(isOn, fn) {
        const savedIsOn = this.#isOn

        try {
            this.#isOn = isOn
            return await fn()
        }
        finally {
            this.#isOn = savedIsOn
        }
    }

    #write(level, text, nodes) {
        if (! this.#isOn) {
            return
        }

        this.#logs.unshift({
            level,
            nodes,
            text,
            timestamp: Date.now(),
        })

        if (this.#limit < this.#logs.length) {
            this.#logs.length = this.#limit
        }

        publish(this.#doc, 'logs_change', this.logs)
    }
}

export default (limit) => {
    const extensions = (doc) => {
        const logger = new Logger(doc, limit)

        return {
            logger,

            useLogs() {
                const [, setFlag] = useState(false)

                useEffect(
                    () => {
                        const handleChange = () => setFlag(f => !f)
                        subscribe(this, 'logs_change', handleChange)

                        return () => {
                            unsubscribe(this, 'logs_change', handleChange)
                        }
                    },

                    []
                )

                return logger.logs
            },
        }
    }

    return {extensions}
}
