import SelectFmDialog from '@/pages/MainPage/dialogs/SelectFmDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectDm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({fmName}) => fmName).join(',') :
        (value ?? {}).fmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectFmDialog}
            {...props}
        />
    )
}
