import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'BASE',
    name: '业务基础方法',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    type: 'BDBASE',
}
