import {Button, Modal} from 'antd'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {fileFromClipboard} from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'
import http from '@/biz/http.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonPasteUpload = (props) => {
    const readOnly = useReadOnly()
    const map = useMapContext()
    const [values, update] = useProperties()

    if (readOnly) {
        return null
    }

    const handleClick = async () => {
        const ext = 'png'
        const type = 'image/png'

        const file = await (async () => {
            try {
                return await fileFromClipboard(
                    type,
                    `${crypto.randomUUID()}.${ext}`
                )
            }
            catch (err) {
                Modal.error({content: err.message})
                throw err
            }
        })()

        if (! file) {
            Modal.error({content: '未从剪贴板读取到图片数据'})
            return
        }

        const {fileId} = await runAsync(
            () => http.upload({file, pathName: map.data.prjId}),
            {action: '上传图片'}
        )

        const defaultName = map.BizNode.PIC.name

        const text = values.text === defaultName ?
            `${defaultName}.${ext}`
            :
            values.text.replace(/\.[^.]*$/, `.${ext}`)

        update({ext, fileId, text, type})
    }

    return (
        <Button
            {...props}
            onClick={handleClick}
        >粘贴上传</Button>
    )
}

export default PropertiesPaneButtonPasteUpload
