import {css} from '@emotion/react'
import {Input} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'
import cssInputable from './cssInputable.mjs'
import useInputable from './useInputable.mjs'

const cssInputPassword = css({
    ...cssInputable,
    color: 'inherit',
    textAlign: 'inherit',
})

const InputPassword = ({
    allowClear,
    changeDelay,
    focus,
    parse,
    readOnly,
    selectOnFocus,
    test,
    transform,
    value,
    onChange,
    onBlur,
    onInput,
    ...props
}) => {
    const gReadOnly = useReadOnly()

    const {
        callbackRef,
        handleBlur,
        handleClick,
        handleCompositionEnd,
        handleCompositionStart,
        handleInput,
        inputValue,
    } = useInputable({
        allowClear,
        changeDelay,
        focus,
        parse,
        readOnly: readOnly || gReadOnly,
        selectOnFocus,
        test,
        transform,
        value,
        onChange,
        onBlur,
        onInput,
    })

    return (
        <div
            ref={callbackRef}
            style={{display: 'contents'}}
            onClick={handleClick}
        >
            <Input.Password
                css={cssInputPassword}
                allowClear={allowClear}
                value={inputValue}
                onBlur={handleBlur}
                onCompositionEnd={handleCompositionEnd}
                onCompositionStart={handleCompositionStart}
                onInput={handleInput}
                {...props}
            />
        </div>
    )
}

export default InputPassword
