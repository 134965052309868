import SelectDmDialog from '@/pages/MainPage/dialogs/SelectDmDialogNew'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectDm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({dmName}) => dmName).join(',') :
        (value ?? {}).dmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectDmDialog}
            {...props}
        />
    )
}
