import {memo, useEffect, useRef} from 'react'
import {css} from '@emotion/react'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const cssNodeText = css({
    boxSizing: 'content-box',
    position: 'relative',
    maxWidth: 300,
    lineHeight: 1.2,
    textDecorationThickness: '0.1em',
    whiteSpace: 'pre-wrap',

    '& :empty::before': {
        content: '"\u200d"',
    },

    '& :focus-visible': {
        outline: 'none',
    },
})

const InputText = ({isEditing, node, text, ...props}) => {
    const map = useTreeDocContext()
    const refEl = useRef()
    const refLastText = useRef()

    useEffect(
        () => {
            // 必须异步执行否则使用 Tab 键产生的新节点无法获取焦点
            setTimeout(() => {
                refEl.current?.focus({preventScroll: true})
            })

            if (isEditing) {
                refLastText.current = text
                refEl.current.textContent = text
                const range = document.createRange()
                range.selectNodeContents(refEl.current)
                const defaultText = map.getDefaultNodeText(node)

                if (defaultText !== text) {
                    range.collapse(false)
                }

                const selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(range)
            }
        },

        [isEditing]
    )

    const cancelEditText = () => {
        refEl.current.textContent = refLastText.current
        map.behaviours.editNodeText(node, refLastText.current)
        map.behaviours.finishEditNodeText(node)
    }

    const finishEditText = () => {
        refEl.current.textContent = text
        map.behaviours.finishEditNodeText(node)
    }

    const handleBlur = isEditing ? finishEditText : undefined

    const handleInput = e => {
        if (e.nativeEvent.isComposing) {
            return
        }

        map.behaviours.editNodeText(node, e.target.textContent)
    }

    const handleKeyDown = (e) => {
        if (isEditing) {
            // 避免与其他快捷键冲突
            e.stopPropagation()

            if (e.nativeEvent.isComposing) {
                return
            }

            // Enter/Esc 结束编辑
            if (! (e.ctrlKey || e.shiftKey)) {
                if ('Enter' === e.key) {
                    e.preventDefault()
                    finishEditText()
                }
                else if ('Escape' === e.key) {
                    e.preventDefault()
                    cancelEditText()
                }
            }
        }
        // 非编辑模式下输入非控制字符清空文本并进入编辑模式
        else if (
            'Process' === e.key ||

            (
                ! e.altKey &&
                ! e.ctrlKey &&
                ! e.metaKey &&
                1 === e.key.length
                ///^[\w `\-=[\]\\;',./~!@#$%^&*()_+{}|:"<>?]$/.test(e.key)
            )
        ) {
            const isDefaultPrevented = map.behaviours.startEditNodeText(
                node, ''
            )

            //if (isDefaultPrevented) {
                //e.preventDefault()
            //}
        }
        //else {
            //e.preventDefault()
        //}
    }

    const style = isEditing ?
        {}
        :
        {
            position: 'absolute',
            zIndex: -1,
            opacity: 0,
            pointerEvents: 'none',
        }

    return (
        <div
            ref={refEl}
            style={style}
            contentEditable
            suppressContentEditableWarning
            onBlur={handleBlur}
            onCompositionEnd={handleInput}
            onDoubleClick={e => e.stopPropagation()}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onPointerDown={e => e.stopPropagation()}
            {...props}
        >
        </div>
    )
}

const InputTextWrapper = ({node, ...props}) => {
    const map = useTreeDocContext()
    const isSelected = map.useIsNodeSelected(node)
    const canEditNodeText = map.behaviours.canEditNodeText(node)

    if (
        ! isSelected ||
        ! canEditNodeText ||
        1 !== map.selectedNodes.size
    ) {
        return null
    }

    return (
        <InputText
            node={node}
            {...props}
        />
    )
}

const Text = ({isEditing, text, ...props}) => {
    if (isEditing) {
        return null
    }

    return <div {...props}>{text}</div>
}

const NodeText = ({node, ...props}) => {
    const map = useTreeDocContext()
    map.useNodeChange(node, ['data'])
    const isEditing = map.useIsEditingText(node)
    const style = map.getNodeTextStyle(node)
    const text = map.getNodeText(node)

    return (
        <div
            css={cssNodeText}
            style={style}
            {...props}
        >
            <InputTextWrapper
                isEditing={isEditing}
                node={node}
                text={text}
            />

            <Text
                isEditing={isEditing}
                text={text}
            />
        </div>
    )
}

const MemorizedNodeText = memo(NodeText, () => true)
MemorizedNodeText.displayName = 'NodeText'

export default MemorizedNodeText
